import styled, { css } from 'styled-components';

interface ContainerProps {
  isErrored: boolean;
  dateInfoValidation?: boolean;
}

export const Container = styled.div<ContainerProps>`
  background: var(--white);
  width: 100%;

  border: 1px solid var(--light-gray);
  color: var(--gray);
  height: 39px;

  display: flex;
  align-items: center;

  & + div {
    margin-top: 8px;
  }

  ${props =>
    props.dateInfoValidation &&
    css`
      width: 100%;
      float: left;
      margin-right: 8px;
      border: 1px solid #ffe76c;
    `}

  ${props =>
    props.isErrored &&
    css`
      border-color: var(--red);
    `}

  input {
    flex: 1;
    background: transparent;
    border: 0;
    color: var(--gray);
    font-size: 1.2rem;
    padding: 8px 15px;
    width: 100%;

    &::placeholder {
      color: var(--light-gray);
    }

    &.input-lg {
      padding: 14px 15px;
    }
  }

  svg {
    margin-right: 16px;
  }

  .test {
    width: 50% !important;
  }

  .react-datepicker-popper,
  .react-datepicker,
  .react-datepicker__month-container {
    min-width: 200px;
  }

  .react-datepicker {
    font-size: 1.1rem;

    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
      font-family: var(--primary-font);
      font-size: 1.2rem;
      margin-bottom: 5px;
      color: var(--black);
    }

    .react-datepicker__month-select {
      color: var(--secondary);
      border: 0;
      padding: 2px;
      font-family: var(--primary-font);
    }
    .react-datepicker__year-select {
      color: var(--secondary);
      border: 0;
      padding: 2px;
      font-family: var(--primary-font);
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      width: 2.3rem;
      line-height: 2.3rem;
    }
  }

  & :disabled {
    //background-color: var(--bs-gray-200);
    cursor: not-allowed !important;
  }
`;
