import styled from 'styled-components';

export const Container = styled.div`
  background: var(--white);
  border: 1px solid var(--light-gray);
  box-shadow: var(--shadow);

  position: absolute;
  top: 39px;

  padding: 10px 15px;
  min-width: 180px;
  z-index: 10;

  ul {
    list-style: none;

    > li {
      color: var(--gray);
      padding: 5px 0;
      cursor: pointer;

      &:hover {
        color: var(--secondary);
      }

      &.active {
        display: flex;
        align-items: center;
        justify-content: space-between;

        svg {
          color: var(--green);
          flex-shrink: 0;
          margin-left: 10px;
          margin-bottom: 3px;
        }
      }
    }
  }
`;
