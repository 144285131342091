import React, { FC, useState } from 'react';
import Modal from 'react-modal';
import { MdClose } from 'react-icons/md';
import { Form } from '@unform/web';
import {
  IReportErrorModal,
  IErrorsSelectOptions,
  LoadingProps,
} from '../../shared/interfaces';
import { errorOptions } from '../../shared/constants';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';
import { ReactSelect } from '../../components/Select/styles';
import Loading from '../../components/Loading';
import Button from '../../components/Button';
import {
  AddFileContainer,
  Container,
  ErrorMessage,
  ModalContent,
  ModalHeader,
  RadioContainer,
  SelectContainer,
  TextAreaContainer,
  WarningContainer,
} from './styles';

const ButtonWithLoading: React.FC<LoadingProps> = ({
  isLoading,
  children,
  ...otherProps
}) => {
  if (isLoading) {
    return (
      <Button
        type="button"
        loading
        className="btn-lg"
        style={{
          width: '100px',
          marginLeft: '370px',
          height: '39px',
          padding: '0',
        }}
      >
        <Loading loading color="var(--white)" />
      </Button>
    );
  }
  return (
    <Button {...otherProps} disabled={isLoading}>
      {children}
    </Button>
  );
};

const ReportErrorModal: FC<IReportErrorModal> = ({
  isOpen,
  setIsReportErrorModalOpened,
  itemType,
  campusId,
  campusName,
  itemId,
  itemName,
}) => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const [formErrorMessage, setFormErrorMessage] = useState<string>('');
  const [valueOrSRError, setValueOrSRError] = useState<string>('valueError');
  const [typeError, setTypeError] = useState<IErrorsSelectOptions | null>(null);
  const [errorDescribe, setErrorDescribe] = useState<string>();
  const [file, setFile] = useState<File>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValueOrSRError(e.target.value);
    setTypeError(null);
  };

  const handleSelectChange = (value: IErrorsSelectOptions) => {
    setTypeError(value);
    setFormErrorMessage('');
  };

  const handleDescribeChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setErrorDescribe(e.target.value);
    setFormErrorMessage('');
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormErrorMessage('');
    if (e.target.files !== null) {
      setFile(e.target.files[0]);
    }
  };

  const handleSubmit = async () => {
    if (!typeError) {
      setFormErrorMessage('Select a error type.');
      return;
    }
    if (!errorDescribe) {
      setFormErrorMessage('Describe how it should work.');
      return;
    }

    const formData = new FormData();

    if (file) {
      formData.append('file', file, file.name);
    }
    formData.append('valueOrSRError', valueOrSRError);
    formData.append('type', typeError.label);
    formData.append('description', errorDescribe);
    formData.append('campus_id', campusId.toString());
    formData.append('campus_name', campusName);
    formData.append('item_id', itemId.toString());
    formData.append('item_name', itemName);
    formData.append('item_type', itemType);
    formData.append('user_id', user.id.toString());
    formData.append('user_name', user.name);
    formData.append('account_id', user.account_id.toString());
    formData.append('account_name', user.office.account.agency_name);
    formData.append('office_id', user.office_id.toString());
    formData.append('status', 'pending');

    setIsLoading(true);
    try {
      const response = await api.post('/priceerror', formData);
      if (response.status === 200) {
        addToast({
          title: 'Thanks for your feedback!',
          type: 'success',
          description:
            'The reported error will be carefully analyzed and the answer is going to appear in your notifications soon.',
        });
      }
    } catch (e: any) {
      addToast({
        title: 'Error',
        type: 'error',
        description:
          'Sorry, we could not save yuor input. Please, try again later.',
      });
      throw new Error(e.message);
    } finally {
      setIsLoading(false);
      setIsReportErrorModalOpened(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsReportErrorModalOpened(false)}
      style={{
        overlay: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          backgroundColor: 'var(--overlay)',
        },
        content: {
          padding: 0,
          borderRadius: 0,
          position: 'initial',
          boxShadow: 'var(--modal-shadow)',
          border: '1px solid var(--divider)',
          width: '500px',
          overflowX: 'hidden',
        },
      }}
    >
      <Form onSubmit={handleSubmit}>
        <Container>
          <ModalHeader>
            <span>Report price error</span>
            <Button
              type="button"
              className="btn-transparent"
              onClick={() => setIsReportErrorModalOpened(false)}
            >
              <MdClose size={20} />
            </Button>
          </ModalHeader>

          <ModalContent>
            <WarningContainer>
              <h3>Warning!</h3>
              <span>
                Be careful not to confuse outdated values with wrong values
              </span>
            </WarningContainer>

            <ErrorMessage>
              <span>{formErrorMessage}</span>
            </ErrorMessage>

            <SelectContainer>
              <span style={{ fontWeight: 700 }}>What error did you find?</span>

              <RadioContainer>
                <label htmlFor="valueError">
                  <input
                    value="valueError"
                    type="radio"
                    id="valueError"
                    name="typeError"
                    defaultChecked={valueOrSRError === 'valueError'}
                    onChange={handleRadioChange}
                  />
                  <span className="checkmark" />
                  <span className="radio-text">Value error</span>
                </label>

                <label
                  style={{
                    marginRight: '20px',
                    marginTop: '0px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  htmlFor="SRError"
                >
                  <input
                    value="SRError"
                    type="radio"
                    id="SRError"
                    name="typeError"
                    defaultChecked={valueOrSRError === 'SRError'}
                    onChange={handleRadioChange}
                  />
                  <span className="checkmark" />
                  <span className="radio-text">Special Rate error</span>
                </label>
              </RadioContainer>

              <ReactSelect
                defaultValue={null}
                value={typeError}
                placeholder="Error type"
                options={errorOptions[valueOrSRError]}
                styles={{
                  menuList: (baseStyles: any) => ({
                    ...baseStyles,
                    maxHeight: '170px',
                    scrollBehavior: 'smooth',
                  }),
                }}
                theme={(theme: object) => ({
                  ...theme,
                  borderRadius: 0,
                })}
                onChange={handleSelectChange}
              />
            </SelectContainer>

            <TextAreaContainer>
              <span style={{ fontWeight: 700 }}>
                Describe how it should be:
              </span>

              <textarea
                rows={2}
                value={errorDescribe}
                onChange={handleDescribeChange}
              />
            </TextAreaContainer>

            <AddFileContainer>
              <span style={{ fontWeight: 700 }}>Add file:</span>
              <label htmlFor="reportFile">
                Choose file
                <input
                  id="reportFile"
                  type="file"
                  onChange={handleFileUpload}
                  style={{
                    display: 'none',
                  }}
                />
              </label>
              <span>{file?.name}</span>
            </AddFileContainer>

            <ButtonWithLoading
              className=""
              isLoading={isLoading}
              style={{
                width: '100px',
                marginLeft: '370px',
              }}
              type="submit"
            >
              Report
            </ButtonWithLoading>
          </ModalContent>
        </Container>
      </Form>
    </Modal>
  );
};

export default ReportErrorModal;
