const formatPrice = (
  value: number,
  currency?: string,
  allyPlus = false
): string => {
  const digits = allyPlus ? 4 : 2;

  return currency
    ? Number(value).toLocaleString('pt-br', {
        style: 'currency',
        currency,
        maximumFractionDigits: digits,
        minimumFractionDigits: digits,
      })
    : Number(value).toLocaleString('pt-br', {
        maximumFractionDigits: digits,
        minimumFractionDigits: digits,
      });
};

export default formatPrice;
