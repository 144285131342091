import React, { ButtonHTMLAttributes } from 'react';

import Loading from '../Loading';
import Tooltip from '../Tooltip';

import { Container } from './styles';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  loading?: boolean;
  tooltip?: string;
  loadingColor?: string;
  loadingWidth?: string | number;
};

const Button: React.FC<ButtonProps> = ({
  children,
  loading,
  tooltip,
  loadingColor,
  loadingWidth,
  ...rest
}) => (
  <Tooltip title={tooltip}>
    <Container type="button" disabled={loading} {...rest}>
      {loading ? (
        <Loading width={loadingWidth} loading={loading} color={loadingColor} />
      ) : (
        children
      )}
    </Container>
  </Tooltip>
);

export default Button;
