import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IconBaseProps } from 'react-icons';

import Button from '../Button';
import DropdownList from '../DropdownList';
import DropdownCard from '../DropdownCard';

import { Container } from './styles';

interface DropdownListItem {
  label: string;
  active: boolean;
  display?: 'block' | 'none';
}

interface ButtonWithDropdownProps {
  list?: DropdownListItem[];
  icon?: React.ComponentType<IconBaseProps>;
  iconClassName?: string;
  onChangeSortOption?: (index: number) => void;
  onDropdownOptionClick?: (index: number) => void;
}

const ButtonWithDropdown: React.FC<ButtonWithDropdownProps> = ({
  list,
  icon: Icon,
  iconClassName,
  onChangeSortOption,
  onDropdownOptionClick,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdown = useRef<HTMLDivElement>(null);

  const handleOpenDropdown = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleClick = useCallback(
    event => {
      if (
        !event.target.classList.value.includes('range') &&
        !event.target.classList.value.includes('switch') &&
        !event.target.classList.value.includes('title') &&
        !event.target.classList.value.includes('dropdown-card') &&
        !event.target.closest(`.${dropdown.current?.className}`) &&
        isOpen
      ) {
        setIsOpen(false);
      }
    },
    [isOpen]
  );

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [handleClick]);

  return (
    <Container ref={dropdown} className="dropdown">
      <Button className="btn-dropdown" onClick={handleOpenDropdown}>
        {Icon && <Icon size={16} className={iconClassName} />}
      </Button>
      {list && isOpen && (
        <DropdownList
          list={list}
          onChangeSortOption={onChangeSortOption}
          onDropdownOptionClick={onDropdownOptionClick}
        />
      )}
      {!list && isOpen && <DropdownCard>{children}</DropdownCard>}
    </Container>
  );
};

export default ButtonWithDropdown;
