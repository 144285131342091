import React, { useCallback } from 'react';
import { FiCheck } from 'react-icons/fi';

import { Container } from './styles';

interface DropdownListItem {
  label: string;
  active: boolean;
  display?: 'block' | 'none';
}

interface DropdownListProps {
  list: DropdownListItem[];
  onChangeSortOption?: (index: number) => void;
  onDropdownOptionClick?: (index: number) => void;
}

const DropdownList: React.FC<DropdownListProps> = ({
  list,
  onChangeSortOption,
  onDropdownOptionClick,
}) => {
  const onOptionClick = useCallback(
    (index: number) => {
      if (onChangeSortOption) onChangeSortOption(index);
      else if (onDropdownOptionClick) onDropdownOptionClick(index);
    },
    [onChangeSortOption, onDropdownOptionClick]
  );

  return (
    <Container>
      <ul>
        {list.map((item, index) => (
          <li
            key={index}
            onClick={() => onOptionClick(index)}
            className={item.active ? 'active' : ''}
            style={{ display: item.display }}
          >
            {item.label}
            {item.active && <FiCheck size={15} />}
          </li>
        ))}
      </ul>
    </Container>
  );
};

export default DropdownList;
