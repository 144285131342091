import styled from 'styled-components';

export const Container = styled.div`
  background: var(--white);
  border: 1px solid var(--light-gray);
  box-shadow: var(--shadow);

  position: absolute;
  top: 39px;

  padding: 20px 15px;
  min-width: 280px;
  z-index: 10;
`;
