import { createGlobalStyle } from 'styled-components';
import { shade } from 'polished';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
  }

  body {
    background: var(--white);
    color: var(--gray);
    -webkit-font-smoothing: antialiased;
  }

  body, input, button {
    font-family: var(--primary-font);
    font-size: 1.2rem;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 700;
    color: var(--secondary);
  }

  h1 {
    font-size: 3.3rem;
    line-height: 4.9rem;
  }

  h2 {
    font-size: 2.1rem;
    line-height: 3.3rem;
  }

  h3 {
    font-size: 1.4rem;
    line-height: 2.3rem;
  }

  button {
    cursor: pointer;
  }

  .mtop10{
    margin-top: 10px;
  }

  .swal-modal {
    .swal-text,
    .swal-footer {
      text-align: center;
    }
  }

  .loading-spinner {
    display: flex;
    margin: 0 auto;
  }

  .advanced-filters{
    margin-bottom: 1.6rem;
  }

  .more-filters {
    margin-top: 1.6rem;

    > .trigger {
      cursor: pointer;
      color: var(--primary);
    }

    .content {
      padding: 1rem;

      .price-range--slider {
        margin: 25px 20px 35px;

        .input-range {
          height: 2.8rem;

          .input-range__label-container {
            font-size: 1.2rem;
            font-family: var(--primary-font);
            color: var(--gray);
          }

          .input-range__label--value {
            top: -2.8rem;

            .input-range__label-container {
              background: var(--primary);
              color: var(--white);
              padding: 1px 7px;
              border-radius: 10px;
              font-weight: 700;
            }
          }

          .input-range__track {
            height: 0.5rem;
            background: var(--light-gray);
            border-radius: 0;
          }

          .input-range__track--active {
            background: var(--primary);
          }

          .input-range__slider {
            background: var(--primary);
            border: 1px solid var(--primary);
          }
        }
      }

      .promotion {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .promotion--switch {
          margin-top: 8px;

          .promotion--yes,
          .promotion--no {
            color: var(--white);
            font-weight: 700;
            font-size: 1.2rem;
            position: relative;
            top: 5px;
            left: 10px;
          }
        }
      }
    }
  }

  .renewal {
    display: flex;
    margin-bottom: 15px;
    align-items: center;
    justify-content: space-between;

    .renewal--switch {
      .renewal--yes,
      .renewal--no {
        color: var(--white);
        font-weight: 700;
        font-size: 1.2rem;
        position: relative;
        top: 5px;
        left: 10px;
      }
    }
  }

  :root {
    --primary-font: 'Nunito', sans-serif;
    --primary: #1155CC;
    --primary-hover: ${shade(0.2, '#1155CC')};
    --secondary: #3C3C3C;
    --secondary-hover: ${shade(0.2, '#3C3C3C')};
    --black: #222222;
    --white: #FFFFFF;
    --white-hover: ${shade(0.2, '#FFFFFF')};
    --white-opacity: rgba(255, 255, 255,.8);
    --gray: #707780;
    --light-gray: #BCBCBC;
    --green: #6FD64B;
    --green-hover: ${shade(0.2, '#6FD64B')};        
    --brown: #A58D61;
    --light-blue: #88ADF1;
    --yellow: #FBC22A;
    --orange: #f7941d;
    --light-orange: #f0ad4e;
    --orange-hover: ${shade(0.2, '#f7941d')};
    --light-red: #FB5D5D;
    --red: #FE0808;
    --red-hover: ${shade(0.2, '#FE0808')};
    --porcelain: #F5F7F8;
    --divider: #EBEBEB;
    --inline-link: #0590FB;
    --inline-link-hover: ${shade(0.2, '#0590FB')};
    --outline: --primary;
    --outline-hover: #E7F0FF;
    --shadow: 0 0 10px rgba(0, 0, 0, .1);
    --modal-shadow: 0 0 40px rgba(0, 0, 0, .15);
    --overlay: rgba(0, 0, 0, .4);
  }
`;
