import styled, { css } from 'styled-components';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  background: var(--white);
  width: 100%;

  border: 1px solid var(--light-gray);
  color: var(--gray);

  display: flex;
  align-items: center;

  & + div {
    margin-top: 8px;
  }

  ${props =>
    props.isErrored &&
    css`
      border-color: var(--red);
    `}

  ${props =>
    props.isFocused &&
    css`
      color: var(--primary);
      border-color: var(--primary);
    `}

  ${props =>
    props.isFilled &&
    css`
      color: var(--primary);
    `}

  input {
    flex: 1;
    background: transparent;
    border: 0;
    color: var(--gray);
    font-size: 1.2rem;
    padding: 9px 15px;

    &::placeholder {
      color: var(--light-gray);
    }

    &.input-lg {
      padding: 14px 15px;
    }
  }

  svg {
    margin-right: 16px;
  }
`;
