import styled, { css } from 'styled-components';

interface Props {
  isWarned: boolean;
}

export const ContainerAddModal = styled.div<Props>`
  width: 800px;
  ${props =>
    props.isWarned &&
    css`
      width: 800px;
    `}

  @media(max-width: 768px) {
    width: 70vw;
  }
`;

export const Container = styled.div`
  width: 570px;
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--divider);
  padding: 15px;

  > span {
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
`;

export const ModalContent = styled.div`
  padding: 5px 15px;

  .form-group {
    margin: 20px 0 5px;

    > div {
      margin-top: 10px;
    }
  }

  &.block {
    display: flex;
    justify-content: space-between;

    h1,
    strong {
      color: var(--gray) !important;
    }

    .ally-plus-image {
      img {
        width: 100%;
      }

      &.main {
        width: 100%;
        margin-inline: auto;
      }

      &.logo {
        width: 90px;
        margin-left: calc(100% - 90px);
      }
    }

    button {
      width: initial;

      &.ally-plus {
        background: #e50069;
        border-color: #e50069;
      }
    }
  }
`;

export const ButtonContainer = styled.div`
  width: 280px;
  margin: 0 auto;
  text-align: center;
  button {
    border: none;
  }

  button {
    width: 100%;
    height: 50px;
  }

  span {
    color: var(--primary);
  }

  &.ally-plus {
    button {
      background-color: #ff0775;
      border: none;

      &:hover {
        background: #dd0967;
      }
    }

    span {
      color: #ff0775;
    }
  }
`;
