import React, { createContext, useCallback, useState, useContext } from 'react';
import { StudentProps } from '../shared/interfaces';

export interface WizardFormData {
  fees: any;
  howManyQuotes?: number;
  quotes?: any[];
  draft_id?: number;
  student: StudentProps;
  mandatoryFees?: any[];
  suggestiveFees?: any[];
}

export interface WizardState {
  currentStep: number;
  quotesData: WizardFormData;
}

interface WizardContextData {
  currentStep: number;
  quotesData: WizardFormData;
  updateQuotesData(formData: WizardFormData): void;
  next(formData: WizardFormData): void;
  previous(formData: WizardFormData): void;
  goToResume(formData: WizardFormData): void;
}

const WizardContext = createContext<WizardContextData>({} as WizardContextData);

const WizardProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<WizardState>(() => ({
    currentStep: 1,
    quotesData: {} as WizardFormData,
  }));

  const updateQuotesData = useCallback(
    (formData: WizardFormData) => {
      const updatedData = {
        currentStep: data.currentStep,
        quotesData: {
          ...data.quotesData,
          ...formData,
        },
      };
      setData(updatedData);
    },
    [data.currentStep, data.quotesData]
  );

  const next = useCallback(
    (formData: WizardFormData) => {
      const updatedData = {
        currentStep: data.currentStep + 1,
        quotesData: {
          ...data.quotesData,
          ...formData,
        },
      };

      setData(updatedData);
    },
    [data.currentStep, data.quotesData]
  );

  const previous = useCallback(
    (formData: WizardFormData) => {
      const updatedData = {
        currentStep: data.currentStep - 1,
        quotesData: {
          ...data.quotesData,
          ...formData,
        },
      };

      setData(updatedData);
    },
    [data.currentStep, data.quotesData]
  );

  const goToResume = useCallback(
    (formData: WizardFormData) => {
      const updatedData = {
        currentStep: 3,
        quotesData: {
          ...data.quotesData,
          ...formData,
        },
      };

      setData(updatedData);
    },
    [data.quotesData]
  );

  return (
    <WizardContext.Provider
      value={{
        currentStep: data.currentStep,
        quotesData: data.quotesData,
        updateQuotesData,
        next,
        previous,
        goToResume,
      }}
    >
      {children}
    </WizardContext.Provider>
  );
};

function useWizard(): WizardContextData {
  const context = useContext(WizardContext);

  if (!context) {
    throw new Error('useWizard must be used within a WizardProvider');
  }

  return context;
}

export { WizardProvider, useWizard };
