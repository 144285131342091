import React, { useState, useCallback } from 'react';
import { MdCheck, MdClose } from 'react-icons/md';

import Button from '../Button';

interface EditableProps {
  text?: string;
  className?: string;
  currencyCode: string;
  updateCurrencyValue: (currencyCode: string) => Promise<boolean>;
}

const Editable: React.FC<EditableProps> = ({
  text,
  className,
  currencyCode,
  updateCurrencyValue,
  children,
}) => {
  const [isEditing, setEditing] = useState(false);

  const handleSaveButtonClick = useCallback(() => {
    const updated = updateCurrencyValue(currencyCode);
    if (updated) setEditing(false);
  }, [updateCurrencyValue, currencyCode]);

  return (
    <div className={className}>
      {isEditing ? (
        <div className="editable-wrapper">
          {children}

          <Button
            type="button"
            className="btn-green"
            onClick={handleSaveButtonClick}
          >
            <MdCheck size={19} />
          </Button>

          <Button
            type="button"
            className="btn-outline btn-outline--gray"
            onClick={() => setEditing(false)}
          >
            <MdClose size={19} />
          </Button>
        </div>
      ) : (
        <div className="editable-text" onClick={() => setEditing(true)}>
          {text || 'Editable'}
        </div>
      )}
    </div>
  );
};

export default Editable;
