import React from 'react';
import Start from '../Start';
import Playground from '../Playground';
import { useWizard } from '../../hooks/wizard';

const Step: React.FC = () => {
  const { currentStep } = useWizard();

  return <>{currentStep === 1 ? <Start /> : <Playground />}</>;
};

export default Step;
