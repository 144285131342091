import styled from 'styled-components';

export const Container = styled.div`
  label {
    display: flex;
    align-items: center;

    position: relative;
    padding-left: 28px;

    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    & + label {
      margin-top: 10px;
    }

    /* Hide the browser's default radio */
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    /* Create a custom radio */
    .checkmark {
      position: absolute;
      left: 0;
      height: 19px;
      width: 19px;
      background-color: var(--white);
      border: 1px solid var(--light-gray);
      border-radius: 50%;
    }

    /* When the radio is checked, add a primary border */
    input:checked ~ .checkmark {
      border-color: var(--primary);
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .checkmark:after {
      left: 6px;
      top: 4px;
      width: 4px;
      height: 8px;
      border: solid var(--primary);
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    .radio-text {
      font-size: 1.2rem;
      color: var(--secondary);
      position: relative;
      top: 1px;
    }
  }
`;
