import styled from 'styled-components';
import allyPlus from '../../assets/icon-allyplus-sm.png';

export const Container = styled.div`
  .quoteOption {
    border: 1px solid var(--bs-gray-200);
    padding: 15px;
    display: grid;
    margin-bottom: 15px;
    background-color: var(--bs-gray-100);

    &:hover {
      background: var(--outline-hover);
    }
  }

  .quoteOptionAllyPlus {
    border: 1px solid var(--bs-gray-200);
    padding: 15px;
    display: grid;
    margin-bottom: 15px;
    background-color: var(--bs-gray-100);
    background-image: url(${allyPlus});
    background-repeat: no-repeat;
    background-position: 700px;

    &:hover {
      background: var(--outline-hover);
      background-image: url(${allyPlus});
      background-repeat: no-repeat;
      background-position: 700px;
    }
  }

  .quoteOptionDisabled {
    cursor: not-allowed;
    border-color: #cdcdcd;
    opacity: 50%;
  }

  h2 {
    font-size: large !important;
    display: inline-block;
    margin-left: 40px;
    color: var(--secondary) !important;
  }

  label {
    display: flex;
    align-items: center;

    position: relative;
    padding-left: 28px;

    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    & + label {
      margin-top: 10px;
    }

    /* Hide the browser's default checkbox */
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
      margin: 15px;
      position: absolute;
      left: 0;
      height: 19px;
      width: 19px;
      background-color: var(--white);
      border: 1px solid var(--light-gray);
    }

    label {
      display: flex;
      position: relative;
    }

    /* When the checkbox is checked, add a primary border */
    input:checked ~ .checkmark {
      border-color: var(--primary);
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .checkmark:after {
      left: 6px;
      top: 4px;
      width: 4px;
      height: 8px;
      border: solid var(--primary);
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    .checkbox-text {
      font-size: 1.2rem;
      color: var(--gray);
      margin-top: 1px;
      display: inline-block;
      margin-left: 40px;
      width: 75%;
    }

    .warning {
      font-size: 1.3rem;
      color: #fb5d5d;
      margin-top: 3px;
      display: inline-block;
      margin-left: 40px;
      width: 75%;
    }
  }
`;
