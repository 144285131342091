/* eslint-disable no-mixed-operators */
/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/rules-of-hooks */
import React, {
  useState,
  useCallback,
  useRef,
  useMemo,
  useEffect,
} from 'react';
import { FaGlobe, FaCog, FaCalendarAlt } from 'react-icons/fa';
import { BiMessageAltDetail } from 'react-icons/bi';
import swal from 'sweetalert';
import { parseISO, format } from 'date-fns';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import CurrencyInput from 'react-currency-input-field';
import {
  MdMoreVert,
  MdCreate,
  MdStar,
  MdMonetizationOn,
  MdLocalOffer,
  MdDelete,
  MdVisibility,
  MdVisibilityOff,
  MdCheck,
} from 'react-icons/md';
import { IoIosPricetags } from 'react-icons/io';
import { FcIdea } from 'react-icons/fc';
import EasyEdit, { Types } from 'react-easy-edit';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import { blue } from '@mui/material/colors';
import Collapsible from 'react-collapsible';
import { filtersOptions } from '../../shared/constants';
import {
  CurrenciesOption,
  TotalByCurrency,
  OfficeCurrency,
} from '../../shared/interfaces';
import { useAuth } from '../../hooks/auth';
import { useWizard } from '../../hooks/wizard';
import api from '../../services/api';

import ButtonWithDropdown from '../ButtonWithDropdown';
import Select from '../Select';
import DatePicker from '../DatePicker';
import CheckboxInput from '../CheckboxInput';
import { Container as CheckboxContainer } from '../CheckboxInput/styles';
import Button from '../Button';
import Editable from '../Editable';

import {
  Container,
  ActionLoading,
  EmptyQuote,
  Header,
  HeaderTop,
  HeaderBackground,
  HeaderBottom,
  Avatar,
  Option,
  OptionName,
  Resume,
  ResumeInfo,
  ResumeActions,
  Price,
  RemoveItem,
  PriceButtons,
  PaymentPlanContainer,
  Instructions,
  Details,
  SaleTypeContainer,
  PaymentTypeContainer,
} from './styles';

import Loading from '../Loading';
import Tooltip from '../Tooltip';
import { useToast } from '../../hooks/toast';
import allyPlus from '../../assets/allyPlus.png';
import ComissionModal from '../../modals/ComissionModal';
import formatPrice from '../../utils/formatPrice';
import { Container as RadioContainer } from '../RadioInput/styles';
import Input from '../Input';

interface ActionsOption {
  label: string;
  active: boolean;
}

interface ResumeItemProps {
  index: number;
  quoteId: number;
  quote?: any;
  isLoadingNewValues: boolean;
  actionLoading: {
    index: number;
    loading: boolean;
  };
  currentFiltersTab?: string;
  defaultDescription?: string;
  officeCurrencies?: CurrenciesOption[];
  saleTypes?: { label: string; value: number; main: any }[];
  officeConfig: any;
  dateOfReference: Date;
  handleUpdateCourseOptions: (
    index: number,
    itemId: number,
    itemType: string,
    newValue: boolean | string,
  ) => void;
  handleChangeItemValue: (
    type: string,
    changedPeriod: any[]
  ) => void;
  handleUpdateSR: (
    index: number,
    item: any,
    specialRate: any,
    newValue: boolean
  ) => void;
  handleRemoveItemFromQuote: (
    index: number,
    type: string | undefined,
    itemId: number
  ) => void;
  handleConvertQuoteValues: (index: number, currencyToConvert: any) => void;
  handleCurrencyValueChange: (
    currencyCode: string,
    index: number,
    newValue: number
  ) => Promise<boolean>;
  handleUpdateQuoteConfigPreferences: (
    index: number,
    quoteId: number,
    type: string,
    newValue: boolean
  ) => void;
  handleChangeQuoteSaleType: (
    index: number,
    quoteId: number,
    saleType: any
  ) => void;
  handleChangeQuoteDueDate: (
    index: number,
    quoteId: number,
    dueDate: any
  ) => void;
  handleRenameQuote: (index: number, quoteId: number, newName: string) => void;
  handleChangeQuoteTranslation: (
    index: number,
    quoteId: number,
    newLanguage: string
  ) => void;
  handleDuplicateQuote: (index: number, quoteId: number) => void;
  handleDeleteQuote: (index: number, quoteId: number) => void;
  handleBackLinkClick: () => void;
}

const ResumeItem: React.FC<ResumeItemProps> = ({
  index,
  quoteId,
  quote,
  actionLoading,
  isLoadingNewValues,
  currentFiltersTab,
  defaultDescription,
  officeCurrencies,
  saleTypes,
  officeConfig,
  dateOfReference,
  handleUpdateCourseOptions,
  handleUpdateSR,
  handleRemoveItemFromQuote,
  handleConvertQuoteValues,
  handleCurrencyValueChange,
  handleUpdateQuoteConfigPreferences,
  handleChangeQuoteSaleType,
  handleChangeQuoteDueDate,
  handleRenameQuote,
  handleChangeQuoteTranslation,
  handleDuplicateQuote,
  handleDeleteQuote,
  handleBackLinkClick,
  handleChangeItemValue,
}) => {
  const { quotesData } = useWizard();
  const { addToast } = useToast();

  const {
    courses,
    accommodations,
    fees,
    experiences,
    otherfees,
    paymentplan,
    totalsByCurrency,
    totalConverted,
    quoteCurrencies,
    config,
    language,
    saleType,
    dueDate,
  } = quote;

  const [inputName, setInputName] = useState({
    value: '',
    isActive: false,
  });
  const [quoteName, setQuoteName] = useState(
    config?.name || `Option ${index + 1}`
  );

  const formRef = useRef<FormHandles>(null);
  const isRenewal = courses.some(({ coursecampus:{ course: { renewal } } }: any) => renewal === 1);

  const [isComissionModalOpen, setIsComissionModalOpen] = useState<boolean>(false);
  const [showPoupancinha, setShowPoupancinha] = useState<boolean>(quote.iepayment?.poupancinha===0? false : true);
  const [poupancinhaEntryType, setPoupancinhaEntryType] = useState<string>(quote.iepayment?.poupancinhaDPType===2? 'value' : 'percentage');
  const [poupancinhaValue, setPoupancinhaValue] = useState<number>(quote.iepayment?.poupancinhaDPValue ? quote.iepayment?.poupancinhaDPValue: 30);
  const [poupancinhaMaxInstallments, setPoupancinhaMaxInstallments] = useState<number>(quote.iepayment?.poupancinhaMax ? quote.iepayment?.poupancinhaMax: 12);
  const [showCardWithEntry, setShowCardWithEntry] = useState<boolean>(quote.iepayment?.cartaoEntrada===0? false : true);
  const [cardEntryType, setCardEntryType] = useState<string>(quote.iepayment?.cartaoEntradaDPType===2? 'value' : 'percentage');
  const [cardValue, setCardValue] = useState<number>(quote.iepayment?.cartaoEntradaDPValue ? quote.iepayment?.cartaoEntradaDPValue : 30);
  const [cardWithEntryMaxInstallments, setCardWithEntryMaxInstallments] = useState<number>(quote.iepayment?.cartaoEntradaMax ? quote.iepayment?.cartaoEntradaMax: 12);
  const [showSplit, setShowSplit] = useState<boolean>(quote.iepayment?.parcelado===0? false : true);
  const [splitMaxInstallments, setSplitMaxInstallments] = useState<number>(quote.iepayment?.parceladoMax ? quote.iepayment?.parceladoMax: 12);
  const [showPravaler, setShowPravaler] = useState<boolean>(quote.iepayment?.pravaler===0? false : true);

  const { user, angularPort } = useAuth();

  const hasPermissionToEditCurrency = true;


  const isAdmin = useMemo((): any => {
    return user.role === 'admin';
  }, [user.role]);

  const ruleValues = useMemo((): any => {
    return {};
  }, []);

  const convertedCurrency = {
    value: quote.convertedCurrency?.code,
    label: quote.convertedCurrency?.name,
  };

  const translationOptions = [
    { label: 'English', value: 'en-US' },
    { label: 'Português', value: 'pt-BR' },
    { label: 'Español', value: 'es-ES' },
  ];
  
  const isEmptyQuote =
    courses.length === 0 &&
    accommodations.length === 0 &&
    fees.length === 0 &&
    experiences.length === 0 &&
    otherfees.length === 0;
  const isQuoteAllyPlus = quote.plugAndPlay === 1 && !isEmptyQuote;
  const isUserAllyPlus = user.office.account.allyPlus === 1;
  const isUserBackOffice = !isUserAllyPlus && !user.office.plugAndPlay;
  const isUserHibrid = !isUserAllyPlus && !isUserBackOffice;

  const actionsOptions= useMemo(() => (totalConverted.value > 0 && ((isUserHibrid && isQuoteAllyPlus) || isUserAllyPlus) ? [
      { label: 'My comission', active: false },
      { label: 'Add custom fee', active: false },
      { label: 'Duplicate', active: false },
      { label: 'Delete', active: false },
    ] : [
      { label: 'Add custom fee', active: false },
      { label: 'Duplicate', active: false },
      { label: 'Delete', active: false },
    ]), [totalConverted, isUserHibrid, isUserAllyPlus, isQuoteAllyPlus]);

  const dueDateFormatted = isQuoteAllyPlus
    ? parseISO(moment().add(10, 'days').format('YYYY-MM-DD') as string)
    : parseISO(dueDate);
  const quoteSaleType = saleType
    ? { label: saleType.name, value: saleType.id }
    : saleTypes?.find(option => option.main === 1);

  const defaultTranslate = translationOptions.find(
    option => option.value === language
  );

  let headerBackground;
  if (
    courses.length > 0 &&
    courses[0].coursecampus.campus.schoolinfo?.bannerUrl
  ) {
    headerBackground = courses[0].coursecampus.campus.schoolinfo?.bannerUrl;
  }

  if (quoteCurrencies && quoteCurrencies.length > 0) {
    quoteCurrencies.forEach((rule: any) => {
      ruleValues[rule.destiny_code] = rule.value;
    });
  }

  const validateEnrol = true;
  const validateMaterial = true;

  const showDiscount =
    (config && config.showdiscount) ||
    (!config && !officeConfig.id) ||
    (!config && officeConfig.id && officeConfig.showdiscount)
      ? true
      : false;

  const onlyAdminEditCurrency =
    (config && config.onlyAdminEditCurrency) ||
    officeConfig.onlyAdminEditCurrency
      ? true
      : false;

  const showAddition =
    (config && config.showaddition) ||
    (!config && !officeConfig.id) ||
    (!config && officeConfig.id && officeConfig.showaddition)
      ? true
      : false;

  const showSpecialRate =
    (config && config.showspecialrate) ||
    (!config && !officeConfig.id) ||
    (!config && officeConfig.id && officeConfig.showspecialrate)
      ? true
      : false;

  const showDueDate =
    (config && config.showduedate) ||
    (!config && !officeConfig.id) ||
    (!config && officeConfig.id && officeConfig.showduedate)
      ? true
      : false;

  const showCurrency =
    (config && config.showcurrency) ||
    (!config && !officeConfig.id) ||
    (!config && officeConfig.id && officeConfig.showcurrency)
      ? true
      : false;

  const canShowSubtotals =
    officeConfig.id &&
    officeConfig.showsubtotals &&
    showDiscount &&
    showAddition
      ? true
      : false;

  const showSubtotals =
    (config && config.showsubtotals) ||
    (!config && !officeConfig.id) ||
    (!config && canShowSubtotals) || 
    isQuoteAllyPlus || isUserAllyPlus
      ? true
      : false;

  const disabledSubtotals = !showDiscount || !showAddition;
  const classNamesSubtotals = disabledSubtotals ? 'opacity' : '';

  const descriptionLabel =
    defaultDescription === quote.description
      ? 'Using default quote description'
      : 'Using custom description';

  const convertValuesChange = useCallback(
    (currencyToConvert: any) => {
      handleConvertQuoteValues(index, currencyToConvert);
    },
    [index, handleConvertQuoteValues]
  );

  const updateCurrencyValue = useCallback(
    (currencyCode: string): Promise<boolean> => {
      if (user.role !== 'admin') {
        try {
          api
            .get<OfficeCurrency[]>('/officecurrency', {
              params: {
                resumeitem: true,
                // TODO: change account_id
                office_id: isUserAllyPlus ? 2867 : user.office_id,
                plugAndPlay: (isUserAllyPlus || (isUserHibrid && isQuoteAllyPlus)) ? 1 : 0,
              },
            })
            .then(response => {
              const currencies = response.data;
              currencies.forEach(ent => {
                if (ent.destiny_code === currencyCode) {
                  if (ent.value > ruleValues[currencyCode]) {
                    handleCurrencyValueChange(currencyCode, index, ent.value);
                    swal(
                      'Error',
                      'The currency value must be equal or higher than the current value.',
                      'error'
                    );
                  } else {
                    handleCurrencyValueChange(
                      currencyCode,
                      index,
                      ruleValues[currencyCode]
                    );
                  }
                }
                return null;
              });
            });
        } catch {
          addToast({
            type: 'error',
            title: 'Error',
            description: 'An error has occurred, please try again.',
          });
        }
      }
      return handleCurrencyValueChange(
        currencyCode,
        index,
        ruleValues[currencyCode]
      );
    },
    [
      addToast,
      handleCurrencyValueChange,
      index,
      ruleValues,
      user.office_id,
      user.role,
      isUserAllyPlus,
      isQuoteAllyPlus,
      isUserHibrid,
    ]
  );

  const handleInputValueChange = useCallback(
    (value: string | undefined, currencyCode: string) => {
      ruleValues[currencyCode] = value ? Number(value) : value;
    },
    [ruleValues]
  );

  const openAddCustomFeeAndDiscountModal = useCallback(() => {
    const editedQuote = {
      ...quote,
      plugAndPlay: (isUserHibrid && isQuoteAllyPlus) || isUserAllyPlus ? 1 : 0,
    };

    const message = {
      quote: editedQuote,
      index,
      action: 'open-add-custom-fee-and-discount-modal',
      origin: process.env.REACT_APP_URL as string,
    };

    angularPort?.postMessage(message);
  }, [angularPort, quote, index, isUserHibrid, isQuoteAllyPlus, isUserAllyPlus]);

  const openAddPaymentPlanModal = useCallback(() => {
    const message = {
      quote,
      index,
      student: quotesData.student,
      action: 'open-add-payment-plan-modal',
      origin: process.env.REACT_APP_URL as string,
    };

    angularPort?.postMessage(message);
  }, [angularPort, quotesData.student, quote, index]);

  const openViewPaymentPlanModal = useCallback(() => {
    const message = {
      quote,
      index,
      student: quotesData.student,
      action: 'open-view-payment-plan-modal',
      origin: process.env.REACT_APP_URL as string,
    };

    angularPort?.postMessage(message);
  }, [angularPort, quotesData.student, quote, index]);

  const paymentPlanButtonClick = useCallback(() => {
    if (paymentplan.length === 0) {
      openAddPaymentPlanModal();
    } else {
      openViewPaymentPlanModal();
    }
  }, [paymentplan, openAddPaymentPlanModal, openViewPaymentPlanModal]);

  const [verifiedDate, setVerifiedDate] = useState('');

  useEffect(() => {
    if (!verifiedDate && dateOfReference) {
      setVerifiedDate(format(dateOfReference, 'dd/MM/yyyy'));
    }
  }, [dateOfReference, verifiedDate]);

  useEffect(() => {
    courses.forEach((course: any) => {
      if (course.pricePeriod === 'fixed') {
        course.endDate = course.startDate;

        course.coursecampus.endDate = format(
          parseISO(course.endDate),
          'dd/MM/yyyy'
        );
        course.coursecampus.endDateFormatted = format(
          parseISO(course.endDate),
          'LLLL dd, yyyy'
        );
      }
    });
  }, [courses]);

  const openEditCourseDateModal = useCallback(
    (course: any) => {
      const message = {
        quote,
        index,
        enrolDate: verifiedDate,
        studentCountry: quotesData.student.nationalityCountry,
        selectedCourse: course.coursecampus,
        action: 'open-edit-course-date-modal',
        origin: process.env.REACT_APP_URL as string,
      };

      angularPort?.postMessage(message);
    },
    [
      quote,
      index,
      verifiedDate,
      quotesData.student.nationalityCountry,
      angularPort,
    ]
  );
  
  const openEditProgramPriceModal = useCallback(
    (course: any, typePrice: string) => {
      const message = {
        quote,
        index,
        typePrice,
        enrolDate: verifiedDate,
        studentCountry: quotesData.student.nationalityCountry,
        selectedCourse: course.coursecampus,
        quoteCourseCampus: course,
        action: 'open-edit-program-price-modal',
        origin: process.env.REACT_APP_URL as string,
      };

      angularPort?.postMessage(message);
    },
    [
      quote,
      index,
      verifiedDate,
      quotesData.student.nationalityCountry,
      angularPort,
    ]
  );
  
  const openEditAccommodationPriceModal = useCallback(
    (accommodation: any, typePrice: string) => {
      const message = {
        quote,
        index,
        typePrice,
        enrolDate: verifiedDate,
        studentCountry: quotesData.student.nationalityCountry,
        selectedAccommodation: accommodation,
        action: 'open-edit-accommodation-price-modal',
        origin: process.env.REACT_APP_URL as string,
      };

      angularPort?.postMessage(message);
    },
    [
      quote,
      index,
      verifiedDate,
      quotesData.student.nationalityCountry,
      angularPort,
    ]
  );
  
  const openEditFeePriceModal = useCallback(
    (fee: any, typePrice: string) => {
      const message = {
        quote,
        index,
        typePrice,
        enrolDate: verifiedDate,
        studentCountry: quotesData.student.nationalityCountry,
        selectedFee: fee,
        action: 'open-edit-fee-price-modal',
        origin: process.env.REACT_APP_URL as string,
      };

      angularPort?.postMessage(message);
    },
    [
      quote,
      index,
      verifiedDate,
      quotesData.student.nationalityCountry,
      angularPort,
    ]
  );

  const openEditAccommodationDateModal = useCallback(
    (accommodation: any) => {
      const message = {
        quote,
        index,
        enrolDate: verifiedDate,
        selectedAccommodation: accommodation,
        studentCountry: quotesData.student.nationalityCountry,
        action: 'open-edit-accommodation-date-modal',
        origin: process.env.REACT_APP_URL as string,
      };

      angularPort?.postMessage(message);
    },
    [
      quote,
      index,
      verifiedDate,
      quotesData.student.nationalityCountry,
      angularPort,
    ]
  );

  const openEditFeeDurationModal = useCallback(
    (fee: any) => {
      const message = {
        quote,
        index,
        selectedFee: fee,
        enrolDate: verifiedDate,
        studentCountry: quotesData.student.nationalityCountry,
        action: 'open-edit-fee-duration-modal',
        origin: process.env.REACT_APP_URL as string,
      };

      angularPort?.postMessage(message);
    },
    [
      quote,
      index,
      angularPort,
      verifiedDate,
      quotesData.student.nationalityCountry,
    ]
  );

  const onDropdownOptionClick = useCallback(
    (idx: number) => {
      if (actionsOptions[idx].label === 'Duplicate') {
        handleDuplicateQuote(index, quoteId);
      } else if (actionsOptions[idx].label === 'Add custom fee') {
        openAddCustomFeeAndDiscountModal();
      } else if (actionsOptions[idx].label === 'Delete') {
        handleDeleteQuote(index, quoteId);
      } else if (actionsOptions[idx].label === 'My comission') {
        setIsComissionModalOpen(true);
      }
    },
    [
      actionsOptions,
      handleDuplicateQuote,
      index,
      quoteId,
      openAddCustomFeeAndDiscountModal,
      handleDeleteQuote,
    ]
  );

  const onRenameQuote = useCallback(async () => {
    setQuoteName(inputName.value);
    setInputName({ ...inputName, isActive: false });

    handleRenameQuote(index, quoteId, inputName.value);
  }, [inputName, handleRenameQuote, index, quoteId]);

  const onChangeTranslation = useCallback(
    inputValue => {
      handleChangeQuoteTranslation(index, quoteId, inputValue.value);
    },
    [handleChangeQuoteTranslation, index, quoteId]
  );

  const onEditDescriptionClick = useCallback(() => {
    quote.description = quote.description
      ? quote.description
      : defaultDescription;

    const message = {
      quote,
      index,
      student: quotesData.student,
      action: 'open-edit-quote-description-modal',
      origin: process.env.REACT_APP_URL as string,
    };

    angularPort?.postMessage(message);
  }, [angularPort, defaultDescription, quotesData.student, quote, index]);

  function formatEndDate(course: any): string | undefined {
    if (!course.coursecampus.endDate) return undefined;

    const date = course.coursecampus.endDate.split('/');
    return format(new Date(`${date[1]}-${date[0]}-${date[2]}`), 'EEEE');
  }

  const openInstructionsForAgentsModal = useCallback(
    (item: any, type: string) => {
      const message = {
        descriptionItem: item,
        descriptionItemType: type,
        action: 'open-instructions-for-agents-modal',
        origin: process.env.REACT_APP_URL as string,
      };

      angularPort?.postMessage(message);
    },
    [angularPort]
  );

  const openItemDescriptionModal = useCallback(
    ({ id, type, item = null }) => {
      const message = {
        descriptionItemId: id || null,
        descriptionItemType: type,
        fee:
          (type === filtersOptions.FILTERS_ADDONS ||
            type === filtersOptions.FILTERS_INSURANCES) &&
          item,
        experience: type === filtersOptions.FILTERS_EXPERIENCES && item,
        accommodation: type === filtersOptions.FILTERS_ACCOMMODATIONS && item,
        action: 'open-view-item-description-modal',
        origin: process.env.REACT_APP_URL as string,
      };

      angularPort?.postMessage(message);
    },
    [angularPort]
  );

  const changeFee = async (value, oldValue, fee_id, oldQuote) => {
    const data = {
      changePeriod: true,
      type: 'fee',
      quote_id: oldQuote.id,
      courseCampus_id: null,
      accommodation_id: null,
      fee_id,
      value: Number(value),
      oldValue,
      timelog: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      country: quotesData.student.nationalityCountry,
    }
    await api.post('/period', data).then((resp) => {
      const newQuote = oldQuote
      newQuote.fees = oldQuote.fees.map((fee) => {
        if (fee.id === resp.data.id) {
          fee.price = resp.data.price
          fee.totalThisFee = resp.data.totalThisFee
          fee.editedFee = 1
        }
        return fee
      })
      handleChangeItemValue('fee', newQuote)
    });
  }

  const changeEnrol = async (value, oldValue, courseCampus_id, oldQuote) => {
    const data = {
      changePeriod: true,
      type: 'enrol',
      quote_id: oldQuote.id,
      courseCampus_id,
      accommodation_id: null,
      fee_id: null,
      value: Number(value),
      oldValue,
      timelog: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      country: quotesData.student.nationalityCountry,
    }
    await api.post('/period', data).then((resp) => {
      const newQuote = oldQuote
      newQuote.courses = oldQuote.courses.map((course) => {
        if (course.coursecampus.id === resp.data.id) {
          course.coursecampus.price = resp.data.price
          course.coursecampus.totalThisCourse = resp.data.totalThisCourse
          course.coursecampus.onlyEnrol = resp.data.onlyEnrol
          course.editedEnrol = 1
          course.coursecampus.enrolActive = Number(value) === 0 ? false : true
          course.coursecampus.specialrate = resp.data.specialrate
        }
        return course
      })
      handleChangeItemValue('courses', newQuote)
    });
  }
  
  const changeTuition = async (value, oldValue, courseCampus_id, oldQuote) => {
    const data = {
      changePeriod: true,
      type: 'tuition',
      quote_id: oldQuote.id,
      courseCampus_id,
      accommodation_id: null,
      fee_id: null,
      value: Number(value),
      oldValue,
      timelog: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      country: quotesData.student.nationalityCountry,
    }
    await api.post('/period', data).then((resp) => {
      const newQuote = oldQuote
      newQuote.courses = oldQuote.courses.map((course) => {
        if (course.coursecampus.id === resp.data.id) {
          course.coursecampus.price = resp.data.price
          course.coursecampus.totalThisCourse = resp.data.totalThisCourse
          course.editedTuition = 1
          course.coursecampus.specialrate = resp.data.specialrate
        }
        return course
      })
      handleChangeItemValue('courses', newQuote)
    });
  }
  

  const changeMaterial = async (value, oldValue, courseCampus_id, oldQuote) => {
    const data = {
      changePeriod: true,
      type: 'material',
      quote_id: oldQuote.id,
      courseCampus_id,
      accommodation_id: null,
      fee_id: null,
      value: Number(value),
      oldValue,
      timelog: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      country: quotesData.student.nationalityCountry,
    }
    await api.post('/period', data).then((resp) => {
      const newQuote = oldQuote
      newQuote.courses = oldQuote.courses.map((course) => {
        if (course.coursecampus.id === resp.data.id) {
          course.coursecampus.price = resp.data.price
          course.coursecampus.totalThisCourse = resp.data.totalThisCourse
          course.coursecampus.onlyMaterial = resp.data.onlyMaterial
          course.coursecampus.materialActive = Number(value) === 0 ? false : true
          course.coursecampus.specialrate = resp.data.specialrate
          course.editedMaterial = 1
        }
        return course
      })
      handleChangeItemValue('courses', newQuote)
    });
  }

  const changeAccommodation = async (value, oldValue, accommodation_id, oldQuote) => {
    const data = {
      changePeriod: true,
      type: 'accommodation',
      quote_id: oldQuote.id,
      courseCampus_id: null,
      accommodation_id,
      fee_id: null,
      value: Number(value),
      oldValue,
      timelog: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      country: quotesData.student.nationalityCountry,
    }
    await api.post('/period', data).then((resp) => {
      const newQuote = oldQuote
      newQuote.accommodations = oldQuote.accommodations.map((accommodation) => {
        if (accommodation.id === resp.data.id) {
          accommodation.price = resp.data.price
          accommodation.totalThisAccommodation = resp.data.totalThisAccommodation
          accommodation.editedAccommodation = 1
        }
        return accommodation
      })
      handleChangeItemValue('accommodation', newQuote)
    });
  }

  const changePlacementFee = async (value, oldValue, accommodation_id, oldQuote) => {
    const data = {
      changePeriod: true,
      type: 'placement_fee',
      quote_id: oldQuote.id,
      courseCampus_id: null,
      accommodation_id,
      fee_id: null,
      value: Number(value),
      oldValue,
      timelog: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      country: quotesData.student.nationalityCountry,
    }
    await api.post('/period', data).then((resp) => {
      const newQuote = oldQuote
      newQuote.accommodations = oldQuote.accommodations.map((accommodation) => {
        if (accommodation.id === resp.data.id) {
          accommodation.price = resp.data.price
          accommodation.totalThisAccommodation = resp.data.totalThisAccommodation
          accommodation.editedPlacementFee = 1
        }
        return accommodation
      })
      handleChangeItemValue('accommodation', newQuote)
    });
  }

  useEffect(() => {
    if (user.account_id === 2015 || user.account_id === 683) {
      api.post('/iepayment', {
        quote_id: quote.id,
        poupancinha: showPoupancinha ? 1 : 0,
        poupancinhaDPType: poupancinhaEntryType === 'percentage' ? 1 : 2,
        poupancinhaDPValue: poupancinhaValue,
        poupancinhaMax: poupancinhaMaxInstallments,
        cartaoEntrada: showCardWithEntry ? 1 : 0,
        cartaoEntradaDPType: cardEntryType === 'percentage' ? 1 : 2,
        cartaoEntradaDPValue: cardValue,
        cartaoEntradaMax: cardWithEntryMaxInstallments,
        parcelado: showSplit ? 1 : 0,
        parceladoMax: splitMaxInstallments,
        pravaler: showPravaler ? 1 : 0,
      });
    }
  }, [
    user,
    quote.id,
    showPoupancinha,
    poupancinhaEntryType,
    poupancinhaValue,
    poupancinhaMaxInstallments,
    showCardWithEntry,
    cardEntryType,
    cardValue,
    cardWithEntryMaxInstallments,
    showSplit,
    splitMaxInstallments,
    showPravaler,
  ]);
  return (
    <Container allyPlus={isQuoteAllyPlus && isUserHibrid && !isEmptyQuote}>
      {isComissionModalOpen && (
        <ComissionModal
          isOpen={isComissionModalOpen}
          setIsComissionModalOpened={setIsComissionModalOpen}
          quoteCode={`#${quote.officeCount}`}
          quoteId={quote.id}
        />
      )}

      {isLoadingNewValues && (
        <ActionLoading>
          <Loading loading color="var(--primary)" />
        </ActionLoading>
      )}

      {actionLoading.index === index && actionLoading.loading && (
        <ActionLoading>
          <Loading loading={actionLoading.loading} color="var(--primary)" />
        </ActionLoading>
      )}

      {quote.empty && (
        <EmptyQuote>
          <h3>Empty quote</h3>
          <p>It seems like you have not added any item to this quote yet.</p>

          <div className="empty-quote--actions">
            {!isUserAllyPlus && !isQuoteAllyPlus && (
              <Button
                type="button"
                className="btn-outline"
                onClick={openAddCustomFeeAndDiscountModal}
              >
                Add Custom Fee
              </Button>
            )}

            <div className="empty-quote--or">or</div>

            <Button type="button" onClick={handleBackLinkClick}>
              Search {currentFiltersTab}
            </Button>
          </div>
        </EmptyQuote>
      )}

      {!quote.empty && (
        <Form
          ref={formRef}
          initialData={{
            quoteSaleType,
            quoteDueDate: dueDateFormatted,
          }}
          onSubmit={() => {}}
        >
          <Header>
            <HeaderTop>
              {isQuoteAllyPlus && isUserHibrid && !isEmptyQuote && (
                <img src={allyPlus} alt="Ally+" />
              )}
              <ButtonWithDropdown
                list={actionsOptions}
                icon={FaCog}
                onDropdownOptionClick={onDropdownOptionClick}
              />
              <HeaderBackground
                backgroundUrl={
                  courses.length === 1 &&
                  courses[0].coursecampus.campus.school.bannerUrl
                    ? courses[0].coursecampus.campus.school.bannerUrl
                    : headerBackground
                }
              />
            </HeaderTop>
            <HeaderBottom>
              <Avatar>
                {courses.length === 1 && (
                  <img
                    src={`${process.env.REACT_APP_AMZ_API}/base/school-logos/school_${courses[0].coursecampus.campus.school_id}.jpg`}
                    alt={courses[0].coursecampus.campus.school.name}
                  />
                )}
                {courses.length > 1 && (
                  <FaGlobe size={40} color="var(--primary)" />
                )}
              </Avatar>
              <span>#{quote.officeCount}</span>
              <Option>
                <OptionName>
                  <input
                    name="quoteName"
                    disabled={!inputName.isActive}
                    value={inputName.isActive ? inputName.value : quoteName}
                    style={{ font: 'inherit', flex: 1 }}
                    title={quoteName}
                    placeholder="Name this quote"
                    onChange={e => {
                      setQuoteName(e.currentTarget.value);
                      setInputName({
                        value: e.currentTarget.value,
                        isActive: true,
                      });
                    }}
                  />
                  {inputName.isActive ? (
                    <Button
                      type="button"
                      className="btn-green"
                      onClick={onRenameQuote}
                      style={{
                        width: '24px',
                        height: '24px',
                        marginTop: '1px',
                        padding: '0px',
                        top: '-4px',
                        position: 'relative',
                      }}
                    >
                      <MdCheck
                        size={19}
                        style={{ display: 'flex', marginInline: 'auto' }}
                      />
                    </Button>
                  ) : (
                    <MdCreate
                      size={16}
                      onClick={() => {
                        setInputName({ value: quoteName, isActive: true });
                      }}
                    />
                  )}
                  {courses.length > 0 &&
                    courses[0].coursecampus.campus.school.schoolpremium && (
                      <MdStar size={20} color="#FBC22A" />
                    )}
                </OptionName>
              </Option>
            </HeaderBottom>
          </Header>

          <Price>
            {courses.length > 0 && (
              <div className="price price-course">
                <h4 className="price-item--title">Programs</h4>
                {courses.map((course: any, idx: number) => (
                  <div className="item" key={idx}>
                    <div
                      style={{
                        float: 'right',
                        display: 'flex',
                        top: '-30px',
                        position: 'relative',
                        minWidth: '45px',
                        right: '-10px',
                        background: '#f4f6f8',
                      }}
                    >
                      {course.coursecampus.description && (
                        <Tooltip title="Instructions for agents">
                          <Instructions
                            onClick={() =>
                              openInstructionsForAgentsModal(
                                course.coursecampus,
                                'course'
                              )
                            }
                          >
                            <FcIdea style={{ marginTop: '-4px' }} size={16} />
                          </Instructions>
                        </Tooltip>
                      )}
                      <Tooltip title="Details">
                        <Details
                          onClick={() =>
                            openItemDescriptionModal({
                              id: course.coursecampus.id,
                              type: filtersOptions.FILTERS_COURSES,
                            })
                          }
                        >
                          <BiMessageAltDetail size={15} />
                        </Details>
                      </Tooltip>

                      <Tooltip title="Remove item">
                        <RemoveItem
                          onClick={() =>
                            handleRemoveItemFromQuote(
                              index,
                              filtersOptions.FILTERS_COURSES,
                              course.id
                            )
                          }
                        >
                          <MdDelete size={16} />
                        </RemoveItem>
                      </Tooltip>
                    </div>
                    <div
                      className="price-item-container title"
                      style={{ marginTop: '0px' }}
                    >
                      <div className="resume-city" style={{ fontSize: '10px' }}>
                        {course.coursecampus.campus.country && (
                          <img
                            style={{ maxWidth: '25px', marginRight: '5px' }}
                            src={`${
                              process.env.REACT_APP_NG_URL
                            }/images/flags/${
                              course.coursecampus.campus.country.nationality
                                ? course.coursecampus.campus.country.nationality
                                    ?.flag
                                : `${course.coursecampus.campus.country?.flagName}.png`
                            }`}
                            alt={course.coursecampus.campus.country.name}
                          />
                        )}
                        {course.coursecampus.campus.country && (
                          <span>
                            {course.coursecampus.campus.city
                              ? course.coursecampus.campus.city.name
                              : course.coursecampus.campus.country.name}
                          </span>
                        )}
                      </div>
                    </div>
                    <div
                      className="resume-details--school"
                      style={{ fontSize: '12px' }}
                    >
                      {course.coursecampus.campus.name}
                    </div>
                    <div className="price-item-container title">
                      <div className="price-item">
                        <span>{course.coursecampus.course.name}</span>
                      </div>
                    </div>
                    <>
                      <div
                        className="price-item-info"
                        onClick={() => openEditCourseDateModal(course)}
                        style={{
                          textDecoration: 'underline',
                          cursor: 'pointer',
                          textDecorationColor: 'lightgray',
                        }}
                      >
                        <FaCalendarAlt
                          size={13}
                          style={{ verticalAlign: 'top' }}
                        />
                        {course.pricePeriod !== 'fixed' && (
                          <>
                            <span>
                              {`${course.coursecampus.duration} ${
                                course.pricePeriod
                              }${
                                course.coursecampus.duration > 1 ? '(s)' : ''
                              }`}
                            </span>
                            <span>&nbsp;|&nbsp;</span>
                          </>
                        )}
                        <span>
                          {`${course.coursecampus.startDateFormatted} - ${
                            course.coursecampus.endDateFormatted || ''
                          }`}
                        </span>
                      </div>
                    </>

                    <div
                      className="price-item-container"
                      style={{ marginTop: '10px' }}
                    >
                      <div className="price-item">
                        <span>Tuition</span>
                      </div>
                      {showSubtotals && (
                        <div className="price-item--right">
                          <div className="edited-tag">
                            {Boolean(course.editedTuition) && (
                              <Tooltip title="Edited">
                                <p
                                  style={{
                                    color: 'orange',
                                    marginTop: '2px',
                                    marginBottom: '0px',
                                    marginRight: '10px',
                                    marginLeft: '10px',
                                    fontSize: '10px',
                                    position: 'relative',
                                  }}
                                >
                                  <MdMonetizationOn size={13} />
                                </p>
                              </Tooltip>
                            )}
                          </div>
                          <div
                            className={
                              course.hasSpecialRates
                                ? 'price-item--value with-promotion'
                                : 'price-item--value'
                            }
                          >
                            <div
                              style={{
                                color: 'var(--primary)',
                                fontWeight: 'bold',
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              {Number(course.discountTuition) > 0 && (
                                <span
                                  className="discount-value"
                                  style={{ marginRight: '10px' }}
                                >
                                  {course.coursecampus.totalThisCourse.currency}{' '}
                                  {parseFloat(
                                    course.discountTuition * 1 +
                                      course.coursecampus.totalThisCourse
                                        .onlyCourse *
                                        1
                                  ).toFixed(2)}
                                </span>
                              )}
                              {!(Number(course.discountTuition) > 0) &&
                                course.coursecampus.totalThisCourse
                                  .onlyCourseWithoutSR >
                                  course.coursecampus.totalThisCourse
                                    .onlyCourse && (
                                  <span
                                    className="discount-value"
                                    style={{ marginRight: '10px' }}
                                  >
                                    {
                                      course.coursecampus.totalThisCourse
                                        .currency
                                    }{' '}
                                    {parseFloat(
                                      course.coursecampus.totalThisCourse
                                        .onlyCourseWithoutSR
                                    ).toFixed(2)}
                                  </span>
                                )}
                              {course.hasSpecialRates &&
                                !course.editedTuition &&
                                course.hasTuitionSR && (
                                  <Tooltip title="Has special rates">
                                    <IoIosPricetags
                                      size={15}
                                      className="with-promotion--icon"
                                      style={{
                                        marginRight: 4,
                                        verticalAlign: 'initial',
                                      }}
                                    />
                                  </Tooltip>
                                )}
                              <span style={{ marginRight: '5px' }}>
                                {course.coursecampus.totalThisCourse.currency}
                              </span>
                              {user.editValues === 1 && (
                                <Tooltip title="Edit Tuition">
                                  <span
                                    style={{
                                      textDecoration: 'underline',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() =>
                                      openEditProgramPriceModal(course, 'tuition')
                                    }
                                  >
                                    {parseFloat(
                                      course.coursecampus.totalThisCourse
                                        .onlyCourse
                                    ).toFixed(2)}
                                  </span>
                                </Tooltip>
                              )}
                              {user.editValues === 0 && (
                                <Tooltip title="Tuition">
                                  <span>
                                    {parseFloat(
                                      course.coursecampus.totalThisCourse
                                        .onlyCourse
                                    ).toFixed(2)}
                                  </span>
                                </Tooltip>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    {validateEnrol && (
                      <div
                        className="price-item-container"
                        style={{ marginTop: '10px' }}
                      >
                        <div className="price-item">
                          <span>Enrolment</span>
                        </div>
                        {showSubtotals && (
                          <div className="price-item--right">
                            <div className="edited-tag">
                              {Boolean(course.editedEnrol) && (
                                <Tooltip title="Edited">
                                  <p
                                    style={{
                                      color: 'orange',
                                      marginTop: '2px',
                                      marginBottom: '0px',
                                      marginRight: '10px',
                                      marginLeft: '10px',
                                      fontSize: '10px',
                                      position: 'relative',
                                    }}
                                  >
                                    <MdMonetizationOn size={13} />
                                  </p>
                                </Tooltip>
                              )}
                            </div>
                            <div
                              className={
                                course.hasSpecialRates
                                  ? 'price-item--value with-promotion'
                                  : 'price-item--value'
                              }
                            >
                              <div
                                style={{
                                  color: 'var(--primary)',
                                  fontWeight: 'bold',
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                }}
                              >
                                {Number(course.discountEnrol) > 0 && (
                                  <span
                                    className="discount-value"
                                    style={{ marginRight: '10px' }}
                                  >
                                    {
                                      course.coursecampus.totalThisCourse
                                        .currency
                                    }{' '}
                                    {parseFloat(
                                      course.discountEnrol * 1 +
                                        course.coursecampus.totalThisCourse
                                          .onlyEnrol *
                                          1
                                    ).toFixed(2)}
                                  </span>
                                )}
                                {!(Number(course.discountEnrol) > 0) &&
                                  course.coursecampus.totalThisCourse
                                    .onlyEnrolWithoutSR >
                                    course.coursecampus.totalThisCourse
                                      .onlyEnrol && (
                                    <span
                                      className="discount-value"
                                      style={{ marginRight: '10px' }}
                                    >
                                      {
                                        course.coursecampus.totalThisCourse
                                          .currency
                                      }{' '}
                                      {parseFloat(
                                        course.coursecampus.totalThisCourse
                                          .onlyEnrolWithoutSR
                                      ).toFixed(2)}
                                    </span>
                                  )}
                                {course.hasSpecialRates &&
                                  !course.editedEnrol &&
                                  course.hasEnrolSR && (
                                    <Tooltip title="Has special rates">
                                      <IoIosPricetags
                                        size={15}
                                        className="with-promotion--icon"
                                        style={{
                                          marginRight: 4,
                                          verticalAlign: 'initial',
                                        }}
                                      />
                                    </Tooltip>
                                  )}
                                <span style={{ marginRight: '5px' }}>
                                  {course.coursecampus.totalThisCourse.currency}
                                </span>
                                {user.editValues === 1 && (
                                  <Tooltip title="Edit Enrol">
                                    <span
                                      style={{
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                      }}
                                      onClick={() =>
                                        openEditProgramPriceModal(course, 'enrol')
                                      }
                                    >
                                      {parseFloat(
                                        course.coursecampus.totalThisCourse
                                          .onlyEnrol
                                      ).toFixed(2)}
                                    </span>
                                  </Tooltip>
                                )}
                                {user.editValues === 0 && (
                                  <Tooltip title="Enrol">
                                    <span>
                                      {parseFloat(
                                        course.coursecampus.totalThisCourse
                                          .onlyEnrol
                                      ).toFixed(2)}
                                    </span>
                                  </Tooltip>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    {validateMaterial && (
                      <div
                        className="price-item-container"
                        style={{ marginTop: '10px' }}
                      >
                        <div className="price-item">
                          <span>Material</span>
                        </div>
                        {showSubtotals && (
                          <div className="price-item--right">
                            <div className="edited-tag">
                              {Boolean(course.editedMaterial) && (
                                <Tooltip title="Edited">
                                  <p
                                    style={{
                                      color: 'orange',
                                      marginTop: '2px',
                                      marginBottom: '0px',
                                      marginRight: '10px',
                                      marginLeft: '10px',
                                      fontSize: '10px',
                                      position: 'relative',
                                    }}
                                  >
                                    <MdMonetizationOn size={13} />
                                  </p>
                                </Tooltip>
                              )}
                            </div>
                            <div
                              className={
                                course.hasSpecialRates
                                  ? 'price-item--value with-promotion'
                                  : 'price-item--value'
                              }
                            >
                              <div
                                style={{
                                  color: 'var(--primary)',
                                  fontWeight: 'bold',
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                }}
                              >
                                {Number(course.discountMaterial) > 0 && (
                                  <span
                                    className="discount-value"
                                    style={{ marginRight: '10px' }}
                                  >
                                    {
                                      course.coursecampus.totalThisCourse
                                        .currency
                                    }{' '}
                                    {parseFloat(
                                      course.discountMaterial * 1 +
                                        course.coursecampus.totalThisCourse
                                          .onlyMaterial *
                                          1
                                    ).toFixed(2)}
                                  </span>
                                )}
                                {!(Number(course.discountMaterial) > 0) &&
                                  course.coursecampus.totalThisCourse
                                    .onlyMaterialWithoutSR >
                                    course.coursecampus.totalThisCourse
                                      .onlyMaterial && (
                                    <span
                                      className="discount-value"
                                      style={{ marginRight: '10px' }}
                                    >
                                      {
                                        course.coursecampus.totalThisCourse
                                          .currency
                                      }{' '}
                                      {parseFloat(
                                        course.coursecampus.totalThisCourse
                                          .onlyMaterialWithoutSR
                                      ).toFixed(2)}
                                    </span>
                                  )}

                                {course.hasSpecialRates &&
                                  !course.editedMaterial &&
                                  course.hasMaterialSR && (
                                    <Tooltip title="Has special rates">
                                      <IoIosPricetags
                                        size={15}
                                        className="with-promotion--icon"
                                        style={{
                                          marginRight: 4,
                                          verticalAlign: 'initial',
                                        }}
                                      />
                                    </Tooltip>
                                  )}
                                <span style={{ marginRight: '5px' }}>
                                  {course.coursecampus.totalThisCourse.currency}
                                </span>
                                {user.editValues === 1 && (
                                  <Tooltip title="Edit Material">
                                    <span
                                      style={{
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                      }}
                                      onClick={() =>
                                        openEditProgramPriceModal(
                                          course,
                                          'material'
                                        )
                                      }
                                    >
                                      {parseFloat(
                                        course.coursecampus.totalThisCourse
                                          .onlyMaterial
                                      ).toFixed(2)}
                                    </span>
                                  </Tooltip>
                                )}
                                {user.editValues === 0 && (
                                  <Tooltip title="Material">
                                    <span>
                                      {parseFloat(
                                        course.coursecampus.totalThisCourse
                                          .onlyMaterial
                                      ).toFixed(2)}
                                    </span>
                                  </Tooltip>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    {course.coursecampus.specialrate.filter(
                      (sr: {
                        rule: any | any[];
                        active: boolean;
                        type: string;
                      }) =>
                        (sr.rule &&
                          sr.rule !== undefined &&
                          sr.type !== 'freePeriod') ||
                        (sr.rule &&
                          sr.rule !== undefined &&
                          sr.type === 'freePeriod' &&
                          course.coursecampus.price.srperiod.rule
                            .discountPeriod > 0) ||
                        ((!sr.rule || sr.rule === undefined) && !sr.active)
                    ).length > 0 && (
                      <div className="promotion-container">
                        <strong>Available Special Rates</strong>
                        {course.coursecampus.specialrate
                          .filter(
                            (sr: {
                              rule: any | any[];
                              active: boolean;
                              type: string;
                            }) =>
                              (sr.rule &&
                                sr.rule !== undefined &&
                                sr.type !== 'freePeriod') ||
                              (sr.rule &&
                                sr.rule !== undefined &&
                                sr.type === 'freePeriod' &&
                                course.coursecampus.price.srperiod.rule
                                  .discountPeriod > 0) ||
                              ((!sr.rule || sr.rule === undefined) &&
                                !sr.active)
                          )
                          .map((sr: any, srIdx: number) => (
                            <div className="price-item-container" key={srIdx}>
                              <div className="price-item">
                                {quote.plugAndPlay === 0 && (
                                  <CheckboxInput
                                    options={[
                                      {
                                        id: `sr_${quoteId}_${sr.id}_${course.id}`,
                                        value: `sr_${quoteId}_${sr.id}`,
                                        label: sr.name,
                                      },
                                    ]}
                                    name={`sr-${sr.id}`}
                                    checked={sr.active ? true : false}
                                    onChange={e =>
                                      handleUpdateSR(
                                        index,
                                        course,
                                        sr,
                                        e.target.checked ? true : false
                                      )
                                    }
                                  />
                                )}
                                {quote.plugAndPlay === 1 && (
                                  <label htmlFor="SrName">{sr.name}</label>
                                )}
                                {!showSpecialRate &&
                                  !isQuoteAllyPlus &&
                                  !isEmptyQuote && (
                                    <div className="quote-config-alert">
                                      <MdVisibilityOff size={13} />
                                      <span>
                                        This special rate will not be seen by
                                        the student
                                      </span>
                                    </div>
                                  )}
                              </div>
                              <div className="price-item--right">
                                {(sr.description ||
                                  (sr.courseStartDateFrom &&
                                    sr.courseStartDateTo)) && (
                                  // eslint-disable-next-line react/jsx-indent
                                  <Tooltip title="Instructions for agents">
                                    <FcIdea
                                      size={18}
                                      className="promotion-instruction--icon"
                                      onClick={() =>
                                        openInstructionsForAgentsModal(sr, 'sr')
                                      }
                                    />
                                  </Tooltip>
                                )}
                              </div>
                            </div>
                          ))}
                      </div>
                    )}

                    {course.coursecampus.specialrate.filter(
                      (sr: {
                        rule: any | any[];
                        active: boolean;
                        type: string;
                      }) =>
                        ((!sr.rule || sr.rule === undefined) &&
                          sr.active &&
                          sr.type !== 'freePeriod') ||
                        (sr.rule &&
                          sr.rule !== undefined &&
                          sr.type === 'freePeriod' &&
                          course.coursecampus.price.srperiod.rule
                            .discountPeriod === 0)
                    ).length > 0 && (
                      <div className="no-promotion-container">
                        <strong>Unavailable Special Rates</strong>
                        {course.coursecampus.specialrate
                          .filter(
                            (sr: {
                              rule: any | any[];
                              active: boolean;
                              type: string;
                            }) =>
                              ((!sr.rule || sr.rule === undefined) &&
                                sr.active &&
                                sr.type !== 'freePeriod') ||
                              (sr.rule &&
                                sr.rule !== undefined &&
                                sr.type === 'freePeriod' &&
                                course.coursecampus.price.srperiod.rule
                                  .discountPeriod === 0)
                          )
                          .map((sr: any, srIdx: number) => (
                            <div className="price-item-container" key={srIdx}>
                              <span>
                                No promotion for{' '}
                                {`${course.coursecampus.duration} ${course.pricePeriod}(s)`}
                                <div className="price-item">
                                  <span>{sr.name}</span>
                                </div>
                              </span>

                              <div className="price-item--right">
                                {(sr.description ||
                                  (sr.courseStartDateFrom &&
                                    sr.courseStartDateTo)) && (
                                  // eslint-disable-next-line react/jsx-indent
                                  <Tooltip title="Instructions for agents">
                                    <FcIdea
                                      size={18}
                                      className="promotion-instruction--icon"
                                      onClick={() =>
                                        openInstructionsForAgentsModal(sr, 'sr')
                                      }
                                    />
                                  </Tooltip>
                                )}
                              </div>
                            </div>
                          ))}
                      </div>
                    )}

                    {showSubtotals && (
                      <div className="price-item-subtotal">
                        <span>Total</span>
                        <span>{course.subtotalFormatted}</span>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
            {experiences.length > 0 && (
              <div className="price price-experience">
                <h4 className="price-item--title">Experiences</h4>
                {experiences.map((experience: any, idx: number) => (
                  <div className="item" key={idx}>
                    <div className="price-item-container title">
                      <div className="price-item">{experience.name}</div>
                      <div className="price-item--right">
                        {showSubtotals && (
                          <>
                            {/* {experience.oldValueFormatted && (
                              <div className="price-item--old-value">
                                <span>{experience.oldValueFormatted}</span>
                                <MdLocalOffer size={12} />
                              </div>
                            )} */}
                            <div className="price-item--value">
                              <span>{experience.valueFormatted}</span>
                              {/* TODO: Botão que será usado quando puder alterar o valor do item */}
                              {/* <MdCreate size={14} onClick={() => {}} /> */}
                            </div>
                          </>
                        )}

                        <div className="actions">
                          <Tooltip title="Details">
                            <Details
                              onClick={() =>
                                openItemDescriptionModal({
                                  item: experience,
                                  id: experience.id,
                                  type: filtersOptions.FILTERS_EXPERIENCES,
                                })
                              }
                            >
                              <BiMessageAltDetail size={15} />
                            </Details>
                          </Tooltip>
                          <Tooltip title="Remove item">
                            <RemoveItem
                              onClick={() =>
                                handleRemoveItemFromQuote(
                                  index,
                                  filtersOptions.FILTERS_EXPERIENCES,
                                  experience.id
                                )
                              }
                            >
                              <MdDelete size={16} />
                            </RemoveItem>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                    <div className="price-item-info">
                      <span>{experience.partner.name}</span>
                    </div>
                    <div className="price-item-info">
                      {/* TODO: Pegar datas corretas */}
                      <span>{`${experience.startDateFormatted} - ${experience.endDateFormatted}`}</span>
                      {/* <span>November 02, 2020 - December 02, 2020</span> */}
                    </div>
                    <div className="price-item-info">
                      <span>{`${experience.duration} ${experience.durationType}(s)`}</span>
                    </div>
                    {showSubtotals && (
                      <div className="price-item-subtotal">
                        <span>Total</span>
                        <span>{experience.subtotalFormatted}</span>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
            {accommodations.length > 0 && (
              <div className="price price-accommodation">
                <h4 className="price-item--title">Accommodations</h4>
                {accommodations.map((accommodation: any, idx: number) => (
                  <div className="item" key={idx}>
                    <div
                      style={{
                        float: 'right',
                        display: 'flex',
                        top: '-30px',
                        position: 'relative',
                        minWidth: '45px',
                        right: '-10px',
                        background: '#f4f6f8',
                      }}
                    >
                      {accommodation.instruction && (
                        <Tooltip title="Instructions for agents">
                          <Instructions
                            onClick={() =>
                              openInstructionsForAgentsModal(
                                accommodation,
                                'accommodation'
                              )
                            }
                          >
                            <FcIdea style={{ marginTop: '-4px' }} size={16} />
                          </Instructions>
                        </Tooltip>
                      )}
                      <Tooltip title="Details">
                        <Details
                          onClick={() =>
                            openItemDescriptionModal({
                              item: accommodation,
                              id: accommodation.id,
                              type: filtersOptions.FILTERS_ACCOMMODATIONS,
                            })
                          }
                        >
                          <BiMessageAltDetail size={15} />
                        </Details>
                      </Tooltip>

                      <Tooltip title="Remove item">
                        <RemoveItem
                          onClick={() =>
                            handleRemoveItemFromQuote(
                              index,
                              filtersOptions.FILTERS_ACCOMMODATIONS,
                              accommodation.id
                            )
                          }
                        >
                          <MdDelete size={16} />
                        </RemoveItem>
                      </Tooltip>
                    </div>
                    <div
                      className="price-item-container title"
                      style={{ marginTop: '0px' }}
                    >
                      <div className="resume-city" style={{ fontSize: '10px' }}>
                        {accommodation.city.country && (
                          <img
                            style={{ maxWidth: '25px', marginRight: '5px' }}
                            src={`${
                              process.env.REACT_APP_NG_URL
                            }/images/flags/${
                              accommodation.city.country.nationality
                                ? accommodation.city.country.nationality?.flag
                                : `${accommodation.city.country?.flagName}.png`
                            }`}
                            alt={accommodation.city.country.name}
                          />
                        )}
                        {accommodation.city.country && (
                          <span>
                            {accommodation.city
                              ? accommodation.city.name
                              : accommodation.city.country.name}
                          </span>
                        )}
                      </div>
                    </div>
                    <div
                      className="resume-details--school"
                      style={{ fontSize: '12px' }}
                    >
                      {accommodation.partner}
                    </div>
                    <div className="price-item-container title">
                      <div className="price-item">
                        <span>
                          {`${accommodation.name} - ${accommodation.type} - ${accommodation.regime}`}
                        </span>
                      </div>
                    </div>
                    <>
                      <div
                        className="price-item-info"
                        onClick={() =>
                          openEditAccommodationDateModal(accommodation)
                        }
                        style={{
                          textDecoration: 'underline',
                          cursor: 'pointer',
                          textDecorationColor: 'lightgray',
                        }}
                      >
                        <FaCalendarAlt
                          size={13}
                          style={{ verticalAlign: 'top' }}
                        />
                        {accommodation.pricePeriod !== 'fixed' && (
                          <>
                            <span>
                              {`${accommodation.duration} ${
                                accommodation.pricePeriod
                              }${accommodation.duration > 1 ? '(s)' : ''}`}
                            </span>
                            <span>&nbsp;|&nbsp;</span>
                          </>
                        )}
                        <span>
                          {`${accommodation.startDateFormatted} - ${
                            accommodation.endDateFormatted || ''
                          }`}
                        </span>
                      </div>
                    </>

                    <div
                      className="price-item-container"
                      style={{ marginTop: '10px' }}
                    >
                      <div className="price-item">
                        <span>Accommodation</span>
                      </div>
                      {showSubtotals && (
                        <div className="price-item--right">
                          <div className="edited-tag">
                            {Boolean(accommodation.editedAccommodation) && (
                              <Tooltip title="Edited">
                                <p
                                  style={{
                                    color: 'orange',
                                    marginTop: '2px',
                                    marginBottom: '0px',
                                    marginRight: '10px',
                                    marginLeft: '10px',
                                    fontSize: '10px',
                                    position: 'relative',
                                  }}
                                >
                                  <MdMonetizationOn size={13} />
                                </p>
                              </Tooltip>
                            )}
                          </div>
                          <div
                            className={
                              accommodation.hasSpecialRates
                                ? 'price-item--value with-promotion'
                                : 'price-item--value'
                            }
                          >
                            <div
                              style={{
                                color: 'var(--primary)',
                                fontWeight: 'bold',
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              {Number(accommodation.discountAccommodation) >
                                0 && (
                                <span
                                  className="discount-value"
                                  style={{ marginRight: '10px' }}
                                >
                                  {
                                    accommodation.totalThisAccommodation
                                      .currency
                                  }{' '}
                                  {parseFloat(
                                    accommodation.discountAccommodation * 1 +
                                      accommodation.totalThisAccommodation
                                        .onlyAccommodation *
                                        1
                                  ).toFixed(2)}
                                </span>
                              )}
                              {!(
                                Number(accommodation.discountAccommodation) > 0
                              ) &&
                                accommodation.totalThisAccommodation
                                  .onlyAccommodationWithoutSR >
                                  accommodation.totalThisAccommodation
                                    .onlyAccommodation && (
                                  <span
                                    className="discount-value"
                                    style={{ marginRight: '10px' }}
                                  >
                                    {
                                      accommodation.totalThisAccommodation
                                        .currency
                                    }{' '}
                                    {parseFloat(
                                      accommodation.totalThisAccommodation
                                        .onlyAccommodationWithoutSR
                                    ).toFixed(2)}
                                  </span>
                                )}
                              {accommodation.hasSpecialRates &&
                                !accommodation.editedAccommodation &&
                                accommodation.hasAccommodationSR && (
                                  <Tooltip title="Has special rates">
                                    <IoIosPricetags
                                      size={15}
                                      className="with-promotion--icon"
                                      style={{
                                        marginRight: 4,
                                        verticalAlign: 'initial',
                                      }}
                                    />
                                  </Tooltip>
                                )}
                              <span style={{ marginRight: '5px' }}>
                                {accommodation.totalThisAccommodation.currency}
                              </span>
                              {user.editValues === 1 && (
                                <Tooltip title="Edit accommodation">
                                  <span
                                    style={{
                                      textDecoration: 'underline',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() =>
                                      openEditAccommodationPriceModal(
                                        accommodation,
                                        'accommodation'
                                      )
                                    }
                                  >
                                    {parseFloat(
                                      accommodation.totalThisAccommodation
                                        .onlyAccommodation
                                    ).toFixed(2)}
                                  </span>
                                </Tooltip>
                              )}
                              {user.editValues === 0 && (
                                <Tooltip title="Accommodation">
                                  <span>
                                    {parseFloat(
                                      accommodation.totalThisAccommodation
                                        .onlyAccommodation
                                    ).toFixed(2)}
                                  </span>
                                </Tooltip>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div
                      className="price-item-container"
                      style={{ marginTop: '10px' }}
                    >
                      <div className="price-item">
                        <span>Placement fee</span>
                      </div>
                      {showSubtotals && (
                        <div className="price-item--right">
                          <div className="edited-tag">
                            {Boolean(accommodation.editedPlacementFee) && (
                              <Tooltip title="Edited">
                                <p
                                  style={{
                                    color: 'orange',
                                    marginTop: '2px',
                                    marginBottom: '0px',
                                    marginRight: '10px',
                                    marginLeft: '10px',
                                    fontSize: '10px',
                                    position: 'relative',
                                  }}
                                >
                                  <MdMonetizationOn size={13} />
                                </p>
                              </Tooltip>
                            )}
                          </div>
                          <div
                            className={
                              accommodation.hasSpecialRates
                                ? 'price-item--value with-promotion'
                                : 'price-item--value'
                            }
                          >
                            <div
                              style={{
                                color: 'var(--primary)',
                                fontWeight: 'bold',
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              {Number(accommodation.discountPlacementFee) >
                                0 && (
                                <span
                                  className="discount-value"
                                  style={{ marginRight: '10px' }}
                                >
                                  {
                                    accommodation.totalThisAccommodation
                                      .currency
                                  }{' '}
                                  {parseFloat(
                                    Number(accommodation.discountPlacementFee) +
                                      Number(
                                        accommodation.totalThisAccommodation
                                          .onlyEnrol
                                      )
                                  ).toFixed(2)}
                                </span>
                              )}
                              {!(
                                Number(accommodation.discountPlacementFee) > 0
                              ) &&
                                accommodation.totalThisAccommodation
                                  .onlyEnrolWithoutSR >
                                  accommodation.totalThisAccommodation
                                    .onlyEnrol && (
                                  <span
                                    className="discount-value"
                                    style={{ marginRight: '10px' }}
                                  >
                                    {
                                      accommodation.totalThisAccommodation
                                        .currency
                                    }{' '}
                                    {parseFloat(
                                      accommodation.totalThisAccommodation
                                        .onlyEnrolWithoutSR
                                    ).toFixed(2)}
                                  </span>
                                )}
                              {accommodation.hasSpecialRates &&
                                !accommodation.editedPlacementFee &&
                                accommodation.hasEnrolSR && (
                                  <Tooltip title="Has special rates">
                                    <IoIosPricetags
                                      size={15}
                                      className="with-promotion--icon"
                                      style={{
                                        marginRight: 4,
                                        verticalAlign: 'initial',
                                      }}
                                    />
                                  </Tooltip>
                                )}
                              <span style={{ marginRight: '5px' }}>
                                {accommodation.totalThisAccommodation.currency}
                              </span>
                              {user.editValues === 1 && (
                                <Tooltip title="Edit Placement Fee">
                                  <span
                                    style={{
                                      textDecoration: 'underline',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() =>
                                      openEditAccommodationPriceModal(
                                        accommodation,
                                        'placementfee'
                                      )
                                    }
                                  >
                                    {parseFloat(
                                      accommodation.totalThisAccommodation
                                        .onlyEnrol
                                    ).toFixed(2)}
                                  </span>
                                </Tooltip>
                              )}
                              {user.editValues === 0 && (
                                <Tooltip title="Placement Fee">
                                  <span>
                                    {parseFloat(
                                      accommodation.totalThisAccommodation
                                        .onlyEnrol
                                    ).toFixed(2)}
                                  </span>
                                </Tooltip>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    {accommodation.specialrate.filter(
                      (sr: {
                        rule: any | any[];
                        active: boolean;
                        type: string;
                      }) =>
                        (sr.rule &&
                          sr.rule !== undefined &&
                          sr.type !== 'freePeriod') ||
                        (sr.rule &&
                          sr.rule !== undefined &&
                          sr.type === 'freePeriod' &&
                          accommodation.price.srperiod.rule.discountPeriod >
                            0) ||
                        ((!sr.rule || sr.rule === undefined) && !sr.active)
                    ).length > 0 && (
                      <div className="promotion-container">
                        <strong>Available Special Rates</strong>
                        {accommodation.specialrate
                          .filter(
                            (sr: {
                              rule: any | any[];
                              active: boolean;
                              type: string;
                            }) =>
                              (sr.rule &&
                                sr.rule !== undefined &&
                                sr.type !== 'freePeriod') ||
                              (sr.rule &&
                                sr.rule !== undefined &&
                                sr.type === 'freePeriod' &&
                                sr.rule.discountPeriod > 0) ||
                              ((!sr.rule || sr.rule === undefined) &&
                                !sr.active)
                          )
                          .map((sr: any, srIdx: number) => (
                            <div className="price-item-container" key={srIdx}>
                              <div className="price-item">
                                {quote.plugAndPlay === 0 && (
                                  <CheckboxInput
                                    options={[
                                      {
                                        id: `sr_${quoteId}_${sr.id}_${accommodation.id}`,
                                        value: `sr_${quoteId}_${sr.id}`,
                                        label: sr.name,
                                      },
                                    ]}
                                    name={`sr-${sr.id}`}
                                    checked={sr.active ? true : false}
                                    onChange={e =>
                                      handleUpdateSR(
                                        index,
                                        accommodation,
                                        sr,
                                        e.target.checked ? true : false
                                      )
                                    }
                                  />
                                )}
                                {quote.plugAndPlay === 1 && (
                                  <label htmlFor="SrName">{sr.name}</label>
                                )}
                                {!showSpecialRate &&
                                  !isQuoteAllyPlus &&
                                  !isEmptyQuote && (
                                    <div className="quote-config-alert">
                                      <MdVisibilityOff size={13} />
                                      <span>
                                        This special rate will not be seen by
                                        the student
                                      </span>
                                    </div>
                                  )}
                              </div>
                              <div className="price-item--right">
                                {(sr.description ||
                                  (sr.courseStartDateFrom &&
                                    sr.courseStartDateTo)) && (
                                  // eslint-disable-next-line react/jsx-indent
                                  <Tooltip title="Instructions for agents">
                                    <FcIdea
                                      size={18}
                                      className="promotion-instruction--icon"
                                      onClick={() =>
                                        openInstructionsForAgentsModal(sr, 'sr')
                                      }
                                    />
                                  </Tooltip>
                                )}
                              </div>
                            </div>
                          ))}
                      </div>
                    )}

                    {accommodation.specialrate.filter(
                      (sr: {
                        rule: any | any[];
                        active: boolean;
                        type: string;
                      }) =>
                        ((!sr.rule || sr.rule === undefined) &&
                          sr.active &&
                          sr.type !== 'freePeriod') ||
                        (sr.rule &&
                          sr.rule !== undefined &&
                          sr.type === 'freePeriod' &&
                          accommodation.price.srperiod.rule.discountPeriod ===
                            0)
                    ).length > 0 && (
                      <div className="no-promotion-container">
                        <strong>Unavailable Special Rates</strong>
                        {accommodation.specialrate
                          .filter(
                            (sr: {
                              rule: any | any[];
                              active: boolean;
                              type: string;
                            }) =>
                              ((!sr.rule || sr.rule === undefined) &&
                                sr.active &&
                                sr.type !== 'freePeriod') ||
                              (sr.rule &&
                                sr.rule !== undefined &&
                                sr.type === 'freePeriod' &&
                                sr.rule.discountPeriod === 0)
                          )
                          .map((sr: any, srIdx: number) => (
                            <div className="price-item-container" key={srIdx}>
                              <span>
                                No promotion for{' '}
                                {`${accommodation.duration} ${accommodation.pricePeriod}(s)`}
                                <div className="price-item">
                                  <span>{sr.name}</span>
                                </div>
                              </span>

                              <div className="price-item--right">
                                {(sr.description ||
                                  (sr.courseStartDateFrom &&
                                    sr.courseStartDateTo)) && (
                                  // eslint-disable-next-line react/jsx-indent
                                  <Tooltip title="Instructions for agents">
                                    <FcIdea
                                      size={18}
                                      className="promotion-instruction--icon"
                                      onClick={() =>
                                        openInstructionsForAgentsModal(sr, 'sr')
                                      }
                                    />
                                  </Tooltip>
                                )}
                              </div>
                            </div>
                          ))}
                      </div>
                    )}

                    {showSubtotals && (
                      <div className="price-item-subtotal">
                        <span>Total</span>
                        <span>{accommodation.subtotalFormatted}</span>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
            {fees.length > 0 && (
              <div className="price price-fee">
                <h4 className="price-item--title">Fees</h4>
                {fees.map((fee: any, idx: number) => (
                  <div className="item" key={idx}>
                    <div
                      style={{
                        float: 'right',
                        display: 'flex',
                        top: '-30px',
                        position: 'relative',
                        minWidth: '45px',
                        right: '-10px',
                        background: '#f4f6f8',
                      }}
                    >
                      {fee.description && (
                        <Tooltip title="Instructions for agents">
                          <Instructions
                            onClick={() =>
                              openInstructionsForAgentsModal(fee, 'fee')
                            }
                          >
                            <FcIdea style={{ marginTop: '-4px' }} size={16} />
                          </Instructions>
                        </Tooltip>
                      )}
                      <Tooltip title="Details">
                        <Details
                          onClick={() =>
                            openItemDescriptionModal({
                              item: fee,
                              id: fee.id,
                              type: filtersOptions.FILTERS_ADDONS,
                            })
                          }
                        >
                          <BiMessageAltDetail size={15} />
                        </Details>
                      </Tooltip>

                      <Tooltip title="Remove item">
                        <RemoveItem
                          onClick={() =>
                            handleRemoveItemFromQuote(
                              index,
                              filtersOptions.FILTERS_ADDONS,
                              fee.id
                            )
                          }
                        >
                          <MdDelete size={16} />
                        </RemoveItem>
                      </Tooltip>
                    </div>

                    <div
                      className="resume-details--school"
                      style={{ fontSize: '12px' }}
                    >
                      {fee.partner}
                    </div>
                    <div className="price-item-container title">
                      <div className="price-item">
                        <span>{fee.name}</span>
                      </div>
                    </div>
                    {fee.pricePeriod !== 'fixed' && (
                      <>
                        <div
                          className="price-item-info"
                          onClick={() => openEditFeeDurationModal(fee)}
                          style={{
                            textDecoration: 'underline',
                            cursor: 'pointer',
                            textDecorationColor: 'lightgray',
                          }}
                        >
                          <FaCalendarAlt
                            size={13}
                            style={{ verticalAlign: 'top' }}
                          />
                          {fee.pricePeriod !== 'fixed' && (
                            <>
                              <span>
                                {`${fee.duration} ${fee.pricePeriod}${
                                  fee.duration > 1 ? '(s)' : ''
                                }`}
                              </span>
                            </>
                          )}
                        </div>
                      </>
                    )}
                    <div className="resume-city" style={{ fontSize: '10px' }}>
                      {fee.category && <span>{fee.category.name}</span>}
                    </div>

                    <div
                      className="price-item-container"
                      style={{ marginTop: '10px' }}
                    >
                      <div className="price-item">
                        <span>Fee</span>
                      </div>
                      {showSubtotals && (
                        <div className="price-item--right">
                          <div className="edited-tag">
                            {Boolean(fee.editedFee) && (
                              <Tooltip title="Edited">
                                <p
                                  style={{
                                    color: 'orange',
                                    marginTop: '2px',
                                    marginBottom: '0px',
                                    marginRight: '10px',
                                    marginLeft: '10px',
                                    fontSize: '10px',
                                    position: 'relative',
                                  }}
                                >
                                  <MdMonetizationOn size={13} />
                                </p>
                              </Tooltip>
                            )}
                          </div>
                          <div className="price-item--value">
                            <div
                              style={{
                                color: 'var(--primary)',
                                fontWeight: 'bold',
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              {Number(fee.discountFee) > 0 && (
                                <span
                                  className="discount-value"
                                  style={{ marginRight: '10px' }}
                                >
                                  {fee.totalThisFee.currency}{' '}
                                  {parseFloat(
                                    Number(fee.discountFee) +
                                      Number(fee.totalThisFee.value)
                                  ).toFixed(2)}
                                </span>
                              )}
                              <span style={{ marginRight: '5px' }}>
                                {fee.totalThisFee.currency}
                              </span>
                              {(user.editValues === 1  || fee.partner_id === 7109) && (
                                <Tooltip title="Edit fee">
                                  <span
                                    style={{
                                      textDecoration: 'underline',
                                      cursor: 'pointer',
                                    }}
                                    onClick={() =>
                                      openEditFeePriceModal(fee, 'fee')
                                    }
                                  >
                                    {parseFloat(fee.totalThisFee.value).toFixed(
                                      2
                                    )}
                                  </span>
                                </Tooltip>
                              )}
                              {user.editValues === 0 && fee.partner_id !== 7109 && (
                                <Tooltip title="Fee">
                                  <span>
                                    {parseFloat(fee.totalThisFee.value).toFixed(
                                      2
                                    )}
                                  </span>
                                </Tooltip>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
            {otherfees.length > 0 && (
              <div className="price price-otherfee">
                {quote.plugAndPlay === 0 && (
                  <h4 className="price-item--title">
                    Other fees and discounts
                  </h4>
                )}
                {quote.plugAndPlay === 1 && (
                  <div className="price-item--title">Discounts</div>
                )}
                {otherfees.map((otherfee: any, idx: number) => (
                  <div className="item" key={idx}>
                    <div className="price-item-container title">
                      <div
                        className={`price-item${
                          (!showAddition && otherfee.type === 'fee') ||
                          (!showDiscount && otherfee.type === 'discount')
                            ? ' opacity'
                            : ''
                        }`}
                      >
                        {otherfee.description}
                      </div>
                      <div className="price-item--right">
                        {showSubtotals && (
                          <>
                            {/* {otherfee.oldValueFormatted && (
                              <div className="price-item--old-value">
                                <span>{otherfee.oldValueFormatted}</span>
                                <MdLocalOffer size={12} />
                              </div>
                            )} */}
                            <div className="price-item--value">
                              <span>{otherfee.valueFormatted}</span>
                              {/* TODO: Botão que será usado quando puder alterar o valor do item */}
                              {/* <MdCreate size={14} onClick={() => {}} /> */}
                            </div>
                          </>
                        )}
                        <div className="actions">
                          <Tooltip title="Remove item">
                            <RemoveItem
                              onClick={() =>
                                handleRemoveItemFromQuote(
                                  index,
                                  filtersOptions.FILTERS_OTHERS,
                                  otherfee.id
                                )
                              }
                            >
                              <MdDelete size={16} />
                              {/* <span>Remove this item</span> */}
                            </RemoveItem>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                    {!showAddition &&
                      otherfee.type === 'fee' &&
                      !isQuoteAllyPlus &&
                      !isEmptyQuote && (
                        <div className="quote-config-alert">
                          <MdVisibilityOff size={13} />
                          <span>This fee will not be seen by the student</span>
                        </div>
                      )}
                    {!showDiscount &&
                      otherfee.type === 'discount' &&
                      !isQuoteAllyPlus &&
                      !isEmptyQuote && (
                        <div className="quote-config-alert">
                          <MdVisibilityOff size={13} />
                          <span>
                            This discount will not be seen by the student
                          </span>
                        </div>
                      )}

                    {showSubtotals && (
                      <div className="price-item-subtotal">
                        <span>Total</span>
                        <span>{otherfee.subtotalFormatted}</span>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
            {!isUserAllyPlus && !isQuoteAllyPlus && (
              <>
                <PriceButtons style={{ borderTop: '0', marginTop: '0' }}>
                  <Button
                    type="button"
                    className="btn-outline"
                    onClick={openAddCustomFeeAndDiscountModal}
                  >
                    Add Custom Fee
                  </Button>
                </PriceButtons>
              </>
            )}
            {isQuoteAllyPlus && <hr />}
            <div
              className={`price-item-subtotal${!showSubtotals ? ' hide' : ''}`}
            >
              <span>Total(s)</span>
              <span>
                {totalsByCurrency &&
                  totalsByCurrency.map(
                    (total: TotalByCurrency, idx: number) => (
                      <span className="block" key={idx}>
                        {total.value_without_sr > 0 &&
                          total.value_without_sr !== total.value &&
                          total.value_without_srFormatted && (
                            <span className="line-through">
                              {total.value_without_srFormatted} <br />
                            </span>
                          )}
                        {total.valueFormatted}
                      </span>
                    )
                  )}
              </span>
            </div>
            <div className="price-item-total">
              <span>Total Converted</span>
              <span className="price-item-total--value">
                {totalConverted &&
                  totalConverted.value_without_sr > 0 &&
                  totalConverted.value_without_sr !== totalConverted.value &&
                  totalConverted.value_without_srFormatted && (
                    <span className="line-through converted_value_without_sr">
                      {totalConverted.value_without_srFormatted} <br />
                    </span>
                  )}
                {totalConverted && totalConverted.valueFormatted}
              </span>
            </div>
            {quoteCurrencies && quoteCurrencies.length > 0 && (
              <>
                <div className="price-item-currencies">
                  {quoteCurrencies.map((rule: OfficeCurrency, idx: number) => (
                    <div key={idx}>
                      {(rule.destiny_code !== user.office.default_code ||
                        isRenewal) &&
                        (onlyAdminEditCurrency === false || isAdmin) && (
                          <>
                            {rule.currency.name}:
                            {hasPermissionToEditCurrency &&
                            !isQuoteAllyPlus &&
                            (isUserHibrid || isUserBackOffice) ? (
                              <Editable
                                text={`${
                                  user.office.currency.symbol
                                } ${formatPrice(rule.value)}`}
                                className="editing-currency"
                                currencyCode={rule.destiny_code}
                                updateCurrencyValue={updateCurrencyValue}
                              >
                                <CurrencyInput
                                  name={`${quoteId}-currency-editing-${rule.destiny_code}`}
                                  defaultValue={ruleValues[rule.destiny_code]}
                                  intlConfig={{
                                    locale: 'en-US',
                                    currency: user.office.default_code,
                                  }}
                                  onValueChange={value =>
                                    handleInputValueChange(
                                      value,
                                      rule.destiny_code
                                    )
                                  }
                                  fixedDecimalLength={2}
                                  placeholder={user.office.currency.symbol}
                                  disableGroupSeparators
                                  autoFocus
                                />
                              </Editable>
                            ) : (
                              ` ${
                                isQuoteAllyPlus
                                  ? 'R$'
                                  : user.office.currency.symbol
                              } ${formatPrice(
                                rule.value,
                                undefined,
                                isUserAllyPlus ||
                                  (isUserHibrid && isQuoteAllyPlus)
                              )}`
                            )}
                          </>
                        )}
                      {rule.destiny_code !== user.office.default_code &&
                        onlyAdminEditCurrency === true &&
                        !isAdmin && (
                          <>
                            {rule.currency.name}:
                            {hasPermissionToEditCurrency &&
                              ` ${user.office.currency.symbol} ${formatPrice(
                                rule.value
                              )}`}
                          </>
                        )}
                    </div>
                  ))}
                </div>
                {!showCurrency && !isQuoteAllyPlus && !isEmptyQuote && (
                  <div className="quote-config-alert text-right">
                    <MdVisibilityOff size={13} />
                    <span>
                      These currency conversion values will not be seen by the
                      student
                    </span>
                  </div>
                )}
              </>
            )}

            {(user.office.account.moduleType === 7 ||
              user.office.account.moduleType >= 15) &&
              (quote.plugAndPlay === 0 ||
                (quote.plugAndPlay === 1 &&
                  quote.convertedCurrency.code !== 'BRL')) && (
                <>
                  <PriceButtons style={{ borderTop: '0', marginTop: '0' }}>
                    <Button type="button" onClick={paymentPlanButtonClick}>
                      {paymentplan.length === 0 ? (
                        'Add Payment Plan'
                      ) : (
                        <>
                          <MdVisibility size={12} />
                          <span>{`(${paymentplan.length}) Payment Plans`}</span>
                        </>
                      )}
                    </Button>

                    {/* {paymentplan.length === 0 && (
                      <PaymentPlanContainer>
                        <div className="payment-plan--empty">
                          Without a payment plan
                        </div>
                      </PaymentPlanContainer>
                    )} */}
                  </PriceButtons>
                  {user.account_id === 2015 && (
                    <>
                      <PaymentTypeContainer
                        style={{
                          paddingBottom: '15px',
                          borderBottom: 'solid 1px #ccc',
                        }}
                      >
                        <CheckboxContainer
                          style={{
                            width: '100%',
                            height: '20px',
                            display: 'inline-block',
                            alignContent: 'center',
                          }}
                        >
                          <label>
                            <input
                              checked={showPoupancinha}
                              type="checkbox"
                              onChange={() => {
                                setShowPoupancinha(prevState => !prevState);
                              }}
                            />
                            <span className="checkmark" />
                            <span className="checkbox-text checkbox-text-big">
                              Poupancinha flexível
                            </span>
                          </label>
                        </CheckboxContainer>

                        <div className="entry">
                          <div className="radio-container">
                            <small>
                              <strong>entrada</strong>
                            </small>

                            <div className="radio-inputs">
                              <RadioContainer style={{ marginRight: '10px' }}>
                                <label>
                                  <input
                                    disabled={!showPoupancinha}
                                    checked={
                                      poupancinhaEntryType === 'percentage'
                                        ? true
                                        : false
                                    }
                                    name="poupancinhaEntryType"
                                    defaultValue="percentage"
                                    type="radio"
                                    onChange={({ target: { value } }) => {
                                      setPoupancinhaEntryType(value);
                                    }}
                                  />

                                  <span
                                    className={`checkmark${
                                      !showPoupancinha ? ' input-disabled' : ''
                                    }`}
                                  />
                                  <span className="radio-text">%</span>
                                </label>
                              </RadioContainer>

                              <RadioContainer>
                                <label>
                                  <input
                                    disabled={!showPoupancinha}
                                    name="poupancinhaEntryType"
                                    checked={
                                      poupancinhaEntryType !== 'percentage'
                                        ? true
                                        : false
                                    }
                                    type="radio"
                                    onChange={({ target: { value } }) => {
                                      setPoupancinhaEntryType(value);
                                    }}
                                  />

                                  <span
                                    className={`checkmark${
                                      !showPoupancinha ? ' input-disabled' : ''
                                    }`}
                                  />
                                  <span className="radio-text">Valor (R$)</span>
                                </label>
                              </RadioContainer>
                            </div>
                          </div>

                          <div className="entry-input">
                            <Input
                              disabled={!showPoupancinha}
                              type="number"
                              defaultValue={poupancinhaValue}
                              name="poupancinhaValue"
                              style={{ width: '100%', padding: '5px' }}
                              className={`${
                                !showPoupancinha ? 'input-disabled' : ''
                              }`}
                              onChange={({ target: { value } }) => {
                                setPoupancinhaValue(Number(value));
                              }}
                            />
                          </div>
                        </div>

                        <div className="split-input">
                          <small style={{ fontSize: '1rem' }}>
                            <strong>max. parcelas</strong>
                          </small>

                          <Input
                            disabled={!showPoupancinha}
                            type="number"
                            defaultValue={poupancinhaMaxInstallments}
                            name="maxSplits"
                            style={{ width: '100%', padding: '5px' }}
                            className={`${
                              !showPoupancinha ? 'input-disabled' : ''
                            }`}
                            onChange={({ target: { value } }) => {
                              value = Number(
                                Math.max(1, Math.min(12, Number(value)))
                              );
                              setPoupancinhaMaxInstallments(Number(value));
                            }}
                          />
                        </div>
                      </PaymentTypeContainer>

                      <PaymentTypeContainer
                        style={{
                          paddingBottom: '15px',
                          paddingTop: '10px',
                          borderBottom: 'solid 1px #ccc',
                        }}
                      >
                        <CheckboxContainer
                          style={{
                            width: '100%',
                            height: '20px',
                            display: 'inline-block',
                            alignContent: 'center',
                            marginTop: '10px',
                          }}
                        >
                          <label>
                            <input
                              checked={showCardWithEntry}
                              type="checkbox"
                              onChange={() =>
                                setShowCardWithEntry(prevState => !prevState)
                              }
                            />
                            <span className="checkmark" />
                            <span className="checkbox-text checkbox-text-big">
                              Cartão com entrada
                            </span>
                          </label>
                        </CheckboxContainer>

                        <div className="entry">
                          <div className="radio-container">
                            <small>
                              <strong>entrada</strong>
                            </small>

                            <div className="radio-inputs">
                              <RadioContainer style={{ marginRight: '10px' }}>
                                <label>
                                  <input
                                    disabled={!showCardWithEntry}
                                    checked={
                                      cardEntryType === 'percentage'
                                        ? true
                                        : false
                                    }
                                    name="entryCard"
                                    defaultValue="percentage"
                                    type="radio"
                                    onChange={({ target: { value } }) => {
                                      setCardEntryType(value);
                                    }}
                                  />

                                  <span
                                    className={`checkmark${
                                      !showCardWithEntry
                                        ? ' input-disabled'
                                        : ''
                                    }`}
                                  />
                                  <span className="radio-text">%</span>
                                </label>
                              </RadioContainer>

                              <RadioContainer>
                                <label>
                                  <input
                                    disabled={!showCardWithEntry}
                                    checked={
                                      cardEntryType !== 'percentage'
                                        ? true
                                        : false
                                    }
                                    name="entryCard"
                                    type="radio"
                                    onChange={({ target: { value } }) => {
                                      setCardEntryType(value);
                                    }}
                                  />

                                  <span
                                    className={`checkmark${
                                      !showCardWithEntry
                                        ? ' input-disabled'
                                        : ''
                                    }`}
                                  />
                                  <span className="radio-text">Valor (R$)</span>
                                </label>
                              </RadioContainer>
                            </div>
                          </div>

                          <div className="entry-input">
                            <Input
                              disabled={!showCardWithEntry}
                              type="number"
                              defaultValue={cardValue}
                              style={{ width: '100%', padding: '5px' }}
                              className={`${
                                !showCardWithEntry ? 'input-disabled' : ''
                              }`}
                              name="cardValue"
                              onChange={({ target: { value } }) => {
                                setCardValue(Number(value));
                              }}
                            />
                          </div>
                        </div>

                        <div className="split-input">
                          <small style={{ fontSize: '1rem' }}>
                            <strong>max. parcelas</strong>
                          </small>

                          <Input
                            disabled={!showCardWithEntry}
                            type="number"
                            defaultValue={cardWithEntryMaxInstallments}
                            style={{ width: '100%', padding: '5px' }}
                            className={`${
                              !showCardWithEntry ? 'input-disabled' : ''
                            }`}
                            name="maxSplits"
                            onChange={({ target: { value } }) => {
                              value = Number(
                                Math.max(1, Math.min(12, Number(value)))
                              );
                              setCardWithEntryMaxInstallments(Number(value));
                            }}
                          />
                        </div>
                      </PaymentTypeContainer>

                      <PaymentTypeContainer
                        style={{
                          marginBottom: '15px',
                          paddingBottom: '15px',
                          paddingTop: '10px',
                          borderBottom: 'solid 1px #ccc',
                        }}
                      >
                        <CheckboxContainer
                          style={{
                            width: '140px',
                            height: '55px',
                            display: 'flex',
                            alignContent: 'center',
                          }}
                        >
                          <label>
                            <input
                              checked={showSplit}
                              type="checkbox"
                              onChange={() =>
                                setShowSplit(prevState => !prevState)
                              }
                            />
                            <span className="checkmark" />
                            <span className="checkbox-text checkbox-text-big">
                              Parcelado
                            </span>
                          </label>
                        </CheckboxContainer>

                        <div className="split-input">
                          <small style={{ fontSize: '1rem' }}>
                            <strong>max. parcelas</strong>
                          </small>

                          <Input
                            disabled={!showSplit}
                            type="number"
                            defaultValue={splitMaxInstallments}
                            style={{ width: '100%', padding: '5px' }}
                            className={`${!showSplit ? 'input-disabled' : ''}`}
                            name="maxSplits"
                            onChange={({ target: { value } }) => {
                              value = Number(
                                Math.max(1, Math.min(12, Number(value)))
                              );
                              setSplitMaxInstallments(Number(value));
                            }}
                          />
                        </div>
                      </PaymentTypeContainer>

                      <PaymentTypeContainer
                        style={{
                          marginBottom: '15px',
                          paddingBottom: '15px',
                          borderBottom: 'solid 1px #ccc',
                        }}
                      >
                        <CheckboxContainer
                          style={{
                            width: '140px',
                            height: '35px',
                            display: 'flex',
                            alignContent: 'center',
                          }}
                        >
                          <label>
                            <input
                              checked={showPravaler}
                              type="checkbox"
                              onChange={() =>
                                setShowPravaler(prevState => !prevState)
                              }
                            />
                            <span className="checkmark" />
                            <span className="checkbox-text checkbox-text-big">
                              Pravaler
                            </span>
                          </label>
                        </CheckboxContainer>
                      </PaymentTypeContainer>
                    </>
                  )}

                  {user.account_id === 683 && (
                    <PaymentTypeContainer
                      style={{
                        marginBottom: '15px',
                        paddingBottom: '15px',
                        borderBottom: 'solid 1px #ccc',
                      }}
                    >
                      <CheckboxContainer
                        style={{
                          width: '140px',
                          height: '35px',
                          display: 'flex',
                          alignContent: 'center',
                        }}
                      >
                        <label>
                          <input
                            checked={showPravaler}
                            type="checkbox"
                            onChange={() =>
                              setShowPravaler(prevState => !prevState)
                            }
                          />
                          <span className="checkmark" />
                          <span className="checkbox-text checkbox-text-big">
                            Pravaler
                          </span>
                        </label>
                      </CheckboxContainer>
                    </PaymentTypeContainer>
                  )}
                </>
              )}

            <div style={{ height: '30px' }}>
              <FaCalendarAlt
                size={13}
                style={{
                  display: 'inline',
                  marginRight: '10px',
                  verticalAlign: 'top',
                }}
              />
              due date:
              <div
                className=""
                style={{
                  display: 'inline',
                  marginLeft: '10px',
                  marginTop: '-5px',
                }}
              >
                <DatePicker
                  name="quoteDueDate"
                  containerStyle={{
                    border: '0',
                    display: 'table-cell',
                  }}
                  dateFormat="MMMM d"
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: '-98px, 0px',
                    },
                  }}
                  onSelect={date =>
                    handleChangeQuoteDueDate(index, quoteId, date)
                  }
                  className={
                    !showDueDate ? ' opacity quoteDueDate' : 'quoteDueDate'
                  }
                  disabled={!isQuoteAllyPlus ? false : true}
                />
                {!showDueDate && !isQuoteAllyPlus && !isEmptyQuote && (
                  <div className="quote-config-alert">
                    <MdVisibilityOff size={13} />
                    <span>This due date will not be seen by the student</span>
                  </div>
                )}
              </div>
            </div>
            <hr />
            <Collapsible
              transitionTime={200}
              className="more-filters advanced-filters"
              openedClassName="more-filters advanced-filters opened"
              trigger="⌄ Advanced options"
              triggerClassName="trigger"
              triggerOpenedClassName="trigger opened"
              contentInnerClassName="content"
              style={{ textAlign: 'right' }}
            >
              <div className="form-group">
                <div className="form-group--description">
                  <label
                    htmlFor="description"
                    className="quote-description--label"
                  >
                    Description:
                    <MdCreate size={14} onClick={onEditDescriptionClick} />
                  </label>
                  <div className="quote-description">{descriptionLabel}</div>
                </div>
              </div>

              {!isUserAllyPlus && !isQuoteAllyPlus && (
                <div className="form-group">
                  <div className="form-group--convert">
                    <label htmlFor="convertValues">Convert values to:</label>
                    <Select
                      options={officeCurrencies}
                      name="convertValues"
                      id="convertValues"
                      defaultValue={convertedCurrency}
                      placeholder="Select currency"
                      onChange={value => convertValuesChange(value)}
                    />
                  </div>
                </div>
              )}

              {!isQuoteAllyPlus && (
                <>
                  <div className="price-title">Preferences</div>
                  <div className="form-group">
                    <div className="form-group--translation">
                      <label htmlFor="translation">Translation:</label>
                      <Select
                        options={translationOptions}
                        name="translation"
                        id="translation"
                        placeholder="Select language"
                        defaultValue={defaultTranslate}
                        onChange={onChangeTranslation}
                      />
                    </div>
                  </div>

                  <div className="form-group--sale-type">
                    <label htmlFor="quoteSaleType">Sale type:</label>
                    <Select
                      options={saleTypes}
                      name="quoteSaleType"
                      id="quoteSaleType"
                      isClearable
                      placeholder="Select sale type"
                      onChange={salectedSaleType =>
                        handleChangeQuoteSaleType(
                          index,
                          quoteId,
                          salectedSaleType
                        )
                      }
                    />
                  </div>
                  <br />
                </>
              )}
              {!isUserAllyPlus && !isQuoteAllyPlus && (
                <div className="form-group">
                  <div className="form-group--preferences">
                    <label htmlFor="preferences">Options:</label>
                    <CheckboxInput
                      options={[
                        {
                          id: `showdiscount_${quoteId}`,
                          value: `showdiscount_${quoteId}`,
                          label: 'Show other fees (discounts only)',
                        },
                      ]}
                      name="showdiscount"
                      defaultChecked={showDiscount}
                      onChange={e =>
                        handleUpdateQuoteConfigPreferences(
                          index,
                          quoteId,
                          'showdiscount',
                          e.target.checked
                        )
                      }
                    />
                    <CheckboxInput
                      options={[
                        {
                          id: `showaddition_${quoteId}`,
                          value: `showaddition_${quoteId}`,
                          label: 'Show other fees (additionals only)',
                        },
                      ]}
                      name="showaddition"
                      defaultChecked={showAddition}
                      onChange={e =>
                        handleUpdateQuoteConfigPreferences(
                          index,
                          quoteId,
                          'showaddition',
                          e.target.checked
                        )
                      }
                    />
                    <CheckboxInput
                      options={[
                        {
                          id: `showspecialrate_${quoteId}`,
                          value: `showspecialrate_${quoteId}`,
                          label: 'Show special rates',
                        },
                      ]}
                      name="showspecialrate"
                      defaultChecked={showSpecialRate}
                      onChange={e =>
                        handleUpdateQuoteConfigPreferences(
                          index,
                          quoteId,
                          'showspecialrate',
                          e.target.checked
                        )
                      }
                    />
                    <CheckboxInput
                      options={[
                        {
                          id: `showduedate_${quoteId}`,
                          value: `showduedate_${quoteId}`,
                          label: 'Show quote due date',
                        },
                      ]}
                      name="showduedate"
                      defaultChecked={showDueDate}
                      onChange={e =>
                        handleUpdateQuoteConfigPreferences(
                          index,
                          quoteId,
                          'showduedate',
                          e.target.checked
                        )
                      }
                    />
                    <CheckboxInput
                      options={[
                        {
                          id: `showcurrency_${quoteId}`,
                          value: `showcurrency_${quoteId}`,
                          label: 'Show currency conversions values',
                        },
                      ]}
                      name="showcurrency"
                      defaultChecked={showCurrency}
                      onChange={e =>
                        handleUpdateQuoteConfigPreferences(
                          index,
                          quoteId,
                          'showcurrency',
                          e.target.checked
                        )
                      }
                    />
                    <CheckboxInput
                      options={[
                        {
                          id: `showsubtotals_${quoteId}`,
                          value: `showsubtotals_${quoteId}`,
                          label: 'Show partial totals',
                        },
                      ]}
                      name="showsubtotals"
                      checked={showSubtotals ? true : false}
                      disabled={disabledSubtotals}
                      className={classNamesSubtotals}
                      onChange={e =>
                        handleUpdateQuoteConfigPreferences(
                          index,
                          quoteId,
                          'showsubtotals',
                          e.target.checked
                        )
                      }
                    />
                    {disabledSubtotals && !isQuoteAllyPlus && !isEmptyQuote && (
                      <div className="quote-config-alert">
                        <MdVisibilityOff size={13} />
                        <span>
                          If you hide any other fee, the partial totals will be
                          hidden automatically
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Collapsible>
          </Price>
        </Form>
      )}
    </Container>
  );
};

export default ResumeItem;
