import React from 'react';
import { MdClose } from 'react-icons/md';
import { TiInputChecked } from 'react-icons/ti';
import Modal from 'react-modal';
import Button from '../../components/Button';
import { IAddedOptionsModal } from '../../shared/interfaces';
import { Container, ModalContent, ModalHeader } from './styles';

const AddedOptionsModal: React.FC<IAddedOptionsModal> = ({
  isOpen,
  setIsAddedOptionsModalOpened,
  insertedAt,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsAddedOptionsModalOpened(false)}
      style={{
        overlay: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          backgroundColor: 'var(--overlay)',
        },
        content: {
          padding: 0,
          borderRadius: 0,
          position: 'initial',
          boxShadow: 'var(--modal-shadow)',
          border: '1px solid var(--divider)',
          width: '300px',
          overflowX: 'hidden',
        },
      }}
    >
      <Container>
        <ModalHeader>
          <span>Added Options</span>
          <Button
            type="button"
            className="btn-transparent"
            onClick={() => setIsAddedOptionsModalOpened(false)}
          >
            <MdClose size={20} />
          </Button>
        </ModalHeader>

        <ModalContent>
          {insertedAt.map(item => (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                margin: '5px 0',
              }}
            >
              <TiInputChecked size={22} color="var(--green)" />
              <span style={{ marginLeft: '5px' }}>{item}</span>
            </div>
          ))}
        </ModalContent>
      </Container>
    </Modal>
  );
};

export default AddedOptionsModal;
