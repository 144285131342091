import React, { useEffect, useState } from 'react';
import { MdAdd, MdRemove } from 'react-icons/md';

import { useWizard } from '../../../../hooks/wizard';

import { Container } from './styles';

interface ItemCountProps {
  Courses: number;
  Accommodations: number;
  Insurances: number;
  'Add-ons': number;
  Experiences: number;
}

interface TriggerProps {
  type: 'Courses' | 'Accommodations' | 'Insurances' | 'Add-ons' | 'Experiences';
  isOpen: boolean;
}

const Trigger: React.FC<TriggerProps> = ({ type, isOpen }) => {
  const [itemCount, setItemCount] = useState({} as ItemCountProps);
  const { quotesData } = useWizard();

  useEffect(() => {
    const count = quotesData.quotes?.reduce(
      (acc: ItemCountProps, quote) => {
        if (type === 'Insurances' || type === 'Add-ons') {
          quote.fees.forEach((fee: any) => {
            if (fee.category_id === 4) {
              acc.Insurances += 1;
            } else {
              acc['Add-ons'] += 1;
            }
          });
        } else {
          acc[type] += quote[type.toLowerCase()].length;
        }

        return acc;
      },
      {
        Courses: 0,
        Accommodations: 0,
        Insurances: 0,
        'Add-ons': 0,
        Experiences: 0,
      }
    );

    if (!count) return;

    setItemCount(count);
  }, [quotesData, type]);

  return (
    <Container>
      <div style={{ display: 'grid' }}>
        {type === 'Courses' ? 'Programs' : type}
        {isOpen && <span className="text-muted">Press enter to search</span>}
      </div>
      {itemCount[type] > 0 && (
        <span className="counter" style={{ marginRight: 5 }}>
          (&nbsp;{itemCount[type]} added&nbsp;)
        </span>
      )}
      <span style={{ display: 'flex', color: 'var(--primary)' }}>
        {isOpen ? <MdRemove size={20} /> : <MdAdd size={20} />}
      </span>
    </Container>
  );
};

export default Trigger;
