import React, {
  useRef,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import Collapsible from 'react-collapsible';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import LoadingBar from 'react-top-loading-bar';

import {
  format,
  parseJSON,
  parseISO,
  add,
  getDay,
  endOfWeek,
  subWeeks,
  subDays,
} from 'date-fns';
import InputRange from 'react-input-range';
import Switch from 'react-switch';
import Carousel from 'react-multi-carousel';
import {
  MdSort,
  MdAttachMoney,
  MdAdd,
  MdRemove,
  MdKeyboardBackspace,
  MdLiveHelp,
  MdInfo,
} from 'react-icons/md';
import { IoMdEyeOff, IoMdEye } from 'react-icons/io';
import swal from 'sweetalert';
import 'react-input-range/lib/css/index.css';
import 'react-multi-carousel/lib/styles.css';
import ReactDatePicker from 'react-datepicker';
import { object } from 'yup/lib/locale';
import Slider from '@mui/material/Slider';
import { Dropdown } from 'react-bootstrap';
import { OptionTypeBase } from 'react-select';
import api from '../../services/api';

import { useStateCallback } from '../../hooks/useStateCallback';
import { useAuth } from '../../hooks/auth';
import { useWizard } from '../../hooks/wizard';
import { useToast } from '../../hooks/toast';
import formatValue from '../../utils/formatValue';
import updateCollapsiblesOverflow from '../../utils/updateCollapsiblesOverflow';
import { filtersOptions } from '../../shared/constants';

import Button from '../../components/Button';
import ButtonWithDropdown from '../../components/ButtonWithDropdown';
import Breadcrumb from '../../components/Breadcrumb';
import Input from '../../components/Input';
import Select from '../../components/Select';
import RadioInput from '../../components/RadioInput';
import DatePicker from '../../components/DatePicker';
import ListItem from '../../components/ListItem';
import ResumeItem from '../../components/ResumeItem';
import CheckboxInput from '../../components/CheckboxInput';

import AddItemToQuoteModal from '../../modals/AddItemToQuoteModal';

import accommodationOptions from './data/accommodationOptions.json';

import { formatingMandatoryAndSuggestiveFees, updateOneFee } from './fees';

import {
  Country,
  CountryOption,
  CountryCOption,
  City,
  CityOption,
  School,
  SchoolOption,
  Campus,
  CampusOption,
  Office,
  PartnerOption,
  CourseType,
  CourseTypeOption,
  Newcategory,
  NewcategoryOption,
  Subcategory,
  SubcategoryOption,
  FeeCategory,
  FeeCategoryOption,
  RestrictionOption,
  ProgramDurationTypeOption,
  AccommodationTypeOption,
  AccommodationRoomOption,
  AccommodationDurationTypeOption,
  AccommodationBathroomTypeOption,
  AccommodationRegimeTypeOption,
  FeeDurationTypeOption,
  SortOption,
  BreadcrumbOption,
  PaginateData,
  PriceRange,
  OfficeCurrency,
  CurrenciesOption,
  ISaleType,
  TotalByCurrency,
  ListItemFormData,
  FiltersFormData,
  LoadingProps,
  InfiniteScrollProps,
  QuoteType,
} from '../../shared/interfaces';

import {
  Container,
  Wrapper,
  Filters,
  FiltersHeader,
  FiltersContent,
  Collapsibles,
  Content,
  ContentHeader,
  ContentFilters,
  ContentWrapper,
  Actions,
  NoContent,
  Hero,
  ResultWrapper,
  ResultHeader,
  ActionsButtons,
  Result,
  ListView,
  Footer,
  Student,
  AllyPlusResult,
  DropdownToggle,
  DropdownMenu,
  InvisibleAllyPlusBtn,
} from './styles';

import { Container as DateContainer } from '../../components/DatePicker/styles';

import shuttleSvg from '../../assets/shuttle.svg';
import searchFailSvg from '../../assets/search-fail.svg';
import SuggestiveFeesModal from './components/SuggestiveFeesModal';
import Loading from '../../components/Loading';
import Trigger from './components/Trigger';
import Tooltip from '../../components/Tooltip';
import SelectAllyPlus from '../../components/SelectAllyPlus';
import allyPlusIcon from '../../assets/allyPlusIcon.png';

interface Map {
  [key: string]: string | undefined;
}

let filteringActive = false;

const ppOptions = [{ id: 'school', label: 'From Schools' }];

let myNewSuggestions: Map = {};
let mutexForSuggestion: Promise<Map>;
let unlockMutex: Function = () => null;

const ButtonWithLoading: React.FC<LoadingProps> = ({
  isLoading,
  children,
  className,
  style,
  ...otherProps
}) => {
  if (isLoading) {
    return (
      <Button
        type="button"
        loading
        className={className}
        style={{
          ...style,
          marginTop: '2rem',
        }}
      >
        <Loading loading color="var(--white)" />
      </Button>
    );
  }
  return (
    <Button
      className={className}
      style={style}
      {...otherProps}
      disabled={isLoading}
    >
      {children}
    </Button>
  );
};

const InfiniteScrollButton: React.FC<InfiniteScrollProps> = ({
  loadMoreClick,
  isLoading,
  hasMore,
}) => {
  if (isLoading) {
    return (
      <Button type="button" loading className="btn-lg">
        <Loading loading color="var(--white)" />
      </Button>
    );
  }

  if (!hasMore) {
    return null;
  }

  return (
    <Button
      type="button"
      onClick={loadMoreClick}
      className="btn-white btn-outline btn-outline--gray"
    >
      Load More
    </Button>
  );
};

const Playground: React.FC = () => {
  const [programStartDate, setProgramStartDate] = useState(new Date());
  const [dateInfo, setDateInfo] = useState(false);
  const [currentYear, setCurrentYear] = useState<number>();
  const [textInfoTooltip, setTextInfoTooltip] = useState<string>('');
  const [dateOfReference, setDateOfReference] = useState(new Date());
  const [filterCountry, setFilterCountry] = useState<CountryOption[]>([]);
  const [filterCity, setFilterCity] = useState<CityOption[]>([]);
  const [filterSchool, setFilterSchool] = useState<SchoolOption[]>([]);
  const [filterCampus, setFilterCampus] = useState<CampusOption[]>([]);
  const [filterCampusOption, setFilterCampusOption] = useState<CampusOption[]>(
    []
  );
  const [newcategory, setNewcategory] = useState<NewcategoryOption[]>([]);
  const [subcategory, setSubcategory] = useState<SubcategoryOption[]>([]);
  const [restriction, setRestriction] = useState<RestrictionOption[]>([]);
  const [programDurationType, setProgramDurationType] = useState<
    ProgramDurationTypeOption[]
  >([]);
  const [accommodationDurationType, setAccommodationDurationType] = useState<
    AccommodationDurationTypeOption[]
  >([]);
  const [feeDurationType, setFeeDurationType] = useState<
    FeeDurationTypeOption[]
  >([]);
  const [, setAccommodationType] = useState<AccommodationTypeOption[]>([]);
  const [, setAccommodationBathroomType] = useState<
    AccommodationBathroomTypeOption[]
  >([]);
  const [, setAccommodationRegimeType] = useState<
    AccommodationRegimeTypeOption[]
  >([]);
  const [isSaveQuotesLoading, setIsSaveQuotesLoading] = useState(false);
  const [isAddItemLoading, setIsAddItemLoading] = useState(false);
  const [isAddOptionLoading, setIsAddOptionLoading] = useState(false);
  const [fiveOptions, setFiveOptions] = useState(false);
  const [defaultCheckedOptions, setDefaultCheckedOptions] = useState(false);
  const [sliderValue, setSliderValue] = useState<number[]>([0, 40]);
  const [viewAllyPlusOption, setViewAllyPlusOption] = useState<boolean>(false);
  const hideResultAllyPlus = useRef(false);
  const userPreferenceEdited = useRef(false);
  const handleChange = (event: Event, newValue: number | number[]) => {
    setSliderValue(newValue as number[]);
  };
  const [selectedSortOpt, setSelectedSortOpt] = useState<SortOption>(
    {} as SortOption
  );
  const [viewType, setViewType] = useState<String>('list');
  const [
    currentAccommodationType,
    setCurrentAccommodationType,
  ] = useState<String>('school');
  const [currentInsuranceType, setCurrentInsuranceType] = useState<String>(
    'school'
  );
  const [readyToSearch, setReadyToSearch] = useState(true);
  const [currentAddOnType, setCurrentAddOnType] = useState<String>('school');
  const [countryOptions, setCountryOptions] = useState<CountryOption[]>([]);
  const [countryCOptions, setCountryCOptions] = useState<CountryCOption[]>([]);
  const [countryCodes, setCountryCodes] = useState<String[]>([]);
  const [cityOptions, setCityOptions] = useState<CityOption[]>([]);
  const [cityIds, setCityIds] = useState<number[]>([]);
  const [newcategoryOptions, setNewcategoryOptions] = useState<
    NewcategoryOption[]
  >([]);
  const [subcategoryOptions, setSubcategoryOptions] = useState<
    SubcategoryOption[]
  >([]);
  const [schoolOptions, setSchoolOptions] = useState<SchoolOption[]>([]);
  const [campusOptions, setCampusOptions] = useState<CampusOption[]>([]);
  const [campusOptionsResponse, setCampusOptionsResponse] = useState(false);
  const [partnerOptions, setPartnerOptions] = useState<PartnerOption[]>([]);
  const [filterProvider, setFilterProvider] = useState<PartnerOption[]>([]);
  const [courseTypeOptions, setCourseTypeOptions] = useState<
    CourseTypeOption[]
  >([]);
  const [feeCategoryOptions, setFeeCategoryOptions] = useState<
    FeeCategoryOption[]
  >([]);
  const [restrictionOptions, setRestrictionOptions] = useState<
    RestrictionOption[]
  >([
    { value: 'online', label: 'online programs' },
    { value: 'renewal', label: 'renewal values' },
    { value: 'onshore', label: 'onshore values' },
    { value: 'offshore', label: 'offshore values' },
    { value: 'age', label: 'student age' },
  ]);

  const [programDurationTypeOptions, setProgramDurationTypeOptions] = useState<
    ProgramDurationTypeOption[]
  >([
    { value: 'fixed', label: 'fixed' },
    { value: 'hour', label: 'hours' },
    { value: 'day', label: 'days' },
    { value: 'week', label: 'weeks' },
    { value: 'month', label: 'months' },
    { value: 'term', label: 'terms' },
    { value: 'semester', label: 'semesters' },
    { value: 'year', label: 'years' },
    { value: 'unit', label: 'units' },
  ]);

  const [accommodationTypeOptions, setAccommodationTypeOptions] = useState<
    AccommodationTypeOption[]
  >([]);
  const [accommodationRoomOptions, setAccommodationRoomOptions] = useState<
    AccommodationRoomOption[]
  >([]);
  const [
    accommodationBathroomTypeOptions,
    setAccommodationBathroomTypeOptions,
  ] = useState<AccommodationBathroomTypeOption[]>([]);
  const [
    accommodationRegimeTypeOptions,
    setAccommodationRegimeTypeOptions,
  ] = useState<AccommodationRegimeTypeOption[]>([]);
  const [
    accommodationDurationTypeOptions,
    setAccommodationDurationTypeOptions,
  ] = useState<AccommodationDurationTypeOption[]>([
    { value: 'fixed', label: 'fixed' },
    { value: 'hour', label: 'hours' },
    { value: 'day', label: 'days' },
    { value: 'week', label: 'weeks' },
    { value: 'month', label: 'months' },
    { value: 'term', label: 'terms' },
    { value: 'semester', label: 'semesters' },
    { value: 'year', label: 'years' },
    { value: 'unit', label: 'units' },
  ]);
  const [feeDurationTypeOptions, setFeeDurationTypeOptions] = useState<
    FeeDurationTypeOption[]
  >([
    { value: 'fixed', label: 'fixed' },
    { value: 'lesson', label: 'lessons' },
    { value: 'session', label: 'sessions' },
    { value: 'hour', label: 'hours' },
    { value: 'day', label: 'days' },
    { value: 'week', label: 'weeks' },
    { value: 'month', label: 'months' },
    { value: 'term', label: 'terms' },
    { value: 'semester', label: 'semesters' },
    { value: 'year', label: 'years' },
    { value: 'unit', label: 'units' },
  ]);

  const modalFormRef = useRef<FormHandles>(null);
  const [sortOptions, setSortOptions] = useState<SortOption[]>([
    {
      label: 'Lowest Price',
      active: true,
      sortProp: 'price',
      sortType: 'asc',
      display: 'block',
    },
    // {
    //   label: 'Highest Price',
    //   active: false,
    //   sortProp: 'price',
    //   sortType: 'desc',
    //   display: 'block',
    // },
    {
      label: 'Most Sold',
      active: false,
      sortProp: 'popular',
      sortType: 'desc',
      display: 'block',
    },
    // {
    //   label: 'A-Z',
    //   active: false,
    //   sortProp: 'name',
    //   sortType: 'asc',
    //   display: 'block',
    // },
    // {
    //   label: 'Z-A',
    //   active: false,
    //   sortProp: 'name',
    //   sortType: 'desc',
    //   display: 'block',
    // },
  ]);
  const [priceRange, setPriceRange] = useState<PriceRange>({
    min: 0,
    max: 15000,
  });
  const [promotionOnly, setPromotionOnly] = useState<boolean>(false);
  const [onlyOnline, setOnlyOnline] = useState<boolean>(false);
  const [onlyRenewal, setOnlyRenewal] = useState<boolean>(false);
  const [onlyOnshore, setOnlyOnshore] = useState<boolean>(false);
  const [onlyOffshore, setOnlyOffshore] = useState<boolean>(false);
  const [onlyStudentAge, setOnlyStudentAge] = useState<boolean>(false);
  const [mainLoading, setMainLoading] = useState<boolean>(false);
  const [isSuggestiveFeesOpened, setIsSuggestiveFeesOpened] = useState<boolean>(
    false
  );
  const [progress, setProgress] = useState(0);

  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [resumeActionLoading, setResumeActionLoading] = useState({
    index: 0,
    loading: false,
  });
  const [breadcrumbOptions, setBreadcrumbOptions] = useState<
    BreadcrumbOption[]
  >([
    { label: 'Quote playground', active: true },
    { label: 'View Quotes', active: true },
  ]);
  const [resultItems, setResultItems] = useStateCallback<any[]>([]);
  const [suggestiveResult, setSuggestiveResult] = useStateCallback<any[]>([]);
  const [resultType, setResultType] = useState<string>(
    filtersOptions.FILTERS_COURSES
  );
  const [totalItems, setTotalItems] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(1);
  const [editingItem, setEditingItem] = useState<ListItemFormData>(
    {} as ListItemFormData
  );
  const [editingItemSuggestiveFees, setEditingItemSuggestiveFees] = useState<
    any[]
  >([]);
  const [cantOpenSuggestiveFee, setCantOpenSuggestiveFee] = useState<boolean>(
    false
  );
  const [editingQuoteOptions, setEditingQuoteOptions] = useState<string[]>([]);
  const [
    openAddItemToQuoteModal,
    setOpenAddItemToQuoteModal,
  ] = useState<boolean>(false);
  const [currentFiltersTab, setCurrentFiltersTab] = useState<string>(
    filtersOptions.FILTERS_COURSES
  );
  const [isCourseFiltersOpen, setIsCourseFiltersOpen] = useState<boolean>(true);
  const [
    isAccommodationFiltersOpen,
    setIsAccommodationFiltersOpen,
  ] = useState<boolean>(false);
  const [isInsuranceFiltersOpen, setIsInsuranceFiltersOpen] = useState<boolean>(
    false
  );
  const [isAddOnFiltersOpen, setIsAddOnFiltersOpen] = useState<boolean>(false);
  const [
    isExperienceFiltersOpen,
    setIsExperienceFiltersOpen,
  ] = useState<boolean>(false);
  const [warnings, setWarnings] = useState([]);
  const [officeCurrencies, setOfficeCurrencies] = useState<OfficeCurrency[]>(
    []
  );
  const [officeCurrenciesOptions, setOfficeCurrenciesOptions] = useState<
    CurrenciesOption[]
  >([]);
  const [saleTypeOptions, setSaleTypeOptions] = useState<
    { label: string; value: number; main: any }[]
  >([]);
  const [officeConfig, setOfficeConfig] = useState(Object);
  const [defaultDescription, setDefaultDescription] = useState<string>('');
  const [openSuggestiveFeeModal, setOpenSuggestiveFeeModal] = useState(false);
  const [clearSuggestiveFee, setClearSuggestiveFee] = useState(false);

  const filtersFormRef = useRef<FormHandles>(null);
  const { user, angularPort } = useAuth();
  const showAvailableValues = useRef(false);
  const listViewRef = useRef<null | HTMLDivElement>(null);
  const [firstLoadingComplete, setFirstLoadingComplete] = useState<boolean>(
    false
  );
  const [courseDurationInitial, setCourseDurationInitial] = useState<number>(1);
  const [
    accommodationDurationInitial,
    setAccommodationDurationInitial,
  ] = useState<number>(1);
  const [
    insuranceDurationInitial,
    setInsuranceDurationInitial,
  ] = useState<number>(1);
  const [addOnDurationInitial, setAddOnDurationInitial] = useState<number>(1);
  const [courseName, setCourseName] = useState<string>('');

  const {
    currentStep,
    quotesData,
    updateQuotesData,
    next,
    previous,
  } = useWizard();
  const { addToast } = useToast();

  let isFiltering = false;
  if (currentStep === 3) {
    isFiltering = true;
  }

  const insuranceTypeOptions = [
    { id: 'school', label: 'From Schools' },
    { id: 'my', label: 'From Providers' },
    { id: 'all', label: 'All' },
  ];

  const addOnTypeOptions = [
    { id: 'school', label: 'From Schools' },
    { id: 'my', label: 'From Providers' },
    { id: 'all', label: 'All' },
  ];

  const periodTypeOptions = [
    { value: 'fixed', label: 'fixed' },
    { value: 'hour', label: 'hours' },
    { value: 'day', label: 'days' },
    { value: 'week', label: 'weeks' },
    { value: 'month', label: 'months' },
    { value: 'term', label: 'terms' },
    { value: 'semester', label: 'semesters' },
    { value: 'year', label: 'years' },
    { value: 'unity', label: 'units' },
  ];

  const responsiveCarousel = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      partialVisibilityGutter: 15,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      partialVisibilityGutter: 15,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 15,
    },
  };

  const isUserAllyPlus = user.office.account.allyPlus === 1;
  const isUserBackOffice = !isUserAllyPlus && !user.office.plugAndPlay;
  const isUserHibrid = !isUserAllyPlus && !isUserBackOffice;
  const isIEAccount = user.account_id === 2015;

  const backToIdleState = useCallback(() => {
    setSearchLoading(false);
    setReadyToSearch(true);
  }, []);

  const handleNewcategoryChange = useCallback(
    (category: NewcategoryOption[]) => {
      setNewcategory(category);
      setSubcategory([]);
    },
    []
  );

  const handleSubcategoryChange = useCallback((subcat: SubcategoryOption[]) => {
    setSubcategory(subcat);
  }, []);

  const handleRestrictionChange = useCallback(
    (restrict: RestrictionOption[]) => {
      setRestriction(restrict);
    },
    []
  );
  const handleProgramDurationTypeChange = useCallback(
    (pprogramDurationType: ProgramDurationTypeOption[]) => {
      setProgramDurationType(pprogramDurationType);
    },
    []
  );

  const getSchoolAndCampusId = async (
    accommodationId: number
  ): Promise<any> => {
    const { data } = await api.get('/accommodationcampus', {
      params: { accommodation_id: accommodationId },
    });

    return data.campus;
  };

  updateCollapsiblesOverflow(currentFiltersTab);

  const getCities = useCallback(() => {
    let category = subcategory ? subcategory : false;
    if (subcategory && subcategory.length === 0) {
      category = false;
    }
    if (!category && newcategory) {
      category = newcategory;
    }
    api
      .get<City[]>(`/city`, {
        params: {
          fromPlugAndPlay: isUserAllyPlus ? 1 : 0,
          forQuote: true,
          fromArray: true,
          search: 2,
          category: category ? category : undefined,
          restriction: restriction ? JSON.stringify(restriction) : undefined,
          programDurationType: programDurationType
            ? JSON.stringify(programDurationType)
            : undefined,
          country_codes: JSON.stringify(countryCodes),
          school_ids: JSON.stringify(filterSchool.map(s => s.value)),
        },
      })
      .then(response => {
        const cities = response.data;

        const citiesFormatted = cities.map(city => ({
          value: city.id,
          label: city.name,
        }));

        setCityOptions(citiesFormatted);
      });
  }, [
    countryCodes,
    filterSchool,
    isUserAllyPlus,
    newcategory,
    programDurationType,
    restriction,
    subcategory,
  ]);

  const getRestrictions = useCallback(() => {
    let category = subcategory ? subcategory : false;
    if (subcategory && subcategory.length === 0) {
      category = false;
    }
    if (!category && newcategory) {
      category = newcategory;
    }

    api
      .get<RestrictionOption[]>(`/restriction`, {
        params: {
          fromPlugAndPlay: isUserAllyPlus ? 1 : 0,
          fromArray: true,
          forQuote: true,
          restriction: true,
          onlyMy: true,
          account_id: user.account_id,
          user_id: user.id,
          category,
          programDurationType: JSON.stringify(programDurationType),
          school_ids: JSON.stringify(filterSchool.map(s => s.value)),
          country_codes:
            !cityIds.length && countryCodes.length
              ? JSON.stringify(countryCodes)
              : undefined,
          city_ids: cityIds.length ? JSON.stringify(cityIds) : undefined,
        },
      })
      .then(response => {
        setRestrictionOptions(response.data);
      });
  }, [
    user.account_id,
    user.id,
    programDurationType,
    subcategory,
    newcategory,
    isUserAllyPlus,
    filterSchool,
    cityIds,
    countryCodes,
  ]);

  const getCategories = useCallback(() => {
    let category = subcategory ? subcategory : false;
    if (subcategory && subcategory.length === 0) {
      category = false;
    }
    if (!category && newcategory) {
      category = newcategory;
    }

    api
      .get<Newcategory[]>(`/newcategory`, {
        params: {
          fromPlugAndPlay: isUserAllyPlus ? 1 : 0,
          fromArray: true,
          forQuote: true,
          onlyMy: true,
          account_id: user.account_id,
          user_id: user.id,
          restriction: JSON.stringify(restriction),
          programDurationType: JSON.stringify(programDurationType),
          category,
          school_ids: JSON.stringify(filterSchool.map(s => s.value)),
          country_codes:
            !cityIds.length && countryCodes.length
              ? JSON.stringify(countryCodes)
              : undefined,
          city_ids: cityIds.length ? JSON.stringify(cityIds) : undefined,
        },
      })
      .then(response => {
        const newcategories = response.data;
        const newcategoriesFormattedAll = newcategories.map(categ => ({
          value: categ.id,
          label: categ.name,
          parent_id: categ.parent_id,
        }));
        const newcategoriesFormattedParent = newcategoriesFormattedAll.filter(
          result => result.parent_id === null
        );
        let subcategoriesFormatted = newcategoriesFormattedAll.filter(
          result => result.parent_id !== null
        );

        if (category) {
          subcategoriesFormatted = newcategoriesFormattedAll.filter(
            result => result.parent_id === category.value
          );
        }
        setNewcategoryOptions(newcategoriesFormattedParent);
        setSubcategoryOptions(subcategoriesFormatted);
      });
  }, [
    user.account_id,
    user.id,
    subcategory,
    restriction,
    programDurationType,
    newcategory,
    isUserAllyPlus,
    filterSchool,
    cityIds,
    countryCodes,
  ]);

  const getProgramDurationTypes = useCallback(() => {
    let category = subcategory ? subcategory : false;
    if (subcategory && subcategory.length === 0) {
      category = false;
    }
    if (!category && newcategory) {
      category = newcategory;
    }

    api
      .get<ProgramDurationTypeOption[]>(`/programdurationtype`, {
        params: {
          fromPlugAndPlay: isUserAllyPlus ? 1 : 0,
          fromArray: true,
          forQuote: true,
          onlyMy: true,
          category,
          account_id: user.account_id,
          user_id: user.id,
          restriction: JSON.stringify(restriction),
          school_ids: JSON.stringify(filterSchool.map(s => s.value)),
          country_codes:
            !cityIds.length && countryCodes.length
              ? JSON.stringify(countryCodes)
              : undefined,
          city_ids: cityIds.length ? JSON.stringify(cityIds) : undefined,
        },
      })
      .then(response => {
        setProgramDurationTypeOptions(response.data);
      });
  }, [
    user.account_id,
    user.id,
    subcategory,
    newcategory,
    isUserAllyPlus,
    restriction,
    filterSchool,
    cityIds,
    countryCodes,
  ]);

  const getAccommodationDurationTypes = useCallback(
    (data: FiltersFormData) => {
      const {
        accommodationDuration,
        accommodationRoomType,
        accommodationRegime,
        accommodationDurationTypeOption,
        accommodationCategory,
        accommodationBathroomType,
        accommodationType,
      } = data;

      let category = subcategory ? subcategory : false;
      if (subcategory && subcategory.length === 0) {
        category = false;
      }
      if (!category && newcategory) {
        category = newcategory;
      }

      api
        .get<AccommodationDurationTypeOption[]>(`/accommodationdurationtype`, {
          params: {
            fromPlugAndPlay: isUserAllyPlus ? 1 : 0,
            fromArray: true,
            forQuote: true,
            onlyMy: true,
            category,
            account_id: user.account_id,
            user_id: user.id,
            typePeriod:
              accommodationDuration && accommodationDurationTypeOption,
            fromAll: accommodationType === 'all' ? 1 : 0,
            fromMy: accommodationType === 'my' ? 1 : 0,
            fromSchool: accommodationType === 'school' ? 1 : 0,
            provider_my: accommodationType === 'my',
            category_all: !accommodationCategory?.length,
            category_homestay:
              accommodationCategory?.includes('homestay') && 'Homestay',
            category_residence:
              accommodationCategory?.includes('residence') && 'Residence',
            category_apartment:
              accommodationCategory?.includes('apartment') && 'Apartment',
            category_hostel:
              accommodationCategory?.includes('hostel') && 'Hostel',
            category_hotel: accommodationCategory?.includes('hotel') && 'Hotel',
            bathroom_all: !accommodationBathroomType?.length,
            bathroom_ensuite:
              accommodationBathroomType?.includes('ensuite') && 'Ensuite',
            bathroom_private:
              accommodationBathroomType?.includes('private') && 'Private',
            bathroom_semiprivate:
              accommodationBathroomType?.includes('semiPrivate') &&
              'Semi Private',
            bathroom_shared:
              accommodationBathroomType?.includes('shared') && 'Shared',
            bathroom_communal:
              accommodationBathroomType?.includes('communal') && 'Communal',
            regime_all: accommodationRegime?.length === 0,
            regime_bb: accommodationRegime?.includes('B&B') ? 'B&B' : false,
            regime_fullboard: accommodationRegime?.includes('Full Board')
              ? 'Full Board'
              : false,
            regime_halfboard: accommodationRegime?.includes('Half Board')
              ? 'Half Board'
              : false,
            regime_nomeals: accommodationRegime?.includes('No Meals')
              ? 'No Meals'
              : false,
            room_all: accommodationRoomType?.length === 0,
            room_double: accommodationRoomType?.includes('Double')
              ? 'Double'
              : false,
            room_private: accommodationRoomType?.includes('Private')
              ? 'Private'
              : false,
            room_shared: accommodationRoomType?.includes('Shared')
              ? 'Shared'
              : false,
            room_triple: accommodationRoomType?.includes('Triple')
              ? 'Triple'
              : false,
            room_twin: accommodationRoomType?.includes('Twin') ? 'Twin' : false,
            school_ids: JSON.stringify(filterSchool.map(s => s.value)),
            country_codes:
              !cityIds.length && countryCodes.length
                ? JSON.stringify(countryCodes)
                : undefined,
            city_ids: cityIds.length ? JSON.stringify(cityIds) : undefined,
          },
        })
        .then(response => {
          setAccommodationDurationTypeOptions(response.data);
        });
    },
    [
      user.account_id,
      user.id,
      subcategory,
      newcategory,
      isUserAllyPlus,
      filterSchool,
      cityIds,
      countryCodes,
    ]
  );

  const getAccommodationTypes = useCallback(
    (data: FiltersFormData) => {
      const {
        accommodationDuration,
        accommodationRoomType,
        accommodationRegime,
        accommodationDurationTypeOption,
        accommodationCategory,
        accommodationBathroomType,
        accommodationType,
      } = data;

      let category = subcategory ? subcategory : false;
      if (subcategory && subcategory.length === 0) {
        category = false;
      }
      if (!category && newcategory) {
        category = newcategory;
      }

      api
        .get<AccommodationTypeOption[]>(`/accommodationtype`, {
          params: {
            fromPlugAndPlay: isUserAllyPlus ? 1 : 0,
            fromArray: true,
            forQuote: true,
            onlyMy: true,
            category,
            account_id: user.account_id,
            user_id: user.id,
            typePeriod:
              accommodationDuration && accommodationDurationTypeOption,
            fromAll: accommodationType === 'all' ? 1 : 0,
            fromMy: accommodationType === 'my' ? 1 : 0,
            fromSchool: accommodationType === 'school' ? 1 : 0,
            provider_my: accommodationType === 'my' ? 1 : 0,
            category_all: !accommodationCategory?.length,
            category_homestay:
              accommodationCategory?.includes('homestay') && 'Homestay',
            category_residence:
              accommodationCategory?.includes('residence') && 'Residence',
            category_apartment:
              accommodationCategory?.includes('apartment') && 'Apartment',
            category_hostel:
              accommodationCategory?.includes('hostel') && 'Hostel',
            category_hotel: accommodationCategory?.includes('hotel') && 'Hotel',
            bathroom_all: !accommodationBathroomType?.length,
            bathroom_ensuite:
              accommodationBathroomType?.includes('ensuite') && 'Ensuite',
            bathroom_private:
              accommodationBathroomType?.includes('private') && 'Private',
            bathroom_semiprivate:
              accommodationBathroomType?.includes('semiPrivate') &&
              'Semi Private',
            bathroom_shared:
              accommodationBathroomType?.includes('shared') && 'Shared',
            bathroom_communal:
              accommodationBathroomType?.includes('communal') && 'Communal',
            regime_all: accommodationRegime?.length === 0,
            regime_bb: accommodationRegime?.includes('B&B') ? 'B&B' : false,
            regime_fullboard: accommodationRegime?.includes('Full Board')
              ? 'Full Board'
              : false,
            regime_halfboard: accommodationRegime?.includes('Half Board')
              ? 'Half Board'
              : false,
            regime_nomeals: accommodationRegime?.includes('No Meals')
              ? 'No Meals'
              : false,
            room_all: accommodationRoomType?.length === 0,
            room_double: accommodationRoomType?.includes('Double')
              ? 'Double'
              : false,
            room_private: accommodationRoomType?.includes('Private')
              ? 'Private'
              : false,
            room_shared: accommodationRoomType?.includes('Shared')
              ? 'Shared'
              : false,
            room_triple: accommodationRoomType?.includes('Triple')
              ? 'Triple'
              : false,
            room_twin: accommodationRoomType?.includes('Twin') ? 'Twin' : false,
            school_ids: JSON.stringify(filterSchool.map(s => s.value)),
            country_codes:
              !cityIds.length && countryCodes.length
                ? JSON.stringify(countryCodes)
                : undefined,
            city_ids: cityIds.length ? JSON.stringify(cityIds) : undefined,
          },
        })
        .then(response => {
          setAccommodationTypeOptions(response.data);
        });
    },
    [
      user.account_id,
      user.id,
      subcategory,
      newcategory,
      isUserAllyPlus,
      filterSchool,
      cityIds,
      countryCodes,
    ]
  );

  const getAccommodationRooms = useCallback(
    (data: FiltersFormData) => {
      const {
        accommodationDuration,
        accommodationRoomType,
        accommodationRegime,
        accommodationDurationTypeOption,
        accommodationCategory,
        accommodationBathroomType,
        accommodationType,
      } = data;

      let category = subcategory ? subcategory : false;
      if (subcategory && subcategory.length === 0) {
        category = false;
      }
      if (!category && newcategory) {
        category = newcategory;
      }

      api
        .get<AccommodationRoomOption[]>(`/accommodationroom`, {
          params: {
            fromPlugAndPlay: isUserAllyPlus ? 1 : 0,
            fromArray: true,
            forQuote: true,
            onlyMy: true,
            category,
            account_id: user.account_id,
            user_id: user.id,
            typePeriod:
              accommodationDuration && accommodationDurationTypeOption,
            fromAll: accommodationType === 'all' ? 1 : 0,
            fromMy: accommodationType === 'my' ? 1 : 0,
            fromSchool: accommodationType === 'school' ? 1 : 0,
            provider_my: accommodationType === 'my' ? 1 : 0,
            category_all: !accommodationCategory?.length,
            category_homestay:
              accommodationCategory?.includes('homestay') && 'Homestay',
            category_residence:
              accommodationCategory?.includes('residence') && 'Residence',
            category_apartment:
              accommodationCategory?.includes('apartment') && 'Apartment',
            category_hostel:
              accommodationCategory?.includes('hostel') && 'Hostel',
            category_hotel: accommodationCategory?.includes('hotel') && 'Hotel',
            bathroom_all: !accommodationBathroomType?.length,
            bathroom_ensuite:
              accommodationBathroomType?.includes('ensuite') && 'Ensuite',
            bathroom_private:
              accommodationBathroomType?.includes('private') && 'Private',
            bathroom_semiprivate:
              accommodationBathroomType?.includes('semiPrivate') &&
              'Semi Private',
            bathroom_shared:
              accommodationBathroomType?.includes('shared') && 'Shared',
            bathroom_communal:
              accommodationBathroomType?.includes('communal') && 'Communal',
            regime_all: accommodationRegime?.length === 0,
            regime_bb: accommodationRegime?.includes('B&B') ? 'B&B' : false,
            regime_fullboard: accommodationRegime?.includes('Full Board')
              ? 'Full Board'
              : false,
            regime_halfboard: accommodationRegime?.includes('Half Board')
              ? 'Half Board'
              : false,
            regime_nomeals: accommodationRegime?.includes('No Meals')
              ? 'No Meals'
              : false,
            room_all: accommodationRoomType?.length === 0,
            room_double: accommodationRoomType?.includes('Double')
              ? 'Double'
              : false,
            room_private: accommodationRoomType?.includes('Private')
              ? 'Private'
              : false,
            room_shared: accommodationRoomType?.includes('Shared')
              ? 'Shared'
              : false,
            room_triple: accommodationRoomType?.includes('Triple')
              ? 'Triple'
              : false,
            room_twin: accommodationRoomType?.includes('Twin') ? 'Twin' : false,
            school_ids: JSON.stringify(filterSchool.map(s => s.value)),
            country_codes:
              !cityIds.length && countryCodes.length
                ? JSON.stringify(countryCodes)
                : undefined,
            city_ids: cityIds.length ? JSON.stringify(cityIds) : undefined,
          },
        })
        .then(response => {
          setAccommodationRoomOptions(response.data);
        });
    },
    [
      user.account_id,
      user.id,
      subcategory,
      newcategory,
      isUserAllyPlus,
      filterSchool,
      cityIds,
      countryCodes,
    ]
  );

  const getAccommodationBathroomTypes = useCallback(
    (data: FiltersFormData) => {
      const {
        accommodationDuration,
        accommodationRoomType,
        accommodationRegime,
        accommodationDurationTypeOption,
        accommodationCategory,
        accommodationBathroomType,
        accommodationType,
      } = data;

      let category = subcategory ? subcategory : false;
      if (subcategory && subcategory.length === 0) {
        category = false;
      }
      if (!category && newcategory) {
        category = newcategory;
      }

      api
        .get<AccommodationBathroomTypeOption[]>(`/accommodationbathroom`, {
          params: {
            fromPlugAndPlay: isUserAllyPlus ? 1 : 0,
            fromArray: true,
            forQuote: true,
            onlyMy: true,
            category,
            account_id: user.account_id,
            user_id: user.id,
            typePeriod:
              accommodationDuration && accommodationDurationTypeOption,
            fromAll: accommodationType === 'all' ? 1 : 0,
            fromMy: accommodationType === 'my' ? 1 : 0,
            fromSchool: accommodationType === 'school' ? 1 : 0,
            provider_my: accommodationType === 'my' ? 1 : 0,
            category_all: !accommodationCategory?.length,
            category_homestay:
              accommodationCategory?.includes('homestay') && 'Homestay',
            category_residence:
              accommodationCategory?.includes('residence') && 'Residence',
            category_apartment:
              accommodationCategory?.includes('apartment') && 'Apartment',
            category_hostel:
              accommodationCategory?.includes('hostel') && 'Hostel',
            category_hotel: accommodationCategory?.includes('hotel') && 'Hotel',
            bathroom_all: !accommodationBathroomType?.length,
            bathroom_ensuite:
              accommodationBathroomType?.includes('ensuite') && 'Ensuite',
            bathroom_private:
              accommodationBathroomType?.includes('private') && 'Private',
            bathroom_semiprivate:
              accommodationBathroomType?.includes('semiPrivate') &&
              'Semi Private',
            bathroom_shared:
              accommodationBathroomType?.includes('shared') && 'Shared',
            bathroom_communal:
              accommodationBathroomType?.includes('communal') && 'Communal',
            regime_all: accommodationRegime?.length === 0,
            regime_bb: accommodationRegime?.includes('B&B') ? 'B&B' : false,
            regime_fullboard: accommodationRegime?.includes('Full Board')
              ? 'Full Board'
              : false,
            regime_halfboard: accommodationRegime?.includes('Half Board')
              ? 'Half Board'
              : false,
            regime_nomeals: accommodationRegime?.includes('No Meals')
              ? 'No Meals'
              : false,
            room_all: accommodationRoomType?.length === 0,
            room_double: accommodationRoomType?.includes('Double')
              ? 'Double'
              : false,
            room_private: accommodationRoomType?.includes('Private')
              ? 'Private'
              : false,
            room_shared: accommodationRoomType?.includes('Shared')
              ? 'Shared'
              : false,
            room_triple: accommodationRoomType?.includes('Triple')
              ? 'Triple'
              : false,
            room_twin: accommodationRoomType?.includes('Twin') ? 'Twin' : false,
            school_ids: JSON.stringify(filterSchool.map(s => s.value)),
            country_codes:
              !cityIds.length && countryCodes.length
                ? JSON.stringify(countryCodes)
                : undefined,
            city_ids: cityIds.length ? JSON.stringify(cityIds) : undefined,
          },
        })
        .then(response => {
          setAccommodationBathroomTypeOptions(response.data);
        });
    },
    [
      user.account_id,
      user.id,
      subcategory,
      newcategory,
      isUserAllyPlus,
      filterSchool,
      cityIds,
      countryCodes,
    ]
  );

  const getAccommodationRegimes = useCallback(
    (data: FiltersFormData) => {
      const {
        accommodationDuration,
        accommodationRoomType,
        accommodationRegime,
        accommodationDurationTypeOption,
        accommodationCategory,
        accommodationBathroomType,
        accommodationType,
      } = data;

      let category = subcategory ? subcategory : false;
      if (subcategory && subcategory.length === 0) {
        category = false;
      }
      if (!category && newcategory) {
        category = newcategory;
      }

      api
        .get<AccommodationRegimeTypeOption[]>(`/accommodationregime`, {
          params: {
            fromPlugAndPlay: isUserAllyPlus ? 1 : 0,
            fromArray: true,
            forQuote: true,
            onlyMy: true,
            category,
            account_id: user.account_id,
            user_id: user.id,
            typePeriod:
              accommodationDuration && accommodationDurationTypeOption,
            fromAll: accommodationType === 'all' ? 1 : 0,
            fromMy: accommodationType === 'my' ? 1 : 0,
            fromSchool: accommodationType === 'school' ? 1 : 0,
            provider_my: accommodationType === 'my' ? 1 : 0,
            category_all: !accommodationCategory?.length,
            category_homestay:
              accommodationCategory?.includes('homestay') && 'Homestay',
            category_residence:
              accommodationCategory?.includes('residence') && 'Residence',
            category_apartment:
              accommodationCategory?.includes('apartment') && 'Apartment',
            category_hostel:
              accommodationCategory?.includes('hostel') && 'Hostel',
            category_hotel: accommodationCategory?.includes('hotel') && 'Hotel',
            bathroom_all: !accommodationBathroomType?.length,
            bathroom_ensuite:
              accommodationBathroomType?.includes('ensuite') && 'Ensuite',
            bathroom_private:
              accommodationBathroomType?.includes('private') && 'Private',
            bathroom_semiprivate:
              accommodationBathroomType?.includes('semiPrivate') &&
              'Semi Private',
            bathroom_shared:
              accommodationBathroomType?.includes('shared') && 'Shared',
            bathroom_communal:
              accommodationBathroomType?.includes('communal') && 'Communal',
            regime_all: accommodationRegime?.length === 0,
            regime_bb: accommodationRegime?.includes('B&B') ? 'B&B' : false,
            regime_fullboard: accommodationRegime?.includes('Full Board')
              ? 'Full Board'
              : false,
            regime_halfboard: accommodationRegime?.includes('Half Board')
              ? 'Half Board'
              : false,
            regime_nomeals: accommodationRegime?.includes('No Meals')
              ? 'No Meals'
              : false,
            room_all: accommodationRoomType?.length === 0,
            room_double: accommodationRoomType?.includes('Double')
              ? 'Double'
              : false,
            room_private: accommodationRoomType?.includes('Private')
              ? 'Private'
              : false,
            room_shared: accommodationRoomType?.includes('Shared')
              ? 'Shared'
              : false,
            room_triple: accommodationRoomType?.includes('Triple')
              ? 'Triple'
              : false,
            room_twin: accommodationRoomType?.includes('Twin') ? 'Twin' : false,
            school_ids: JSON.stringify(filterSchool.map(s => s.value)),
            country_codes:
              !cityIds.length && countryCodes.length
                ? JSON.stringify(countryCodes)
                : undefined,
            city_ids: cityIds.length ? JSON.stringify(cityIds) : undefined,
          },
        })
        .then(response => {
          setAccommodationRegimeTypeOptions(response.data);
        });
    },
    [
      user.account_id,
      user.id,
      subcategory,
      newcategory,
      isUserAllyPlus,
      filterSchool,
      cityIds,
      countryCodes,
    ]
  );

  const getFeeDurationTypes = useCallback(
    (data: FiltersFormData) => {
      const {
        feeDuration,
        feeDurationTypeOption,
        feeCategory,
        addOnType,
      } = data;

      api
        .get<FeeDurationTypeOption[]>(`/feedurationtype`, {
          params: {
            fromPlugAndPlay: isUserAllyPlus ? 1 : 0,
            fromArray: true,
            forQuote: true,
            onlyMy: true,
            account_id: user.account_id,
            user_id: user.id,
            typePeriod: feeDuration && feeDurationTypeOption,
            fromAll: addOnType === 'all' ? 1 : 0,
            fromMy: addOnType === 'my' ? 1 : 0,
            fromSchool: addOnType === 'school' ? 1 : 0,
            provider_my: addOnType === 'my',
            school_ids: JSON.stringify(filterSchool.map(s => s.value)),
            country_codes:
              !cityIds.length && countryCodes.length
                ? JSON.stringify(countryCodes)
                : undefined,
            city_ids: cityIds.length ? JSON.stringify(cityIds) : undefined,
          },
        })
        .then(response => {
          setFeeDurationTypeOptions(response.data);
        });
    },
    [
      user.account_id,
      user.id,
      isUserAllyPlus,
      filterSchool,
      cityIds,
      countryCodes,
    ]
  );

  const getFeeCategoryTypes = useCallback(
    (data: FiltersFormData) => {
      const {
        feeDuration,
        feeDurationTypeOption,
        feeCategory,
        addOnType,
      } = data;

      api
        .get<FeeCategory[]>(`/feecategoryquote`, {
          params: {
            fromPlugAndPlay: isUserAllyPlus ? 1 : 0,
            fromArray: true,
            forQuote: true,
            onlyMy: true,
            account_id: user.account_id,
            user_id: user.id,
            typePeriod: feeDuration && feeDurationTypeOption,
            fromAll: addOnType === 'all' ? 1 : 0,
            fromMy: addOnType === 'my' ? 1 : 0,
            fromSchool: addOnType === 'school' ? 1 : 0,
            provider_my: addOnType === 'my',
            school_ids: JSON.stringify(filterSchool.map(s => s.value)),
            country_codes:
              !cityIds.length && countryCodes.length
                ? JSON.stringify(countryCodes)
                : undefined,
            city_ids: cityIds.length ? JSON.stringify(cityIds) : undefined,
          },
        })
        .then(response => {
          const feeCategories = response.data;

          const feeCategoriesFormatted = feeCategories.map(category => ({
            value: category.value,
            label: category.label,
          }));
          setFeeCategoryOptions(response.data);
        });
    },
    [
      user.account_id,
      user.id,
      isUserAllyPlus,
      filterSchool,
      cityIds,
      countryCodes,
    ]
  );

  const updateSmartFilters = () => {
    const timeout = setTimeout(() => {
      const data = filtersFormRef.current?.getData() as FiltersFormData;
      if (currentFiltersTab === 'Accommodations') {
        getAccommodationTypes(data);
        getAccommodationRooms(data);
        getAccommodationBathroomTypes(data);
        getAccommodationRegimes(data);
        getAccommodationDurationTypes(data);
      } else if (currentFiltersTab === 'Add-ons') {
        getFeeDurationTypes(data);
        getFeeCategoryTypes(data);
      }
    }, 200);
  };

  const handleAccommodationDurationTypeChange = useCallback(() => {
    setAccommodationDurationType(accommodationDurationType);
    updateSmartFilters();
  }, [accommodationDurationType, updateSmartFilters]);
  const handleFeeDurationTypeChange = useCallback(() => {
    setFeeDurationType(feeDurationType);
    updateSmartFilters();
  }, [feeDurationType, updateSmartFilters]);
  const handleAccommodationTypeChange = useCallback(
    (aaccommodationType: AccommodationTypeOption[]) => {
      setAccommodationType(aaccommodationType);
    },
    []
  );

  const getSchools = useCallback(() => {
    let category = subcategory ? subcategory : false;
    if (subcategory && subcategory.length === 0) {
      category = false;
    }
    if (!category && newcategory) {
      category = newcategory;
    }

    api
      .get<School[]>(`/schoolui`, {
        params: {
          fromPlugAndPlay: isUserAllyPlus ? 1 : 0,
          fromArray: true,
          forQuote: true,
          onlyMy: true,
          category,
          restriction: JSON.stringify(restriction),
          programDurationType: JSON.stringify(programDurationType),
          country_codes:
            !cityIds.length && countryCodes.length
              ? JSON.stringify(countryCodes)
              : undefined,
          city_ids: cityIds.length ? JSON.stringify(cityIds) : undefined,
        },
      })
      .then(response => {
        const schools = response.data;

        const schoolsFormatted = schools.map(school => {
          const mySchool = school.account_id === user.account_id;
          const schoolName = mySchool
            ? `(My School) ${school.name}`
            : school.name;
          const isAllyPlus =
            school.allyPlus === 1 && Boolean(user.office.plugAndPlay);

          return isAllyPlus
            ? {
                value: school.id,
                label: schoolName,
                school: 1,
                allyPlusIcon,
              }
            : {
                value: school.id,
                label: schoolName,
                school: 1,
              };
        });
        setSchoolOptions(schoolsFormatted);
      });
  }, [
    subcategory,
    newcategory,
    isUserAllyPlus,
    programDurationType,
    restriction,
    cityIds,
    countryCodes,
    user.account_id,
    user.office.plugAndPlay,
  ]);

  const getCampuses = useCallback(() => {
    let category = subcategory ? subcategory : false;
    if (subcategory && subcategory.length === 0) {
      category = false;
    }
    if (!category && newcategory) {
      category = newcategory;
    }

    api
      .get<Campus[]>(`/campusui`, {
        params: {
          fromPlugAndPlay: isUserAllyPlus ? 1 : 0,
          fromArray: true,
          forQuote: true,
          onlyMy: true,
          school_ids: JSON.stringify(filterSchool.map(s => s.value)),
          category,
          restriction: JSON.stringify(restriction),
          programDurationType: JSON.stringify(programDurationType),
          country_codes:
            !cityIds.length && countryCodes.length
              ? JSON.stringify(countryCodes)
              : undefined,
          city_ids: cityIds.length ? JSON.stringify(cityIds) : undefined,
        },
      })
      .then(response => {
        const campuses = response.data;

        const campusFormatted = campuses.map(campus => {
          const myCampus = campus.account_id === user.account_id;
          const campusName = myCampus
            ? `(My School) ${campus.name}`
            : campus.name;
          const isAllyPlus =
            campus.allyPlus === 1 && Boolean(user.office.plugAndPlay);

          return isAllyPlus
            ? {
                value: campus.id,
                label: campusName,
                campus: 1,
                allyPlusIcon,
              }
            : {
                value: campus.id,
                label: campusName,
                campus: 1,
              };
        });
        setCampusOptions(campusFormatted);
        setCampusOptionsResponse(true);
      });
  }, [
    subcategory,
    newcategory,
    isUserAllyPlus,
    filterSchool,
    restriction,
    programDurationType,
    cityIds,
    countryCodes,
    user.account_id,
    user.office.plugAndPlay,
  ]);

  const getCountries = useCallback(() => {
    api
      .get<Country[]>(`/country`, {
        params: {
          fromPlugAndPlay: isUserAllyPlus ? 1 : 0,
          account_id: user.account_id,
          fromThisAccount: true,
        },
      })
      .then(response => {
        const countriesC = response.data;

        const countriesCFormatted = countriesC.map(country => ({
          value: country.code,
          label: country.name,
        }));

        setCountryCOptions(countriesCFormatted);
      });
  }, [isUserAllyPlus, user.account_id]);

  // const getSubcategories = useCallback(() => {
  //   if (!newcategory) {
  //     setSubcategory([]);
  //     setSubcategoryOptions([]);
  //     return;
  //   }
  //   if (newcategory && newcategory.length === 0) {
  //     setSubcategory([]);
  //     setSubcategoryOptions([]);
  //     return;
  //   }
  //   api
  //     .get<Newcategory[]>(`/newcategory`, {
  //       params: {
  //         parent: newcategory,
  //         teste: 1,
  //       },
  //     })
  //     .then(response => {
  //       const subcategories = response.data;
  //       const subcategoriesFormatted = subcategories.map(subcat => {
  //         return {
  //           value: subcat.id,
  //           label: subcat.name,
  //         };
  //       });
  //       setSubcategory([]);
  //       setSubcategoryOptions(subcategoriesFormatted);
  //     });
  // }, [newcategory]);

  const enhanceCourseWithCountry = useCallback(
    (course: Record<string, any>) => {
      const newCourse = { ...course };

      const courseCountry = countryOptions.find(
        country => country.value === course.coursecampus.campus.country_code
      );
      if (!courseCountry) {
        return newCourse;
      }
      if (!newCourse.coursecampus.campus.country) {
        newCourse.coursecampus.campus.country = {};
      }

      newCourse.coursecampus.campus.country.country_code = courseCountry.value;
      newCourse.coursecampus.campus.country.name = courseCountry.label;
      newCourse.coursecampus.campus.country.flagName = courseCountry.label.replaceAll(
        ' ',
        '-'
      );
      return newCourse;
    },
    [countryOptions]
  );

  const enhanceCoursesCountry = useCallback(
    courses => {
      return courses.map((course: Record<string, any>) => {
        return enhanceCourseWithCountry(course);
      });
    },
    [enhanceCourseWithCountry]
  );

  const enhanceAccommodationWithCountry = useCallback(
    (accommodation: Record<string, any>) => {
      if (!accommodation.accommodation) {
        accommodation.accommodation = {};
        accommodation.accommodation.city = accommodation.city;
        accommodation.accommodation.name = accommodation.name;
        accommodation.accommodation.currency = accommodation.currency;
      }
      const newAccommodation = { ...accommodation };

      const accommodationCountry = countryOptions.find(
        country =>
          country.value === accommodation.accommodation.city.country_code
      );
      if (!accommodationCountry) {
        return newAccommodation;
      }
      if (!newAccommodation.accommodation.city.country) {
        newAccommodation.accommodation.city.country = {};
      }

      newAccommodation.accommodation.city.country_code =
        accommodationCountry.value;
      newAccommodation.accommodation.city.country.name =
        accommodationCountry.label;
      newAccommodation.accommodation.city.country.flagName = accommodationCountry.label.replaceAll(
        ' ',
        '-'
      );
      newAccommodation.city = newAccommodation.accommodation.city;
      newAccommodation.currency = newAccommodation.accommodation.currency;
      return newAccommodation;
    },
    [countryOptions]
  );

  const enhanceAccommodationsCountry = useCallback(
    accommodations => {
      return accommodations.map((accommodation: Record<string, any>) => {
        return enhanceAccommodationWithCountry(accommodation);
      });
    },
    [enhanceAccommodationWithCountry]
  );

  const enhanceFeeWithCountry = useCallback(
    (fee: Record<string, any>) => {
      if (!fee.fee) {
        fee.fee = {};
        fee.fee.city = fee.city;
        fee.fee.country = fee.country;
        fee.fee.currency = fee.currency;
      }
      if (fee.campus) {
        if (fee.campus.campus) {
          fee.campus = fee.campus.campus;
        }
        fee.city = fee.campus.city;
        fee.fee.city = fee.campus.city;
      }
      const newFee = { ...fee };

      let feeCountry = countryOptions.find(
        country => country.value === fee.fee.country?.code
      );

      if (!feeCountry) {
        feeCountry = countryOptions.find(
          country => country.value === fee.fee.city?.country_code
        );
      }

      if (!feeCountry) {
        feeCountry = countryOptions.find(
          country => country.value === user.office.city?.country_code
        );
        //   console.warn('Fee Country not found for: ', fee.fee.city.country_code);
        if (!feeCountry) {
          return newFee;
        }
      }
      if (!newFee.fee.city) {
        newFee.fee.city = {};
        newFee.fee.city.country = {};
      }
      if (!newFee.fee.city.country) {
        newFee.fee.city.country = {};
      }

      newFee.fee.city.country_code = feeCountry.value;
      newFee.fee.city.country.name = feeCountry.label;
      newFee.fee.city.country.flagName = feeCountry.label.replaceAll(' ', '-');
      newFee.city = newFee.fee.city;
      if (newFee.campus && !newFee.campus?.country)
        newFee.campus.country = feeCountry;
      newFee.country = feeCountry;
      newFee.fee.country = feeCountry;
      newFee.currency = newFee.fee.currency;
      return newFee;
    },
    [countryOptions, user.office.city?.country_code]
  );

  const enhanceFeesCountry = useCallback(
    fees => {
      return fees.map((fee: Record<string, any>) => {
        return enhanceFeeWithCountry(fee);
      });
    },
    [enhanceFeeWithCountry]
  );

  const getAccommodationsFlagName = useCallback(accommodations => {
    for (let index = 0; index < accommodations.length; index++) {
      const accommodation = accommodations[index];
      accommodation.city.country = {
        ...accommodation.city.country,
        flagName: accommodation.city.country.name.replaceAll(' ', '-'),
      };
    }
    return accommodations;
  }, []);

  const getFeesCategories = useCallback(
    fees => {
      for (let index = 0; index < fees.length; index++) {
        const fee = fees[index];
        const feeCategory = feeCategoryOptions.find(
          category => category.value === fee.category_id
        );
        fee.category = {
          id: feeCategory?.value,
          name: feeCategory?.label,
        };
      }
      return fees;
    },
    [feeCategoryOptions]
  );

  const getFeesUsingMandatoryRule = useCallback(
    async (
      student,
      selectedQuote,
      dataFromEvent,
      filterType,
      accommodationId = undefined
    ) => {
      let selectedCourseOrAccom = null;
      let campusData = { school_id: null, id: null };

      if (filterType === filtersOptions.FILTERS_COURSES) {
        selectedCourseOrAccom = selectedQuote.courses.find(
          (course: any) =>
            course.coursecampus.id === dataFromEvent.courseCampus_id
        );
      }

      if (filterType === filtersOptions.FILTERS_ACCOMMODATIONS) {
        selectedCourseOrAccom = selectedQuote.accommodations.find(
          (accommodation: any) => accommodation.id === accommodationId
        );
        campusData = await getSchoolAndCampusId(accommodationId);
      }

      const response = await api.get('/mandatoryrule', {
        params: {
          fromArray: true,
          plugAndPlay:
            isUserAllyPlus || (isUserHibrid && selectedQuote.plugAndPlay === 1)
              ? 1
              : 0,
          birthDate: student.birthDate,
          city_id:
            filterType === filtersOptions.FILTERS_COURSES
              ? selectedCourseOrAccom.coursecampus.campus.city_id
              : selectedCourseOrAccom?.city_id,
          country_code:
            filterType === filtersOptions.FILTERS_COURSES
              ? selectedCourseOrAccom.coursecampus.campus.country_code
              : selectedCourseOrAccom?.city?.country_code,
          courseCampus_id:
            filterType === filtersOptions.FILTERS_COURSES
              ? selectedCourseOrAccom.courseCampus_id
              : undefined,
          accommodation_id:
            filterType === filtersOptions.FILTERS_ACCOMMODATIONS
              ? accommodationId
              : undefined,
          duration:
            filterType === filtersOptions.FILTERS_COURSES
              ? dataFromEvent.duration
              : dataFromEvent.accommodation_duration,
          getFees: true,
          school_id:
            filterType === filtersOptions.FILTERS_COURSES
              ? selectedCourseOrAccom.coursecampus.campus.school_id
              : campusData?.school_id,
          campus_id:
            filterType === filtersOptions.FILTERS_COURSES
              ? selectedCourseOrAccom.coursecampus.campus.id
              : campusData?.id,
          country: student.nationalityCountry,
          startDate: format(parseISO(dataFromEvent.startDate), 'dd/MM/yyyy'),
          endDate: format(parseISO(dataFromEvent.endDate), 'dd/MM/yyyy'),
        },
      });

      const updatedMandatoryFees = getFeesCategories(response.data);

      const idToBeUsed =
        filterType === filtersOptions.FILTERS_COURSES
          ? dataFromEvent.courseCampus_id
          : accommodationId;

      const quoteId = selectedQuote.id;
      const {
        suggestiveFees,
        mandatoryFees,
      } = formatingMandatoryAndSuggestiveFees(
        dateOfReference,
        updatedMandatoryFees,
        selectedQuote,
        idToBeUsed,
        filterType,
        [quoteId]
      );

      setEditingItemSuggestiveFees(suggestiveFees);

      return { mandatoryFees, suggestiveFees };
    },
    [isUserAllyPlus, getFeesCategories, dateOfReference]
  );

  const fetchCourse = useCallback(
    (data: FiltersFormData, useLoading = true) => {
      useLoading && setSearchLoading(true);

      const { sortProp, sortType } = selectedSortOpt;
      const {
        courseStartDate,
        courseDuration,
        courseFromValue,
        courseToValue,
        courseType,
        coursePeriodType,
        programDurationTypeOption,
        restrictionOption,
        campus_id,
      } = data;

      if (
        courseFromValue &&
        courseToValue &&
        courseFromValue * 1 > courseToValue * 1
      ) {
        useLoading && setSearchLoading(false);
        addToast({
          type: 'error',
          title: 'Error',
          description: 'Price Range is invalid, please try again.',
        });
        return;
      }

      let category = subcategory ? subcategory : false;
      if (subcategory && subcategory.length === 0) {
        category = false;
      }
      if (!category && newcategory) {
        category = newcategory;
      }

      const restrictionString = restriction.length
        ? JSON.stringify(restriction)
        : '';
      const timeStartedCourse = new Date();
      api
        .get<PaginateData>(`/coursecampuspaginate`, {
          params: {
            fromArray: true,
            busca: 2,
            city_ids: cityIds.length ? JSON.stringify(cityIds) : undefined,
            country_codes: countryCodes.length
              ? JSON.stringify(countryCodes)
              : undefined,
            campus_ids: data.filterCampus?.length
              ? JSON.stringify(data.filterCampus)
              : undefined,
            school_ids: filterSchool?.length
              ? JSON.stringify(filterSchool.map(s => s.value))
              : undefined,
            country: quotesData.student.nationalityCountry,
            courseStartDateFrom: programStartDate
              ? format(parseJSON(programStartDate), 'dd/MM/yyyy')
              : undefined,
            course_name: courseName,
            duration: courseDuration || 1,
            typePeriod: courseDuration && programDurationTypeOption,
            type: courseType,
            category,
            enrolDate: format(parseJSON(dateOfReference), 'dd/MM/yyyy'),
            filterValue: 1, // sempre 1
            page: currentPage > 0 ? currentPage : 1,
            queryType: 'newQuote',
            sortProp,
            sortType,
            startDate: format(parseJSON(dateOfReference), 'dd/MM/yyyy'),
            fromValue: courseFromValue,
            toValue: courseToValue,
            onlySR: promotionOnly,
            online: restrictionString.includes('online'),
            renewal: restrictionString.includes('renewal'),
            onshore: restrictionString.includes('onshore'),
            offshore: restrictionString.includes('offshore'),
            studentAge: restrictionString.includes('age')
              ? quotesData.student.age
              : undefined,
            available: showAvailableValues.current,
          },
        })
        .then(async response => {
          const timeEndedCourse = new Date();
          const diffInMillisecondsCourse =
            timeEndedCourse.getTime() - timeStartedCourse.getTime();
          api.post(`/searchperformancerate`, {
            total: response.data.total,
            milliseconds: diffInMillisecondsCourse,
            type: 'program',
            filterCountry: countryCodes.length > 0 ? 1 : 0,
            filterCity: cityIds.length > 0 ? 1 : 0,
            filterSchool: filterSchool?.length > 0 ? 1 : 0,
            filterDuration: courseDuration ? 1 : 0,
            filterTypePeriod: coursePeriodType ? 1 : 0,
            filterCategory: category ? 1 : 0,
            filterName: courseName ? 1 : 0,
            filterAge: onlyStudentAge ? 1 : 0,
            filterOnline: onlyOnline ? 1 : 0,
            filterRenewal: onlyRenewal ? 1 : 0,
            filterOnshore: onlyOnshore ? 1 : 0,
            filterOffshore: onlyOffshore ? 1 : 0,
            filterWorkLoad: sliderValue ? 1 : 0,
            filterPromotionOnly: promotionOnly ? 1 : 0,
            filterOnlyAvailable: showAvailableValues.current ? 1 : 0,
            filterRoomType: 0,
            filterBathroomType: 0,
            filterRegime: 0,
            filterFrom: 0,
            allyPlus: quotesData.quotes?.length
              ? quotesData.quotes[0].plugAndPlay
              : 0,
          });
          const { data: courses, total, per_page } = response.data;
          const updatedCourses = enhanceCoursesCountry(courses);
          updatedCourses.forEach((course: any) => {
            if (course.coursecampus.enrolActive === 'yes') {
              course.coursecampus.enrolActive = true;
            } else if (course.coursecampus.enrolActive === 'no') {
              course.coursecampus.enrolActive = false;
            }
            if (course.coursecampus.materialActive === 'yes') {
              course.coursecampus.materialActive = true;
            } else if (course.coursecampus.materialActive === 'no') {
              course.coursecampus.materialActive = false;
            }
          });
        })
        .catch(() => {
          useLoading && setSearchLoading(false);
        });
    },
    [
      selectedSortOpt,
      subcategory,
      newcategory,
      restriction,
      cityIds,
      countryCodes,
      filterSchool,
      quotesData.student.nationalityCountry,
      quotesData.student.age,
      quotesData.quotes,
      programStartDate,
      courseName,
      dateOfReference,
      currentPage,
      promotionOnly,
      addToast,
      onlyStudentAge,
      onlyOnline,
      onlyRenewal,
      onlyOnshore,
      onlyOffshore,
      sliderValue,
      enhanceCoursesCountry,
    ]
  );

  const fetchAccommodations = useCallback(
    (data: FiltersFormData, oldResultItems: any[]) => {
      setFirstLoadingComplete(false);
      const {
        accommodationPartner,
        accommodationDuration,
        accommodationFromValue,
        accommodationToValue,
        accommodationRoomType,
        accommodationRegime,
        accommodationPeriodType,
        accommodationName,
        accommodationCategory,
        accommodationBathroomType,
        accommodationType,
      } = data;
      const { sortProp, sortType } = selectedSortOpt;

      if (
        accommodationFromValue &&
        accommodationToValue &&
        accommodationFromValue * 1 > accommodationToValue * 1
      ) {
        setSearchLoading(false);
        setReadyToSearch(true);
        addToast({
          type: 'error',
          title: 'Error',
          description: 'Price Range is invalid, please try again.',
        });
        return;
      }

      const timeStartedAccommodation = new Date();

      let school_ids = filterSchool?.length
        ? JSON.stringify(filterSchool.map(s => s.value))
        : undefined;
      let partner_ids_json = accommodationPartner?.length
        ? JSON.stringify(accommodationPartner)
        : undefined;

      if (accommodationType === 'all' && accommodationPartner) {
        const newPartner_ids: number[] = [];
        const provider_ids: number[] = [];
        const partner_ids = partnerOptions.map(s => s.value);
        accommodationPartner.forEach(id => {
          if (!partner_ids.includes(id)) provider_ids.push(id);
          else newPartner_ids.push(id);
        });
        school_ids = JSON.stringify(provider_ids);
        partner_ids_json = JSON.stringify(newPartner_ids);
      }

      api
        .get('/accommodationpaginate', {
          params: {
            category_all: !accommodationCategory?.length,
            category_homestay:
              accommodationCategory?.includes('homestay') && 'Homestay',
            category_residence:
              accommodationCategory?.includes('residence') && 'Residence',
            category_apartment:
              accommodationCategory?.includes('apartment') && 'Apartment',
            category_hostel:
              accommodationCategory?.includes('hostel') && 'Hostel',
            category_hotel: accommodationCategory?.includes('hotel') && 'Hotel',
            bathroom_all: !accommodationBathroomType?.length,
            bathroom_ensuite:
              accommodationBathroomType?.includes('ensuite') && 'Ensuite',
            bathroom_private:
              accommodationBathroomType?.includes('private') && 'Private',
            bathroom_semiprivate:
              accommodationBathroomType?.includes('semiPrivate') &&
              'Semi Private',
            bathroom_shared:
              accommodationBathroomType?.includes('shared') && 'Shared',
            bathroom_communal:
              accommodationBathroomType?.includes('communal') && 'Communal',
            courseStartDateFrom: programStartDate
              ? format(parseJSON(programStartDate), 'dd/MM/yyyy')
              : undefined,
            fromArray: true,
            campus_ids: data.filterCampus?.length
              ? JSON.stringify(data.filterCampus)
              : undefined,
            school_ids,
            partner_ids: partner_ids_json,
            city_ids: cityIds.length ? JSON.stringify(cityIds) : undefined,
            country: quotesData.student.nationalityCountry,
            country_codes: countryCodes.length
              ? JSON.stringify(countryCodes)
              : undefined,
            duration: accommodationDuration,
            enrolDate: format(parseJSON(dateOfReference), 'dd/MM/yyyy'),
            filterValue: 1,
            page: currentPage ? currentPage : 1,
            fromAll: accommodationType === 'all',
            fromMy: accommodationType === 'my',
            fromSchool: accommodationType === 'school',
            provider_my: accommodationType === 'my',
            provider_myname: false,
            provider_name: false,
            provider_view: false,
            queryType: 'newQuote',
            regime_all: accommodationRegime?.length === 0,
            regime_bb: accommodationRegime?.includes('B&B') ? 'B&B' : false,
            regime_fullboard: accommodationRegime?.includes('Full Board')
              ? 'Full Board'
              : false,
            regime_halfboard: accommodationRegime?.includes('Half Board')
              ? 'Half Board'
              : false,
            regime_nomeals: accommodationRegime?.includes('No Meals')
              ? 'No Meals'
              : false,
            room_all: accommodationRoomType?.length === 0,
            room_double: accommodationRoomType?.includes('Double')
              ? 'Double'
              : false,
            room_private: accommodationRoomType?.includes('Private')
              ? 'Private'
              : false,
            room_shared: accommodationRoomType?.includes('Shared')
              ? 'Shared'
              : false,
            room_triple: accommodationRoomType?.includes('Triple')
              ? 'Triple'
              : false,
            room_twin: accommodationRoomType?.includes('Twin') ? 'Twin' : false,
            schoolAccount: 0,
            school_name: false,
            plugAndPlay: isUserAllyPlus ? 1 : 0,
            sortProp,
            sortType,
            fromValue: accommodationFromValue,
            toValue: accommodationToValue,
            accommodation_name: accommodationName,
            typePeriod: accommodationDuration && accommodationPeriodType,
            studentAge: onlyStudentAge ? quotesData.student.age : undefined,
          },
        })
        .then(async response => {
          const timeEndedAccommodation = new Date();
          const diffInMillisecondsAccommodation =
            timeEndedAccommodation.getTime() -
            timeStartedAccommodation.getTime();
          let filterFrom = 1;
          if (accommodationType === 'my') filterFrom = 2;
          if (accommodationType === 'all') filterFrom = 3;
          api.post(`/searchperformancerate`, {
            total: response.data.total,
            milliseconds: diffInMillisecondsAccommodation,
            type: 'accommodation',
            filterCountry: countryCodes.length > 0 ? 1 : 0,
            filterCity: cityIds.length > 0 ? 1 : 0,
            filterSchool: filterSchool?.length > 0 ? 1 : 0,
            filterDuration: accommodationDuration ? 1 : 0,
            filterTypePeriod: accommodationPeriodType ? 1 : 0,
            filterCategory: accommodationCategory?.length === 0 ? 0 : 1,
            filterName: accommodationName ? 1 : 0,
            filterAge: onlyStudentAge ? 1 : 0,
            filterOnline: 0,
            filterOnshore: 0,
            filterOffshore: 0,
            filterWorkLoad: 0,
            filterPromotionOnly: 0,
            filterOnlyAvailable: 0,
            filterRoomType: accommodationRoomType?.length === 0 ? 0 : 1,
            filterBathroomType: accommodationBathroomType?.length === 0 ? 0 : 1,
            filterRegime: accommodationRegime?.length === 0 ? 0 : 1,
            filterFrom,
            allyPlus: quotesData.quotes?.length
              ? quotesData.quotes[0].plugAndPlay
              : 0,
          });
          const { data: accommodations, total, per_page } = response.data;

          const updatedAccommodations = enhanceAccommodationsCountry(
            accommodations
          );

          setAccommodationDurationInitial(accommodationDuration || 1);

          updatedAccommodations.forEach((accommodation: any) => {
            accommodation.withoutValue = true;
          });

          setResultType(filtersOptions.FILTERS_ACCOMMODATIONS);
          setResultItems([...oldResultItems, ...updatedAccommodations]);
          setPerPage(per_page);
          setTotalItems(total);
          setSearchLoading(false);
          // IMPORTANT:
          // The requests for each element are made using firstLoadingComplete
          setFirstLoadingComplete(true);
        })
        .catch(() => {
          backToIdleState();
        });
    },
    [
      selectedSortOpt,
      filterSchool,
      programStartDate,
      cityIds,
      quotesData.student.nationalityCountry,
      quotesData.student.age,
      quotesData.quotes,
      countryCodes,
      dateOfReference,
      currentPage,
      isUserAllyPlus,
      onlyStudentAge,
      addToast,
      partnerOptions,
      enhanceAccommodationsCountry,
      setResultItems,
      backToIdleState,
    ]
  );

  const fetchInsurances = useCallback(
    (data: FiltersFormData, oldResultItems: any[]) => {
      setFirstLoadingComplete(false);
      const { sortProp, sortType } = selectedSortOpt;
      const {
        insurancePartner,
        insuranceDuration,
        insuranceFromValue,
        insuranceToValue,
        insuranceType,
        insurancePeriodType,
        insuranceName,
      } = data;

      if (
        insuranceFromValue &&
        insuranceToValue &&
        insuranceFromValue * 1 > insuranceToValue * 1
      ) {
        setSearchLoading(false);
        setReadyToSearch(true);
        addToast({
          type: 'error',
          title: 'Error',
          description: 'Price Range is invalid, please try again.',
        });
        return;
      }

      let school_ids = filterSchool?.length
        ? JSON.stringify(filterSchool.map(s => s.value))
        : undefined;
      let partner_ids_json = insurancePartner?.length
        ? JSON.stringify(insurancePartner)
        : undefined;

      if (insuranceType === 'all' && insurancePartner) {
        const newPartner_ids: number[] = [];
        const provider_ids: number[] = [];
        const partner_ids = partnerOptions.map(s => s.value);
        insurancePartner.forEach(id => {
          if (!partner_ids.includes(id)) provider_ids.push(id);
          else newPartner_ids.push(id);
        });
        school_ids = JSON.stringify(provider_ids);
        partner_ids_json = JSON.stringify(newPartner_ids);
      }

      const timeStartedFee = new Date();
      api
        .get('/feepaginate', {
          params: {
            fromArray: true,
            fromSchool: insuranceType === 'school',
            fromAll: insuranceType === 'all',
            fromMy: insuranceType === 'my',
            campus_ids: data.filterCampus?.length
              ? JSON.stringify(data.filterCampus)
              : undefined,
            school_ids,
            partner_ids: partner_ids_json,
            city_ids: cityIds.length ? JSON.stringify(cityIds) : undefined,
            country: quotesData.student.nationalityCountry,
            country_codes: countryCodes.length
              ? JSON.stringify(countryCodes)
              : undefined,
            duration: insuranceDuration || 1,
            enrolDate: format(parseJSON(dateOfReference), 'dd/MM/yyyy'),
            filterValue: 1,
            page: currentPage ? currentPage : 1,
            provider_my: insuranceType === 'my',
            provider_myname: false,
            provider_name: false,
            provider_view: false,
            queryType: 'newQuote',
            schoolAccount: false,
            school_name: false,
            sortProp,
            sortType,
            fromValue: insuranceFromValue,
            toValue: insuranceToValue,
            typePeriod: insuranceDuration && insurancePeriodType,
            category_ids: JSON.stringify([4]),
            plugAndPlay: isUserAllyPlus ? 1 : 0,
            fee_name: insuranceName,
            fee_city_ids: cityIds ? JSON.stringify(cityIds) : undefined,
            fee_country_codes: countryCodes
              ? JSON.stringify(countryCodes)
              : undefined,
          },
        })
        .then(async response => {
          const timeEndedFee = new Date();
          const diffInMillisecondsFee =
            timeEndedFee.getTime() - timeStartedFee.getTime();
          let filterFrom = 1;
          if (insuranceType === 'my') filterFrom = 2;
          if (insuranceType === 'all') filterFrom = 3;
          api.post(`/searchperformancerate`, {
            total: response.data.total,
            milliseconds: diffInMillisecondsFee,
            type: 'fee',
            filterCountry: countryCodes.length > 0 ? 1 : 0,
            filterCity: cityIds.length > 0 ? 1 : 0,
            filterSchool: filterSchool?.length > 0 ? 1 : 0,
            filterDuration: insuranceDuration ? 1 : 0,
            filterTypePeriod: insurancePeriodType ? 1 : 0,
            filterCategory: 0,
            filterName: insuranceName ? 1 : 0,
            filterAge: 0,
            filterOnline: 0,
            filterOnshore: 0,
            filterOffshore: 0,
            filterWorkLoad: 0,
            filterPromotionOnly: 0,
            filterOnlyAvailable: 0,
            filterRoomType: 0,
            filterBathroomType: 0,
            filterRegime: 0,
            filterFrom,
            allyPlus: quotesData.quotes?.length
              ? quotesData.quotes[0].plugAndPlay
              : 0,
          });
          const { data: insurances, total, per_page } = response.data;

          let updatedInsurances = enhanceFeesCountry(insurances);

          updatedInsurances = getFeesCategories(insurances);

          setInsuranceDurationInitial(insuranceDuration || 1);

          updatedInsurances.forEach((fee: any) => {
            fee.withoutValue = true;
          });

          setResultType(filtersOptions.FILTERS_INSURANCES);
          setResultItems([...oldResultItems, ...updatedInsurances]);
          setPerPage(per_page);
          setTotalItems(total);
          setSearchLoading(false);
          setFirstLoadingComplete(true);
        })
        .catch(() => {
          backToIdleState();
        });
    },
    [
      selectedSortOpt,
      filterSchool,
      cityIds,
      quotesData.student.nationalityCountry,
      quotesData.quotes,
      isUserAllyPlus,
      countryCodes,
      dateOfReference,
      currentPage,
      addToast,
      partnerOptions,
      enhanceFeesCountry,
      getFeesCategories,
      setResultItems,
      backToIdleState,
    ]
  );

  const fetchAddons = useCallback(
    (data: FiltersFormData, oldResultItems: any[]) => {
      setFirstLoadingComplete(false);
      const { sortProp, sortType } = selectedSortOpt;
      const {
        addOnDuration,
        addOnFromValue,
        addOnToValue,
        addOnCategory,
        addOnName,
        addOnType,
        addOnPeriodType,
        addOnPartner,
      } = data;

      if (
        addOnFromValue &&
        addOnToValue &&
        addOnFromValue * 1 > addOnToValue * 1
      ) {
        setSearchLoading(false);
        setReadyToSearch(true);
        addToast({
          type: 'error',
          title: 'Error',
          description: 'Price Range is invalid, please try again.',
        });
        return;
      }

      let school_ids = filterSchool?.length
        ? JSON.stringify(filterSchool.map(s => s.value))
        : undefined;
      let partner_ids_json = addOnPartner?.length
        ? JSON.stringify(addOnPartner)
        : undefined;

      if (addOnType === 'all' && addOnPartner) {
        const newPartner_ids: number[] = [];
        const provider_ids: number[] = [];
        const partner_ids = partnerOptions.map(s => s.value);
        addOnPartner.forEach(id => {
          if (!partner_ids.includes(id)) provider_ids.push(id);
          else newPartner_ids.push(id);
        });
        school_ids = JSON.stringify(provider_ids);
        partner_ids_json = JSON.stringify(newPartner_ids);
      }

      const timeStartedAddons = new Date();
      api
        .get('/feepaginate', {
          params: {
            fromArray: true,
            campus_ids: data.filterCampus?.length
              ? JSON.stringify(data.filterCampus)
              : undefined,
            school_ids,
            partner_ids: partner_ids_json,
            city_ids: cityIds.length ? JSON.stringify(cityIds) : undefined,
            country: quotesData.student.nationalityCountry,
            country_codes: countryCodes.length
              ? JSON.stringify(countryCodes)
              : undefined,
            duration: addOnDuration || 1,
            enrolDate: format(parseJSON(dateOfReference), 'dd/MM/yyyy'),
            filterValue: 1,
            page: currentPage ? currentPage : 1,
            fromSchool: addOnType === 'school',
            fromMy: addOnType === 'my',
            fromAll: addOnType === 'all',
            provider_my: addOnType === 'my',
            provider_myname: false,
            provider_name: false,
            provider_view: false,
            queryType: 'newQuote',
            schoolAccount: false,
            school_name: false,
            sortProp,
            sortType,
            fromValue: addOnFromValue,
            toValue: addOnToValue,
            typePeriod: addOnDuration && addOnPeriodType,
            fee_name: addOnName,
            addOn: true,
            category_ids: JSON.stringify(addOnCategory),
            plugAndPlay: isUserAllyPlus ? 1 : 0,
            fee_city_ids: cityIds ? JSON.stringify(cityIds) : undefined,
            fee_country_codes: countryCodes
              ? JSON.stringify(countryCodes)
              : undefined,
          },
        })
        .then(async response => {
          const timeEndedAddons = new Date();
          const diffInMillisecondsAddons =
            timeEndedAddons.getTime() - timeStartedAddons.getTime();
          let filterFrom = 1;
          if (addOnType === 'my') filterFrom = 2;
          if (addOnType === 'all') filterFrom = 3;
          api.post(`/searchperformancerate`, {
            total: response.data.total,
            milliseconds: diffInMillisecondsAddons,
            type: 'addons',
            filterCountry: countryCodes.length > 0 ? 1 : 0,
            filterCity: cityIds.length > 0 ? 1 : 0,
            filterSchool: filterSchool?.length > 0 ? 1 : 0,
            filterDuration: addOnDuration ? 1 : 0,
            filterTypePeriod: addOnPeriodType ? 1 : 0,
            filterCategory: addOnCategory?.length === 0 ? 0 : 1,
            filterName: addOnName ? 1 : 0,
            filterAge: 0,
            filterOnline: 0,
            filterOnshore: 0,
            filterOffshore: 0,
            filterWorkLoad: 0,
            filterPromotionOnly: 0,
            filterOnlyAvailable: 0,
            filterRoomType: 0,
            filterBathroomType: 0,
            filterRegime: 0,
            filterFrom,
            allyPlus: quotesData.quotes?.length
              ? quotesData.quotes[0].plugAndPlay
              : 0,
          });
          const { data: addons, total, per_page } = response.data;

          let updatedAddons = enhanceFeesCountry(addons);

          updatedAddons = getFeesCategories(addons);

          setAddOnDurationInitial(addOnDuration || 1);

          updatedAddons.forEach((fee: any) => {
            fee.withoutValue = true;
          });

          setResultType(filtersOptions.FILTERS_ADDONS);
          setResultItems([...oldResultItems, ...updatedAddons]);
          setPerPage(per_page);
          setTotalItems(total);
          setSearchLoading(false);
          setFirstLoadingComplete(true);
        })
        .catch(() => {
          backToIdleState();
        });
    },
    [
      selectedSortOpt,
      filterSchool,
      cityIds,
      quotesData.student.nationalityCountry,
      quotesData.quotes,
      isUserAllyPlus,
      countryCodes,
      dateOfReference,
      currentPage,
      addToast,
      partnerOptions,
      enhanceFeesCountry,
      getFeesCategories,
      setResultItems,
      backToIdleState,
    ]
  );

  const fetchExperiences = useCallback(
    (data: FiltersFormData, oldResultItems: any[]) => {
      const { experienceName, experiencePartner } = data;
      setFirstLoadingComplete(false);
      api
        .get('/experiencepaginate', {
          params: {
            fromArray: true,
            queryType: 'newQuote',
            experience_name: experienceName,
            page: currentPage ? currentPage : 1,
            partner_ids: JSON.stringify(experiencePartner),
          },
        })
        .then(response => {
          const { data: experiences, total, per_page } = response.data;

          setResultType(filtersOptions.FILTERS_EXPERIENCES);
          setResultItems([...oldResultItems, ...experiences]);
          setPerPage(per_page);
          setTotalItems(total);
          setSearchLoading(false);
          setFirstLoadingComplete(true);
          experiences.forEach((experience: any) => {
            experience.loadedRealValues = true;
          });
        })
        .finally(() => {
          setSearchLoading(false);
          setReadyToSearch(true);
        });
    },
    [currentPage, setResultItems]
  );

  const toggleCollapsibleOpened = useCallback(
    (tab: string) => {
      setCurrentFiltersTab(tab);
      setIsCourseFiltersOpen(
        tab === filtersOptions.FILTERS_COURSES && !isCourseFiltersOpen
      );
      setIsAccommodationFiltersOpen(
        tab === filtersOptions.FILTERS_ACCOMMODATIONS &&
          !isAccommodationFiltersOpen
      );
      setIsInsuranceFiltersOpen(
        tab === filtersOptions.FILTERS_INSURANCES && !isInsuranceFiltersOpen
      );
      setIsAddOnFiltersOpen(
        tab === filtersOptions.FILTERS_ADDONS && !isAddOnFiltersOpen
      );
      setIsExperienceFiltersOpen(
        tab === filtersOptions.FILTERS_EXPERIENCES && !isExperienceFiltersOpen
      );

      setCurrentPage(0);

      updateCollapsiblesOverflow(tab);
    },
    [
      isAccommodationFiltersOpen,
      isAddOnFiltersOpen,
      isCourseFiltersOpen,
      isExperienceFiltersOpen,
      isInsuranceFiltersOpen,
    ]
  );

  const handleCountryChange = useCallback((countries: CountryCOption[]) => {
    setFilterCountry(countries);
    const currentCountryCodes = countries
      ? countries.map(country => country.value)
      : [];

    setCountryCodes(currentCountryCodes);
  }, []);

  const handleCityChange = useCallback((cities: CityOption[]) => {
    setFilterCity(cities);

    const currentCityIds = cities ? cities.map(city => city.value) : [];

    setCityIds(currentCityIds);
  }, []);

  const handleChangeAddOnType = useCallback(
    option => {
      setCurrentAddOnType(option);
      updateSmartFilters();
    },
    [updateSmartFilters]
  );

  const handleChangeAccommodationType = useCallback(
    option => {
      setCurrentAccommodationType(option);
      updateSmartFilters();
    },
    [updateSmartFilters]
  );

  const orderByLabel = (
    options: SchoolOption[] | PartnerOption[] | (SchoolOption & PartnerOption)[]
  ): void => {
    options.sort((a, b) => {
      if (a.label !== undefined && b.label !== undefined)
        return a?.label.localeCompare(b?.label);
      return 0;
    });
  };

  const handleSchoolChange = useCallback(
    async (schools: SchoolOption[]) => {
      setCampusOptionsResponse(false);
      const schoolWithoutAllyPlusIcon = schools.map(
        ({ label, school, value }) => ({
          label,
          school,
          value,
        })
      );

      setFilterSchool(schoolWithoutAllyPlusIcon);

      let category = subcategory ? subcategory : false;
      if (subcategory && subcategory.length === 0) {
        category = false;
      }
      if (!category && newcategory) {
        category = newcategory;
      }

      if (schools.length > 0) {
        const { data: countries } = await api.get('/country', {
          params: {
            fromPlugAndPlay: isUserAllyPlus ? 1 : 0,
            fromArray: true,
            forQuote: true,
            category,
            school_ids: JSON.stringify(schools.map(s => s.value)),
          },
        });
        const countriesFormatted = countries.map((country: any) => ({
          value: country.code,
          label: country.name,
        }));

        setCountryCOptions(countriesFormatted);
      } else {
        getCountries();
      }

      const { data: cities } = await api.get('/city', {
        params: {
          fromArray: true,
          forQuote: true,
          search: 1,
          category: category ? category : undefined,
          restriction: restriction ? JSON.stringify(restriction) : undefined,
          programDurationType: programDurationType
            ? JSON.stringify(programDurationType)
            : undefined,
          country_codes: JSON.stringify(countryCodes),
          school_ids: JSON.stringify(schools.map(s => s.value)),
        },
      });
      const citiesFormatted = cities.map((city: any) => ({
        value: city.id,
        label: city.name,
      }));

      setCityOptions(citiesFormatted);
    },
    [
      subcategory,
      newcategory,
      restriction,
      programDurationType,
      countryCodes,
      isUserAllyPlus,
      getCountries,
    ]
  );

  const handleCampusChange = useCallback(
    async (campuss: CampusOption[]) => {
      const campusWithoutAllyPlusIcon = campuss.map(
        ({ label, campus, value }) => ({
          label,
          campus,
          value,
        })
      );

      setFilterCampus(campusWithoutAllyPlusIcon);
      setFilterCampusOption(campuss);
      // let category = subcategory ? subcategory : false;
      // if (subcategory && subcategory.length === 0) {
      //   category = false;
      // }
      // if (!category && newcategory) {
      //   category = newcategory;
      // }

      // if (schools.length > 0) {
      //   const { data: countries } = await api.get('/country', {
      //     params: {
      //       fromPlugAndPlay: isUserAllyPlus ? 1 : 0,
      //       fromArray: true,
      //       forQuote: true,
      //       category,
      //       school_ids: JSON.stringify(schools.map(s => s.value)),
      //     },
      //   });
      //   const countriesFormatted = countries.map((country: any) => ({
      //     value: country.code,
      //     label: country.name,
      //   }));

      //   setCountryCOptions(countriesFormatted);
      // } else {
      //   getCountries();
      // }

      // const { data: cities } = await api.get('/city', {
      //   params: {
      //     fromArray: true,
      //     forQuote: true,
      //     search: 1,
      //     category: category ? category : undefined,
      //     restriction: restriction ? JSON.stringify(restriction) : undefined,
      //     programDurationType: programDurationType
      //       ? JSON.stringify(programDurationType)
      //       : undefined,
      //     country_codes: JSON.stringify(countryCodes),
      //     school_ids: JSON.stringify(schools.map(s => s.value)),
      //   },
      // });
      // const citiesFormatted = cities.map((city: any) => ({
      //   value: city.id,
      //   label: city.name,
      // }));

      // setCityOptions(citiesFormatted);
    },
    [filterCampusOption]
  );

  const handleProviderChange = (
    value: OptionTypeBase | PartnerOption[] | null
  ) => {
    if (!value) return;

    orderByLabel(value as PartnerOption[]);

    setFilterProvider(value as PartnerOption[]);
  };

  const filterProvidersValue = (id = 0) => {
    const provider =
      id === 0
        ? filterProvider
        : filterProvider.filter(partner => partner.category_id === id);

    orderByLabel(provider);

    return provider;
  };

  const handleSchoolsOrProvidersChange = (
    selectedValues: OptionTypeBase | (SchoolOption | PartnerOption)[] | null
  ) => {
    if (!selectedValues) return;

    if (selectedValues.length === 0) {
      setFilterSchool([]);
      setFilterProvider([]);
    }

    const schools: SchoolOption[] = [];
    const providers: PartnerOption[] = [];

    selectedValues.forEach((item: SchoolOption | PartnerOption) => {
      item.school === 1
        ? schools.push(item as SchoolOption)
        : providers.push(item as PartnerOption);
    });

    const schoolWithoutAllyPlusIcon = schools.map(
      ({ label, school, value }) => ({
        label,
        school,
        value,
      })
    );

    orderByLabel(schoolWithoutAllyPlusIcon);
    orderByLabel(providers);

    setFilterSchool(schoolWithoutAllyPlusIcon);
    setFilterProvider(providers);
  };

  const filterSchoolsOrProvidersValue = (id = 0) => {
    const filtersValue =
      id === 0
        ? [...filterSchool, ...filterProvider]
        : [
            ...filterSchool,
            ...filterProvider.filter(partner => partner.category_id === id),
          ];

    orderByLabel(filtersValue);

    return filtersValue;
  };

  const handleBackLinkClick = useCallback(() => {
    if (currentStep === 3) {
      const updatedOptions = breadcrumbOptions.map((option, index) => {
        if (index === 1) {
          option.active = !option.active;
        }
        return option;
      });
      setBreadcrumbOptions(updatedOptions);

      previous(quotesData);
    }
  }, [currentStep, breadcrumbOptions, quotesData, previous]);

  const handleSortOptionChange = useCallback(
    (index: number) => {
      const updatedSortOptions = sortOptions.map((option, i) => ({
        ...option,
        active: i === index,
      }));
      const activeSortOpt = updatedSortOptions.find(s => s.active);

      if (activeSortOpt) {
        filteringActive = true;
        setSelectedSortOpt(activeSortOpt);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      isFiltering = true;

      setSortOptions(updatedSortOptions);
    },
    [sortOptions, isFiltering]
  );

  const updateProgressBar = (
    lastElementNumber: number,
    totalItemInList: number
  ): void => {
    const prgrss = (lastElementNumber * 100) / totalItemInList;
    setProgress(prgrss);
  };

  /** *
   *  BEGIN:
   *  Request data for each Accommodation.
   *
   */

  const fetchNewPricesForAccommodation = useCallback(
    async (accommodation, courseStartDate, _dateOfReference, duration) => {
      const accommodationAffectedKeys = [
        'price',
        'priceIsExpired',
        'priceIsAboutToExpire',
        'srIsAboutToExpire',
        'srExpireAt',
        'srApplied',
        'extraprice',
        'duration',
        'extraDuration',
        'onlyAccommodation',
        'onlyEnrol',
        'onlyMaterial',
        'priceError',
        'priceOld',
        'sumThisValue',
        'specialrate',
        'totalThisAccommodation',
      ];

      const response = await api.get(
        `/accommodationprice/${accommodation.id}`,
        {
          params: {
            country: quotesData.student.nationalityCountry,
            duration,
            courseStartDateFrom: format(
              parseJSON(courseStartDate),
              'dd/MM/yyyy'
            ),
            enrolDate: format(parseJSON(dateOfReference), 'dd/MM/yyyy'),
            priceIsExpired: accommodation.priceIsExpired,
            expiredEnrolDate: accommodation.priceIsExpired
              ? format(parseISO(accommodation.price.date.endDate), 'dd/MM/yyyy')
              : null,
          },
        }
      );

      const newAccommodation = {
        ...accommodation,
      };

      accommodationAffectedKeys.forEach(key => {
        newAccommodation[key] = response.data[key];
        newAccommodation.loadedRealValues = true;
      });
      newAccommodation.duration = response.data['duration'];
      newAccommodation.price = response.data['price'];
      newAccommodation.pricePeriod = response.data['typePeriod'];

      return enhanceAccommodationWithCountry(newAccommodation);
    },
    [
      dateOfReference,
      enhanceAccommodationWithCountry,
      quotesData.student.nationalityCountry,
    ]
  );

  const updateAccommodationWithPriceAndDuration = useCallback(
    async (
      accommodation: any,
      duration: number,
      oldResultItems: any[]
    ): Promise<any> => {
      const newAccommodation = await fetchNewPricesForAccommodation(
        accommodation,
        programStartDate,
        dateOfReference,
        duration
      );

      // updating ResultItems array with the new Accommodation data
      const newResultItems = oldResultItems.map((result: any) => {
        if (result.id === Number(newAccommodation.id)) {
          return newAccommodation;
        }
        return result;
      });
      return newResultItems;
    },
    [dateOfReference, fetchNewPricesForAccommodation, programStartDate]
  );

  const fetchNewPricesForFee = useCallback(
    async (fee, _dateOfReference, duration) => {
      const feeAffectedKeys: string[] = [
        'duration',
        'price',
        'priceIsExpired',
        'priceIsAboutToExpire',
        'extraDuration',
        'onlyCourse',
        'onlyEnrol',
        'onlyMaterial',
        'priceError',
        'priceOld',
        'sumThisValue',
        'totalThisFee',
      ];

      const response = await api.get(`/feeprice/${fee.id}`, {
        params: {
          country: quotesData.student.nationalityCountry,
          duration,
          priceIsExpired: fee.priceIsExpired,
          expiredEnrolDate: fee.priceIsExpired
            ? format(parseISO(fee.price.date.endDate), 'dd/MM/yyyy')
            : null,
          enrolDate: format(parseJSON(dateOfReference), 'dd/MM/yyyy'),
        },
      });

      const newFee = {
        ...fee,
      };

      feeAffectedKeys.forEach(key => {
        newFee[key] = response.data[key];
      });
      newFee.loadedRealValues = true;
      newFee.duration = response.data['duration'];
      if (!newFee.currency) {
        newFee.currency = response.data['currency'];
        newFee.currency_code = response.data['currency_code'];
      }
      if (!newFee.fee.currency) {
        newFee.fee.currency = response.data['currency'];
        newFee.fee.currency_code = response.data['currency_code'];
      }
      newFee.price = response.data['price'];
      newFee.pricePeriod = response.data['typePeriod'];

      return enhanceFeeWithCountry(newFee);
    },
    [
      dateOfReference,
      enhanceFeeWithCountry,
      quotesData.student.nationalityCountry,
    ]
  );

  const updateFeeWithPriceAndDuration = useCallback(
    async (
      addon: any,
      duration: number,
      oldResultItems: any[]
    ): Promise<any> => {
      // resultItems.filter(Addon => Addon.id === id)[0] || [];
      const newAddon = await fetchNewPricesForFee(
        addon,
        dateOfReference,
        duration
      );

      // updating ResultItems array with the new Addon data
      const newResultItems = oldResultItems.map((result: any) => {
        if (result.id === Number(newAddon.id)) {
          return newAddon;
        }
        return result;
      });
      return newResultItems;
    },
    [dateOfReference, fetchNewPricesForFee]
  );

  const fetchNewPricesForCourse = useCallback(
    async (course, student, courseStartDate, _dateOfReference, duration) => {
      const courseAffectedKeys = [
        'duration',
        'priceIsExpired',
        'priceIsAboutToExpire',
        'srIsAboutToExpire',
        'srExpireAt',
        'srApplied',
        'course',
        'campus',
        'enrolActive',
        'materialActive',
        'extraDuration',
        'onlyCourse',
        'onlyEnrol',
        'onlyMaterial',
        'price',
        'extraprice',
        'specialrate',
        'priceError',
        'priceOld',
        'sumThisValue',
        'totalThisCourse',
      ];
      const { id } = course;
      let { isAllyplus, isBackoffice } = course;

      if (!isAllyplus || !isBackoffice) {
        isBackoffice = course.coursecampus.campus.school.accountlink;
        isAllyplus = course.coursecampus.campus.school.allyplus;
      }

      const response = await api.get(`/firstprice/${id}`, {
        params: {
          country: student.nationalityCountry,
          courseStartDateFrom: format(parseJSON(courseStartDate), 'dd/MM/yyyy'),
          teste: 4,
          duration,
          enrolDate: format(parseJSON(_dateOfReference), 'dd/MM/yyyy'),
          startDate: format(parseJSON(_dateOfReference), 'dd/MM/yyyy'),
          student_id: student.id,
        },
      });

      const newCourse = {
        ...course,
      };

      courseAffectedKeys.forEach(key => {
        if (key === 'enrolActive' || key === 'materialActive') {
          if (response.data[key] === 'yes') {
            response.data[key] = true;
          } else if (response.data[key] === 'no') {
            response.data[key] = false;
          }
        }
        newCourse.coursecampus[key] = response.data[key];
        if (
          typeof isAllyplus !== 'undefined' &&
          typeof isBackoffice !== 'undefined'
        ) {
          newCourse.isAllyPlus = isAllyplus;
          newCourse.isBackoffice = isBackoffice;
        }
        newCourse.loadedRealValues = true;
      });

      newCourse.duration = response.data['duration'];
      newCourse.price = response.data['price'];
      newCourse.pricePeriod = response.data['typePeriod'];

      return enhanceCourseWithCountry(newCourse);
    },
    [enhanceCourseWithCountry]
  );

  const updateCourseWithPriceAndDuration = useCallback(
    async (
      selectedCourse: any,
      duration: number,
      oldResultItems: any[]
    ): Promise<any> => {
      // resultItems.filter(course => course.id === id)[0] || [];
      const newCourse = await fetchNewPricesForCourse(
        selectedCourse,
        quotesData.student,
        programStartDate,
        dateOfReference,
        duration
      );
      /*
      if ( !newCourse.coursecampus.price &&
        newCourse.coursecampus.priceError === 'duration'
      ) {
        throw new Error('Duration is invalid');
      } */

      // updating ResultItems array with the new course data
      const newResultItems = oldResultItems.map((result: any) => {
        if (result.id === Number(newCourse.id)) {
          return newCourse;
        }
        return result;
      });
      return newResultItems;
    },
    [
      dateOfReference,
      programStartDate,
      fetchNewPricesForCourse,
      quotesData.student,
    ]
  );

  const fetchOneCourseData = useCallback(
    async (
      courseIndex: number,
      oldResultItems: any[],
      suggestive: boolean
    ): Promise<boolean> => {
      if (!oldResultItems) return false;
      if (courseIndex >= oldResultItems.length) {
        return true;
      }

      const course = oldResultItems[courseIndex];
      if (course.loadedRealValues) {
        // 3. This course was already loaded, to go next one.
        fetchOneCourseData(courseIndex + 1, oldResultItems, suggestive);
        return false;
      }

      // 4. Request update for course and receives the new result items
      const newResultItems = await updateCourseWithPriceAndDuration(
        course,
        courseDurationInitial,
        oldResultItems
      );

      setSuggestiveResult(newResultItems, stt =>
        fetchOneCourseData(courseIndex + 1, stt, suggestive)
      );
      return true;
    },
    [
      updateCourseWithPriceAndDuration,
      setSuggestiveResult,
      courseDurationInitial,
    ]
  );

  const updateCourse = useCallback(
    async (selectedCourse: any, duration: number): Promise<any> => {
      const newCourse = await fetchNewPricesForCourse(
        selectedCourse,
        quotesData.student,
        programStartDate,
        dateOfReference,
        duration
      );

      return newCourse;
    },
    [
      dateOfReference,
      programStartDate,
      fetchNewPricesForCourse,
      quotesData.student,
    ]
  );

  const updateAccommodation = useCallback(
    async (accommodation: any, duration: number): Promise<any> => {
      const newAccommodation = await fetchNewPricesForAccommodation(
        accommodation,
        programStartDate,
        dateOfReference,
        duration
      );

      return newAccommodation;
    },
    [dateOfReference, fetchNewPricesForAccommodation, programStartDate]
  );

  const updateFee = useCallback(
    async (addon: any, duration: number): Promise<any> => {
      const newAddon = await fetchNewPricesForFee(
        addon,
        dateOfReference,
        duration
      );

      return newAddon;
    },
    [dateOfReference, fetchNewPricesForFee]
  );

  const loadItems = useCallback(
    async (oldResultItems: any[]) => {
      const array = [...oldResultItems];

      updateProgressBar(
        resultItems.length - (array.length - 1),
        resultItems.length
      );

      if (array.length === 1) {
        let course = array[0];

        if (!course.loadedRealValues) {
          switch (resultType) {
            case filtersOptions.FILTERS_COURSES:
              course = await updateCourse(course, courseDurationInitial);
              break;
            case filtersOptions.FILTERS_ACCOMMODATIONS:
              course = await updateAccommodation(
                course,
                accommodationDurationInitial
              );
              break;
            case filtersOptions.FILTERS_INSURANCES:
              course = await updateFee(course, insuranceDurationInitial);
              break;
            case filtersOptions.FILTERS_ADDONS:
              course = await updateFee(course, addOnDurationInitial);
              break;
            default:
              null;
              break;
          }
        }

        setResultItems((prevState: any[]) =>
          prevState.map(result => {
            if (result.id === course.id) {
              return course;
            }
            return result;
          })
        );
        return;
      }
      const halfLength = Number((array.length / 2).toFixed(2));
      const modifiedArr = array.splice(0, halfLength);
      loadItems(modifiedArr);
      loadItems(array);
    },
    [
      resultItems,
      resultType,
      setResultItems,
      updateCourse,
      updateAccommodation,
      updateFee,
      courseDurationInitial,
      accommodationDurationInitial,
      insuranceDurationInitial,
      addOnDurationInitial,
    ]
  );

  const fetchSuggestiveResult = useCallback(
    async (data: FiltersFormData, isGeneralSearch: boolean) => {
      const { courseDuration, coursePeriodType } = data;
      const { sortProp, sortType } = selectedSortOpt;

      await api
        .get<PaginateData>(`/coursecampuspaginate`, {
          params: {
            fromArray: true,
            busca: 1,
            city_ids:
              cityIds.length && !isGeneralSearch
                ? JSON.stringify(cityIds)
                : undefined,
            country_codes:
              countryCodes.length && !isGeneralSearch
                ? JSON.stringify(countryCodes)
                : undefined,
            campus_id: false,
            country: quotesData.student.nationalityCountry,
            courseStartDateFrom: programStartDate
              ? format(parseJSON(programStartDate), 'dd/MM/yyyy')
              : undefined,
            duration: 1,
            typePeriod: courseDuration && coursePeriodType,
            enrolDate: format(parseJSON(dateOfReference), 'dd/MM/yyyy'),
            filterValue: 1, // sempre 1
            page: currentPage > 0 ? currentPage : 1,
            queryType: 'newQuote',
            sortProp,
            sortType,
            startDate: format(parseJSON(dateOfReference), 'dd/MM/yyyy'),
            available: showAvailableValues.current,
            plugAndPlay: isUserAllyPlus ? 1 : 0,
            online: onlyOnline,
            renewal: onlyRenewal,
            onshore: onlyOnshore,
            offshore: onlyOffshore,
            suggestive: true,
          },
        })
        .then(async response => {
          setCourseDurationInitial(courseDuration || 1);
          const { data: courses } = response.data;
          const updatedCourses = enhanceCoursesCountry(courses);

          updatedCourses.forEach((course: any) => {
            const {
              coursecampus: {
                enrolActive,
                materialActive,
                campus: {
                  school: { allyplus },
                },
              },
            } = course;

            if (enrolActive === 'yes') {
              course.coursecampus.enrolActive = true;
            } else if (enrolActive === 'no') {
              course.coursecampus.enrolActive = false;
            }
            if (materialActive === 'yes') {
              course.coursecampus.materialActive = true;
            } else if (materialActive === 'no') {
              course.coursecampus.materialActive = false;
            }
            course.withoutValue = true;
            course.availableWithAllyPlus = Boolean(allyplus);
          });

          setSuggestiveResult(updatedCourses, newStatus => {
            newStatus.length
              ? fetchOneCourseData(0, newStatus, true)
              : fetchSuggestiveResult(data, true);
          });
        })
        .catch(() => {
          backToIdleState();
        });
    },
    [
      selectedSortOpt,
      cityIds,
      countryCodes,
      quotesData.student.nationalityCountry,
      programStartDate,
      dateOfReference,
      currentPage,
      isUserAllyPlus,
      onlyOnline,
      onlyRenewal,
      onlyOnshore,
      onlyOffshore,
      enhanceCoursesCountry,
      setSuggestiveResult,
      fetchOneCourseData,
      backToIdleState,
    ]
  );

  const fetchCourses = useCallback(
    async (data: FiltersFormData, oldResultItems: any[]) => {
      setFirstLoadingComplete(false);

      const { sortProp, sortType } = selectedSortOpt;
      const {
        courseStartDate,
        courseDuration,
        courseFromValue,
        programDurationTypeOption,
        courseToValue,
        courseType,
        coursePeriodType,
        restrictionOption,
      } = data;

      if (
        courseFromValue &&
        courseToValue &&
        courseFromValue * 1 > courseToValue * 1
      ) {
        setSearchLoading(false);
        setReadyToSearch(true);
        addToast({
          type: 'error',
          title: 'Error',
          description: 'Price Range is invalid, please try again.',
        });
        return;
      }

      let category = subcategory ? subcategory : false;
      if (subcategory && subcategory.length === 0) {
        category = false;
      }
      if (!category && newcategory) {
        category = newcategory;
      }
      const timeStartedCourse = new Date();

      await fetchSuggestiveResult(data, false);

      let showAllyPlusItems = 1;
      if (userPreferenceEdited.current) {
        showAllyPlusItems = hideResultAllyPlus.current ? 0 : 1;
      } else {
        showAllyPlusItems =
          typeof user.preference !== 'undefined' &&
          typeof user.preference.allyplus !== 'undefined'
            ? user.preference.allyplus
            : 1;
      }

      showAllyPlusItems = isIEAccount ? 0 : showAllyPlusItems;

      hideResultAllyPlus.current = showAllyPlusItems === 1 ? false : true;
      const restrictionString = restrictionOption.length
        ? JSON.stringify(restrictionOption)
        : '';
      api
        .get<PaginateData>(`/coursecampuspaginate`, {
          params: {
            fromArray: true,
            busca: 1,
            city_ids: cityIds.length ? JSON.stringify(cityIds) : undefined,
            country_codes: countryCodes.length
              ? JSON.stringify(countryCodes)
              : undefined,
            campus_ids: data.filterCampus?.length
              ? JSON.stringify(data.filterCampus)
              : undefined,
            school_ids: filterSchool?.length
              ? JSON.stringify(filterSchool.map(s => s.value))
              : undefined,
            country: quotesData.student.nationalityCountry,
            courseStartDateFrom: programStartDate
              ? format(parseJSON(programStartDate), 'dd/MM/yyyy')
              : undefined,
            course_name: courseName,
            duration: courseDuration || 1,
            typePeriod: courseDuration && programDurationTypeOption,
            type: courseType,
            category,
            enrolDate: format(parseJSON(dateOfReference), 'dd/MM/yyyy'),
            filterValue: 1, // sempre 1
            page: currentPage > 0 ? currentPage : 1,
            queryType: 'newQuote',
            sortProp,
            sortType,
            startDate: format(parseJSON(dateOfReference), 'dd/MM/yyyy'),
            fromValue: courseFromValue,
            toValue: courseToValue,
            onlySR: promotionOnly,
            online: restrictionString.includes('online'),
            renewal: restrictionString.includes('renewal'),
            onshore: restrictionString.includes('onshore'),
            offshore: restrictionString.includes('offshore'),
            studentAge: restrictionString.includes('age')
              ? quotesData.student.age
              : undefined,
            available: showAvailableValues.current,
            allyplus: showAllyPlusItems,
            plugAndPlay: isUserAllyPlus ? 1 : 0,
            minStudyPerHour: sliderValue[0],
            maxStudyPerHour: sliderValue[1],
          },
        })
        .then(async response => {
          const timeEndedCourse = new Date();
          // hideResultAllyPlus.current = response.data.allyplus ? false : true;
          const diffInMillisecondsCourse =
            timeEndedCourse.getTime() - timeStartedCourse.getTime();
          api.post(`/searchperformancerate`, {
            total: response.data.total,
            milliseconds: diffInMillisecondsCourse,
            type: 'program',
            filterCountry: countryCodes.length > 0 ? 1 : 0,
            filterCity: cityIds.length > 0 ? 1 : 0,
            filterSchool: filterSchool?.length > 0 ? 1 : 0,
            filterDuration: courseDuration ? 1 : 0,
            filterTypePeriod: coursePeriodType ? 1 : 0,
            filterCategory: category ? 1 : 0,
            filterName: courseName ? 1 : 0,
            filterAge: onlyStudentAge ? 1 : 0,
            filterOnline: onlyOnline ? 1 : 0,
            filterRenewal: onlyRenewal ? 1 : 0,
            filterOnshore: onlyOnshore ? 1 : 0,
            filterOffshore: onlyOffshore ? 1 : 0,
            filterWorkLoad: sliderValue ? 1 : 0,
            filterPromotionOnly: promotionOnly ? 1 : 0,
            filterOnlyAvailable: showAvailableValues.current ? 1 : 0,
            filterRoomType: 0,
            filterBathroomType: 0,
            filterRegime: 0,
            filterFrom: 0,
            allyPlus: quotesData.quotes?.length
              ? quotesData.quotes[0].plugAndPlay
              : 0,
          });
          setCourseDurationInitial(courseDuration || 1);
          const { data: courses, total, per_page } = response.data;
          const updatedCourses = enhanceCoursesCountry(courses);

          updatedCourses.forEach((course: any) => {
            const {
              coursecampus: {
                enrolActive,
                materialActive,
                campus: {
                  school: { allyplus },
                },
              },
            } = course;

            if (enrolActive === 'yes') {
              course.coursecampus.enrolActive = true;
            } else if (enrolActive === 'no') {
              course.coursecampus.enrolActive = false;
            }
            if (materialActive === 'yes') {
              course.coursecampus.materialActive = true;
            } else if (materialActive === 'no') {
              course.coursecampus.materialActive = false;
            }
            course.withoutValue = true;
            course.availableWithAllyPlus = Boolean(allyplus);
            course.showAllyPlusItems = showAllyPlusItems;
          });

          setResultType(filtersOptions.FILTERS_COURSES);
          setResultItems([...oldResultItems, ...updatedCourses]);
          setPerPage(per_page);
          setTotalItems(total);
          setSearchLoading(false);
          // IMPORTANT:
          // The requests for each element are made using firstLoadingComplete
          setFirstLoadingComplete(true);
        })
        .catch(() => {
          backToIdleState();
        });
    },
    [
      selectedSortOpt,
      subcategory,
      newcategory,
      showAvailableValues,
      cityIds,
      countryCodes,
      filterSchool,
      quotesData.student.nationalityCountry,
      quotesData.student.age,
      quotesData.quotes,
      isUserAllyPlus,
      programStartDate,
      dateOfReference,
      currentPage,
      promotionOnly,
      onlyOnline,
      onlyRenewal,
      onlyOnshore,
      onlyOffshore,
      onlyStudentAge,
      sliderValue,
      addToast,
      enhanceCoursesCountry,
      setResultItems,
      backToIdleState,
      courseName,
      fetchSuggestiveResult,
      user.preference,
      isIEAccount,
    ]
  );

  const fetchDataForQuote = useCallback(
    currentResultItems => {
      try {
        const data = filtersFormRef.current?.getData() as FiltersFormData;
        if (currentFiltersTab === filtersOptions.FILTERS_COURSES) {
          fetchCourses(data, currentResultItems);
        }
        if (currentFiltersTab === filtersOptions.FILTERS_ACCOMMODATIONS) {
          fetchAccommodations(data, currentResultItems);
        }
        if (currentFiltersTab === filtersOptions.FILTERS_INSURANCES) {
          fetchInsurances(data, currentResultItems);
        }
        if (currentFiltersTab === filtersOptions.FILTERS_ADDONS) {
          fetchAddons(data, currentResultItems);
        }
        if (currentFiltersTab === filtersOptions.FILTERS_EXPERIENCES) {
          fetchExperiences(data, currentResultItems);
        }
      } catch (err: any) {
        addToast({
          type: 'error',
          title: 'Error',
          description:
            err.message || 'An error has occurred, please try again.',
        });
      }
    },
    [
      currentFiltersTab,
      fetchCourses,
      fetchAccommodations,
      fetchInsurances,
      fetchAddons,
      fetchExperiences,
      addToast,
    ]
  );

  const onClickContactButton = async () => {
    if (user.role === 'admin') {
      window.open(`${process.env.REACT_APP_NG_URL}/settings`);
      return;
    }

    api
      .get('/sendmailnewrequestallyplus', {
        params: {
          userName: user.name,
          agencyName: user.office.account.agency_name,
        },
      })
      .then(() => {
        addToast({
          title: 'Sucesso',
          description:
            'Breve um agente entrará em contato com mais informações.',
          type: 'success',
        });
      })
      .catch((e: any) => {
        addToast({
          title: 'Erro',
          description:
            e.message || 'Occoreu um erro. Por favor, tente novamente.',
          type: 'error',
        });
      });
  };

  const handleSearch = useCallback(
    (shouldResetResultItems, shouldShowLoading = true) => {
      if (currentStep === 3) {
        handleBackLinkClick();
      }

      if (shouldShowLoading) setSearchLoading(true);

      if (shouldResetResultItems) {
        setSuggestiveResult([]);
        setResultItems([], stt => fetchDataForQuote(stt));
      } else {
        fetchDataForQuote(resultItems);
      }
    },
    [
      fetchDataForQuote,
      setResultItems,
      setSuggestiveResult,
      resultItems,
      currentStep,
      handleBackLinkClick,
    ]
  );

  const handleInfiniteScrollClick = useCallback(() => {
    if (readyToSearch) {
      setReadyToSearch(false);
      setCurrentPage(currentPage + 1);
      const shouldResetResultItems = false;
      handleSearch(shouldResetResultItems, false);
    }
  }, [handleSearch, readyToSearch, currentPage]);

  const handleSearchClick = useCallback(() => {
    // TODO: adding prevent default here
    if (readyToSearch) {
      setReadyToSearch(false);
      setCurrentPage(0);
      const shouldResetResultItems = true;
      handleSearch(shouldResetResultItems);
    }
  }, [handleSearch, readyToSearch]);

  const handleDurationChange = useCallback(
    async (data: ListItemFormData, isSuggestive: boolean) => {
      try {
        const {
          id,
          type,
          data: item,
          duration,
          departureDate,
          experienceDepartureDate: experienceDate_id,
        } = data;

        const jsonItem = JSON.parse(String(item));

        if (!programStartDate) return;

        if (type === filtersOptions.FILTERS_COURSES) {
          if (isSuggestive) {
            const newResultItems = await updateCourseWithPriceAndDuration(
              jsonItem,
              duration,
              suggestiveResult
            );
            setSuggestiveResult(newResultItems);
          } else {
            const newResultItems = await updateCourseWithPriceAndDuration(
              jsonItem,
              duration,
              resultItems
            );
            setResultItems(newResultItems);
          }
        } else if (type === filtersOptions.FILTERS_ACCOMMODATIONS) {
          // const accom = resultItems.filter(result => result.id === Number(id));
          const newResultItems = await updateAccommodationWithPriceAndDuration(
            jsonItem,
            duration,
            resultItems
          );
          setResultItems(newResultItems);
        } else if (
          type === filtersOptions.FILTERS_INSURANCES ||
          type === filtersOptions.FILTERS_ADDONS
        ) {
          const addon = resultItems.filter(result => result.id === Number(id));
          const newResultItems = await updateFeeWithPriceAndDuration(
            jsonItem,
            duration,
            resultItems
          );
          setResultItems(newResultItems);
        } else if (type === filtersOptions.FILTERS_EXPERIENCES) {
          const response = await api.get(`/experienceprice/${id}`, {
            params: {
              duration: duration ? duration : jsonItem.duration,
              country: quotesData.student.nationalityCountry,
              experienceDate_id,
            },
          });

          if (!resultItems) return;
          if (!response.data.periods.length) {
            throw new Error('This item do not have any values');
          }
          if (!response.data.price) {
            throw new Error('Duration is invalid');
          }
          setResultItems(
            resultItems.map(result => {
              result.departureDates = {
                startDate: departureDate?.label.split('-')[0].trim(),
                endDate: departureDate?.label.split('-')[1].trim(),
                id: departureDate?.value,
                experience_id: Number(id),
              };

              result.showBanner = !!jsonItem.showBanner;

              if (result.id === Number(id)) {
                result.price = response.data.price;
                result.currency_code = response.data.currency_code;
                result.totalThisExperience = response.data.totalThisExperience;
              }
              return result;
            })
          );
        }
      } catch (error: any) {
        addToast({
          type: 'error',
          title: 'Error',
          description:
            error.message || 'An error has occurred, please try again.',
        });
      }
    },
    [
      programStartDate,
      resultItems,
      quotesData.student,
      updateAccommodationWithPriceAndDuration,
      updateCourseWithPriceAndDuration,
      updateFeeWithPriceAndDuration,
      setResultItems,
      addToast,
      setSuggestiveResult,
      suggestiveResult,
    ]
  );

  /**
   * Recursive method to load all the requested one-by-one.
   */

  useEffect(() => {
    if (firstLoadingComplete) {
      // in order to only run one time
      setFirstLoadingComplete(false);

      if (!resultItems || resultItems.length === 0) {
        setReadyToSearch(true);
        return;
      }

      loadItems(resultItems);
    }
    const willItemLoad = resultItems.some(
      ({ loadedRealValues }) => !loadedRealValues
    );

    if (!willItemLoad && !searchLoading) setReadyToSearch(true);
    else setReadyToSearch(false);
  }, [loadItems, resultType, resultItems, firstLoadingComplete, searchLoading]);

  const handleAddItemToQuote = useCallback(
    (data: ListItemFormData) => {
      setEditingItem(data);
      setOpenAddItemToQuoteModal(!openAddItemToQuoteModal);
    },
    [openAddItemToQuoteModal]
  );

  const getEndOfWeekDay = useCallback((date: Date): Date => {
    const lastWeekDays = [0, 1, 2];
    const currentWeekDays = [3, 4, 5, 6];

    const dayOfWeek = getDay(date);

    let endOfWeekDay = date;
    if (lastWeekDays.includes(dayOfWeek)) {
      endOfWeekDay = subDays(endOfWeek(subWeeks(date, 1)), 1);
    } else if (currentWeekDays.includes(dayOfWeek)) {
      endOfWeekDay = subDays(endOfWeek(date), 1);
    }

    return endOfWeekDay;
  }, []);

  const calculateEndDate = useCallback(
    (
      itemType: string,
      startDate: string | Date,
      duration: number,
      durationType: string
    ): Date | null => {
      const startDateAsJSON = parseJSON(startDate);

      let endDate;
      if (durationType === 'hour') {
        endDate = add(startDateAsJSON, { days: 1 });
      } else if (durationType === 'day') {
        endDate = add(startDateAsJSON, { days: duration });
      } else if (durationType === 'week') {
        endDate = add(startDateAsJSON, { weeks: duration });
      } else if (durationType === 'month') {
        endDate = add(startDateAsJSON, { months: duration });
      } else if (durationType === 'term') {
        endDate = add(startDateAsJSON, { months: duration * 3 });
      } else if (durationType === 'semester') {
        endDate = add(startDateAsJSON, { months: duration * 6 });
      } else if (durationType === 'year') {
        endDate = add(startDateAsJSON, { years: duration });
      } else if (durationType === 'fixed') {
        endDate = add(startDateAsJSON, { days: 1 });
      } else if (durationType === 'lesson') {
        endDate = add(startDateAsJSON, { days: 1 });
      }

      if (!endDate) return null;

      const finalDate =
        itemType === filtersOptions.FILTERS_COURSES
          ? getEndOfWeekDay(endDate)
          : endDate;

      return finalDate;
    },
    [getEndOfWeekDay]
  );

  const saveQuote = useCallback(
    async (quote: any): Promise<any> => {
      try {
        const chosenCourses: any[] = [];
        const chosenAccommodations: any[] = [];

        quote.courses.forEach((course: any) => {
          const courseToAdd = course.coursecampus;
          if (courseToAdd.price) {
            courseToAdd.price.srperiod = courseToAdd.price.srperiod
              ? courseToAdd.price.srperiod
              : { active: false };
            courseToAdd.price.srmaterial = courseToAdd.price.srmaterial
              ? courseToAdd.price.srmaterial
              : { active: false };
            courseToAdd.price.srenrol = courseToAdd.price.srenrol
              ? courseToAdd.price.srenrol
              : { active: false };
            courseToAdd.price.srcourse = courseToAdd.price.srcourse
              ? courseToAdd.price.srcourse
              : { active: false };
          }
          courseToAdd.srReject = course.srReject;
          courseToAdd.editedTuition = course.editedTuition ?? 0;
          courseToAdd.editedMaterial = course.editedMaterial ?? 0;
          courseToAdd.editedEnrol = course.editedEnrol ?? 0;
          courseToAdd.discountTuition = course.discountTuition ?? 0;
          courseToAdd.discountMaterial = course.discountMaterial ?? 0;
          courseToAdd.discountEnrol = course.discountEnrol ?? 0;
          chosenCourses.push(courseToAdd);
        });

        quote.accommodations.forEach((accommodation: any) => {
          const accommodationToAdd = accommodation;
          if (accommodationToAdd.price) {
            accommodationToAdd.price.srperiod = accommodationToAdd.price
              .srperiod
              ? accommodationToAdd.price.srperiod
              : { active: false };
            accommodationToAdd.price.srmaterial = accommodationToAdd.price
              .srmaterial
              ? accommodationToAdd.price.srmaterial
              : { active: false };
            accommodationToAdd.price.srenrol = accommodationToAdd.price.srenrol
              ? accommodationToAdd.price.srenrol
              : { active: false };
            accommodationToAdd.price.srcourse = accommodationToAdd.price
              .srcourse
              ? accommodationToAdd.price.srcourse
              : { active: false };
          }
          accommodationToAdd.srReject = accommodation.srReject;
          accommodationToAdd.editedAccommodation =
            accommodation.editedAccommodation ?? 0;
          accommodationToAdd.editedPlacementFee =
            accommodation.editedPlacementFee ?? 0;
          accommodationToAdd.discountAccommodation =
            accommodation.discountAccommodation ?? 0;
          accommodationToAdd.discountPlacementFee =
            accommodation.discountPlacementFee ?? 0;
          chosenAccommodations.push(accommodationToAdd);
        });

        await api.post('/quotecurrency', {
          data: quote.totalConverted ? quote.totalConverted.rules : [],
          quote_id: quote.id,
          plugAndPlay: quote.plugAndPlay,
        });

        const res = await api.put(`/quote/${quote.id}`, {
          timelog: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
          dueDate: format(parseISO(quote.dueDate), 'dd/MM/yyyy'),
          description: quote.description
            ? quote.description
            : defaultDescription,
          opportunity_id: quote.opportunity_id,
          chosenCourses,
          chosenAccommodations,
          newAccommodations: quote.accommodations,
          newExperiences: quote.experiences,
          newFees: quote.fees,
          newOtherfees: quote.otherfees,
          quoteValues: quote.totalsByCurrency,
          feeDeleted: quote.feeDeleted ? quote.feeDeleted : null,
          newQuote: true,
          teste: 1,
          autoSave: true,
          iof: quote.iof,
          school_id: quote.school_id,
          campus_id: quote.campus_id,
          converting: quote.converting,
          plugAndPlay: quote.plugAndPlay,
          typeOption: quote.typeOption,
          saleType_id:
            quote.saleType && quote.saleType.id ? quote.saleType.id : null,
        });

        try {
          const response = await api.get(`/quote/${quote.id}`, {
            params: {
              withBusiness: true,
            },
          });

          quote.description = response.data.description;
        } catch (error: any) {
          addToast({
            type: 'error',
            title: 'Error',
            description:
              error.message || 'An error has occurred, please try again.',
          });
        }

        quote.converting = false;

        if (res.data.error) {
          await swal({
            title: 'Inconsistent Quote',
            text:
              'There is an inconsistency in the values of this quote. Double check all items and values in this quote, including totals and conversion, before you try to save it again. ',
            icon: 'warning',
            buttons: ['Cancel', 'Ok'],
          }).then(confirmed => {
            const message = {
              action: 'refresh',
              origin: process.env.REACT_APP_URL as string,
            };
            angularPort?.postMessage(message);
          });
        }

        return res.data;
      } catch (err: any) {
        throw new Error(err);
      }
    },
    [addToast, angularPort, defaultDescription]
  );

  const updateQuoteConvertedCurrency = useCallback(
    (index: number, currencyToConvert: any) => {
      const { quotes } = quotesData;

      const newQuotes: any[] = [];

      if (!quotes) return;

      quotes.forEach((quote, quoteIndex) => {
        if (quoteIndex !== index) {
          newQuotes.push(quote);
          return;
        }

        const selectedQuote = _.cloneDeep(quote);

        selectedQuote.convertedCurrency = currencyToConvert;
        newQuotes.push(selectedQuote);
      });

      const formData = {
        ...quotesData,
        quotes: newQuotes,
      };

      updateQuotesData(formData);
    },
    [quotesData, updateQuotesData]
  );

  const updateQuoteCurrencyAndTotalValues = useCallback(
    async (quote: any, quoteCurrency: any): Promise<any> => {
      try {
        const res = await api.put(`/quote/${quote.id}`, {
          updateQuoteCurrencyAndTotalValues: true,
          currency: quoteCurrency,
        });

        if (res.data.error) {
          await swal({
            title: 'Inconsistent Quote',
            text:
              'There is an inconsistency in the values of this quote. Double check all items and values in this quote, including totals and conversion, before you try to save it again. ',
            icon: 'warning',
            buttons: ['Cancel', 'Ok'],
          }).then(confirmed => {
            const message = {
              action: 'refresh',
              origin: process.env.REACT_APP_URL as string,
            };
            angularPort?.postMessage(message);
          });
        }

        return res.data;
      } catch (err: any) {
        throw new Error(err);
      }
    },
    [angularPort]
  );

  const onStudentClick = useCallback(() => {
    const message = {
      student: quotesData.student,
      action: 'navigate-to-student-profile',
      origin: process.env.REACT_APP_URL as string,
    };

    angularPort?.postMessage(message);
  }, [angularPort, quotesData.student]);

  const calculateTotalsByCurrency = useCallback((quote: any) => {
    const totalsByCurrency: TotalByCurrency[] = [];

    quote.courses.forEach((course: any) => {
      if (course.coursecampus.totalThisCourse && course.coursecampus.campus) {
        const currencyTotal = totalsByCurrency.find(
          total =>
            total.currency_code === course.coursecampus.campus.currency_code
        );

        if (!currencyTotal) {
          totalsByCurrency.push({
            currency: course.coursecampus.campus.currency.symbol,
            currency_code: course.coursecampus.campus.currency_code,
            value: course.coursecampus.totalThisCourse.value,
            value_without_sr:
              course.coursecampus.totalThisCourse.valueWithoutSR,
          });
          return;
        }

        currencyTotal.value += course.coursecampus.totalThisCourse.value;
        currencyTotal.value_without_sr +=
          course.coursecampus.totalThisCourse.valueWithoutSR;
      }
    });

    quote.experiences.forEach((experience: any) => {
      if (experience.totalThisExperience && experience.currency.code) {
        const currencyTotal = totalsByCurrency.find(
          total => total.currency_code === experience.currency.code
        );

        if (!currencyTotal) {
          totalsByCurrency.push({
            currency: experience.currency.symbol,
            currency_code: experience.currency.code,
            value: experience.totalThisExperience.value,
            value_without_sr: experience.totalThisExperience.value,
          });
          return;
        }

        currencyTotal.value += experience.totalThisExperience.value;
        currencyTotal.value_without_sr += experience.totalThisExperience.value;
      }
    });

    quote.accommodations.forEach((accommodation: any) => {
      if (accommodation.totalThisAccommodation) {
        const currencyTotal = totalsByCurrency.find(
          total => total.currency_code === accommodation.currency_code
        );

        if (!currencyTotal) {
          totalsByCurrency.push({
            currency: accommodation.currency.symbol,
            currency_code: accommodation.currency_code,
            value: accommodation.totalThisAccommodation.value,
            value_without_sr:
              accommodation.totalThisAccommodation.valueWithoutSR,
          });
          return;
        }

        currencyTotal.value += accommodation.totalThisAccommodation.value;
        currencyTotal.value_without_sr +=
          accommodation.totalThisAccommodation.valueWithoutSR;
      }
    });

    quote.fees.forEach((fee: any) => {
      if (fee.totalThisFee) {
        const currencyTotal = totalsByCurrency.find(
          total => total.currency_code === fee.currency_code
        );

        if (!currencyTotal) {
          totalsByCurrency.push({
            currency: fee.currency.symbol,
            currency_code: fee.currency_code,
            value: fee.totalThisFee.value,
            value_without_sr: fee.totalThisFee.valueWithoutSR,
          });
          return;
        }

        currencyTotal.value += fee.totalThisFee.value;
        currencyTotal.value_without_sr += fee.totalThisFee.valueWithoutSR;
      }
    });

    quote.otherfees.forEach((otherfee: any) => {
      const currencyTotal = totalsByCurrency.find(
        total => total.currency_code === otherfee.currency.code
      );

      if (!currencyTotal) {
        if (otherfee.value > 0) {
          totalsByCurrency.push({
            currency: otherfee.currency.symbol,
            currency_code: otherfee.currency.code,
            value: otherfee.value,
            value_without_sr: otherfee.value,
          });
        } else {
          totalsByCurrency.push({
            currency: otherfee.currency.symbol,
            currency_code: otherfee.currency.code,
            value: otherfee.value,
            value_without_sr: 0,
          });
        }
        return;
      }

      currencyTotal.value += otherfee.value;
      if (otherfee.value > 0) {
        currencyTotal.value_without_sr += otherfee.value;
      }
    });

    totalsByCurrency.forEach(total => {
      total.valueFormatted = formatValue(total.value, total.currency_code);
    });
    totalsByCurrency.forEach(total => {
      total.value_without_srFormatted = formatValue(
        total.value_without_sr,
        total.currency_code
      );
    });

    return totalsByCurrency;
  }, []);

  const calculateConvertedValue = useCallback(
    async (quote: any, index?: number, currencyToConvert?: any) => {
      if (
        _.isNumber(index) &&
        currencyToConvert &&
        currencyToConvert.value !== quote.convertedCurrency.code
      ) {
        updateQuoteConvertedCurrency(index, currencyToConvert);
      }
      const isCourseRenewal = quote.courses.some(
        ({
          coursecampus: {
            course: { renewal },
          },
        }: any) => renewal === 1
      );

      const currencyCode = currencyToConvert
        ? currencyToConvert.value
        : quote.convertedCurrency?.code;

      const currencies =
        quote.quoteCurrencies && quote.quoteCurrencies.length > 0
          ? quote.quoteCurrencies
          : officeCurrencies;

      quote.totalsByCurrency.forEach((total: any) => {
        const findCurrency = currencies.find(
          (currency: any) => currency.destiny_code === total.currency_code
        );

        if (!findCurrency) {
          const currencyToAdd = officeCurrencies.find(
            (officeCurr: any) => officeCurr.destiny_code === total.currency_code
          );
          currencies.push(currencyToAdd);
        }
      });

      const hasDefaultCurrency = currencies.find(
        (currency: any) => currency.destiny_code === user.office.default_code
      );

      if (!hasDefaultCurrency) {
        currencies.push({
          currency: user.office.currency,
          destiny_code: user.office.default_code,
          value: 1,
        });
      }

      const { data: convertedValue } = await api.post(
        `/convert?code=${
          isCourseRenewal
            ? user.office.default_code
            : currencyCode || user.office.default_code
        }`,
        {
          saveConverted: true,
          saveTotals: true,
          arrayValues: quote.totalsByCurrency,
          rules: currencies,
          quote_id: quote.id,
          plugAndPlay:
            quote.plugAndPlay || quote.typeOption === 'allyplus' ? 1 : 0,
          renewal: isCourseRenewal,
        }
      );

      const rules: any[] = [];

      currencies.forEach((curr: OfficeCurrency) => {
        if (
          quote.totalsByCurrency.length === 1 &&
          quote.totalsByCurrency[0].currency_code === currencyCode
        )
          return;

        curr.valueFormatted = formatValue(curr.value, curr.destiny_code);

        if (
          curr.destiny_code === currencyCode &&
          curr.destiny_code !== user.office.default_code
        ) {
          rules.push(curr);
        } else {
          const findTotalCurrencyRule = quote.totalsByCurrency.find(
            (total: any) =>
              total.currency_code === curr.destiny_code &&
              total.currency_code !== user.office.default_code
          );
          if (findTotalCurrencyRule) {
            rules.push(curr);
          }
        }
      });

      convertedValue.rules = rules;

      return convertedValue;
    },
    [
      updateQuoteConvertedCurrency,
      officeCurrencies,
      user.office.currency,
      user.office.default_code,
    ]
  );

  /**
   *  Updating selectedQuote with new fees
   */
  const quoteWithNewFees = useCallback(
    async (quote: QuoteType, dataReceived): Promise<QuoteType> => {
      const selectedQuote = _.cloneDeep(quote);

      selectedQuote.fees = selectedQuote.fees.map((fee: any) => {
        return updateOneFee(fee, dataReceived);
      });

      selectedQuote.totalsByCurrency = calculateTotalsByCurrency(selectedQuote);
      selectedQuote.totalConverted = await calculateConvertedValue(
        selectedQuote,
        dataReceived.index
      );
      selectedQuote.totalConverted.valueFormatted = formatValue(
        selectedQuote.totalConverted.value,
        selectedQuote.totalConverted.currency_code
      );
      selectedQuote.totalConverted.value_without_srFormatted = formatValue(
        selectedQuote.totalConverted.value_without_sr,
        selectedQuote.totalConverted.currency_code
      );
      return selectedQuote;
    },
    [calculateTotalsByCurrency, calculateConvertedValue]
  );

  const getMandatoryFees = useCallback(
    async (item, formData) => {
      const { data, id, duration, type } = item;

      if (!data) return;

      const jsonItem = JSON.parse(data);
      const quotesIndexToBeChanged = formData.quotes;

      if (
        type !== filtersOptions.FILTERS_COURSES &&
        type !== filtersOptions.FILTERS_ACCOMMODATIONS
      ) {
        return;
      }

      if (type === filtersOptions.FILTERS_ACCOMMODATIONS) {
        jsonItem.coursecampus = {};
        jsonItem.coursecampus = jsonItem;
        jsonItem.school_id = jsonItem.coursecampus.campus
          ? jsonItem.coursecampus.campus.school_id
          : undefined;
        jsonItem.campus_id = jsonItem.coursecampus.campus
          ? jsonItem.coursecampus.campus.id
          : undefined;
      }

      try {
        const startDateMand = programStartDate;
        const endDateMandatory = calculateEndDate(
          'Courses',
          startDateMand,
          jsonItem.coursecampus.duration,
          jsonItem.coursecampus.price.periodType
        );

        const endDateMand = endDateMandatory;
        let endDateMandStr = '';
        if (endDateMand) {
          const endDateMandDay = endDateMand.getDate().toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false,
          });
          const endDateMandMonth = (endDateMand.getMonth() + 1).toLocaleString(
            'en-US',
            {
              minimumIntegerDigits: 2,
              useGrouping: false,
            }
          );
          const endDateMandYear = endDateMand.getFullYear();
          endDateMandStr = `${endDateMandDay}/${endDateMandMonth}/${endDateMandYear}`;
        } else {
          endDateMandStr = format(parseJSON(programStartDate), 'dd/MM/yyyy');
        }
        const response = await api.get('/mandatoryrule', {
          params: {
            fromArray: true,
            plugAndPlay:
              isUserAllyPlus || (isUserHibrid && Number(formData.allyPlus))
                ? 1
                : 0,
            birthDate: quotesData.student.birthDate,
            city_id:
              type === filtersOptions.FILTERS_COURSES
                ? jsonItem.coursecampus.campus.city_id
                : jsonItem.city_id,
            country_code:
              type === filtersOptions.FILTERS_COURSES
                ? jsonItem.coursecampus.campus.country_code
                : jsonItem.accommodation.city.country_code,
            courseCampus_id:
              type === filtersOptions.FILTERS_COURSES ? id : undefined,
            accommodation_id:
              type === filtersOptions.FILTERS_ACCOMMODATIONS ? id : undefined,
            duration,
            getFees: true,
            school_id:
              type === filtersOptions.FILTERS_COURSES
                ? jsonItem.coursecampus.campus.school_id
                : jsonItem.school_id,
            campus_id:
              type === filtersOptions.FILTERS_COURSES
                ? jsonItem.coursecampus.campus.id
                : jsonItem.campus_id,
            country: quotesData.student.nationalityCountry,
            startDate: format(parseJSON(programStartDate), 'dd/MM/yyyy'),
            endDate: endDateMandStr,
          },
        });

        const updatedFees = getFeesCategories(response.data);

        const quotesIds: string[] = [];
        quotesData.quotes?.forEach((quote, idx) => {
          if (quotesIndexToBeChanged.includes(String(idx)))
            quotesIds.push(quote.id);
        });

        const {
          suggestiveFees,
          mandatoryFees,
        } = formatingMandatoryAndSuggestiveFees(
          dateOfReference,
          updatedFees,
          quotesData,
          id,
          type,
          quotesIds
        );

        if (suggestiveFees.length > 0 && !cantOpenSuggestiveFee) {
          setOpenSuggestiveFeeModal(true);
        }

        setEditingItemSuggestiveFees(suggestiveFees);

        /*
          Now we have the Mandatory Fees and Suggestive Fees
          to be added in the quotes.
         */

        // In this point we only add the mandatoryFee because Suggestive Fee
        // will be added later.
        if (mandatoryFees.length) {
          quotesData.quotes?.forEach(async (quote, idx) => {
            if (quotesIndexToBeChanged.includes(String(idx))) {
              const feesToExclude: any = [];

              // NOTE: Varrar mandatorieFees e ver se o id bate com alguma mandatoryCourseCampus_id ou mandatoryAccommodation_id
              // se bater, pode add, se nao bater, colocar no feesToExclude
              mandatoryFees.forEach((mandFee: any) => {
                let hasLinkItem = false;
                if (
                  mandFee.mandatoryCourseCampus_id === id ||
                  mandFee.mandatoryAccommodation_id === id
                ) {
                  hasLinkItem = true;
                }
                if (
                  mandFee.mandatoryCourseCampus_id === undefined &&
                  mandFee.mandatoryAccommodation_id === undefined
                ) {
                  hasLinkItem = true;
                }

                if (!hasLinkItem) {
                  feesToExclude.push(mandFee.id);
                }
              });

              /*
                TODO: Think about this logic below, could be improved.

              */
              quote.fees.forEach((quoteFee: any) => {
                const hasFee = mandatoryFees?.find(
                  fee => quoteFee.id === fee.id
                );
                if (hasFee) {
                  feesToExclude.push(hasFee.id);
                }
              });
              // quote.courses.forEach((quoteCourseCampus: any) => {
              //   const hasNotCourse = mandatoryFees?.find(
              //     fee =>
              //       quoteCourseCampus.courseCampus_id ===
              //       fee.mandatoryCourseCampus_id
              //   );
              //   if (hasNotCourse) {
              //     feesToExclude.push(hasNotCourse.id);
              //   }
              // });

              const newMandatories = mandatoryFees?.filter(
                mandatoryFee => !feesToExclude.includes(mandatoryFee.id)
              );
              const feeExist: any = [];
              updatedFees.forEach((mandatoryFee: any) => {
                const quoteMandatoryFee = mandatoryFees?.find(
                  fee => mandatoryFee.id === fee.id
                );
                if (quoteMandatoryFee) {
                  feeExist.push(quoteMandatoryFee.id);
                }
              });

              const feeToRemove = mandatoryFees.filter(x => {
                return !feeExist.includes(x.id);
              });

              feeToRemove.forEach((fee: { id: any }) => {
                const remove = newMandatories.findIndex(
                  del => del.id === fee.id
                );
                if (remove !== -1) {
                  newMandatories.splice(remove, 1);
                }
              });

              quote.fees.push(...newMandatories);
            }
          });
        }

        updateQuotesData(quotesData);
      } catch (error: any) {
        addToast({
          title: 'Error',
          description: error.message || 'Something went wrong',
          type: 'error',
        });
      }
    },
    [
      programStartDate,
      calculateEndDate,
      quotesData,
      getFeesCategories,
      dateOfReference,
      cantOpenSuggestiveFee,
      updateQuotesData,
      addToast,
      isUserAllyPlus,
      isUserHibrid,
    ]
  );

  const validateValueInteractions = useCallback(
    async (quote: any): Promise<any> => {
      let canProceed = true;
      if (quote.totalConverted) {
        const oldQuote = quotesData?.quotes?.find(
          (qt: any) => qt.id === quote.id
        );

        const index = quotesData?.quotes?.findIndex(
          (qt: any) => qt.id === quote.id
        );

        if (!oldQuote || index === undefined) return true;

        // quote.totalsByCurrency = calculateTotalsByCurrency(quote);
        // quote.totalConverted = await calculateConvertedValue(quote, index);

        if (
          quote.totalConverted.value !== oldQuote.totalConverted.value &&
          quote.paymentplan &&
          quote.paymentplan.length > 0 &&
          !quote.converting
        ) {
          try {
            await swal({
              title: 'Clear payment plans?',
              text:
                'Once you proceed, it will clear all the payment plans of the updated quote.',
              icon: 'warning',
              buttons: ['No, cancel', 'Yes, confirm'],
            }).then(confirmed => {
              if (confirmed) {
                swal('Quote updated!', {
                  icon: 'success',
                });
              } else {
                canProceed = false;
              }
            });
          } catch (e) {
            canProceed = false;
          }
        }
      }

      return canProceed;
    },
    [quotesData?.quotes]
  );

  const handleConvertQuoteValues = useCallback(
    async (index: number, currencyToConvert: any) => {
      const { quotes } = quotesData;

      const newQuotes: any[] = [];

      if (!quotes) return;

      setResumeActionLoading({ index, loading: true });

      await quotes.reduce(async (promise, quote, idx) => {
        await promise;

        if (idx !== index) {
          quote.totalsByCurrency = calculateTotalsByCurrency(quote);
          quote.totalConverted = await calculateConvertedValue(quote, index);
          quote.totalConverted.valueFormatted = formatValue(
            quote.totalConverted.value,
            quote.totalConverted.currency_code
          );
          quote.totalConverted.value_without_srFormatted = formatValue(
            quote.totalConverted.value_without_sr,
            quote.totalConverted.currency_code
          );
          newQuotes.push(quote);
          return;
        }

        quote.converting = true;

        const selectedQuote = _.cloneDeep(quote);

        if (
          currencyToConvert &&
          currencyToConvert.value !== selectedQuote.convertedCurrency.code
        ) {
          selectedQuote.convertedCurrency = officeCurrencies.find(
            currency => currency.destiny_code === currencyToConvert.value
          )?.currency;
        }

        const canProcced = await validateValueInteractions(selectedQuote);
        if (!canProcced) {
          newQuotes.push(quote);
          return;
        }

        selectedQuote.totalsByCurrency = calculateTotalsByCurrency(
          selectedQuote
        );
        selectedQuote.totalConverted = await calculateConvertedValue(
          selectedQuote,
          index,
          currencyToConvert
        );
        selectedQuote.totalConverted.valueFormatted = formatValue(
          selectedQuote.totalConverted.value,
          selectedQuote.totalConverted.currency_code
        );
        selectedQuote.totalConverted.value_without_srFormatted = formatValue(
          selectedQuote.totalConverted.value_without_sr,
          selectedQuote.totalConverted.currency_code
        );

        const { quoteCurrencies } = await saveQuote(selectedQuote);

        selectedQuote.quoteCurrencies = quoteCurrencies;
        selectedQuote.quoteCurrencies.forEach((currency: any) => {
          currency.valueFormatted = formatValue(
            currency.value,
            currency.destiny_code
          );
        });

        newQuotes.push(selectedQuote);
      }, Promise.resolve());

      setResumeActionLoading({ index, loading: false });

      const formData = {
        ...quotesData,
        quotes: newQuotes,
        updating: true,
      };

      updateQuotesData(formData);
    },
    [
      quotesData,
      updateQuotesData,
      calculateTotalsByCurrency,
      calculateConvertedValue,
      officeCurrencies,
      validateValueInteractions,
      saveQuote,
    ]
  );

  const handleCurrencyValueChange = useCallback(
    async (
      currencyCode: string,
      index: number,
      newValue: number
    ): Promise<boolean> => {
      if (!newValue) {
        addToast({
          title: 'Error',
          description: "Currency value couldn't be empty.",
          type: 'error',
        });
        return false;
      }

      const { quotes } = quotesData;

      const newQuotes: any[] = [];

      if (!quotes) return false;

      await quotes.reduce(async (promise, quote, idx) => {
        await promise;

        if (idx !== index) {
          newQuotes.push(quote);
          return;
        }

        const selectedQuote = _.cloneDeep(quote);

        selectedQuote.quoteCurrencies.forEach((currency: any) => {
          if (currency.destiny_code === currencyCode) {
            currency.value = newValue;
            currency.valueFormatted = formatValue(
              currency.value,
              currency.destiny_code
            );
          }
        });

        const canProcced = await validateValueInteractions(selectedQuote);
        if (!canProcced) {
          newQuotes.push(quote);
          return;
        }

        const { data: totalConverted } = await api.put(`quote/${quote.id}`, {
          updateCurrency: true,
          saveConverted: true,
          rules: selectedQuote.quoteCurrencies,
        });

        selectedQuote.totalsByCurrency = calculateTotalsByCurrency(
          selectedQuote
        );
        selectedQuote.totalConverted = totalConverted;
        selectedQuote.totalConverted.valueFormatted = formatValue(
          selectedQuote.totalConverted.value,
          selectedQuote.totalConverted.currency_code
        );
        selectedQuote.totalConverted.value_without_srFormatted = formatValue(
          selectedQuote.totalConverted.value_without_sr,
          selectedQuote.totalConverted.currency_code
        );

        newQuotes.push(selectedQuote);
      }, Promise.resolve());

      const formData = {
        ...quotesData,
        quotes: newQuotes,
      };

      updateQuotesData(formData);

      return true;
    },
    [
      quotesData,
      updateQuotesData,
      calculateTotalsByCurrency,
      validateValueInteractions,
      addToast,
    ]
  );

  const typeUser = useMemo(() => {
    const isAccountAllyPlus = user.office.account.allyPlus === 1;
    const isOfficeAllyPlus = Boolean(user.office.plugAndPlay);
    if (isAccountAllyPlus) {
      return 'allyplus';
    }
    if (!isAccountAllyPlus && isOfficeAllyPlus) {
      return 'hybrid';
    }
    return 'backoffice';
  }, [user]);

  const handleRenewalQuote = useCallback(
    async (quoteId: number, isRenewal: boolean) => {
      try {
        await api.post(`/quoteconfig`, {
          quote_id: quoteId,
          changeRenewal: true,
          renewal: isRenewal ? 1 : 0,
        });
      } catch (e) {
        addToast({
          type: 'error',
          title: 'Error',
          description: 'An error has occurred, please try again.',
        });
      }
    },
    [addToast]
  );

  const handleAddItemToQuoteSubmit = useCallback(
    async (data: any) => {
      setIsAddItemLoading(true);
      try {
        if (!data.quotes || !data.quotes.length) {
          throw new Error('Select at least one quote to add this item');
        }
        setEditingQuoteOptions(data.quotes);

        const { data: itemAsString, type } = editingItem;

        if (itemAsString && type) {
          const { quotes } = quotesData;
          const itemAsJSON = JSON.parse(itemAsString);

          if (data.quotes.length > 0 && quotesData.quotes) {
            data.quotes.map((index: any) => {
              const quote = quotesData.quotes
                ? quotesData.quotes[index]
                : undefined;
              if (quote.plugAndPlay === 1) {
                let validateCurrency = false;
                if (
                  quote.quoteCurrencies.length === 0 ||
                  quote.quoteCurrencies.length === 1 ||
                  itemAsJSON.coursecampus?.campus.currency_code === 'BRL'
                ) {
                  validateCurrency = true;
                } else {
                  const quoteHasCurrencyBRL = quote.quoteCurrencies.filter(
                    (currency: { destiny_code: string }) =>
                      currency.destiny_code === 'BRL'
                  );
                  const existItemCurrencyInQuote = quote.quoteCurrencies.filter(
                    (currency: { destiny_code: string }) =>
                      currency.destiny_code ===
                      itemAsJSON.coursecampus?.campus.currency_code
                  );
                  validateCurrency =
                    quoteHasCurrencyBRL && existItemCurrencyInQuote;
                }
                if (!validateCurrency) {
                  throw new Error(
                    "You can't add different currency items quote."
                  );
                }
              }
              return quote;
            });
          }

          let hasCurrency = false;
          let itemCurrencyCode: string;

          if (type === filtersOptions.FILTERS_COURSES) {
            itemCurrencyCode = itemAsJSON.coursecampus.campus.currency_code;
          } else {
            itemCurrencyCode = itemAsJSON.currency_code;
          }

          if (officeCurrencies) {
            officeCurrencies.forEach(cur => {
              if (cur.destiny_code === itemCurrencyCode) {
                hasCurrency = true;
              }
            });

            if (!hasCurrency) {
              throw new Error(
                'You do not have this currency in your office. To add it, go to Settings > Financial > Currencies and add a new currency rule'
              );
            }
          }
          const newQuotes: any[] = [];

          if (!quotes) return;

          if (!cantOpenSuggestiveFee) {
            await getMandatoryFees(editingItem, data);
          }

          const courseStartDate = programStartDate;

          if (!courseStartDate) {
            throw new Error('Select the Program Start Date to add this item');
          }
          if (!dateOfReference) {
            throw new Error('Select the Program Enrol Date to add this item');
          }

          await quotes.reduce(async (promise, quote, index) => {
            await promise;

            const findIndex = data.quotes.find(
              (quoteIndex: string) => Number(quoteIndex) === index
            );

            if (!findIndex) {
              quote.totalsByCurrency = calculateTotalsByCurrency(quote);
              quote.totalConverted = await calculateConvertedValue(
                quote,
                index
              );
              quote.totalConverted.valueFormatted = formatValue(
                quote.totalConverted.value,
                quote.totalConverted.currency_code
              );
              quote.totalConverted.value_without_srFormatted = formatValue(
                quote.totalConverted.value_without_sr,
                quote.totalConverted.currency_code
              );
              newQuotes.push(quote);
              return;
            }

            const selectedQuote = _.cloneDeep(quote);

            selectedQuote.empty = false;

            if (type === filtersOptions.FILTERS_COURSES) {
              const updatedCourses = selectedQuote.courses.concat(itemAsString);
              const isCourseRenewal = selectedQuote.courses.some(
                ({
                  coursecampus: {
                    course: { renewal },
                  },
                }: any) => renewal !== 0
              );
              selectedQuote.courses = updatedCourses.map((course: any) => {
                course = course.id ? course : JSON.parse(course);
                if (
                  selectedQuote.school_id &&
                  selectedQuote.school_id !==
                    course.coursecampus.campus.school_id &&
                  selectedQuote.plugAndPlay &&
                  !isCourseRenewal
                ) {
                  throw new Error(
                    'Items from another school on the same quote are not allowed'
                  );
                } else {
                  selectedQuote.school_id =
                    course.coursecampus.campus.school_id;
                  selectedQuote.campus_id = course.coursecampus.campus.id;

                  course.valueFormatted = formatValue(
                    course.coursecampus.totalThisCourse.onlyCourse,
                    course.coursecampus.campus.currency_code
                  );
                  course.oldValueFormatted = course.coursecampus.totalThisCourse
                    .oldValue
                    ? formatValue(
                        course.coursecampus.totalThisCourse.oldValue,
                        course.coursecampus.campus.currency_code
                      )
                    : undefined;
                  course.enrolFormatted = formatValue(
                    course.coursecampus.totalThisCourse.onlyEnrol,
                    course.coursecampus.campus.currency_code
                  );
                  course.materialFormatted = formatValue(
                    course.coursecampus.totalThisCourse.onlyMaterial,
                    course.coursecampus.campus.currency_code
                  );
                  course.subtotalFormatted = formatValue(
                    course.coursecampus.totalThisCourse.value,
                    course.coursecampus.campus.currency_code
                  );

                  course.coursecampus.enrolDate = format(
                    parseJSON(dateOfReference),
                    'dd/MM/yyyy'
                  );
                  course.coursecampus.startDate = format(
                    parseJSON(courseStartDate),
                    'dd/MM/yyyy'
                  );
                  course.coursecampus.startDateFormatted = format(
                    parseJSON(courseStartDate),
                    'LLLL dd, yyyy'
                  );
                  const auxEndDate = calculateEndDate(
                    filtersOptions.FILTERS_COURSES,
                    courseStartDate,
                    course.coursecampus.duration,
                    course.pricePeriod
                  );
                  course.coursecampus.endDate = auxEndDate
                    ? format(parseJSON(auxEndDate), 'dd/MM/yyyy')
                    : null;
                  course.coursecampus.endDateFormatted = auxEndDate
                    ? format(parseJSON(auxEndDate), 'LLLL dd, yyyy')
                    : null;

                  course.hasSpecialRates = false;
                  course.hasMaterialSR = false;
                  course.hasEnrolSR = false;
                  course.hasTuitionSR = false;

                  if (
                    (course.coursecampus.price.srmaterial &&
                      course.coursecampus.price.srmaterial.active) ||
                    (course.coursecampus.price.srenrol &&
                      course.coursecampus.price.srenrol.active) ||
                    (course.coursecampus.price.srcourse &&
                      course.coursecampus.price.srcourse.active) ||
                    (course.coursecampus.price.srperiod &&
                      course.coursecampus.price.srperiod.active)
                  ) {
                    course.hasSpecialRates = true;
                    if (course.coursecampus.price.srmaterial?.active) {
                      course.hasMaterialSR = true;
                    }
                    if (course.coursecampus.price.srenrol?.active) {
                      course.hasEnrolSR = true;
                    }
                    if (
                      course.coursecampus.price.srperiod?.active ||
                      course.coursecampus.price.srcourse?.active
                    ) {
                      course.hasTuitionSR = true;
                    }
                  }

                  return course;
                }
              });

              /* Adding selected school on FilterOptions.
               */
              const schoolId = itemAsJSON.coursecampus?.campus?.school?.id;

              const schoolLabel = itemAsJSON.coursecampus?.campus?.school?.name;

              if (schoolId && schoolLabel) {
                const selectedSchool = {
                  value: schoolId,
                  label: schoolLabel,
                  school: 1,
                };
                const containsSchool = filterSchool.find(
                  sch => sch.value === selectedSchool.value
                );
                // only adds new school
                if (containsSchool === undefined) {
                  handleSchoolChange([...filterSchool, selectedSchool]);
                }
              }
              if (onlyRenewal || isCourseRenewal) {
                handleRenewalQuote(
                  selectedQuote.id,
                  onlyRenewal || isCourseRenewal
                );
              }
            } else if (type === filtersOptions.FILTERS_ACCOMMODATIONS) {
              const updatedAccommodations = selectedQuote.accommodations.concat(
                itemAsString
              );
              selectedQuote.accommodations = updatedAccommodations.map(
                (accommodation: any) => {
                  accommodation = accommodation.id
                    ? accommodation
                    : JSON.parse(accommodation);
                  if (
                    !selectedQuote.school_id &&
                    accommodation.agency_id !== user.account_id
                  ) {
                    selectedQuote.school_id = accommodation.campus?.school_id;
                  }
                  if (
                    accommodation.agency_id === user.account_id ||
                    (accommodation.agency_id !== user.account_id &&
                      !selectedQuote.school_id) ||
                    (selectedQuote.school_id &&
                      selectedQuote.school_id ===
                        accommodation.campus?.school_id)
                  ) {
                    if (
                      selectedQuote.campus_id &&
                      accommodation.campus &&
                      selectedQuote.campus_id !== accommodation.campus?.id
                    ) {
                      throw new Error(
                        'Items from another campus on the same quote are not allowed'
                      );
                    }
                    accommodation.valueFormatted = formatValue(
                      accommodation.totalThisAccommodation.onlyAccommodation,
                      accommodation.currency_code
                    );
                    accommodation.oldValueFormatted = accommodation
                      .totalThisAccommodation.oldValue
                      ? formatValue(
                          accommodation.totalThisAccommodation.oldValue,
                          accommodation.currency_code
                        )
                      : undefined;
                    accommodation.placementFeeFormatted = formatValue(
                      accommodation.totalThisAccommodation.onlyEnrol,
                      accommodation.currency_code
                    );
                    accommodation.subtotalFormatted = formatValue(
                      accommodation.totalThisAccommodation.value,
                      accommodation.currency_code
                    );

                    accommodation.enrolDate = format(
                      parseJSON(dateOfReference),
                      'dd/MM/yyyy'
                    );
                    accommodation.startDate = format(
                      parseJSON(courseStartDate),
                      'dd/MM/yyyy'
                    );
                    accommodation.startDateFormatted = format(
                      parseJSON(courseStartDate),
                      'LLLL dd, yyyy'
                    );
                    const auxEndDate = calculateEndDate(
                      filtersOptions.FILTERS_ACCOMMODATIONS,
                      courseStartDate,
                      accommodation.duration,
                      accommodation.pricePeriod
                    );
                    accommodation.endDate = auxEndDate
                      ? format(parseJSON(auxEndDate), 'dd/MM/yyyy')
                      : null;
                    accommodation.endDateFormatted = auxEndDate
                      ? format(parseJSON(auxEndDate), 'LLLL dd, yyyy')
                      : null;

                    accommodation.hasSpecialRates = false;
                    accommodation.hasEnrolSR = false;
                    accommodation.hasAccommodationSR = false;

                    if (
                      (accommodation.totalThisAccommodation.applied &&
                        accommodation.price.srmaterial) ||
                      (accommodation.totalThisAccommodation.applied &&
                        accommodation.price.srenrol) ||
                      (accommodation.totalThisAccommodation.applied &&
                        accommodation.price.srcourse) ||
                      (accommodation.totalThisAccommodation.applied &&
                        accommodation.price.srperiod)
                    ) {
                      accommodation.hasSpecialRates = true;
                      if (accommodation.srenrol_id > 0) {
                        accommodation.hasEnrolSR = true;
                      }
                      if (
                        accommodation.srperiod_id > 0 ||
                        accommodation.srcourse_id > 0
                      ) {
                        accommodation.hasAccommodationSR = true;
                      }
                    }

                    return accommodation;
                  }
                  throw new Error(
                    'Items from another school on the same quote are not allowed'
                  );
                }
              );
            } else if (
              type === filtersOptions.FILTERS_INSURANCES ||
              type === filtersOptions.FILTERS_ADDONS
            ) {
              const updatedFees = selectedQuote.fees.concat(itemAsString);
              selectedQuote.fees = updatedFees.map((fee: any) => {
                fee = fee.id ? fee : JSON.parse(fee);
                if (fee.campus) {
                  if (
                    !selectedQuote.school_id ||
                    (selectedQuote.school_id &&
                      selectedQuote.school_id === fee.campus?.school_id)
                  ) {
                    selectedQuote.school_id = fee.campus?.school_id;
                    if (
                      selectedQuote.campus_id &&
                      selectedQuote.campus_id !== fee.campus?.id
                    ) {
                      throw new Error(
                        'Items from another campus on the same quote are not allowed'
                      );
                    }
                  } else {
                    throw new Error(
                      'Items from another school on the same quote are not allowed'
                    );
                  }
                }
                fee.valueFormatted = formatValue(
                  fee.totalThisFee.value,
                  fee.currency_code
                );
                fee.oldValueFormatted = fee.totalThisFee.oldValue
                  ? formatValue(fee.totalThisFee.oldValue, fee.currency_code)
                  : undefined;
                fee.subtotalFormatted = formatValue(
                  fee.totalThisFee.value,
                  fee.currency_code
                );

                fee.enrolDate = format(
                  parseJSON(dateOfReference),
                  'dd/MM/yyyy'
                );

                return fee;
              });
            } else if (type === filtersOptions.FILTERS_EXPERIENCES) {
              const updatedExperiences = selectedQuote.experiences.concat(
                itemAsString
              );
              selectedQuote.experiences = updatedExperiences.map(
                (experience: any) => {
                  experience = experience.id
                    ? experience
                    : JSON.parse(experience);
                  if (
                    experience.totalThisExperience &&
                    experience.currency.code
                  ) {
                    experience.valueFormatted = formatValue(
                      experience.totalThisExperience.value,
                      experience.currency.code
                    );
                    experience.subtotalFormatted = formatValue(
                      experience.totalThisExperience.value,
                      experience.currency.code
                    );
                  }
                  experience.startDateFormatted =
                    experience.departureDates.startDate;
                  experience.endDateFormatted =
                    experience.departureDates.endDate;
                  return experience;
                }
              );
            }

            if (typeUser === 'hybrid') {
              if (!selectedQuote.plugAndPlay) {
                const hasCourse = selectedQuote.courses?.length > 0;

                selectedQuote.plugAndPlay =
                  hasCourse && data.allyPlus ? Number(data.allyPlus) : 0;
              }
            } else {
              selectedQuote.plugAndPlay = typeUser === 'allyplus' ? 1 : 0;
            }

            selectedQuote.totalsByCurrency = calculateTotalsByCurrency(
              selectedQuote
            );
            selectedQuote.totalConverted = await calculateConvertedValue(
              selectedQuote,
              index
            );
            selectedQuote.totalConverted.valueFormatted = formatValue(
              selectedQuote.totalConverted.value,
              selectedQuote.totalConverted.currency_code
            );
            selectedQuote.totalConverted.value_without_srFormatted = formatValue(
              selectedQuote.totalConverted.value_without_sr,
              selectedQuote.totalConverted.currency_code
            );
            selectedQuote.typeOption =
              selectedQuote.plugAndPlay === 1 ? 'allyplus' : 'backoffice';

            const response = await saveQuote(selectedQuote);

            if (!response) {
              throw new Error('Something went wrong');
            }

            const { quoteCurrencies } = response;

            selectedQuote.quoteCurrencies = quoteCurrencies;
            selectedQuote.quoteCurrencies.forEach((currency: any) => {
              currency.valueFormatted = formatValue(
                currency.value,
                currency.destiny_code
              );
            });

            newQuotes.push(selectedQuote);
          }, Promise.resolve());

          updateQuotesData({
            ...quotesData,
            quotes: newQuotes,
          });

          setOpenAddItemToQuoteModal(false);

          addToast({
            type: 'success',
            title: 'Success',
            description: 'Item added to selected quotes successfully.',
          });
        }
      } catch (err: any) {
        addToast({
          type: 'error',
          title: 'Error',
          description:
            err.message || 'An error has occurred, please try again.',
        });
      } finally {
        setIsAddItemLoading(false);
      }
    },
    [
      editingItem,
      quotesData,
      officeCurrencies,
      cantOpenSuggestiveFee,
      programStartDate,
      dateOfReference,
      updateQuotesData,
      addToast,
      getMandatoryFees,
      calculateTotalsByCurrency,
      calculateConvertedValue,
      saveQuote,
      calculateEndDate,
      filterSchool,
      handleSchoolChange,
      user.account_id,
      typeUser,
      handleRenewalQuote,
      onlyRenewal,
    ]
  );

  const handleAddSuggestiveFeeToQuote = useCallback(
    (data: ListItemFormData) => {
      setEditingItem(data);
      setCantOpenSuggestiveFee(true);
    },
    []
  );

  const handleRemoveItemFromQuote = useCallback(
    async (index: number, type: string | undefined, itemId: number) => {
      const { quotes } = quotesData;

      const newQuotes: any[] = [];

      if (!quotes) return;
      await swal({
        title: 'Are you sure?',
        icon: 'warning',
        buttons: ['No, Cancel!', 'Yes, remove it!'],
        // eslint-disable-next-line consistent-return
      }).then(async confirmed => {
        if (confirmed) {
          await quotes.reduce(async (promise, quote, idx) => {
            await promise;

            if (idx !== index) {
              newQuotes.push(quote);
              return;
            }

            setResumeActionLoading({ index, loading: true });

            const selectedQuote = _.cloneDeep(quote);
            selectedQuote.subtitle = '';
            if (selectedQuote.courses.length > 0) {
              if (
                selectedQuote.courses[0].course?.campus.city &&
                selectedQuote.courses[0].course?.campus.country
              ) {
                selectedQuote.subtitle = `${
                  selectedQuote.courses[0].course.campus.city.name
                } - ${selectedQuote.courses[0].course.campus.school.name} - ${
                  selectedQuote.courses[0].course.course.name
                } (${selectedQuote.courses[0].duration} ${
                  selectedQuote.courses[0].pricePeriod
                }${selectedQuote.courses[0].duration > 1 ? 's' : ''})`;
              } else if (
                selectedQuote.courses[0].coursecampus?.campus?.city &&
                selectedQuote.courses[0].coursecampus?.campus?.country
              ) {
                selectedQuote.subtitle = `${
                  selectedQuote.courses[0].coursecampus?.campus?.city?.name
                } - ${
                  selectedQuote.courses[0].coursecampus?.campus?.school?.name
                } - ${selectedQuote.courses[0].coursecampus?.course?.name} (${
                  selectedQuote.courses[0].coursecampus?.duration
                } ${selectedQuote.courses[0].coursecampus?.pricePeriod}${
                  selectedQuote.courses[0].coursecampus?.duration > 1 ? 's' : ''
                })`;
              } else if (selectedQuote.courses[0].course?.course?.campus) {
                selectedQuote.subtitle = `${
                  selectedQuote.courses[0].course?.course.campus.country.name
                } - ${
                  selectedQuote.courses[0].course?.course.campus.school.name
                } - ${selectedQuote.courses[0].course.course.name} (${
                  selectedQuote.courses[0].duration
                } ${selectedQuote.courses[0].pricePeriod}${
                  selectedQuote.courses[0].duration > 1 ? 's' : ''
                })`;
              }

              if (
                selectedQuote.accommodations.length > 0 &&
                !selectedQuote.subtitle.includes('Accommodation')
              ) {
                selectedQuote.subtitle += ' + Accommodation';
              }

              if (selectedQuote.fees.length > 0) {
                selectedQuote.fees.forEach((item: { category_id: number }) => {
                  if (
                    item.category_id === 4 &&
                    !selectedQuote.subtitle.includes('Insurance')
                  ) {
                    selectedQuote.subtitle += ' + Insurance';
                  }
                });
                selectedQuote.fees.forEach((item: { category_id: number }) => {
                  if (
                    item.category_id !== 4 &&
                    !selectedQuote.subtitle.includes('Add-ons')
                  ) {
                    selectedQuote.subtitle += ' + Add-ons';
                  }
                });
              }
            }

            if (
              !selectedQuote.subtitle &&
              selectedQuote.accommodations.length > 0 &&
              selectedQuote.courses.length === 0
            ) {
              selectedQuote.subtitle = `${
                selectedQuote.accommodations[0].city?.name
              } - ${selectedQuote.accommodations[0].partner} - ${
                selectedQuote.accommodations[0].name
              }(${`${selectedQuote.accommodations[0].duration} ${selectedQuote.accommodations[0].pricePeriod}`}${
                selectedQuote.accommodations[0].duration > 1 ? 's' : ''
              })`;

              if (selectedQuote.fees.length > 0) {
                selectedQuote.fees.forEach((item: { category_id: number }) => {
                  if (
                    item.category_id === 4 &&
                    !selectedQuote.subtitle.includes('Insurance')
                  ) {
                    selectedQuote.subtitle += ' + Insurance';
                  }
                });
                selectedQuote.fees.forEach((item: { category_id: number }) => {
                  if (
                    item.category_id !== 4 &&
                    !selectedQuote.subtitle.includes('Add-ons')
                  ) {
                    selectedQuote.subtitle += ' + Add-ons';
                  }
                });
              }
            }

            if (
              !selectedQuote.subtitle &&
              selectedQuote.fees.length > 0 &&
              selectedQuote.accommodations.length === 0 &&
              selectedQuote.courses.length === 0
            ) {
              selectedQuote.subtitle = `${selectedQuote.fees[0].partner} - ${
                selectedQuote.fees[0].name
              } (${`${selectedQuote.fees[0].duration} ${selectedQuote.fees[0].pricePeriod}`}${
                selectedQuote.fees[0].duration > 1 ? 's' : ''
              })`;
            }

            if (type === filtersOptions.FILTERS_COURSES) {
              const quoteCurrency = selectedQuote.courses.find(
                (course: any) => course.id === itemId
              );

              const courseCurrency = quoteCurrency.coursecampus.campus.currency;
              const totalCourse = quoteCurrency.coursecampus.totalThisCourse;

              const { totalsByCurrency } = selectedQuote;
              totalsByCurrency.map((quoteCurrencies: any) => {
                if (quoteCurrencies.currency === courseCurrency.symbol) {
                  quoteCurrencies.value -= totalCourse.value;
                  if (quoteCurrencies.value <= 0) {
                    updateQuoteCurrencyAndTotalValues(quote, courseCurrency);
                  }
                }
                return true;
              });

              const itemIndex = selectedQuote.courses.findIndex(
                (course: any) => course.id === itemId
              );
              if (itemIndex > -1) {
                selectedQuote.courses.splice(itemIndex, 1);
                if (
                  selectedQuote.courses.length === 0 &&
                  selectedQuote.accommodations.length === 0 &&
                  selectedQuote.fees.length === 0
                ) {
                  selectedQuote.school_id = undefined;
                  selectedQuote.campus_id = undefined;
                }
              }

              if (
                selectedQuote.plugAndPlay === 1 &&
                selectedQuote.courses.length === 0 &&
                selectedQuote.otherfees.length === 1
              ) {
                selectedQuote.otherfees.splice(0, 1);
              }
            } else if (type === filtersOptions.FILTERS_EXPERIENCES) {
              const quoteCurrency = selectedQuote.experiences.find(
                (experiences: any) => experiences.id === itemId
              );

              const { currency } = quoteCurrency;

              const { totalsByCurrency } = selectedQuote;
              totalsByCurrency.map((quoteCurrencies: any) => {
                if (quoteCurrencies.currency === currency.symbol) {
                  quoteCurrencies.value -=
                    quoteCurrency.totalThisExperience.value;
                  if (quoteCurrencies.value <= 0) {
                    updateQuoteCurrencyAndTotalValues(quote, currency);
                  }
                }
                return true;
              });

              const itemIndex = selectedQuote.experiences.findIndex(
                (experience: any) => experience.id === itemId
              );
              if (itemIndex > -1) {
                selectedQuote.experiences.splice(itemIndex, 1);
              }
            } else if (type === filtersOptions.FILTERS_ACCOMMODATIONS) {
              const quoteCurrency = selectedQuote.accommodations.find(
                (accommodation: any) => accommodation.id === itemId
              );

              const { currency } = quoteCurrency;
              const { totalThisAccommodation } = quoteCurrency;

              const { totalsByCurrency } = selectedQuote;
              totalsByCurrency.map((quoteCurrencies: any) => {
                if (quoteCurrencies.currency === currency.symbol) {
                  quoteCurrencies.value -= totalThisAccommodation.value;
                  if (quoteCurrencies.value <= 0) {
                    updateQuoteCurrencyAndTotalValues(quote, currency);
                  }
                }
                return true;
              });

              const itemIndex = selectedQuote.accommodations.findIndex(
                (accommodation: any) => accommodation.id === itemId
              );
              if (itemIndex > -1) {
                selectedQuote.accommodations.splice(itemIndex, 1);
                if (
                  selectedQuote.courses.length === 0 &&
                  selectedQuote.accommodations.length === 0 &&
                  selectedQuote.fees.length === 0
                ) {
                  selectedQuote.school_id = undefined;
                  selectedQuote.campus_id = undefined;
                }
              }
            } else if (
              type === filtersOptions.FILTERS_INSURANCES ||
              type === filtersOptions.FILTERS_ADDONS
            ) {
              const quoteCurrency = selectedQuote.fees.find(
                (fees: any) => fees.id === itemId
              );
              const { currency } = quoteCurrency;

              const { totalsByCurrency } = selectedQuote;
              totalsByCurrency.map((quoteCurrencies: any) => {
                if (quoteCurrencies.currency === currency.symbol) {
                  quoteCurrencies.value -= quoteCurrency.totalThisFee.value;
                  if (quoteCurrencies.value <= 0) {
                    updateQuoteCurrencyAndTotalValues(quote, currency);
                  }
                }
                return true;
              });

              const itemIndex = selectedQuote.fees.findIndex(
                (fee: any) => fee.id === itemId
              );
              const itemContent =
                itemIndex > -1
                  ? selectedQuote.fees.find((fee: any) => fee.id === itemId)
                  : null;

              if (itemContent && itemContent.partner_id === 10103) {
                selectedQuote.feeDeleted = {
                  name: itemContent.name,
                  value: itemContent.totalThisFee.value,
                  timelog: new Date(),
                };
              }

              if (itemIndex > -1) {
                selectedQuote.fees.splice(itemIndex, 1);
                if (
                  selectedQuote.courses.length === 0 &&
                  selectedQuote.accommodations.length === 0 &&
                  selectedQuote.fees.length === 0
                ) {
                  selectedQuote.school_id = undefined;
                  selectedQuote.campus_id = undefined;
                }
              }
            } else if (type === filtersOptions.FILTERS_OTHERS) {
              const itemIndex = selectedQuote.otherfees.findIndex(
                (otherfee: any) => otherfee.id === itemId
              );
              if (itemIndex > -1) {
                selectedQuote.otherfees.splice(itemIndex, 1);
              }
            }

            const canProcced = await validateValueInteractions(selectedQuote);
            if (!canProcced) {
              newQuotes.push(quote);
              return;
            }

            if (selectedQuote.paymentplan.length) {
              selectedQuote.paymentplan = [];
            }

            if (
              selectedQuote.courses.length === 0 &&
              selectedQuote.experiences.length === 0 &&
              selectedQuote.accommodations.length === 0 &&
              selectedQuote.fees.length === 0 &&
              selectedQuote.otherfees.length === 0
            ) {
              selectedQuote.empty = true;
            }

            selectedQuote.totalsByCurrency = calculateTotalsByCurrency(
              selectedQuote
            );
            selectedQuote.totalConverted = await calculateConvertedValue(
              selectedQuote,
              index
            );
            selectedQuote.totalConverted.valueFormatted = formatValue(
              selectedQuote.totalConverted.value,
              selectedQuote.totalConverted.currency_code
            );
            selectedQuote.totalConverted.value_without_srFormatted = formatValue(
              selectedQuote.totalConverted.value_without_sr,
              selectedQuote.totalConverted.currency_code
            );

            const { quoteCurrencies } = await saveQuote(selectedQuote);

            selectedQuote.quoteCurrencies = quoteCurrencies;
            selectedQuote.quoteCurrencies.forEach((currency: any) => {
              currency.valueFormatted = formatValue(
                currency.value,
                currency.destiny_code
              );
            });

            newQuotes.push(selectedQuote);
          }, Promise.resolve());

          setResumeActionLoading({ index, loading: false });

          const formData = {
            ...quotesData,
            quotes: newQuotes,
          };

          updateQuotesData(formData);
        } else {
          return false;
        }
      });
    },
    [
      quotesData,
      updateQuotesData,
      validateValueInteractions,
      calculateTotalsByCurrency,
      calculateConvertedValue,
      saveQuote,
      updateQuoteCurrencyAndTotalValues,
    ]
  );

  const handleUpdateCourseOptions = useCallback(
    async (
      index: number,
      itemId: number,
      itemType: string,
      newValue: boolean | string
    ) => {
      setResumeActionLoading({ index, loading: true });

      try {
        const { quotes } = quotesData;

        const courseStartDate = programStartDate;
        // const courseStartDate = filtersFormRef.current?.getFieldValue(
        //   'courseStartDate'
        // );

        const newQuotes: any[] = [];

        if (!quotes) return;

        await quotes.reduce(async (promise, quote, idx) => {
          await promise;

          if (idx !== index) {
            newQuotes.push(quote);
            return;
          }

          const selectedQuote = _.cloneDeep(quote);

          const itemIndex = selectedQuote.courses.findIndex(
            (course: any) => course.id === itemId
          );
          if (itemIndex > -1) {
            const course = selectedQuote.courses[itemIndex];
            const { coursecampus } = course;

            const currentEnrolActive = coursecampus.enrolActive ? true : 'no';
            const enrolActive =
              itemType === 'enrol' ? newValue : currentEnrolActive;

            const currentMaterialActive = coursecampus.materialActive
              ? true
              : 'no';
            const materialActive =
              itemType === 'material' ? newValue : currentMaterialActive;

            const courseAffectedKeys = [
              'duration',
              'enrolActive',
              'materialActive',
              'onlyCourse',
              'onlyEnrol',
              'onlyMaterial',
              'price',
              'specialrate',
              'priceOld',
              'sumThisValue',
              'totalThisCourse',
            ];

            course.srReject = [];
            course.srReject.push(1);
            if (course.srperiod_id > 0 && course.srperiod_active === 0) {
              course.srReject.push(course.srperiod_id);
            }
            if (course.srcourse_id > 0 && course.srcourse_active === 0) {
              course.srReject.push(course.srcourse_id);
            }
            if (course.srenrol_id > 0 && course.srenrol_active === 0) {
              course.srReject.push(course.srenrol_id);
            }
            if (course.srmaterial_id > 0 && course.srmaterial_active === 0) {
              course.srReject.push(course.srmaterial_id);
            }

            await api
              .get(`/firstprice/${coursecampus.id}`, {
                params: {
                  country: quotesData.student.nationalityCountry,
                  courseStartDateFrom:
                    courseStartDate &&
                    format(parseJSON(courseStartDate), 'dd/MM/yyyy'),
                  duration: coursecampus.duration,
                  enrolActive,
                  enrolDate: format(parseJSON(dateOfReference), 'dd/MM/yyyy'),
                  materialActive,
                  student_id: quotesData.student.id,
                  srperiod_id: course.srperiod_id,
                  srcourse_id: course.srcourse_id,
                  srenrol_id: course.srenrol_id,
                  srmaterial_id: course.srmaterial_id,
                  srReject: JSON.stringify(course.srReject),
                },
              })
              .then(response => {
                courseAffectedKeys.forEach(key => {
                  if (key === 'enrolActive' || key === 'materialActive') {
                    if (response.data[key] === 'yes') {
                      response.data[key] = true;
                    } else if (response.data[key] === 'no') {
                      response.data[key] = false;
                    }
                  }
                  coursecampus[key] = response.data[key];
                });

                coursecampus.srReject = course.srReject;
                coursecampus.srperiod_id = course.srperiod_id;
                coursecampus.srcourse_id = course.srcourse_id;
                coursecampus.srenrol_id = course.srenrol_id;
                coursecampus.srmaterial_id = course.srmaterial_id;

                course.enrolFormatted = formatValue(
                  coursecampus.totalThisCourse.onlyEnrol,
                  coursecampus.campus.currency_code
                );
                course.materialFormatted = formatValue(
                  coursecampus.totalThisCourse.onlyMaterial,
                  coursecampus.campus.currency_code
                );
                course.subtotalFormatted = formatValue(
                  coursecampus.totalThisCourse.value,
                  coursecampus.campus.currency_code
                );

                const isCourseRenewal = selectedQuote.courses.some(
                  ({
                    coursecampus: {
                      course: { renewal },
                    },
                  }: any) => renewal !== 0
                );

                if (
                  selectedQuote.school_id &&
                  selectedQuote.school_id !==
                    course.coursecampus.campus.school_id &&
                  selectedQuote.plugAndPlay &&
                  !isCourseRenewal
                ) {
                  throw new Error(
                    'Items from another school on the same quote are not allowed'
                  );
                } else {
                  selectedQuote.school_id =
                    course.coursecampus.campus?.school_id;
                  selectedQuote.campus_id = course.coursecampus.campus?.id;
                }
              });
          }

          const canProcced = await validateValueInteractions(selectedQuote);
          if (!canProcced) {
            newQuotes.push(quote);
            return;
          }

          selectedQuote.totalsByCurrency = calculateTotalsByCurrency(
            selectedQuote
          );
          selectedQuote.totalConverted = await calculateConvertedValue(
            selectedQuote,
            index
          );
          selectedQuote.totalConverted.valueFormatted = formatValue(
            selectedQuote.totalConverted.value,
            selectedQuote.totalConverted.currency_code
          );
          selectedQuote.totalConverted.value_without_srFormatted = formatValue(
            selectedQuote.totalConverted.value_without_sr,
            selectedQuote.totalConverted.currency_code
          );

          const { quoteCurrencies } = await saveQuote(selectedQuote);

          selectedQuote.quoteCurrencies = quoteCurrencies;
          selectedQuote.quoteCurrencies.forEach((currency: any) => {
            currency.valueFormatted = formatValue(
              currency.value,
              currency.destiny_code
            );
          });

          newQuotes.push(selectedQuote);
        }, Promise.resolve());

        const formData = {
          ...quotesData,
          quotes: newQuotes,
        };

        updateQuotesData(formData);
      } finally {
        setResumeActionLoading({ index, loading: false });
      }
    },
    [
      quotesData,
      programStartDate,
      updateQuotesData,
      validateValueInteractions,
      calculateTotalsByCurrency,
      calculateConvertedValue,
      saveQuote,
      dateOfReference,
    ]
  );

  const handleUpdateSR = useCallback(
    async (index: number, item: any, specialRate: any, newValue: boolean) => {
      if (
        user.office.account.type === 2 &&
        specialRate &&
        (specialRate.errorPartner || specialRate.errorAgency)
      ) {
        specialRate.active = false;
        swal(
          'Specific Agencies Only',
          "This Special Rate can't be applied to this student agency because it is only to other specific agencies.",
          'error'
        );
        return;
      }

      if (
        (item.coursecampus?.specialrate || item.specialrate) &&
        (item.coursecampus?.specialrate.length > 0 ||
          item.specialrate.length > 0)
      ) {
        let sameType = false;
        if (item.coursecampus?.specialrate) {
          item.coursecampus.specialrate.forEach((sr: any) => {
            if (sr.id !== specialRate.id) {
              if (
                sr.active &&
                (specialRate.type === 'typePeriod' ||
                  specialRate.type === 'freePeriod') &&
                (sr.type === 'typePeriod' || sr.type === 'freePeriod')
              ) {
                sameType = true;
              } else if (
                sr.active &&
                ((specialRate.type === 'typeFree' &&
                  specialRate.reference === 'enrol') ||
                  specialRate.type === 'freeEnrol') &&
                ((sr.type === 'typeFree' && sr.reference === 'enrol') ||
                  sr.type === 'freeEnrol')
              ) {
                sameType = true;
              } else if (
                sr.active &&
                ((specialRate.type === 'typeFree' &&
                  specialRate.reference === 'material') ||
                  specialRate.type === 'freeMaterial') &&
                ((sr.type === 'typeFree' && sr.reference === 'material') ||
                  sr.type === 'freeMaterial')
              ) {
                sameType = true;
              } else if (
                sr.active &&
                ((specialRate.type === 'typeDiscount' &&
                  specialRate.typeDiscount === 'value' &&
                  specialRate.reference === 'material') ||
                  specialRate.type === 'DiscountValueMaterial') &&
                ((sr.type === 'typeDiscount' &&
                  specialRate.typeDiscount === 'value' &&
                  sr.reference === 'material') ||
                  sr.type === 'DiscountValueMaterial')
              ) {
                sameType = true;
              } else if (
                sr.active &&
                ((specialRate.type === 'typeDiscount' &&
                  specialRate.typeDiscount === 'value' &&
                  specialRate.reference === 'enrol') ||
                  specialRate.type === 'DiscountValueEnrol') &&
                ((sr.type === 'typeDiscount' &&
                  specialRate.typeDiscount === 'value' &&
                  sr.reference === 'enrol') ||
                  sr.type === 'DiscountValueEnrol')
              ) {
                sameType = true;
              } else if (
                sr.active &&
                ((specialRate.type === 'typeDiscount' &&
                  specialRate.typeDiscount === 'value' &&
                  specialRate.reference === 'course') ||
                  specialRate.type === 'DiscountValueCourse') &&
                ((sr.type === 'typeDiscount' &&
                  specialRate.typeDiscount === 'value' &&
                  sr.reference === 'course') ||
                  sr.type === 'DiscountValueCourse')
              ) {
                sameType = true;
              } else if (
                sr.active &&
                ((specialRate.type === 'typeDiscount' &&
                  specialRate.typeDiscount === 'percent' &&
                  specialRate.reference === 'material') ||
                  specialRate.type === 'DiscountPercentMaterial') &&
                ((sr.type === 'typeDiscount' &&
                  specialRate.typeDiscount === 'percent' &&
                  sr.reference === 'material') ||
                  sr.type === 'DiscountPercentMaterial')
              ) {
                sameType = true;
              } else if (
                sr.active &&
                ((specialRate.type === 'typeDiscount' &&
                  specialRate.typeDiscount === 'percent' &&
                  specialRate.reference === 'enrol') ||
                  specialRate.type === 'DiscountPercentEnrol') &&
                ((sr.type === 'typeDiscount' &&
                  specialRate.typeDiscount === 'percent' &&
                  sr.reference === 'enrol') ||
                  sr.type === 'DiscountPercentEnrol')
              ) {
                sameType = true;
              } else if (
                sr.active &&
                ((specialRate.type === 'typeDiscount' &&
                  specialRate.typeDiscount === 'percent' &&
                  specialRate.reference === 'course') ||
                  specialRate.type === 'DiscountPercentCourse') &&
                ((sr.type === 'typeDiscount' &&
                  specialRate.typeDiscount === 'percent' &&
                  sr.reference === 'course') ||
                  sr.type === 'DiscountPercentCourse')
              ) {
                sameType = true;
              }
            }
          });
        } else if (item.specialrate) {
          item.specialrate.forEach((sr: any) => {
            if (sr.id !== specialRate.id) {
              if (
                sr.active &&
                (specialRate.type === 'typePeriod' ||
                  specialRate.type === 'freePeriod') &&
                (sr.type === 'typePeriod' || sr.type === 'freePeriod')
              ) {
                sameType = true;
              } else if (
                sr.active &&
                ((specialRate.type === 'typeFree' &&
                  specialRate.reference === 'enrol') ||
                  specialRate.type === 'freeEnrol') &&
                ((sr.type === 'typeFree' && sr.reference === 'enrol') ||
                  sr.type === 'freeEnrol')
              ) {
                sameType = true;
              } else if (
                sr.active &&
                ((specialRate.type === 'typeFree' &&
                  specialRate.reference === 'material') ||
                  specialRate.type === 'freeMaterial') &&
                ((sr.type === 'typeFree' && sr.reference === 'material') ||
                  sr.type === 'freeMaterial')
              ) {
                sameType = true;
              } else if (
                sr.active &&
                ((specialRate.type === 'typeDiscount' &&
                  specialRate.typeDiscount === 'value' &&
                  specialRate.reference === 'material') ||
                  specialRate.type === 'DiscountValueMaterial') &&
                ((sr.type === 'typeDiscount' &&
                  specialRate.typeDiscount === 'value' &&
                  sr.reference === 'material') ||
                  sr.type === 'DiscountValueMaterial')
              ) {
                sameType = true;
              } else if (
                sr.active &&
                ((specialRate.type === 'typeDiscount' &&
                  specialRate.typeDiscount === 'value' &&
                  specialRate.reference === 'enrol') ||
                  specialRate.type === 'DiscountValueEnrol') &&
                ((sr.type === 'typeDiscount' &&
                  specialRate.typeDiscount === 'value' &&
                  sr.reference === 'enrol') ||
                  sr.type === 'DiscountValueEnrol')
              ) {
                sameType = true;
              } else if (
                sr.active &&
                ((specialRate.type === 'typeDiscount' &&
                  specialRate.typeDiscount === 'value' &&
                  specialRate.reference === 'course') ||
                  specialRate.type === 'DiscountValueCourse') &&
                ((sr.type === 'typeDiscount' &&
                  specialRate.typeDiscount === 'value' &&
                  sr.reference === 'course') ||
                  sr.type === 'DiscountValueCourse')
              ) {
                sameType = true;
              } else if (
                sr.active &&
                ((specialRate.type === 'typeDiscount' &&
                  specialRate.typeDiscount === 'percent' &&
                  specialRate.reference === 'material') ||
                  specialRate.type === 'DiscountPercentMaterial') &&
                ((sr.type === 'typeDiscount' &&
                  specialRate.typeDiscount === 'percent' &&
                  sr.reference === 'material') ||
                  sr.type === 'DiscountPercentMaterial')
              ) {
                sameType = true;
              } else if (
                sr.active &&
                ((specialRate.type === 'typeDiscount' &&
                  specialRate.typeDiscount === 'percent' &&
                  specialRate.reference === 'enrol') ||
                  specialRate.type === 'DiscountPercentEnrol') &&
                ((sr.type === 'typeDiscount' &&
                  specialRate.typeDiscount === 'percent' &&
                  sr.reference === 'enrol') ||
                  sr.type === 'DiscountPercentEnrol')
              ) {
                sameType = true;
              } else if (
                sr.active &&
                ((specialRate.type === 'typeDiscount' &&
                  specialRate.typeDiscount === 'percent' &&
                  specialRate.reference === 'course') ||
                  specialRate.type === 'DiscountPercentCourse') &&
                ((sr.type === 'typeDiscount' &&
                  specialRate.typeDiscount === 'percent' &&
                  sr.reference === 'course') ||
                  sr.type === 'DiscountPercentCourse')
              ) {
                sameType = true;
              }
            }
          });
        }

        if (sameType) {
          specialRate.active = false;
          swal(
            'Same type SR',
            'Currently we do not accept the application of more than one special rate with the same type. Remove the selection of the current SR before you try to select another one with the same type.',
            'warning'
          );
          return;
        }

        if (!item.srReject) {
          item.srReject = [];
          item.srReject.push(1);
        }

        if (item.srReject.indexOf(specialRate.id) === -1) {
          item.srReject.push(specialRate.id);
        } else {
          item.srReject.forEach((srId: number, idx: number) => {
            if (srId === specialRate.id) {
              item.srReject.splice(idx, 1);
            }
          });
        }

        const { quotes } = quotesData;

        const newQuotes: any[] = [];

        const courseStartDate = programStartDate;
        // const courseStartDate = filtersFormRef.current?.getFieldValue(
        //   'courseStartDate'
        // );

        if (!quotes) return;

        await quotes.reduce(async (promise, quote, idx) => {
          await promise;

          if (idx !== index) {
            newQuotes.push(quote);
            return;
          }

          setResumeActionLoading({ index, loading: true });

          const selectedQuote = _.cloneDeep(quote);

          const itemAccommodation = selectedQuote.accommodations.findIndex(
            (accommodation: any) => accommodation.id === item.id
          );

          const itemIndex = selectedQuote.courses.findIndex(
            (course: any) => course.id === item.id
          );
          if (itemIndex > -1) {
            const course = selectedQuote.courses[itemIndex];
            const { id, coursecampus } = course;

            const currentEnrolActive = coursecampus.enrolActive ? true : 'no';

            const currentMaterialActive = coursecampus.materialActive
              ? true
              : 'no';

            course.srReject = item.srReject;

            const courseAffectedKeys = [
              'duration',
              'enrolActive',
              'materialActive',
              'onlyCourse',
              'onlyEnrol',
              'onlyMaterial',
              'price',
              'specialrate',
              'priceOld',
              'specialrate',
              'sumThisValue',
              'totalThisCourse',
              'discountTuition',
              'discountEnrol',
              'discountMaterial',
              'editedTuition',
              'editedEnrol',
              'editedMaterial',
            ];

            await api
              .get(`/firstprice/${course.courseCampus_id}`, {
                params: {
                  country: quotesData.student.nationalityCountry,
                  // courseStartDateFrom: format(
                  //   parseJSON(courseStartDate),
                  //   'dd/MM/yyyy'
                  // ),
                  courseStartDateFrom: course.coursecampus.startDate
                    ? course.coursecampus.startDate
                    : format(parseJSON(programStartDate), 'dd/MM/yyyy'),
                  teste: 2,
                  duration: coursecampus.duration,
                  enrolActive: currentEnrolActive,
                  materialActive: currentMaterialActive,
                  srperiod_id: course.srperiod_id,
                  srcourse_id: course.srcourse_id,
                  srenrol_id: course.srenrol_id,
                  srmaterial_id: course.srmaterial_id,
                  // enrolDate: format(parseJSON(dateOfReference), 'dd/MM/yyyy'),
                  enrolDate: course.coursecampus.enrolDate
                    ? course.coursecampus.enrolDate
                    : format(parseJSON(dateOfReference), 'dd/MM/yyyy'),
                  student_id: quotesData.student.id,
                  srReject: JSON.stringify(course.srReject),
                },
              })
              .then(response => {
                courseAffectedKeys.forEach(key => {
                  if (key === 'enrolActive' || key === 'materialActive') {
                    if (response.data[key] === 'yes') {
                      response.data[key] = true;
                    } else if (response.data[key] === 'no') {
                      response.data[key] = false;
                    }
                  }
                  coursecampus[key] = response.data[key];
                });

                course.discountTuition = 0;
                course.discountEnrol = 0;
                course.discountMaterial = 0;
                course.editedTuition = 0;
                course.editedEnrol = 0;
                course.editedMaterial = 0;
                course.valueFormatted = formatValue(
                  course.coursecampus.totalThisCourse.onlyCourse,
                  course.coursecampus.campus.currency_code
                );
                course.oldValueFormatted = course.coursecampus.totalThisCourse
                  .oldValue
                  ? formatValue(
                      course.coursecampus.totalThisCourse.oldValue,
                      course.coursecampus.campus.currency_code
                    )
                  : undefined;

                course.enrolFormatted = formatValue(
                  coursecampus.totalThisCourse.onlyEnrol,
                  coursecampus.campus.currency_code
                );
                course.materialFormatted = formatValue(
                  coursecampus.totalThisCourse.onlyMaterial,
                  coursecampus.campus.currency_code
                );
                course.subtotalFormatted = formatValue(
                  coursecampus.totalThisCourse.value,
                  coursecampus.campus.currency_code
                );
              });
          } else if (itemAccommodation > -1) {
            const accommodation =
              selectedQuote.accommodations[itemAccommodation];

            accommodation.srReject = item.srReject;

            const accommodationAffectedKeys = [
              'enrolActive',
              'materialActive',
              'onlyCourse',
              'onlyEnrol',
              'onlyMaterial',
              'price',
              'priceOld',
              'specialrate',
              'sumThisValue',
              'totalThisAccommodation',
            ];

            await api
              .get(`/accommodationprice/${accommodation.id}`, {
                params: {
                  courseStartDateFrom: accommodation.startDate
                    ? accommodation.startDate
                    : format(parseJSON(programStartDate), 'dd/MM/yyyy'),
                  country: quotesData.student.nationalityCountry,
                  period_id: accommodation.period_id,
                  srperiod_id: accommodation.srperiod_id,
                  srcourse_id: accommodation.srcourse_id,
                  srenrol_id: accommodation.srenrol_id,
                  srmaterial_id: accommodation.srmaterial_id,
                  duration: accommodation.duration,
                  student_id: quotesData.student.id,
                  srReject: JSON.stringify(accommodation.srReject),
                },
              })
              .then(response => {
                accommodationAffectedKeys.forEach(key => {
                  if (key === 'enrolActive' || key === 'materialActive') {
                    if (response.data[key] === 'yes') {
                      response.data[key] = true;
                    } else if (response.data[key] === 'no') {
                      response.data[key] = false;
                    }
                  }
                  accommodation[key] = response.data[key];
                });
                accommodation.discountAccommodation = 0;
                accommodation.discountPlacementFee = 0;
                accommodation.editedAccommodation = 0;
                accommodation.editedPlacementFee = 0;
                accommodation.valueFormatted = formatValue(
                  accommodation.totalThisAccommodation.onlyAccommodation,
                  accommodation.currency_code
                );
                accommodation.oldValueFormatted = accommodation
                  .totalThisAccommodation.oldValue
                  ? formatValue(
                      accommodation.totalThisAccommodation.oldValue,
                      accommodation.currency_code
                    )
                  : undefined;
                accommodation.placementFeeFormatted = formatValue(
                  accommodation.totalThisAccommodation.onlyEnrol,
                  accommodation.currency_code
                );
                accommodation.enrolFormatted = formatValue(
                  accommodation.totalThisAccommodation.onlyEnrol,
                  accommodation.currency_code
                );
                accommodation.materialFormatted = formatValue(
                  accommodation.totalThisAccommodation.onlyMaterial,
                  accommodation.currency_code
                );
                accommodation.subtotalFormatted = formatValue(
                  accommodation.totalThisAccommodation.value,
                  accommodation.currency_code
                );

                accommodation.discountAccommodation =
                  accommodation.qcc?.discountAccommodation;
                accommodation.discountPlacementFee =
                  accommodation.qcc?.discountPlacementFee;
              });
          }

          const canProcced = await validateValueInteractions(selectedQuote);
          if (!canProcced) {
            newQuotes.push(quote);
            return;
          }

          selectedQuote.totalsByCurrency = calculateTotalsByCurrency(
            selectedQuote
          );
          selectedQuote.totalConverted = await calculateConvertedValue(
            selectedQuote,
            index
          );
          selectedQuote.totalConverted.valueFormatted = formatValue(
            selectedQuote.totalConverted.value,
            selectedQuote.totalConverted.currency_code
          );
          selectedQuote.totalConverted.value_without_srFormatted = formatValue(
            selectedQuote.totalConverted.value_without_sr,
            selectedQuote.totalConverted.currency_code
          );

          const { quoteCurrencies } = await saveQuote(selectedQuote);

          selectedQuote.quoteCurrencies = quoteCurrencies;
          selectedQuote.quoteCurrencies.forEach((currency: any) => {
            currency.valueFormatted = formatValue(
              currency.value,
              currency.destiny_code
            );
          });

          newQuotes.push(selectedQuote);
        }, Promise.resolve());

        setResumeActionLoading({ index, loading: false });

        const formData = {
          ...quotesData,
          quotes: newQuotes,
        };

        updateQuotesData(formData);
      }
    },
    [
      user.office.account.type,
      quotesData,
      programStartDate,
      updateQuotesData,
      validateValueInteractions,
      calculateTotalsByCurrency,
      calculateConvertedValue,
      saveQuote,
      dateOfReference,
    ]
  );

  const handleNavigationClick = useCallback(() => {
    if (currentStep === 2) {
      const updatedOptions = breadcrumbOptions.map((option, index) => {
        option.active = true;
        return option;
      });

      setBreadcrumbOptions(updatedOptions);

      next(quotesData);
    }
    if (currentStep === 3) {
      handleBackLinkClick();
    }
  }, [currentStep, breadcrumbOptions, quotesData, next, handleBackLinkClick]);

  const handleOpenEditFeeDurationModal = useCallback(
    async (event: any) => {
      if (!event.data.item) return false;

      const { quotes } = quotesData;

      const newQuotes: any[] = [];

      if (!quotes) return false;

      await quotes.reduce(async (promise, quote, index) => {
        await promise;

        if (index !== event.data.index) {
          newQuotes.push(quote);
          return false;
        }

        const selectedQuote = await quoteWithNewFees(quote, event.data);

        const { quoteCurrencies } = await saveQuote(selectedQuote);

        selectedQuote.quoteCurrencies = quoteCurrencies;
        selectedQuote.quoteCurrencies.forEach((currency: any) => {
          currency.valueFormatted = formatValue(
            currency.value,
            currency.destiny_code
          );
        });
        newQuotes.push(selectedQuote);
        return true;
      }, Promise.resolve());

      const formData = {
        ...quotesData,
        quotes: newQuotes,
      };

      updateQuotesData(formData);
      return true;
    },
    [quotesData, quoteWithNewFees, updateQuotesData, saveQuote]
  );

  const handleChangeItemValue = useCallback(
    async (type: string, changedPeriod: any) => {
      const { quotes } = quotesData;

      const newQuotes: any[] = [];

      if (!quotes) return;

      if (type === 'fee') {
        await quotes.reduce(async (promise, quote, index) => {
          await promise;

          if (quotes[index].id !== changedPeriod.id) {
            newQuotes.push(quote);
            return;
          }

          setResumeActionLoading({ index, loading: true });

          const selectedQuote = _.cloneDeep(quote);
          if (quote.fees.length > changedPeriod.fees.length) {
            selectedQuote.fees = [...changedPeriod.fees];
          }

          selectedQuote.empty = false;

          selectedQuote.fees = [...changedPeriod.fees];
          selectedQuote.fees = selectedQuote.fees.map((fee: any) => {
            fee.pricePeriod = fee.price.periodType;
            fee.enrolDate = fee.enrolDate
              ? fee.enrolDate
              : format(new Date(), 'dd/MM/yyyy');
            fee.valueFormatted = formatValue(
              fee.totalThisFee.value,
              fee.currency.code
            );
            fee.subtotalFormatted = formatValue(
              fee.totalThisFee.value,
              fee.currency.code
            );
            fee.editedValue = fee.editedValue ? 1 : 0;
            return fee;
          });

          const canProcced = await validateValueInteractions(selectedQuote);
          if (!canProcced) {
            newQuotes.push(quote);
            return;
          }

          selectedQuote.totalsByCurrency = calculateTotalsByCurrency(
            selectedQuote
          );
          selectedQuote.totalConverted = await calculateConvertedValue(
            selectedQuote,
            index
          );
          selectedQuote.totalConverted.valueFormatted = formatValue(
            selectedQuote.totalConverted.value,
            selectedQuote.totalConverted.currency_code
          );
          selectedQuote.totalConverted.value_without_srFormatted = formatValue(
            selectedQuote.totalConverted.value_without_sr,
            selectedQuote.totalConverted.currency_code
          );

          const { quoteCurrencies } = await saveQuote(selectedQuote);

          selectedQuote.quoteCurrencies = quoteCurrencies;
          selectedQuote.quoteCurrencies.forEach((currency: any) => {
            currency.valueFormatted = formatValue(
              currency.value,
              currency.destiny_code
            );
          });

          newQuotes.push(selectedQuote);
          setResumeActionLoading({ index, loading: false });
        }, Promise.resolve());
      } else if (type === 'courses' || type === 'accommodation') {
        await quotes.reduce(async (promise, quote, index) => {
          await promise;
          if (quotes[index].id !== changedPeriod.id) {
            newQuotes.push(quote);
            return;
          }

          setResumeActionLoading({ index, loading: true });

          const selectedQuote = _.cloneDeep(quote);

          const canProcced = await validateValueInteractions(selectedQuote);
          if (!canProcced) {
            newQuotes.push(quote);
            return;
          }

          selectedQuote.totalsByCurrency = calculateTotalsByCurrency(
            selectedQuote
          );
          selectedQuote.totalConverted = await calculateConvertedValue(
            selectedQuote,
            index
          );
          selectedQuote.totalConverted.valueFormatted = formatValue(
            selectedQuote.totalConverted.value,
            selectedQuote.totalConverted.currency_code
          );
          selectedQuote.totalConverted.value_without_srFormatted = formatValue(
            selectedQuote.totalConverted.value_without_sr,
            selectedQuote.totalConverted.currency_code
          );

          const { quoteCurrencies } = await saveQuote(selectedQuote);

          selectedQuote.quoteCurrencies = quoteCurrencies;
          selectedQuote.quoteCurrencies.forEach((currency: any) => {
            currency.valueFormatted = formatValue(
              currency.value,
              currency.destiny_code
            );
          });

          if (type === 'courses') {
            selectedQuote.courses = selectedQuote.courses.map(
              (course: any, i) => {
                if (course.id === changedPeriod.courses[i].id) {
                  course.subtotalFormatted = formatValue(
                    changedPeriod.courses[i].coursecampus.totalThisCourse.value,
                    changedPeriod.courses[i].coursecampus.campus.currency_code
                  );
                }
                return course;
              }
            );
          } else if (type === 'accommodation') {
            selectedQuote.accommodations = selectedQuote.accommodations.map(
              (accommodation: any, i) => {
                if (accommodation.id === changedPeriod.accommodations[i].id) {
                  accommodation.subtotalFormatted = formatValue(
                    changedPeriod.accommodations[i].totalThisAccommodation
                      .value,
                    changedPeriod.accommodations[i].currency.code
                  );
                }
                return accommodation;
              }
            );
          }

          newQuotes.push(selectedQuote);
          setResumeActionLoading({ index, loading: false });
        }, Promise.resolve());
      }

      const formData = {
        ...quotesData,
        quotes: newQuotes,
      };
      updateQuotesData(formData);
    },
    [
      quotesData,
      updateQuotesData,
      validateValueInteractions,
      calculateTotalsByCurrency,
      calculateConvertedValue,
      saveQuote,
    ]
  );

  const handleActionsCallback = useCallback(
    async (event: MessageEvent) => {
      // Debug purpose: remove it later
      // eslint-disable-next-line no-console
      console.log('3. Chegou no React', event.data);
      /*
      if (event.data && event.data.origin !== process.env.REACT_APP_NG_URL)
        return;
      */
      if (event.data && event.data.callback) {
        if (event.data.action === 'open-view-item-description-modal') {
          // * Não é necessário nenhuma ação
        } else if (event.data.action === 'open-instructions-for-agents-modal') {
          // * Não é necessário nenhuma ação
        } else if (
          event.data.action === 'open-add-custom-fee-and-discount-modal' ||
          event.data.action === 'open-view-item-change-fee-modal'
        ) {
          const { quotes } = quotesData;

          const newQuotes: any[] = [];

          if (!quotes) return;

          await quotes.reduce(async (promise, quote, index) => {
            setResumeActionLoading({ index, loading: true });
            await promise;

            if (index !== event.data.index) {
              newQuotes.push(quote);
              return;
            }

            const selectedQuote = _.cloneDeep(quote);
            if (quote.fees.length > event.data.quote.fees.length) {
              selectedQuote.fees = event.data.quote.fees;
            }

            selectedQuote.empty = false;

            if (event.data.item.totalThisFee) {
              const updatedFees = selectedQuote.fees.concat(event.data.item);
              selectedQuote.fees = updatedFees.map((fee: any) => {
                fee.pricePeriod = fee.price.periodType;
                fee.enrolDate = fee.enrolDate
                  ? fee.enrolDate
                  : format(new Date(), 'dd/MM/yyyy');
                fee.valueFormatted = formatValue(
                  fee.totalThisFee.value,
                  fee.currency.code
                );
                fee.subtotalFormatted = formatValue(
                  fee.totalThisFee.value,
                  fee.currency.code
                );
                fee.editedValue = fee.editedValue ? 1 : 0;
                return fee;
              });
            } else {
              const updatedOtherFees = selectedQuote.otherfees.concat(
                event.data.item
              );
              selectedQuote.otherfees = updatedOtherFees.map(
                (otherfee: any) => {
                  otherfee.valueFormatted = formatValue(
                    otherfee.value,
                    otherfee.currency.code
                  );
                  otherfee.subtotalFormatted = formatValue(
                    otherfee.value,
                    otherfee.currency.code
                  );
                  return otherfee;
                }
              );
            }

            const canProcced = await validateValueInteractions(selectedQuote);
            if (!canProcced) {
              newQuotes.push(quote);
              return;
            }

            selectedQuote.totalsByCurrency = calculateTotalsByCurrency(
              selectedQuote
            );
            selectedQuote.totalConverted = await calculateConvertedValue(
              selectedQuote,
              index
            );
            selectedQuote.totalConverted.valueFormatted = formatValue(
              selectedQuote.totalConverted.value,
              selectedQuote.totalConverted.currency_code
            );
            selectedQuote.totalConverted.value_without_srFormatted = formatValue(
              selectedQuote.totalConverted.value_without_sr,
              selectedQuote.totalConverted.currency_code
            );

            const { quoteCurrencies } = await saveQuote(selectedQuote);

            selectedQuote.quoteCurrencies = quoteCurrencies;
            selectedQuote.quoteCurrencies.forEach((currency: any) => {
              currency.valueFormatted = formatValue(
                currency.value,
                currency.destiny_code
              );
            });

            newQuotes.push(selectedQuote);
            setResumeActionLoading({ index, loading: false });
          }, Promise.resolve());

          const formData = {
            ...quotesData,
            quotes: newQuotes,
          };
          updateQuotesData(formData);
        } else if (event.data.action === 'open-add-payment-plan-modal') {
          const { quotes } = quotesData;

          const newQuotes: any[] = [];

          if (!quotes) return;

          await quotes.reduce(async (promise, quote, index) => {
            await promise;

            if (index !== event.data.index) {
              newQuotes.push(quote);
              return;
            }

            const selectedQuote = _.cloneDeep(quote);

            if (event.data.item.reload) {
              api
                .get(`/quote/${selectedQuote.id}`, {
                  params: {
                    withBusiness: true,
                  },
                })
                .then(response => {
                  selectedQuote.paymentplan = response.data.paymentplan;

                  if (selectedQuote.paymentplan.length > 0) {
                    selectedQuote.paymentplan.forEach((pp: any) => {
                      pp.totalVisa = 0;
                      pp.totalFuture = 0;
                      pp.hasInterest = false;
                      pp.hasAllyCheckout = false;
                      pp.instalments.forEach((instalment: any) => {
                        // VERIFICA SE TEM JUROS OU NÃO
                        if (
                          instalment.value > 0 &&
                          instalment.valueView > 0 &&
                          instalment.value !== instalment.valueView
                        ) {
                          pp.hasInterest = true;
                        }
                        if (instalment.instalmentType_id === 1) {
                          pp.totalVisa += instalment.value;
                        } else if (instalment.instalmentType_id === 2) {
                          pp.totalFuture += instalment.value;
                        }
                        if (instalment.value !== instalment.valueView) {
                          pp.hasInterest = true;
                        }

                        if (instalment.method_id === 6) {
                          pp.hasAllyCheckout = true;
                        }
                      });
                    });
                  }
                  newQuotes.push(selectedQuote);
                  const formData = {
                    ...quotesData,
                    quotes: newQuotes,
                  };
                  updateQuotesData(formData);
                });
            } else {
              selectedQuote.paymentplan.push(event.data.item);
              if (selectedQuote.paymentplan.length > 0) {
                selectedQuote.paymentplan.forEach((pp: any) => {
                  pp.totalVisa = 0;
                  pp.totalFuture = 0;
                  pp.hasInterest = false;
                  pp.hasAllyCheckout = false;
                  pp.instalments.forEach((instalment: any) => {
                    // VERIFICA SE TEM JUROS OU NÃO
                    if (
                      instalment.value > 0 &&
                      instalment.valueView > 0 &&
                      instalment.value !== instalment.valueView
                    ) {
                      pp.hasInterest = true;
                    }
                    if (instalment.instalmentType_id === 1) {
                      pp.totalVisa += instalment.value;
                    } else if (instalment.instalmentType_id === 2) {
                      pp.totalFuture += instalment.value;
                    }
                    if (instalment.value !== instalment.valueView) {
                      pp.hasInterest = true;
                    }

                    if (instalment.method_id === 6) {
                      pp.hasAllyCheckout = true;
                    }
                  });
                });
              }
              newQuotes.push(selectedQuote);
            }
          }, Promise.resolve());
          const formData = {
            ...quotesData,
            quotes: newQuotes,
          };

          updateQuotesData(formData);
        } else if (event.data.action === 'open-view-payment-plan-modal') {
          const { quotes } = quotesData;

          const newQuotes: any[] = [];

          if (!quotes) return;

          await quotes.reduce(async (promise, quote, index) => {
            await promise;

            if (index !== event.data.index) {
              newQuotes.push(quote);
              return;
            }

            const selectedQuote = _.cloneDeep(quote);

            selectedQuote.paymentplan = event.data.item;

            newQuotes.push(selectedQuote);
          }, Promise.resolve());

          const formData = {
            ...quotesData,
            quotes: newQuotes,
          };

          updateQuotesData(formData);
        } else if (event.data.action === 'open-edit-course-date-modal') {
          let shouldProceed = true;
          const { quotes } = quotesData;

          const newQuotes: any[] = [];

          // There's no quotes availables
          if (!quotes) return;

          let selectedQuote: QuoteType = quotes[0]; // default value
          await quotes.reduce(async (promise, quote, index) => {
            await promise;

            if (index !== event.data.index) {
              newQuotes.push(quote);
              return;
            }

            selectedQuote = _.cloneDeep(quote) as QuoteType;

            selectedQuote.empty = false;

            selectedQuote.courses.forEach((course: any) => {
              if (course.coursecampus.id === event.data.item.courseCampus_id) {
                const formattedStartDate = format(
                  parseISO(event.data.item.startDate),
                  'dd/MM/yyyy'
                );
                const formattedEndDate = format(
                  parseISO(event.data.item.endDate),
                  'dd/MM/yyyy'
                );

                course.valueFormatted = formatValue(
                  event.data.item.totalThisCourse.onlyCourse,
                  course.coursecampus.campus.currency_code
                );
                course.oldValueFormatted = event.data.item.totalThisCourse
                  .oldValue
                  ? formatValue(
                      event.data.item.totalThisCourse.oldValue,
                      course.coursecampus.campus.currency_code
                    )
                  : undefined;
                course.enrolFormatted = formatValue(
                  event.data.item.totalThisCourse.onlyEnrol,
                  course.coursecampus.campus.currency_code
                );
                course.materialFormatted = formatValue(
                  event.data.item.totalThisCourse.onlyMaterial,
                  course.coursecampus.campus.currency_code
                );
                course.subtotalFormatted = formatValue(
                  event.data.item.totalThisCourse.value,
                  course.coursecampus.campus.currency_code
                );

                if (
                  course.coursecampus.duration === event.data.item.duration &&
                  course.coursecampus.startDate === formattedStartDate &&
                  course.coursecampus.endDate === formattedEndDate
                ) {
                  shouldProceed = false;
                  return;
                }
                course.coursecampus.duration = event.data.item.duration;
                course.coursecampus.specialrate = event.data.item.specialrate;
                course.coursecampus.price = event.data.item.price;
                course.discountEnrol = 0;
                course.discountMaterial = 0;
                course.discountTuition = 0;
                course.editedEnrol = 0;
                course.editedMaterial = 0;
                course.editedTuition = 0;
                course.coursecampus.onlyCourse =
                  event.data.item.totalThisCourse.onlyCourse;
                course.coursecampus.onlyCourseWithoutSR =
                  event.data.item.totalThisCourse.onlyCourseWithoutSR;
                course.coursecampus.onlyEnrolWithoutSR =
                  event.data.item.totalThisCourse.onlyEnrolWithoutSR;
                course.coursecampus.onlyMaterialWithoutSR =
                  event.data.item.totalThisCourse.onlyMaterialWithoutSR;
                course.coursecampus.onlyEnrol =
                  event.data.item.totalThisCourse.onlyEnrol;
                course.coursecampus.onlyMaterial =
                  event.data.item.totalThisCourse.onlyMaterial;
                course.coursecampus.value =
                  event.data.item.totalThisCourse.value;
                course.coursecampus.valueWithoutSR =
                  event.data.item.totalThisCourse.valueWithoutSR;
                course.coursecampus.totalThisCourse =
                  event.data.item.totalThisCourse;
                course.coursecampus.startDate = formattedStartDate;
                course.coursecampus.startDateFormatted = format(
                  parseISO(event.data.item.startDate),
                  'LLLL dd, yyyy'
                );
                course.coursecampus.endDate = formattedEndDate;
                course.coursecampus.endDateFormatted = format(
                  parseISO(event.data.item.endDate),
                  'LLLL dd, yyyy'
                );
              }
            });

            if (!shouldProceed) {
              newQuotes.push(quote);
              return;
            }

            /*
            Now we'll update the quote fees
            */

            setMainLoading(true);

            // That will be necessary to avoid unexpected behaviors with
            // asynchronous  code.
            const staticFees = [];
            for (let i = 0; i < event.data.quote.fees.length; i++) {
              const fee = event.data.quote.fees[i];
              // Checkings:
              // 1. If the fee isn't fixed.
              const isntFixed = fee.pricePeriod !== 'fixed';

              // 2. If the fee is related to this course
              const isRelatedToCourse =
                String(fee.mandatoryCourseCampus_id) ===
                String(event.data.item.courseCampus_id);

              if ((!isntFixed && !isRelatedToCourse) || !isRelatedToCourse) {
                staticFees.push(fee);
              }
            }

            /** *
             * Now we re-request the mandatory rules for the selected Course
             */
            const {
              suggestiveFees,
              mandatoryFees,
            } = await getFeesUsingMandatoryRule(
              quotesData.student,
              selectedQuote,
              event.data.item,
              filtersOptions.FILTERS_COURSES
            );

            // ...suggestiveFees will be added via modal
            let auxSuggestiveFees: any[] = [];
            if (suggestiveFees.length > 0) {
              setIsSuggestiveFeesOpened(true);
              myNewSuggestions = {};
              mutexForSuggestion = new Promise(t => {
                unlockMutex = (dt: Map) => t(dt);
              });

              const suggestiveFeesMap = await mutexForSuggestion;
              auxSuggestiveFees = Object.values(suggestiveFeesMap);
            }

            /* Update the selected Quote to be saved */
            selectedQuote.fees = [
              ...staticFees,
              ...auxSuggestiveFees,
              ...mandatoryFees,
            ];

            selectedQuote.totalsByCurrency = calculateTotalsByCurrency(
              selectedQuote
            );
            selectedQuote.totalConverted = await calculateConvertedValue(
              selectedQuote,
              index
            );
            selectedQuote.totalConverted.valueFormatted = formatValue(
              selectedQuote.totalConverted.value,
              selectedQuote.totalConverted.currency_code
            );
            selectedQuote.totalConverted.value_without_srFormatted = formatValue(
              selectedQuote.totalConverted.value_without_sr,
              selectedQuote.totalConverted.currency_code
            );

            const { quoteCurrencies } = await saveQuote(selectedQuote);

            selectedQuote.quoteCurrencies = quoteCurrencies;
            selectedQuote.quoteCurrencies.forEach((currency: any) => {
              currency.valueFormatted = formatValue(
                currency.value,
                currency.destiny_code
              );
            });

            newQuotes.push(selectedQuote);
          }, Promise.resolve());

          if (!shouldProceed) {
            return;
          }

          /*
           Now we'll update the quote fees
          */

          setMainLoading(true);

          // That will be necessary to avoid unexpected behaviors with
          // asynchronous  code.
          const staticFees = [];
          for (let i = 0; i < event.data.quote.fees.length; i++) {
            const fee = event.data.quote.fees[i];
            // Checkings:
            // 1. If the fee isn't fixed.
            const isntFixed = fee.pricePeriod !== 'fixed';

            // 2. If the fee is related to this course
            const isRelatedToCourse =
              String(fee.mandatoryCourseCampus_id) ===
              String(event.data.item.courseCampus_id);

            if ((!isntFixed && !isRelatedToCourse) || !isRelatedToCourse) {
              staticFees.push(fee);
            }
          }

          /* Updates newQuotes with the new SelectedQuote */
          const updateQuoteList = newQuotes.map(qt => {
            if (qt.id === selectedQuote.id) return selectedQuote;
            return qt;
          });

          // selectedQuote.totalsByCurrency = calculateTotalsByCurrency(
          //   selectedQuote
          // );
          // selectedQuote.totalConverted = await calculateConvertedValue(
          //   selectedQuote,
          //   index
          // );
          // selectedQuote.totalConverted.valueFormatted = formatValue(
          //   selectedQuote.totalConverted.value,
          //   selectedQuote.totalConverted.currency_code
          // );
          // selectedQuote.totalConverted.value_without_srFormatted = formatValue(
          //   selectedQuote.totalConverted.value_without_sr,
          //   selectedQuote.totalConverted.currency_code
          // );

          // const { quoteCurrencies } = await saveQuote(selectedQuote);

          // selectedQuote.quoteCurrencies = quoteCurrencies;
          // selectedQuote.quoteCurrencies.forEach((currency: any) => {
          //   currency.valueFormatted = formatValue(
          //     currency.value,
          //     currency.destiny_code
          //   );
          // });

          newQuotes.push(selectedQuote);
          /* Saves the most recent version on ContextAPI */
          const formData = {
            ...quotesData,
            quotes: updateQuoteList,
          };
          updateQuotesData(formData);

          /* Disabling the loading */
          setMainLoading(false);
        } else if (event.data.action === 'open-edit-program-price-modal') {
          const shouldProceed = true;
          const { quotes } = quotesData;

          const newQuotes: any[] = [];

          // There's no quotes availables
          if (!quotes) return;

          let selectedQuote: QuoteType = quotes[0]; // default value
          await quotes.reduce(async (promise, quote, index) => {
            await promise;

            if (index !== event.data.index) {
              newQuotes.push(quote);
              return;
            }

            selectedQuote = _.cloneDeep(quote) as QuoteType;

            selectedQuote.empty = false;

            selectedQuote.courses.forEach((course: any) => {
              if (course.coursecampus.id === event.data.item.courseCampus_id) {
                course.valueFormatted = formatValue(
                  event.data.item.coursecampus.totalThisCourse.onlyCourse,
                  course.coursecampus.campus.currency_code
                );
                course.oldValueFormatted = event.data.item.totalThisCourse
                  .oldValue
                  ? formatValue(
                      event.data.item.totalThisCourse.oldValue,
                      course.coursecampus.campus.currency_code
                    )
                  : undefined;
                course.enrolFormatted = formatValue(
                  event.data.item.totalThisCourse.onlyEnrol,
                  course.coursecampus.campus.currency_code
                );
                course.materialFormatted = formatValue(
                  event.data.item.totalThisCourse.onlyMaterial,
                  course.coursecampus.campus.currency_code
                );
                course.subtotalFormatted = formatValue(
                  event.data.item.totalThisCourse.value,
                  course.coursecampus.campus.currency_code
                );

                if (event.data.item.typePrice === 'tuition') {
                  course.editedTuition = 1;
                  course.discountTuition = event.data.item.discountTuition;
                } else if (event.data.item.typePrice === 'enrol') {
                  course.editedEnrol = 1;
                  course.discountEnrol = event.data.item.discountEnrol;
                } else if (event.data.item.typePrice === 'material') {
                  course.editedMaterial = 1;
                  course.discountMaterial = event.data.item.discountMaterial;
                }

                // if (
                //   course.coursecampus.duration === event.data.item.duration &&
                //   course.coursecampus.startDate === formattedStartDate &&
                //   course.coursecampus.endDate === formattedEndDate
                // ) {
                //   shouldProceed = false;
                //   return;
                // }

                course.coursecampus.specialrate = event.data.item.specialrate;
                course.coursecampus.price = event.data.item.price;
                course.coursecampus.onlyCourse =
                  event.data.item.totalThisCourse.onlyCourse;
                course.coursecampus.onlyCourseWithoutSR =
                  event.data.item.totalThisCourse.onlyCourseWithoutSR;
                course.coursecampus.onlyEnrolWithoutSR =
                  event.data.item.totalThisCourse.onlyEnrolWithoutSR;
                course.coursecampus.onlyMaterialWithoutSR =
                  event.data.item.totalThisCourse.onlyMaterialWithoutSR;
                course.coursecampus.onlyEnrol =
                  event.data.item.totalThisCourse.onlyEnrol;
                course.coursecampus.onlyMaterial =
                  event.data.item.totalThisCourse.onlyMaterial;
                course.coursecampus.value =
                  event.data.item.totalThisCourse.value;
                course.coursecampus.valueWithoutSR =
                  event.data.item.totalThisCourse.valueWithoutSR;
                course.coursecampus.totalThisCourse =
                  event.data.item.totalThisCourse;
              }
            });

            if (!shouldProceed) {
              newQuotes.push(quote);
              return;
            }

            /*
            Now we'll update the quote fees
            */

            setMainLoading(true);

            selectedQuote.totalsByCurrency = calculateTotalsByCurrency(
              selectedQuote
            );
            selectedQuote.totalConverted = await calculateConvertedValue(
              selectedQuote,
              index
            );
            selectedQuote.totalConverted.valueFormatted = formatValue(
              selectedQuote.totalConverted.value,
              selectedQuote.totalConverted.currency_code
            );
            selectedQuote.totalConverted.value_without_srFormatted = formatValue(
              selectedQuote.totalConverted.value_without_sr,
              selectedQuote.totalConverted.currency_code
            );

            newQuotes.push(selectedQuote);
          }, Promise.resolve());

          if (!shouldProceed) {
            return;
          }

          setMainLoading(true);

          /* Updates newQuotes with the new SelectedQuote */
          const updateQuoteList = newQuotes.map(qt => {
            if (qt.id === selectedQuote.id) return selectedQuote;
            return qt;
          });

          newQuotes.push(selectedQuote);
          /* Saves the most recent version on ContextAPI */
          const formData = {
            ...quotesData,
            quotes: updateQuoteList,
          };
          updateQuotesData(formData);

          /* Disabling the loading */
          setMainLoading(false);
        } else if (
          event.data.action === 'open-edit-accommodation-price-modal'
        ) {
          const shouldProceed = true;
          const { quotes } = quotesData;

          const newQuotes: any[] = [];

          // There's no quotes availables
          if (!quotes) return;

          let selectedQuote: QuoteType = quotes[0]; // default value

          await quotes.reduce(async (promise, quote, index) => {
            await promise;

            if (index !== event.data.index) {
              newQuotes.push(quote);
              return;
            }

            selectedQuote = _.cloneDeep(quote) as QuoteType;

            selectedQuote.empty = false;

            selectedQuote.accommodations.forEach((accommodation: any) => {
              if (accommodation.id === event.data.item.id) {
                accommodation.valueFormatted = formatValue(
                  event.data.item.totalThisAccommodation.onlyAccommodation,
                  accommodation.currency_code
                );
                accommodation.oldValueFormatted = event.data.item
                  .totalThisAccommodation.oldValue
                  ? formatValue(
                      event.data.item.totalThisAccommodation.oldValue,
                      accommodation.currency_code
                    )
                  : undefined;
                accommodation.enrolFormatted = formatValue(
                  event.data.item.totalThisAccommodation.onlyEnrol,
                  accommodation.currency_code
                );
                accommodation.subtotalFormatted = formatValue(
                  event.data.item.totalThisAccommodation.value,
                  accommodation.currency_code
                );

                if (event.data.item.typePrice === 'accommodation') {
                  accommodation.editedAccommodation = 1;
                  accommodation.discountAccommodation =
                    event.data.item.discountAccommodation * 1;
                } else if (event.data.item.typePrice === 'placementfee') {
                  accommodation.editedPlacementFee = 1;
                  accommodation.discountPlacementFee =
                    event.data.item.discountPlacementFee;
                } else if (event.data.item.typePrice === 'material') {
                  accommodation.editedMaterial = 1;
                  accommodation.discountMaterial =
                    event.data.item.discountMaterial;
                }

                accommodation.specialrate = event.data.item.specialrate;
                accommodation.price = event.data.item.price;
                accommodation.onlyAccommodation =
                  event.data.item.totalThisAccommodation.onlyAccommodation;
                accommodation.onlyAccommodationWithoutSR =
                  event.data.item.totalThisAccommodation.onlyAccommodationWithoutSR;
                accommodation.onlyEnrolWithoutSR =
                  event.data.item.totalThisAccommodation.onlyEnrolWithoutSR;
                accommodation.onlyMaterialWithoutSR =
                  event.data.item.totalThisAccommodation.onlyMaterialWithoutSR;
                accommodation.onlyEnrol =
                  event.data.item.totalThisAccommodation.onlyEnrol;
                accommodation.onlyMaterial =
                  event.data.item.totalThisAccommodation.onlyMaterial;
                accommodation.value =
                  event.data.item.totalThisAccommodation.value;
                accommodation.valueWithoutSR =
                  event.data.item.totalThisAccommodation.valueWithoutSR;
                accommodation.totalThisAccommodation =
                  event.data.item.totalThisAccommodation;
              }
            });

            if (!shouldProceed) {
              newQuotes.push(quote);
              return;
            }

            setMainLoading(true);

            selectedQuote.totalsByCurrency = calculateTotalsByCurrency(
              selectedQuote
            );
            selectedQuote.totalConverted = await calculateConvertedValue(
              selectedQuote,
              index
            );
            selectedQuote.totalConverted.valueFormatted = formatValue(
              selectedQuote.totalConverted.value,
              selectedQuote.totalConverted.currency_code
            );
            selectedQuote.totalConverted.value_without_srFormatted = formatValue(
              selectedQuote.totalConverted.value_without_sr,
              selectedQuote.totalConverted.currency_code
            );

            newQuotes.push(selectedQuote);
          }, Promise.resolve());

          if (!shouldProceed) {
            return;
          }

          setMainLoading(true);

          /* Updates newQuotes with the new SelectedQuote */
          const updateQuoteList = newQuotes.map(qt => {
            if (qt.id === selectedQuote.id) return selectedQuote;
            return qt;
          });

          newQuotes.push(selectedQuote);
          /* Saves the most recent version on ContextAPI */
          const formData = {
            ...quotesData,
            quotes: updateQuoteList,
          };
          updateQuotesData(formData);

          /* Disabling the loading */
          setMainLoading(false);
        } else if (event.data.action === 'open-edit-fee-price-modal') {
          const shouldProceed = true;
          const { quotes } = quotesData;

          const newQuotes: any[] = [];

          // There's no quotes availables
          if (!quotes) return;

          let selectedQuote: QuoteType = quotes[0]; // default value

          await quotes.reduce(async (promise, quote, index) => {
            await promise;

            if (index !== event.data.index) {
              newQuotes.push(quote);
              return;
            }

            selectedQuote = _.cloneDeep(quote) as QuoteType;

            selectedQuote.empty = false;

            selectedQuote.fees.forEach((fee: any) => {
              if (fee.id === event.data.item.id) {
                fee.valueFormatted = formatValue(
                  event.data.item.totalThisFee.value,
                  fee.currency_code
                );
                fee.oldValueFormatted = event.data.item.totalThisFee.oldValue
                  ? formatValue(
                      event.data.item.totalThisFee.oldValue,
                      fee.currency_code
                    )
                  : undefined;
                fee.enrolFormatted = formatValue(
                  event.data.item.totalThisFee.onlyEnrol,
                  fee.currency_code
                );
                fee.subtotalFormatted = formatValue(
                  event.data.item.totalThisFee.value,
                  fee.currency_code
                );

                if (event.data.item.typePrice === 'fee') {
                  fee.editedFee = 1;
                  fee.discountFee = event.data.item.discountFee * 1;
                } else if (event.data.item.typePrice === 'placementfee') {
                  fee.editedPlacementFee = 1;
                  fee.discountPlacementFee =
                    event.data.item.discountPlacementFee;
                } else if (event.data.item.typePrice === 'material') {
                  fee.editedMaterial = 1;
                  fee.discountMaterial = event.data.item.discountMaterial;
                }

                fee.specialrate = event.data.item.specialrate;
                fee.price = event.data.item.price;
                fee.value = event.data.item.totalThisFee.value;
                fee.valueWithoutSR =
                  event.data.item.totalThisFee.valueWithoutSR;
                fee.totalThisFee = event.data.item.totalThisFee;
              }
            });

            if (!shouldProceed) {
              newQuotes.push(quote);
              return;
            }

            setMainLoading(true);

            selectedQuote.totalsByCurrency = calculateTotalsByCurrency(
              selectedQuote
            );
            selectedQuote.totalConverted = await calculateConvertedValue(
              selectedQuote,
              index
            );
            selectedQuote.totalConverted.valueFormatted = formatValue(
              selectedQuote.totalConverted.value,
              selectedQuote.totalConverted.currency_code
            );
            selectedQuote.totalConverted.value_without_srFormatted = formatValue(
              selectedQuote.totalConverted.value_without_sr,
              selectedQuote.totalConverted.currency_code
            );

            newQuotes.push(selectedQuote);
          }, Promise.resolve());

          if (!shouldProceed) {
            return;
          }

          setMainLoading(true);

          /* Updates newQuotes with the new SelectedQuote */
          const updateQuoteList = newQuotes.map(qt => {
            if (qt.id === selectedQuote.id) return selectedQuote;
            return qt;
          });

          newQuotes.push(selectedQuote);
          /* Saves the most recent version on ContextAPI */
          const formData = {
            ...quotesData,
            quotes: updateQuoteList,
          };
          updateQuotesData(formData);

          /* Disabling the loading */
          setMainLoading(false);
        } else if (event.data.action === 'open-edit-accommodation-date-modal') {
          const shouldProceed = true;
          const { quotes } = quotesData;

          const newQuotes: any[] = [];

          if (!quotes) return;

          let selectedQuote: QuoteType = quotes[0]; // default value
          await quotes.reduce(async (promise, quote, index) => {
            await promise;

            if (index !== event.data.index) {
              newQuotes.push(quote);
              return;
            }

            selectedQuote = _.cloneDeep(quote) as QuoteType;

            selectedQuote.empty = false;

            selectedQuote.accommodations.forEach((accommodation: any) => {
              if (accommodation.id === event.data.item.accommodation_id) {
                accommodation.duration = event.data.item.accommodation_duration;
                accommodation.startDate = format(
                  parseISO(event.data.item.startDate),
                  'dd/MM/yyyy'
                );
                accommodation.price = event.data.item.price;
                accommodation.specialrate = event.data.item.specialrate;
                accommodation.discountPlacementFee = 0;
                accommodation.discountAccommodation = 0;
                accommodation.editedPlacementFee = 0;
                accommodation.editedAccommodation = 0;

                accommodation.oldValueFormatted = event.data.item
                  .totalThisAccommodation.oldValue
                  ? formatValue(
                      event.data.item.totalThisAccommodation.oldValue,
                      accommodation.currency_code
                    )
                  : undefined;
                accommodation.startDateFormatted = format(
                  parseISO(event.data.item.startDate),
                  'LLLL dd, yyyy'
                );
                accommodation.endDate = format(
                  parseISO(event.data.item.endDate),
                  'dd/MM/yyyy'
                );
                accommodation.endDateFormatted = format(
                  parseISO(event.data.item.endDate),
                  'LLLL dd, yyyy'
                );
                accommodation.totalThisAccommodation =
                  event.data.item.totalThisAccommodation;

                accommodation.valueFormatted = formatValue(
                  event.data.item.totalThisAccommodation.onlyAccommodation,
                  event.data.item.currency_code
                );

                accommodation.subtotalFormatted = formatValue(
                  event.data.item.totalThisAccommodation.value,
                  event.data.item.currency_code
                );
              }
            });

            const canProcced = await validateValueInteractions(selectedQuote);
            if (!canProcced) {
              newQuotes.push(quote);
              return;
            }

            selectedQuote.totalsByCurrency = calculateTotalsByCurrency(
              selectedQuote
            );
            selectedQuote.totalConverted = await calculateConvertedValue(
              selectedQuote,
              index
            );
            selectedQuote.totalConverted.valueFormatted = formatValue(
              selectedQuote.totalConverted.value,
              selectedQuote.totalConverted.currency_code
            );
            selectedQuote.totalConverted.value_without_srFormatted = formatValue(
              selectedQuote.totalConverted.value_without_sr,
              selectedQuote.totalConverted.currency_code
            );

            const { quoteCurrencies } = await saveQuote(selectedQuote);

            selectedQuote.quoteCurrencies = quoteCurrencies;
            selectedQuote.quoteCurrencies.forEach((currency: any) => {
              currency.valueFormatted = formatValue(
                currency.value,
                currency.destiny_code
              );
            });

            newQuotes.push(selectedQuote);
          }, Promise.resolve());

          /*
           Now we'll update the quote fees
          */

          setMainLoading(true);

          // First we isolate the static fees
          const staticFees = [];
          for (let i = 0; i < event.data.quote.fees.length; i++) {
            const fee = event.data.quote.fees[i];
            // Checkings:
            // 1. If the fee isn't fixed.
            const isntFixed = fee.pricePeriod !== 'fixed';

            // 2. If the fee is related to this accommodation
            const isRelatedToAccommodation =
              fee.mandatoryAccommodation_id ===
              event.data.item.accommodation_id;

            if (
              (!isntFixed && !isRelatedToAccommodation) ||
              !isRelatedToAccommodation
            ) {
              staticFees.push(fee);
            }
          }

          // Now we re-request the mandatory rules for the selected accomdation
          const {
            suggestiveFees,
            mandatoryFees,
          } = await getFeesUsingMandatoryRule(
            quotesData.student,
            selectedQuote,
            event.data.item,
            filtersOptions.FILTERS_ACCOMMODATIONS,
            event.data.item.accommodation_id
          );

          // ...suggestiveFees will be added via modal
          let auxSuggestiveFees: any[] = [];
          if (suggestiveFees.length > 0) {
            setIsSuggestiveFeesOpened(true);
            myNewSuggestions = {};
            mutexForSuggestion = new Promise(t => {
              unlockMutex = (dt: Map) => t(dt);
            });

            const suggestiveFeesMap = await mutexForSuggestion;
            auxSuggestiveFees = Object.values(suggestiveFeesMap);
          }

          /* Update the selected Quote to be saved */
          selectedQuote.fees = [
            ...staticFees,
            ...auxSuggestiveFees,
            ...mandatoryFees,
          ];

          /* Updates newQuotes with the new SelectedQuote */
          const updateQuoteList = newQuotes.map(qt => {
            if (qt.id === selectedQuote.id) return selectedQuote;
            return qt;
          });

          /* Saves the most recent version on ContextAPI */
          const formData = {
            ...quotesData,
            quotes: updateQuoteList,
          };
          updateQuotesData(formData);

          /* Disabling the loading */
          setMainLoading(false);
        } else if (event.data.action === 'open-edit-quote-description-modal') {
          const { quotes } = quotesData;

          const newQuotes: any[] = [];

          if (!quotes) return;

          await quotes.reduce(async (promise, quote, index) => {
            await promise;

            if (index !== event.data.index) {
              newQuotes.push(quote);
              return;
            }

            const selectedQuote = _.cloneDeep(quote);

            selectedQuote.description = event.data.item.description;

            newQuotes.push(selectedQuote);
          }, Promise.resolve());

          const formData = {
            ...quotesData,
            quotes: newQuotes,
          };

          updateQuotesData(formData);
        } else if (event.data.action === 'open-edit-fee-duration-modal') {
          await handleOpenEditFeeDurationModal(event);
        }
      }
    },
    [
      quotesData,
      updateQuotesData,
      validateValueInteractions,
      calculateTotalsByCurrency,
      calculateConvertedValue,
      saveQuote,
      getFeesUsingMandatoryRule,
      handleOpenEditFeeDurationModal,
    ]
  );

  const onCloseModalOnEdition = useCallback(() => {
    setIsSuggestiveFeesOpened(false);
    unlockMutex(myNewSuggestions);
  }, []);

  const filterAllProviders = (id = 0) => {
    const hasCategory = id !== 0;

    const providerOptions = partnerOptions
      .map(partner => {
        const isAccommodationOrInsuranceProvider =
          hasCategory && partner.category_id === id;

        const isAddOnProvider =
          !hasCategory &&
          partner.category_id !== 4 &&
          partner.category_id !== 1;

        if (!isAccommodationOrInsuranceProvider && !isAddOnProvider) return {};

        return {
          value: partner.value,
          label: partner.label,
          provider: 1,
        };
      })
      .filter(
        partner => typeof partner.value !== 'undefined'
      ) as PartnerOption[];

    const responseOptions = [...providerOptions, ...schoolOptions];

    orderByLabel(responseOptions);

    return responseOptions;
  };

  const onAddingSugestionsOnEdition = useCallback(
    (data: ListItemFormData) => {
      const newList = editingItemSuggestiveFees.map(fee => {
        if (String(fee.id) === String(data.id))
          return { ...fee, wasAdded: true };
        return { ...fee };
      });

      setEditingItemSuggestiveFees(newList);

      const { id, data: item } = data;

      const jsonItem = JSON.parse(String(item));

      if (!myNewSuggestions[id]) {
        myNewSuggestions[id] = jsonItem;
      }
    },
    [editingItemSuggestiveFees]
  );

  const handleInputRangeChange = useCallback((range: PriceRange) => {
    setPriceRange(range);
  }, []);

  const handleChangeQuoteSaleType = useCallback(
    async (index: number, quoteId: number, saleType?: any) => {
      quotesData.quotes?.forEach(async (quote, idx) => {
        if (index === idx) {
          quote.saleType = {
            id: saleType?.value,
            name: saleType?.label,
          };

          await saveQuote(quote);
        }
      });
    },
    [quotesData.quotes, saveQuote]
  );

  const handleAddOption = useCallback(async () => {
    setIsAddOptionLoading(true);

    try {
      if (quotesData.quotes?.length === 5) {
        throw new Error('You can only add up to 5 options in a quote.');
      }

      const {
        quotes,
        mandatoryFees,
        suggestiveFees,
        student,
        draft_id,
      } = quotesData;

      const feesToAdd: any[] = [];

      mandatoryFees?.forEach((fee: any) => {
        const feeToAdd = fee;
        if (!feeToAdd.mandatoryFromSchool) {
          feesToAdd.push(feeToAdd);
        }
      });

      const quoteToAdd: any = {
        empty: false,
        school_id: '',
        campus_id: '',
        courses: [],
        accommodations: [],
        fees: feesToAdd,
        experiences: [],
        otherfees: [],
        paymentplan: [],
      };

      quoteToAdd.totalsByCurrency = !quoteToAdd.empty
        ? calculateTotalsByCurrency(quoteToAdd)
        : [];

      const dueDate =
        quotes?.[0]?.dueDate ||
        format(
          add(new Date(), { days: user.office.quoteDuration }),
          'yyyy-MM-dd'
        );

      const { data: opportunity } = await api.get(`/student/${student.id}`, {
        params: {
          lastopportunity: true,
        },
      });

      const res = await api.post('/quote', {
        timelog: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
        dueDate: format(parseISO(dueDate), 'dd/MM/yyyy'),
        description: defaultDescription,
        opportunity_id: quotes?.[0]?.opportunity_id || opportunity.id,
        chosenCourses: quoteToAdd.courses,
        newAccommodations: quoteToAdd.accommodations,
        newExperiences: quoteToAdd.experiences,
        newFees: quoteToAdd.fees,
        newOtherfees: quoteToAdd.otherfees,
        quoteValues: quoteToAdd.totalsByCurrency,
        newQuote: true,
        plugAndPlay: 0,
        iof: quotes?.[0]?.iof || null,
        school_id: '',
        campus_id: '',
        draft_id,
      });

      quoteToAdd.saleType = saleTypeOptions?.find(option => option.main === 1);
      if (quoteToAdd.saleType) {
        quoteToAdd.saleType.id = quoteToAdd.saleType.value;
        quoteToAdd.saleType.name = quoteToAdd.saleType.label;
        quoteToAdd.quoteSaleType = quoteToAdd.saleType;
        const ind = quotesData.quotes?.length ? quotesData.quotes?.length : 0;
        handleChangeQuoteSaleType(ind, res.data.id, quoteToAdd.saleType);
      }
      quoteToAdd.id = res.data.id;
      quoteToAdd.iof = res.data.iof;
      quoteToAdd.iepayment = res.data.iepayment;
      quoteToAdd.school_id = '';
      quoteToAdd.campus_id = '';
      quoteToAdd.dueDate = res.data.dueDate;
      quoteToAdd.language = res.data.language;
      quoteToAdd.officeCount = res.data.officeCount;
      quoteToAdd.plugAndPlay = res.data.plugAndPlay;
      quoteToAdd.opportunity_id = res.data.opportunity_id;
      quoteToAdd.convertedCurrency = user.office.currency;

      if (!quoteToAdd.empty) {
        quoteToAdd.totalConverted = await calculateConvertedValue(quoteToAdd);
        quoteToAdd.totalConverted.valueFormatted = formatValue(
          quoteToAdd.totalConverted.value,
          quoteToAdd.totalConverted.currency_code
        );
        quoteToAdd.totalConverted.value_without_srFormatted = formatValue(
          quoteToAdd.totalConverted.value_without_sr,
          quoteToAdd.totalConverted.currency_code
        );

        await api.post('/quotecurrency', {
          data: quoteToAdd.totalConverted
            ? quoteToAdd.totalConverted.rules
            : [],
          quote_id: quoteToAdd.id,
          plugAndPlay: isUserAllyPlus ? 1 : 0,
        });

        quoteToAdd.quoteCurrencies = res.data.quoteCurrencies || [];

        quoteToAdd.quoteCurrencies?.forEach((currency: any) => {
          currency.valueFormatted = formatValue(
            currency.value,
            currency.destiny_code
          );
        });
      }

      quotes?.push(quoteToAdd);

      const formData = {
        howManyQuotes: (quotesData.howManyQuotes as number) + 1,
        draft_id,
        quotes,
        student,
        suggestiveFees,
        mandatoryFees,
      };

      updateQuotesData(formData);
      if (quotesData.quotes?.length === 5) {
        setFiveOptions(true);
      }
    } catch (err: any) {
      addToast({
        title: 'Error',
        type: 'error',
        description: err.message || 'Something went wrong',
      });
    } finally {
      setIsAddOptionLoading(false);
    }
  }, [
    quotesData,
    calculateTotalsByCurrency,
    user.office.quoteDuration,
    user.office.currency,
    defaultDescription,
    saleTypeOptions,
    updateQuotesData,
    handleChangeQuoteSaleType,
    calculateConvertedValue,
    addToast,
    isUserAllyPlus,
  ]);

  const handleUpdateQuoteConfigPreferences = useCallback(
    async (index: number, quoteId: number, type: string, newValue: boolean) => {
      setResumeActionLoading({ index, loading: true });

      const { data: quoteConfig } = await api.post(
        `/quoteconfig?quote_id=${quoteId}`,
        {
          changeShowdiscount: type === 'showdiscount' ? true : undefined,
          showdiscount: type === 'showdiscount' ? newValue : undefined,
          changeShowaddition: type === 'showaddition' ? true : undefined,
          showaddition: type === 'showaddition' ? newValue : undefined,
          changeShowspecialrate: type === 'showspecialrate' ? true : undefined,
          showspecialrate: type === 'showspecialrate' ? newValue : undefined,
          changeShowduedate: type === 'showduedate' ? true : undefined,
          showduedate: type === 'showduedate' ? newValue : undefined,
          changeShowcurrency: type === 'showcurrency' ? true : undefined,
          showcurrency: type === 'showcurrency' ? newValue : undefined,
          changeShowsubtotals: type === 'showsubtotals' ? true : undefined,
          showsubtotals: type === 'showsubtotals' ? newValue : undefined,
        }
      );

      const { quotes } = quotesData;

      const newQuotes: any[] = [];

      if (!quotes) return;

      await quotes.reduce(async (promise, quote, idx) => {
        await promise;

        if (idx !== index) {
          newQuotes.push(quote);
          return;
        }

        const selectedQuote = _.cloneDeep(quote);

        if (selectedQuote.config) {
          selectedQuote.config[type] = newValue ? 1 : 0;
          if (type !== 'showsubtotals') {
            selectedQuote.config['showsubtotals'] = quoteConfig.showsubtotals
              ? 1
              : 0;
          }
        } else {
          selectedQuote.config = quoteConfig;
        }

        newQuotes.push(selectedQuote);
      }, Promise.resolve());

      setResumeActionLoading({ index, loading: false });

      const formData = {
        ...quotesData,
        quotes: newQuotes,
      };

      updateQuotesData(formData);
    },
    [quotesData, updateQuotesData]
  );

  const handleChangeQuoteDueDate = useCallback(
    async (index: number, quoteId: number, newDueDate: any) => {
      quotesData.quotes?.forEach(async (quote, idx) => {
        if (index === idx) {
          quote.dueDate = format(parseJSON(newDueDate), 'yyyy-MM-dd');

          const { dueDate } = await saveQuote(quote);

          quote.dueDate = dueDate;
        }
      });
    },
    [quotesData.quotes, saveQuote]
  );

  const handleRenameQuote = useCallback(
    async (index: number, quoteId: number, newName: string) => {
      try {
        const { data: quoteConfig } = await api.post(`/quoteconfig`, {
          quote_id: quoteId,
          changeName: true,
          name: newName,
        });

        quotesData.quotes?.forEach((quote, idx) => {
          if (index !== idx) return;
          if (quote.config) {
            quote.config.name = newName;
          } else {
            quote.config = quoteConfig;
          }
        });

        updateQuotesData(quotesData);
      } catch (e) {
        addToast({
          type: 'error',
          title: 'Error',
          description: 'An error has occurred, please try again.',
        });
      }
    },
    [quotesData, updateQuotesData, addToast]
  );

  const handleChangeQuoteTranslation = useCallback(
    async (index: number, quoteId: number, newLanguage: string) => {
      try {
        await api.put(`/quote/${quoteId}`, {
          changeLanguage: true,
          language: newLanguage,
        });

        quotesData.quotes?.forEach(async (quote, idx) => {
          if (index === idx) {
            quote.language = newLanguage;
          }
        });
      } catch (e) {
        addToast({
          type: 'error',
          title: 'Error',
          description: 'An error has occurred, please try again.',
        });
      }
    },
    [quotesData.quotes, addToast]
  );

  const handleDuplicateQuote = useCallback(
    async (index: number, quoteId: number) => {
      try {
        const {
          quotes,
          mandatoryFees,
          suggestiveFees,
          student,
          draft_id,
        } = quotesData;

        if (quotes?.length === 5) {
          throw new Error('You can only add up to 5 options in a quote.');
        }

        const res = await api.put(`/quote/${quoteId}`, {
          duplicateQuote: true,
          draft_id,
          student_id: student.id,
        });

        let clonedQuote: any;
        quotesData.quotes?.forEach(async (quote, idx) => {
          if (index === idx) {
            clonedQuote = _.cloneDeep(quote);
          }
        });

        if (!clonedQuote) return;

        clonedQuote.id = res.data.id;
        clonedQuote.officeCount = res.data.officeCount;
        clonedQuote.plugAndPlay = res.data.plugAndPlay;

        quotes?.push(clonedQuote);

        const formData = {
          howManyQuotes: (quotesData.howManyQuotes as number) + 1,
          draft_id,
          quotes,
          student,
          mandatoryFees,
          suggestiveFees,
        };

        updateQuotesData(formData);
      } catch (err: any) {
        addToast({
          type: 'error',
          title: 'Error',
          description:
            err.message || 'An error has occurred, please try again.',
        });
      }
    },
    [quotesData, updateQuotesData, addToast]
  );

  const handleDeleteQuote = useCallback(
    async (index: number, quoteId: number) => {
      try {
        const {
          quotes,
          mandatoryFees,
          suggestiveFees,
          student,
          draft_id,
        } = quotesData;

        await api.put(`quote/${quoteId}`, {
          status: 'delete',
          dateTime: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
          timelog: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
          draft_id,
        });

        quotes?.splice(index, 1);

        const formData = {
          howManyQuotes: (quotesData.howManyQuotes as number) - 1,
          draft_id,
          quotes,
          student,
          mandatoryFees,
          suggestiveFees,
        };

        updateQuotesData(formData);
        setFiveOptions(false);
      } catch (e) {
        addToast({
          type: 'error',
          title: 'Error',
          description: 'An error has occurred, please try again.',
        });
      }
    },
    [quotesData, updateQuotesData, addToast]
  );

  if (quotesData.quotes && quotesData.quotes?.length > 0) {
    quotesData.quotes.sort((quoteOne: any, quoteTwo: any) => {
      return quoteOne.id < quoteTwo.id ? -1 : 1;
    });
  }

  const saveDraftsAndRedirect = useCallback(async () => {
    const { draft_id } = quotesData;
    try {
      await api.put(`draft/${draft_id}`, {
        finish: true,
      });

      const message = {
        action: 'finish-and-redirect',
        studentId: quotesData.student.id,
        origin: process.env.REACT_APP_URL as string,
      };

      angularPort?.postMessage(message);
    } catch (e) {
      addToast({
        type: 'error',
        title: 'Error',
        description: 'An error has occurred, please try again.',
      });
    } finally {
      setIsSaveQuotesLoading(false);
    }
  }, [addToast, angularPort, quotesData]);

  const handleFinishAndSaveQuotes = useCallback(async () => {
    setIsSaveQuotesLoading(true);
    const { quotes } = quotesData;

    if (!quotes) return;

    const noPaymentPlanQuote = quotes.some(
      quote => quote.paymentplan.length < 1 && quote.plugAndPlay === 0
    );

    let noSaleType = false;
    if (officeConfig) {
      if (officeConfig.useSaleTypeRequired === 1) {
        noSaleType = quotes.some(quote => quote.saleType?.id === undefined);
      }
    }

    if (noSaleType) {
      addToast({
        title: 'Error',
        description:
          'Sales Types are mandatory, please add it on Advanced Options',
        type: 'error',
      });
      setIsSaveQuotesLoading(false);
      // eslint-disable-next-line consistent-return
      return false;
    }

    let title = 'Atenção';
    let text =
      'Estou ciente que revisei todos os valores inseridos nesse orçamento, antes de enviar para o meu cliente';
    let buttons = ['Não, cancelar ação!', 'Sim, prosseguir e salvar!'];
    if (user.language === 'en-US') {
      text =
        'I am aware that I reviewed all the values ​​entered in this quote before sending it to my client';
      buttons = ['No, I want to change!', 'Yes, finish and save!'];
      title = 'Attention';
    }
    if (!isIEAccount) {
      await swal({
        title,
        text,
        icon: 'warning',
        buttons,
      }).then(async confirmed => {
        if (confirmed) {
          saveDraftsAndRedirect();
        } else {
          setIsSaveQuotesLoading(false);
        }
      });
    } else saveDraftsAndRedirect();
  }, [
    quotesData,
    officeConfig.useSaleTypeRequired,
    user.language,
    isIEAccount,
    saveDraftsAndRedirect,
    addToast,
  ]);

  const handleSuggestionFeeDurationChange = useCallback(
    data => {
      const { id, duration } = data;

      const insuranceAffectedKeys = ['duration', 'price', 'totalThisFee'];

      api
        .get(`/feeprice/${id}`, {
          params: {
            country: quotesData.student.nationalityCountry,
            duration,
            enrolDate: format(parseJSON(dateOfReference), 'dd/MM/yyyy'),
          },
        })
        .then(response => {
          if (!response.data.price) return;

          const suggestiveFees = editingItemSuggestiveFees?.map(result => {
            if (result.id === Number(id)) {
              insuranceAffectedKeys.forEach(key => {
                result[key] = response.data[key];
              });
            }
            return result;
          });

          setEditingItemSuggestiveFees(suggestiveFees);
        });
    },
    [
      dateOfReference,
      editingItemSuggestiveFees,
      quotesData.student.nationalityCountry,
    ]
  );

  const handleShowAvailableValuesToggle = useCallback(() => {
    showAvailableValues.current = !showAvailableValues.current;
    handleSearchClick();
  }, [handleSearchClick]);

  const handleHideAllyPlusItems = useCallback(
    async (hide: boolean) => {
      await api.post(`/userpreference`, {
        changeAllyplus: 1,
        allyplus: hide ? 0 : 1,
      });

      handleSearchClick();

      setViewAllyPlusOption(hide);
      userPreferenceEdited.current = true;
      hideResultAllyPlus.current = hide;
    },
    [handleSearchClick, hideResultAllyPlus]
  );

  const closeSuggestiveFeesModal = useCallback(() => {
    setOpenSuggestiveFeeModal(false);
    setClearSuggestiveFee(true);
  }, []);

  useEffect(() => {
    if (cantOpenSuggestiveFee) {
      handleAddItemToQuoteSubmit({
        quotes: editingQuoteOptions,
      });
      setCantOpenSuggestiveFee(false);
    }
  }, [
    cantOpenSuggestiveFee,
    editingItem,
    editingQuoteOptions,
    handleAddItemToQuoteSubmit,
  ]);

  useEffect(() => {
    if (clearSuggestiveFee) {
      updateQuotesData({
        ...quotesData,
        suggestiveFees: [],
      });
      setEditingItemSuggestiveFees([]);
      setClearSuggestiveFee(false);
    }
  }, [quotesData, clearSuggestiveFee, updateQuotesData]);

  useEffect(() => {
    api.get<Country[]>(`/country`).then(response => {
      const countries = response.data;

      const countriesFormatted = countries.map(country => ({
        value: country.code,
        label: country.name,
      }));

      setCountryOptions(countriesFormatted);
    });
  }, []);

  useEffect(() => {
    getCountries();
  }, [getCountries]);

  useEffect(() => {
    getCities();
  }, [getCities]);

  useEffect(() => {
    getSchools();
  }, [getSchools]);

  useEffect(() => {
    getCampuses();
  }, [getCampuses]);

  useEffect(() => {
    if (currentFiltersTab === 'Courses') {
      getRestrictions();
      getProgramDurationTypes();
      getCategories();
    } else if (currentFiltersTab === 'Accommodations') {
      if (filterSchool || countryCodes || cityIds) {
        const timeout = setTimeout(() => {
          const data = filtersFormRef.current?.getData() as FiltersFormData;
          getAccommodationDurationTypes(data);
          getAccommodationTypes(data);
          getAccommodationRooms(data);
          getAccommodationBathroomTypes(data);
          getAccommodationRegimes(data);
        }, 200);
      }
    } else if (currentFiltersTab === 'Add-ons') {
      if (filterSchool || countryCodes || cityIds) {
        const timeout = setTimeout(() => {
          const data = filtersFormRef.current?.getData() as FiltersFormData;
          getFeeDurationTypes(data);
          getFeeCategoryTypes(data);
        }, 200);
      }
    }
  }, [
    filterSchool,
    countryCodes,
    cityIds,
    currentFiltersTab,
    getCategories,
    getProgramDurationTypes,
    getAccommodationDurationTypes,
    getAccommodationTypes,
    getAccommodationRooms,
    getAccommodationBathroomTypes,
    getAccommodationRegimes,
    getRestrictions,
    getFeeDurationTypes,
    getFeeCategoryTypes,
  ]);

  /*
  TODO: update this code in the future

  useEffect(() => {
    if (selectedSortOpt && selectedSortOpt.active) {
      // if (selectedSortOpt && selectedSortOpt.active && currentStep !== 3) {
      if (filteringActive) {
        handleSearch(firstRender);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      isFiltering = false;
      filteringActive = false;
      // if (currentStep === 2) {
      //  handleSearch();
      // }
    }
  }, [selectedSortOpt, handleSearch, currentStep, isFiltering]);
  // }, [selectedSortOpt, currentStep, handleSearch]);
*/

  useEffect(() => {
    api
      .get<Office>(`/office/${user.office_id}`, {
        params: {
          with: JSON.stringify([
            'preferences.city',
            'preferences.school',
            'favcountry.country',
            'favcity.city.country',
            'account.partners',
          ]),
          plugAndPlay: isUserAllyPlus ? 1 : 0,
        },
      })
      .then(response => {
        const office = response.data;
        let partners = [];
        if (isUserAllyPlus) {
          partners = office.partner ? office.partner.partners : [];
        } else {
          partners = office.account.partners;
        }

        const partnersFormatted = partners.map(
          (partner: { id: any; name: string; category_id: any }) => ({
            value: partner.id,
            label: partner.name,
            category_id: partner.category_id,
          })
        );

        orderByLabel(partnersFormatted);

        setPartnerOptions(partnersFormatted);
        setDefaultDescription(office.description);
      });
  }, [isUserAllyPlus, quotesData.quotes, user.office_id]);

  useEffect(() => {
    api
      .get<Newcategory[]>(`/newcategory`, {
        params: {
          teste: 2,
        },
      })
      .then(response => {
        const newcategories = response.data;

        const newcategoriesFormatted = newcategories.map(category => ({
          value: category.id,
          label: category.name,
        }));

        setNewcategoryOptions(newcategoriesFormatted);
      });
  }, []);

  useEffect(() => {
    api
      .get<FeeCategory[]>(`/feecategoryquote`, {
        params: {},
      })
      .then(response => {
        const feeCategories = response.data;

        const feeCategoriesFormatted = feeCategories.map(category => ({
          value: category.value,
          label: category.label,
        }));
        setFeeCategoryOptions(feeCategoriesFormatted);
      });
  }, []);

  useEffect(() => {
    async function fetchSaleTypes(): Promise<void> {
      const res = await api.get<ISaleType[]>('/saletype');

      const serializedTypes = res.data.map(type => ({
        label: type.name,
        value: type.id,
        main: type.main,
      }));

      setSaleTypeOptions(serializedTypes);
    }

    fetchSaleTypes();
  }, []);

  useEffect(() => {
    api
      .get<OfficeCurrency[]>('/officecurrency', {
        params: {
          office_id: isUserAllyPlus ? 2867 : user.office_id,
          playground: true,
        },
      })
      .then(response => {
        const currencies = response.data;

        currencies.unshift({
          destiny_code: user.office.currency.code,
          value: 1,
          currency: user.office.currency,
        });

        const currenciesFormatted = currencies.map(currency => ({
          value: currency.destiny_code,
          label: currency.currency.name,
        }));

        setOfficeCurrencies(currencies);
        setOfficeCurrenciesOptions(currenciesFormatted);
      });
  }, [user.office_id, user.office.currency, quotesData, isUserAllyPlus]);

  useEffect(() => {
    if (angularPort) {
      angularPort.onmessage = handleActionsCallback;
    }
  }, [angularPort, handleActionsCallback]);

  useEffect(() => {
    const optIdx = sortOptions.findIndex(opt => opt.sortProp === 'popular');
    const highPriceIdx = sortOptions.findIndex(
      opt => opt.label === 'Highest Price'
    );
    const lowPriceIdx = sortOptions.findIndex(
      opt => opt.label === 'Lowest Price'
    );

    if (
      isAccommodationFiltersOpen ||
      isAddOnFiltersOpen ||
      isInsuranceFiltersOpen ||
      isExperienceFiltersOpen
    ) {
      sortOptions[optIdx].display = 'none';
    } else {
      sortOptions[optIdx].display = 'block';
    }

    // sortOptions[highPriceIdx].display = 'block';
    // sortOptions[lowPriceIdx].display = 'block';

    // if (isExperienceFiltersOpen) {
    //   sortOptions[highPriceIdx].display = 'none';
    //   sortOptions[lowPriceIdx].display = 'none';
    // }

    setSortOptions(sortOptions);
  }, [
    isAccommodationFiltersOpen,
    isAddOnFiltersOpen,
    isInsuranceFiltersOpen,
    isExperienceFiltersOpen,
    sortOptions,
  ]);

  useEffect(() => {
    async function getOfficeConfig(): Promise<void> {
      const res = await api.get('/officeconfig', {
        params: {
          // change to IE id
          office_id: isUserAllyPlus ? 12 : user.office_id,
        },
      });

      setOfficeConfig(res.data);
    }

    getOfficeConfig();
  }, [isUserAllyPlus, user]);

  useEffect(() => {
    if (!programStartDate) return;
    const courseStartYear = programStartDate.getFullYear();
    const thisDate = new Date();
    const thisYear = thisDate.getFullYear();
    if (courseStartYear > thisYear) {
      setDateInfo(true);
      setCurrentYear(thisYear);
      setTextInfoTooltip(
        `Some institutions may not accept applications for future years with
        ${currentYear} pricing shown on Ally. Please, verify with provider before enroll student.`
      );
    } else {
      setDateInfo(false);
    }
  }, [currentYear, programStartDate, setDateInfo]);

  const infiniteLoadingHasMore = resultItems.length < totalItems;

  const isCountryOrCityFilterDisabled = useMemo(() => {
    if (isAddOnFiltersOpen && currentAddOnType === 'my') {
      return false;
    }

    if (isInsuranceFiltersOpen && currentInsuranceType === 'my') {
      return false;
    }

    if (isExperienceFiltersOpen) {
      return true;
    }

    return false;
  }, [
    currentAddOnType,
    currentInsuranceType,
    isAddOnFiltersOpen,
    isInsuranceFiltersOpen,
    isExperienceFiltersOpen,
  ]);

  const defaultStudentValue = useMemo(() => {
    const baseFlagUrl = `${process.env.REACT_APP_NG_URL}/images/flags/`;

    return (
      <>
        <img
          src={`${baseFlagUrl}${quotesData.student.nationality.flag}`}
          className="flag"
          alt="Flag"
        />
        {quotesData.student.fullName}
      </>
    );
  }, [quotesData.student]);

  return (
    <>
      <Container>
        <Wrapper>
          <Filters>
            <FiltersHeader>
              <div>
                <small>You are creating</small>
                <br />
                <strong>
                  {quotesData.howManyQuotes}{' '}
                  {quotesData.howManyQuotes === 1 ? 'quote' : 'quotes'}
                </strong>
              </div>

              <Button
                type="button"
                className="btn-outline icon"
                onClick={handleNavigationClick}
              >
                {currentStep === 2 && 'View Quotes'}
                {currentStep === 3 && (
                  <>
                    <MdKeyboardBackspace size={18} />
                    Playground
                  </>
                )}
              </Button>
            </FiltersHeader>

            <FiltersContent>
              <Form
                ref={filtersFormRef}
                initialData={{
                  addOnType: 'school',
                  insuranceType: 'school',
                  accommodationType: 'school',
                  courseStartDate: new Date(),
                }}
                onSubmit={handleSearchClick}
                style={{ width: '100%' }}
              >
                <Collapsibles>
                  <Collapsible
                    className={`filters-${filtersOptions.FILTERS_COURSES}`}
                    openedClassName={`filters-${filtersOptions.FILTERS_COURSES}`}
                    trigger={
                      <Trigger type="Courses" isOpen={isCourseFiltersOpen} />
                    }
                    transitionTime={100}
                    open={isCourseFiltersOpen}
                    handleTriggerClick={() =>
                      toggleCollapsibleOpened(filtersOptions.FILTERS_COURSES)
                    }
                  >
                    {(typeUser === 'allyplus' || typeUser === 'hybrid') && (
                      <div className="renewal">
                        <div className="renewal--title">
                          Only renewal programs
                        </div>
                        <div className="renewal--switch">
                          <Switch
                            checked={onlyRenewal}
                            checkedIcon={
                              <span className="renewal--yes">YES</span>
                            }
                            uncheckedIcon={
                              <span className="renewal--no">NO</span>
                            }
                            width={70}
                            height={30}
                            onChange={() => setOnlyRenewal(!onlyRenewal)}
                          />
                        </div>
                      </div>
                    )}
                    <div className="form-group">
                      <Select
                        register={false}
                        options={countryCOptions}
                        value={filterCountry}
                        name="country"
                        placeholder="Countries"
                        onChange={value =>
                          handleCountryChange(value as CountryCOption[])
                        }
                        isClearable
                        isMulti
                        isDisabled={isCountryOrCityFilterDisabled}
                      />
                    </div>
                    <div className="form-group">
                      <Select
                        register={false}
                        value={filterCity}
                        options={cityOptions}
                        name="city"
                        id="city"
                        placeholder="Cities"
                        onChange={value =>
                          handleCityChange(value as CityOption[])
                        }
                        isClearable
                        isMulti
                        isDisabled={isCountryOrCityFilterDisabled}
                      />
                    </div>
                    <div className="form-group">
                      <SelectAllyPlus
                        register={false}
                        options={schoolOptions}
                        name="school"
                        id="school_1"
                        value={filterSchool}
                        placeholder="Schools"
                        onChange={value =>
                          handleSchoolChange(value as SchoolOption[])
                        }
                        isClearable
                        isMulti
                      />
                    </div>
                    {filterSchool &&
                      filterSchool.length !== 0 &&
                      campusOptionsResponse &&
                      campusOptions &&
                      campusOptions.length &&
                      campusOptions.length !== 0 &&
                      campusOptions.length > 1 && (
                        <>
                          <div className="form-group">
                            <SelectAllyPlus
                              options={campusOptions}
                              name="filterCampus"
                              id="filterCampus"
                              value={filterCampus}
                              placeholder="Campus"
                              onChange={value =>
                                handleCampusChange(value as CampusOption[])
                              }
                              isClearable
                              isMulti
                            />
                          </div>
                        </>
                      )}
                    <div className="form-group">
                      <Select
                        options={newcategoryOptions}
                        value={newcategory}
                        name="newcategory"
                        id="newcategory"
                        placeholder="Program category"
                        onChange={value =>
                          handleNewcategoryChange(value as NewcategoryOption[])
                        }
                        isClearable
                      />
                    </div>
                    {newcategory && newcategory.length !== 0 && (
                      <>
                        <div className="form-group">
                          <Select
                            options={subcategoryOptions}
                            value={subcategory}
                            name="subcategory"
                            id="subcategory"
                            placeholder="Program subcategory"
                            onChange={value =>
                              handleSubcategoryChange(
                                value as SubcategoryOption[]
                              )
                            }
                            isClearable
                          />
                        </div>
                      </>
                    )}
                    <div className="form-group">
                      <div className="form-group">
                        <Select
                          options={restrictionOptions}
                          name="restrictionOption"
                          id="restrictionOption"
                          placeholder="Restrict By"
                          onChange={value =>
                            handleRestrictionChange(
                              value as RestrictionOption[]
                            )
                          }
                          isClearable
                          isMulti
                        />
                      </div>
                    </div>
                    <div className="form-group form-group--column">
                      <div className="form-group--duration">
                        <Input
                          type="number"
                          name="courseDuration"
                          id="courseDuration"
                          placeholder="Duration"
                        />
                      </div>
                      <div className="form-group--period">
                        <Select
                          options={programDurationTypeOptions}
                          name="programDurationTypeOption"
                          id="programDurationTypeOption"
                          defaultValue={{ value: 'week', label: 'weeks' }}
                          placeholder="Select period"
                          onChange={value =>
                            handleProgramDurationTypeChange(
                              value as ProgramDurationTypeOption[]
                            )
                          }
                        />
                        {/* <Select
                          options={periodTypeOptions}
                          defaultValue={{ value: 'week', label: 'weeks' }}
                          name="coursePeriodType"
                          id="coursePeriodType"
                          placeholder="Select period"
                        /> */}
                      </div>
                    </div>
                    <div className="form-group form-group--column">
                      <div className="form-group--date">
                        <label htmlFor="courseStartDate">
                          Program start date:
                        </label>
                        <DateContainer
                          dateInfoValidation={dateInfo}
                          isErrored={false}
                        >
                          <ReactDatePicker
                            selected={programStartDate}
                            dateFormat="dd/MM/yyyy"
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            onChange={date => setProgramStartDate(date as Date)}
                            required
                          />
                        </DateContainer>
                        {dateInfo && (
                          <Tooltip
                            title={textInfoTooltip}
                            place="top"
                            className="tooltip-info-date"
                            effect="solid"
                            offset={{ right: 60 }}
                          >
                            <MdInfo className="icon-info-date" size={19} />
                          </Tooltip>
                        )}
                        {/* <DatePicker name="courseStartDate" required /> */}
                      </div>
                    </div>
                    <Collapsible
                      transitionTime={200}
                      className="more-filters"
                      openedClassName="more-filters opened"
                      trigger="More filters >"
                      triggerClassName="trigger"
                      triggerOpenedClassName="trigger opened"
                      contentInnerClassName="content"
                    >
                      <div className="form-group--date">
                        <label htmlFor="dateOfReference">Enrolment Date:</label>
                        <DateContainer isErrored={false}>
                          <ReactDatePicker
                            selected={dateOfReference}
                            dateFormat="dd/MM/yyyy"
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            onChange={date => setDateOfReference(date as Date)}
                            required
                          />
                        </DateContainer>
                      </div>

                      <div
                        className="form-group "
                        style={{ marginTop: '25px' }}
                      >
                        <label className="inline" htmlFor="restrictions_1">
                          Study lessons/hours per week
                        </label>
                        <Slider
                          getAriaLabel={() => 'Lessons/Hours per week'}
                          value={sliderValue}
                          max={40}
                          min={0}
                          onChange={handleChange}
                          valueLabelDisplay="auto"
                        />
                      </div>
                      {/* <div className="price-range--title">Price range</div>
                      <div className="price-range form-group form-group--column">
                        <div className="form-group--duration">
                          <Input
                            type="number"
                            name="courseFromValue"
                            id="courseFromValue"
                            placeholder="Minimum"
                          />
                        </div>
                        <p className="mtop10"> — </p>
                        <div className="form-group--duration">
                          <Input
                            type="number"
                            name="courseToValue"
                            id="courseToValue"
                            placeholder="Maximum"
                          />
                        </div>
                      </div>

                      <br /> */}
                      <div
                        style={{ margin: '15px 0px' }}
                        className="form-group"
                      >
                        <input
                          id="courseFilterName"
                          placeholder="Program name"
                          value={courseName}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setCourseName(e.target.value)
                          }
                          style={{
                            border: '1px solid var(--light-gray)',
                            resize: 'none',
                            color: 'var(--gray)',
                            padding: '9px 15px',
                            width: '100%',
                          }}
                        />
                      </div>

                      <div className="promotion">
                        <div className="promotion--title">Promotion only</div>
                        <div className="promotion--switch">
                          <Switch
                            checked={promotionOnly}
                            checkedIcon={
                              <span className="promotion--yes">YES</span>
                            }
                            uncheckedIcon={
                              <span className="promotion--no">NO</span>
                            }
                            width={70}
                            height={30}
                            onChange={() => setPromotionOnly(!promotionOnly)}
                          />
                        </div>
                      </div>
                    </Collapsible>
                    {/* {((!!filterCountry?.length && !!filterCity?.length) ||
                      (!!filterCountry?.length && !!filterSchool?.length) ||
                      (!!filterCity?.length && !!filterSchool?.length)) && (
                      <div className="form-group">
                        <div className="online-check">
                          <CheckboxInput
                            options={[
                              {
                                id: 'available_1',
                                value: 'onlyAvailable',
                                label: 'Only available programs',
                              },
                            ]}
                            name="available"
                            defaultChecked={onlyAvailable}
                            onChange={() => setOnlyAvailable(!onlyAvailable)}
                          />
                        </div>
                      </div>
                    )} */}
                    <ButtonWithLoading
                      isLoading={!readyToSearch}
                      type="submit"
                      className="btn-lg"
                      style={{
                        marginTop: '2rem',
                      }}
                    >
                      Search Programs
                    </ButtonWithLoading>
                  </Collapsible>
                  <Collapsible
                    className={`filters-${filtersOptions.FILTERS_ACCOMMODATIONS}`}
                    openedClassName={`filters-${filtersOptions.FILTERS_ACCOMMODATIONS}`}
                    trigger={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <Trigger
                        type="Accommodations"
                        isOpen={isAccommodationFiltersOpen}
                      />
                    }
                    triggerTagName="div"
                    transitionTime={100}
                    open={isAccommodationFiltersOpen}
                    handleTriggerClick={() =>
                      toggleCollapsibleOpened(
                        filtersOptions.FILTERS_ACCOMMODATIONS
                      )
                    }
                  >
                    <div className="form-group">
                      <label htmlFor="accommodationName">
                        Accommodation Type:
                      </label>
                      <RadioInput
                        options={
                          isUserAllyPlus
                            ? ppOptions
                            : accommodationOptions.types
                        }
                        name="accommodationType"
                        onChange={e =>
                          handleChangeAccommodationType(e.currentTarget.value)
                        }
                      />
                    </div>
                    {currentAccommodationType === 'all' && (
                      <>
                        <div className="form-group">
                          <Select
                            register={false}
                            value={filterCountry}
                            options={countryCOptions}
                            name="country"
                            placeholder="Countries"
                            onChange={value =>
                              handleCountryChange(value as CountryCOption[])
                            }
                            isClearable
                            isMulti
                            isDisabled={isCountryOrCityFilterDisabled}
                          />
                        </div>
                        <div className="form-group">
                          <Select
                            register={false}
                            value={filterCity}
                            options={cityOptions}
                            name="city"
                            id="city"
                            placeholder="Cities"
                            onChange={value =>
                              handleCityChange(value as CityOption[])
                            }
                            isClearable
                            isMulti
                            isDisabled={isCountryOrCityFilterDisabled}
                          />
                        </div>

                        <div className="form-group">
                          <SelectAllyPlus
                            options={filterAllProviders(1)}
                            name="accommodationPartner"
                            id="accommodationPartner"
                            placeholder="Schools/Providers"
                            isClearable
                            isMulti
                            value={filterSchoolsOrProvidersValue(1)}
                            onChange={handleSchoolsOrProvidersChange}
                          />
                        </div>
                      </>
                    )}
                    {currentAccommodationType === 'school' && (
                      <>
                        <div className="form-group">
                          <Select
                            register={false}
                            value={filterCountry}
                            options={countryCOptions}
                            name="country"
                            placeholder="Countries"
                            onChange={value =>
                              handleCountryChange(value as CountryCOption[])
                            }
                            isClearable
                            isMulti
                            isDisabled={isCountryOrCityFilterDisabled}
                          />
                        </div>

                        <div className="form-group">
                          <Select
                            register={false}
                            value={filterCity}
                            options={cityOptions}
                            name="city"
                            id="city"
                            placeholder="Cities"
                            onChange={value =>
                              handleCityChange(value as CityOption[])
                            }
                            isClearable
                            isMulti
                            isDisabled={isCountryOrCityFilterDisabled}
                          />
                        </div>
                        <div className="form-group">
                          <SelectAllyPlus
                            register={false}
                            options={schoolOptions}
                            name="school"
                            id="school_2"
                            value={filterSchool}
                            placeholder="Schools"
                            onChange={value =>
                              handleSchoolChange(value as SchoolOption[])
                            }
                            isClearable
                            isMulti
                          />
                        </div>
                      </>
                    )}
                    {currentAccommodationType === 'my' && (
                      <>
                        <div className="form-group">
                          <Select
                            register={false}
                            value={filterCountry}
                            options={countryCOptions}
                            name="country"
                            placeholder="Countries"
                            onChange={value =>
                              handleCountryChange(value as CountryCOption[])
                            }
                            isClearable
                            isMulti
                            isDisabled={isCountryOrCityFilterDisabled}
                          />
                        </div>
                        <div className="form-group">
                          <Select
                            register={false}
                            value={filterCity}
                            options={cityOptions}
                            name="city"
                            id="city"
                            placeholder="Cities"
                            onChange={value =>
                              handleCityChange(value as CityOption[])
                            }
                            isClearable
                            isMulti
                            isDisabled={isCountryOrCityFilterDisabled}
                          />
                        </div>

                        <div className="form-group">
                          <Select
                            options={partnerOptions.filter(
                              partner => partner.category_id === 1
                            )}
                            name="accommodationPartner"
                            id="accommodationPartner"
                            placeholder="Providers"
                            isClearable
                            isMulti
                            value={filterProvidersValue(1)}
                            onChange={handleProviderChange}
                          />
                        </div>
                      </>
                    )}
                    {/* <div className="form-group ">
                      <label className="inline" htmlFor="restrictions_2">
                        Restrict by{' '}
                        <span className="smaller">
                          (you can select more than one option).
                        </span>
                      </label>
                      <div
                        id="restrictions_2"
                        className="form-group form-group--column"
                      >
                        <Button
                          type="button"
                          tooltip="Search only accommodations limited by the student age"
                          className={`btn-outline icon ${
                            onlyStudentAge
                              ? 'toggleTokenOn'
                              : 'btn-outline--gray toggleTokenOff'
                          }`}
                          onClick={() => setOnlyStudentAge(!onlyStudentAge)}
                        >
                          Age
                        </Button>
                      </div>
                    </div> */}
                    <div className="form-group">
                      <Input
                        name="accommodationName"
                        id="accommodationName"
                        placeholder="Accommodation name"
                      />
                    </div>
                    <div className="form-group">
                      <Select
                        onChange={updateSmartFilters}
                        options={accommodationTypeOptions}
                        name="accommodationCategory"
                        id="accommodationCategory"
                        placeholder="Accommodation type"
                        isClearable
                        isMulti
                      />
                    </div>
                    <div className="form-group">
                      <Select
                        onChange={updateSmartFilters}
                        options={accommodationRoomOptions}
                        name="accommodationRoomType"
                        id="accommodationRoomType"
                        placeholder="Room types"
                        isClearable
                        isMulti
                      />
                    </div>
                    <div className="form-group">
                      <Select
                        onChange={updateSmartFilters}
                        options={accommodationBathroomTypeOptions}
                        name="accommodationBathroomType"
                        id="accommodationBathroomType"
                        placeholder="Bathroom types"
                        isClearable
                        isMulti
                      />
                    </div>
                    <div className="form-group">
                      <Select
                        onChange={updateSmartFilters}
                        options={accommodationRegimeTypeOptions}
                        name="accommodationRegime"
                        id="accommodationRegime"
                        placeholder="Regime"
                        isClearable
                        isMulti
                      />
                    </div>
                    <div className="form-group form-group--column">
                      <div className="form-group--date">
                        <label htmlFor="courseStartDate">Checkin date:</label>
                        <DateContainer
                          dateInfoValidation={dateInfo}
                          isErrored={false}
                        >
                          <ReactDatePicker
                            selected={programStartDate}
                            dateFormat="dd/MM/yyyy"
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            onChange={date => setProgramStartDate(date as Date)}
                            required
                          />
                        </DateContainer>
                        {dateInfo && (
                          <Tooltip
                            title={textInfoTooltip}
                            place="top"
                            className="tooltip-info-date"
                            effect="solid"
                            offset={{ right: 60 }}
                          >
                            <MdInfo className="icon-info-date" size={19} />
                          </Tooltip>
                        )}
                        {/* <DatePicker name="courseStartDate" required /> */}
                      </div>
                      <div className="form-group--date">
                        <label htmlFor="dateOfReference">Enrolment Date:</label>
                        <DateContainer isErrored={false}>
                          <ReactDatePicker
                            selected={dateOfReference}
                            dateFormat="dd/MM/yyyy"
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            onChange={date => setDateOfReference(date as Date)}
                            required
                          />
                        </DateContainer>
                      </div>
                    </div>
                    <div className="form-group form-group--column">
                      <div className="form-group--duration">
                        <Input
                          type="number"
                          name="accommodationDuration"
                          id="accommodationDuration"
                          placeholder="Duration"
                        />
                      </div>
                      <div className="form-group--period">
                        <Select
                          options={accommodationDurationTypeOptions}
                          name="accommodationDurationTypeOption"
                          id="accommodationDurationTypeOption"
                          defaultValue={{ value: 'week', label: 'weeks' }}
                          placeholder="Select period"
                          isClearable
                          onChange={value =>
                            handleAccommodationDurationTypeChange(
                              value as AccommodationDurationTypeOption[]
                            )
                          }
                        />
                      </div>
                    </div>
                    {/* <Collapsible
                      transitionTime={200}
                      className="more-filters"
                      openedClassName="more-filters opened"
                      trigger="More filters >"
                      triggerClassName="trigger"
                      triggerOpenedClassName="trigger opened"
                      contentInnerClassName="content"
                    >
                      <div className="price-range--title">Price range</div>
                      <div className="price-range form-group form-group--column">
                        <div className="form-group--duration">
                          <Input
                            type="number"
                            name="accommodationFromValue"
                            id="accommodationFromValue"
                            placeholder="Minimum"
                          />
                        </div>
                        <p className="mtop10"> — </p>
                        <div className="form-group--duration">
                          <Input
                            type="number"
                            name="accommodationToValue"
                            id="accommodationToValue"
                            placeholder="Maximum"
                          />
                        </div>
                      </div>
                    </Collapsible> */}
                    <ButtonWithLoading
                      isLoading={!readyToSearch}
                      type="submit"
                      className="btn-lg"
                      style={{
                        marginTop: '2rem',
                      }}
                    >
                      Search Accommodations
                    </ButtonWithLoading>
                  </Collapsible>
                  <Collapsible
                    className={`filters-${filtersOptions.FILTERS_INSURANCES}`}
                    openedClassName={`filters-${filtersOptions.FILTERS_INSURANCES}`}
                    trigger={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <Trigger
                        type="Insurances"
                        isOpen={isInsuranceFiltersOpen}
                      />
                    }
                    triggerTagName="div"
                    transitionTime={100}
                    open={isInsuranceFiltersOpen}
                    handleTriggerClick={() =>
                      toggleCollapsibleOpened(filtersOptions.FILTERS_INSURANCES)
                    }
                  >
                    <div className="form-group">
                      <RadioInput
                        options={insuranceTypeOptions}
                        name="insuranceType"
                        onChange={e =>
                          setCurrentInsuranceType(e.currentTarget.value)
                        }
                      />
                    </div>

                    {currentInsuranceType === 'all' && (
                      <>
                        <div className="form-group">
                          <Select
                            register={false}
                            value={filterCountry}
                            options={countryCOptions}
                            name="country"
                            placeholder="Countries"
                            onChange={value =>
                              handleCountryChange(value as CountryCOption[])
                            }
                            isClearable
                            isMulti
                            isDisabled={isCountryOrCityFilterDisabled}
                          />
                        </div>
                        <div className="form-group">
                          <Select
                            register={false}
                            value={filterCity}
                            options={cityOptions}
                            name="city"
                            id="city"
                            placeholder="Cities"
                            onChange={value =>
                              handleCityChange(value as CityOption[])
                            }
                            isClearable
                            isMulti
                            isDisabled={isCountryOrCityFilterDisabled}
                          />
                        </div>
                        <div className="form-group">
                          <SelectAllyPlus
                            options={filterAllProviders(4)}
                            name="insurancePartner"
                            id="insurancePartner"
                            placeholder="Schools/Providers"
                            isClearable
                            isMulti
                            value={filterSchoolsOrProvidersValue(4)}
                            onChange={handleSchoolsOrProvidersChange}
                          />
                        </div>
                      </>
                    )}

                    {currentInsuranceType === 'school' && (
                      <>
                        <div className="form-group">
                          <Select
                            register={false}
                            value={filterCountry}
                            options={countryCOptions}
                            name="country"
                            placeholder="Countries"
                            onChange={value =>
                              handleCountryChange(value as CountryCOption[])
                            }
                            isClearable
                            isMulti
                            isDisabled={isCountryOrCityFilterDisabled}
                          />
                        </div>

                        <div className="form-group">
                          <Select
                            register={false}
                            value={filterCity}
                            options={cityOptions}
                            name="city"
                            id="city"
                            placeholder="Cities"
                            onChange={value =>
                              handleCityChange(value as CityOption[])
                            }
                            isClearable
                            isMulti
                            isDisabled={isCountryOrCityFilterDisabled}
                          />
                        </div>

                        <div className="form-group">
                          <SelectAllyPlus
                            register={false}
                            options={schoolOptions}
                            name="school"
                            id="school_3"
                            value={filterSchool}
                            placeholder="Schools"
                            onChange={value =>
                              handleSchoolChange(value as SchoolOption[])
                            }
                            isClearable
                            isMulti
                          />
                        </div>
                      </>
                    )}

                    {currentInsuranceType === 'my' && (
                      <>
                        <div className="form-group">
                          <Select
                            register={false}
                            value={filterCountry}
                            options={countryCOptions}
                            name="country"
                            placeholder="Countries"
                            onChange={value =>
                              handleCountryChange(value as CountryCOption[])
                            }
                            isClearable
                            isMulti
                            isDisabled={isCountryOrCityFilterDisabled}
                          />
                        </div>
                        <div className="form-group">
                          <Select
                            register={false}
                            value={filterCity}
                            options={cityOptions}
                            name="city"
                            id="city"
                            placeholder="Cities"
                            onChange={value =>
                              handleCityChange(value as CityOption[])
                            }
                            isClearable
                            isMulti
                            isDisabled={isCountryOrCityFilterDisabled}
                          />
                        </div>
                        <div className="form-group">
                          <Select
                            options={partnerOptions.filter(
                              partner => partner.category_id === 4
                            )}
                            name="insurancePartner"
                            id="insurancePartner"
                            placeholder="Providers"
                            isClearable
                            isMulti
                            value={filterProvidersValue(4)}
                            onChange={handleProviderChange}
                          />
                        </div>
                      </>
                    )}

                    <div className="form-group">
                      <Input
                        name="insuranceName"
                        id="insuranceName"
                        placeholder="Insurance Name"
                      />
                    </div>

                    <div className="form-group form-group--date">
                      <label htmlFor="dateOfReference">Enrolment Date:</label>
                      <DateContainer isErrored={false}>
                        <ReactDatePicker
                          selected={dateOfReference}
                          dateFormat="dd/MM/yyyy"
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          onChange={date => setDateOfReference(date as Date)}
                        />
                      </DateContainer>
                    </div>

                    <div className="form-group form-group--column">
                      <div className="form-group--duration">
                        <Input
                          type="number"
                          name="insuranceDuration"
                          id="insuranceDuration"
                          placeholder="Duration"
                        />
                      </div>
                      <div className="form-group--period">
                        <Select
                          options={periodTypeOptions}
                          defaultValue={{ value: 'week', label: 'weeks' }}
                          name="insurancePeriodType"
                          id="insurancePeriodType"
                          placeholder="Period type"
                        />
                      </div>
                    </div>

                    {/* <Collapsible
                      transitionTime={200}
                      className="more-filters"
                      openedClassName="more-filters opened"
                      trigger="More filters >"
                      triggerClassName="trigger"
                      triggerOpenedClassName="trigger opened"
                      contentInnerClassName="content"
                    >
                      <div className="price-range--title">Price range</div>
                      <div className="price-range form-group form-group--column">
                        <div className="form-group--duration">
                          <Input
                            type="number"
                            name="insuranceFromValue"
                            id="insuranceFromValue"
                            placeholder="Minimum"
                          />
                        </div>
                        <p className="mtop10"> — </p>
                        <div className="form-group--duration">
                          <Input
                            type="number"
                            name="insuranceToValue"
                            id="insuranceToValue"
                            placeholder="Maximum"
                          />
                        </div>
                      </div>
                    </Collapsible> */}

                    <ButtonWithLoading
                      isLoading={!readyToSearch}
                      type="submit"
                      className="btn-lg"
                      style={{
                        marginTop: '2rem',
                      }}
                    >
                      Search Insurances
                    </ButtonWithLoading>
                  </Collapsible>
                  <Collapsible
                    className={`filters-${filtersOptions.FILTERS_ADDONS}`}
                    openedClassName={`filters-${filtersOptions.FILTERS_ADDONS}`}
                    trigger={
                      <Trigger type="Add-ons" isOpen={isAddOnFiltersOpen} />
                    }
                    triggerTagName="div"
                    transitionTime={100}
                    open={isAddOnFiltersOpen}
                    handleTriggerClick={() =>
                      toggleCollapsibleOpened(filtersOptions.FILTERS_ADDONS)
                    }
                  >
                    <div className="form-group">
                      <RadioInput
                        options={addOnTypeOptions}
                        name="addOnType"
                        onChange={e =>
                          handleChangeAddOnType(e.currentTarget.value)
                        }
                      />
                    </div>

                    {currentAddOnType === 'all' && (
                      <>
                        <div className="form-group">
                          <Select
                            register={false}
                            value={filterCountry}
                            options={countryCOptions}
                            name="country"
                            placeholder="Countries"
                            onChange={value =>
                              handleCountryChange(value as CountryCOption[])
                            }
                            isClearable
                            isMulti
                            isDisabled={isCountryOrCityFilterDisabled}
                          />
                        </div>
                        <div className="form-group">
                          <Select
                            register={false}
                            value={filterCity}
                            options={cityOptions}
                            name="city"
                            id="city"
                            placeholder="Cities"
                            onChange={value =>
                              handleCityChange(value as CityOption[])
                            }
                            isClearable
                            isMulti
                            isDisabled={isCountryOrCityFilterDisabled}
                          />
                        </div>
                        <div className="form-group">
                          <SelectAllyPlus
                            options={filterAllProviders()}
                            name="addOnPartner"
                            id="addOnPartner"
                            placeholder="Schools/Providers"
                            isClearable
                            isMulti
                            value={filterSchoolsOrProvidersValue()}
                            onChange={handleSchoolsOrProvidersChange}
                          />
                        </div>
                      </>
                    )}

                    {currentAddOnType === 'school' && (
                      <>
                        <div className="form-group">
                          <Select
                            register={false}
                            value={filterCountry}
                            options={countryCOptions}
                            name="country"
                            placeholder="Countries"
                            onChange={value =>
                              handleCountryChange(value as CountryCOption[])
                            }
                            isClearable
                            isMulti
                            isDisabled={isCountryOrCityFilterDisabled}
                          />
                        </div>

                        <div className="form-group">
                          <Select
                            register={false}
                            value={filterCity}
                            options={cityOptions}
                            name="city"
                            id="city"
                            placeholder="Cities"
                            onChange={value =>
                              handleCityChange(value as CityOption[])
                            }
                            isClearable
                            isMulti
                            isDisabled={isCountryOrCityFilterDisabled}
                          />
                        </div>

                        <div className="form-group">
                          <SelectAllyPlus
                            register={false}
                            options={schoolOptions}
                            name="school"
                            id="school_4"
                            value={filterSchool}
                            placeholder="Schools"
                            onChange={value =>
                              handleSchoolChange(value as SchoolOption[])
                            }
                            isClearable
                            isMulti
                          />
                        </div>
                      </>
                    )}

                    {currentAddOnType === 'my' && (
                      <>
                        <div className="form-group">
                          <Select
                            register={false}
                            value={filterCountry}
                            options={countryCOptions}
                            name="country"
                            placeholder="Countries"
                            onChange={value =>
                              handleCountryChange(value as CountryCOption[])
                            }
                            isClearable
                            isMulti
                            isDisabled={isCountryOrCityFilterDisabled}
                          />
                        </div>
                        <div className="form-group">
                          <Select
                            register={false}
                            value={filterCity}
                            options={cityOptions}
                            name="city"
                            id="city"
                            placeholder="Cities"
                            onChange={value =>
                              handleCityChange(value as CityOption[])
                            }
                            isClearable
                            isMulti
                            isDisabled={isCountryOrCityFilterDisabled}
                          />
                        </div>
                        <div className="form-group">
                          <Select
                            options={partnerOptions}
                            name="addOnPartner"
                            id="addOnPartner"
                            placeholder="Providers"
                            isClearable
                            isMulti
                            value={filterProvidersValue()}
                            onChange={handleProviderChange}
                          />
                        </div>
                      </>
                    )}

                    <div className="form-group">
                      <Input
                        name="addOnName"
                        id="addOnName"
                        placeholder="Add-on name"
                      />
                    </div>

                    <div className="form-group">
                      <Select
                        options={feeCategoryOptions}
                        name="addOnCategory"
                        id="addOnCategory"
                        placeholder="Categories"
                        isClearable
                        isMulti
                      />
                    </div>

                    <div className="form-group form-group--date">
                      <label htmlFor="dateOfReference">Enrolment Date:</label>
                      <DateContainer isErrored={false}>
                        <ReactDatePicker
                          selected={dateOfReference}
                          dateFormat="dd/MM/yyyy"
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          onChange={date => setDateOfReference(date as Date)}
                        />
                      </DateContainer>
                    </div>

                    <div className="form-group form-group--column">
                      <div className="form-group--duration">
                        <Input
                          type="number"
                          name="addOnDuration"
                          id="addOnDuration"
                          placeholder="Duration"
                        />
                      </div>

                      <div className="form-group--period">
                        <Select
                          options={feeDurationTypeOptions}
                          name="feeDurationTypeOption"
                          id="feeDurationTypeOption"
                          defaultValue={{ value: 'week', label: 'weeks' }}
                          placeholder="Select period"
                          isClearable
                          onChange={value =>
                            handleFeeDurationTypeChange(
                              value as FeeDurationTypeOption[]
                            )
                          }
                        />
                      </div>
                    </div>

                    <ButtonWithLoading
                      isLoading={!readyToSearch}
                      type="submit"
                      className="btn-lg"
                      style={{
                        marginTop: '2rem',
                      }}
                    >
                      Search Add-ons
                    </ButtonWithLoading>
                  </Collapsible>
                  {quotesData?.quotes?.[0]?.plugAndPlay === 0 && (
                    <Collapsible
                      className={`filters-${filtersOptions.FILTERS_EXPERIENCES}`}
                      openedClassName={`filters-${filtersOptions.FILTERS_EXPERIENCES}`}
                      trigger={
                        // eslint-disable-next-line react/jsx-wrap-multilines
                        <Trigger
                          type="Experiences"
                          isOpen={isExperienceFiltersOpen}
                        />
                      }
                      triggerTagName="div"
                      transitionTime={100}
                      open={isExperienceFiltersOpen}
                      handleTriggerClick={() =>
                        toggleCollapsibleOpened(
                          filtersOptions.FILTERS_EXPERIENCES
                        )
                      }
                    >
                      <div className="form-group">
                        <Select
                          options={filterAllProviders()}
                          name="experiencePartner"
                          id="experiencePartner"
                          placeholder="Providers"
                          isClearable
                          isMulti
                          value={filterProvider}
                          onChange={handleProviderChange}
                        />
                      </div>

                      <div className="form-group">
                        <Input
                          name="experienceName"
                          id="experienceName"
                          placeholder="Experience name"
                        />
                      </div>

                      <div className="form-group form-group--date">
                        <label htmlFor="dateOfReference">Enrolment Date:</label>
                        <DateContainer isErrored={false}>
                          <ReactDatePicker
                            selected={dateOfReference}
                            dateFormat="dd/MM/yyyy"
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            onChange={date => setDateOfReference(date as Date)}
                          />
                        </DateContainer>
                      </div>

                      <Button
                        type="submit"
                        className="btn-lg"
                        style={{
                          marginTop: '2rem',
                        }}
                      >
                        Search Experiences
                      </Button>
                    </Collapsible>
                  )}
                </Collapsibles>
              </Form>
            </FiltersContent>
          </Filters>

          <Content>
            <ContentHeader>
              <Breadcrumb
                options={breadcrumbOptions}
                backToPlayground={handleBackLinkClick}
                className="breadcrumb-container"
              />
              {currentStep === 3 && (
                <div style={{ display: 'flex', gap: '0.5rem' }}>
                  <Button
                    type="button"
                    loading={isAddOptionLoading}
                    loadingWidth={19}
                    loadingColor="var(--primary)"
                    className="btn-icon primary"
                    onClick={handleAddOption}
                    tooltip="Add option"
                    style={{
                      width: '39px',
                      height: '39px',
                    }}
                  >
                    <MdAdd size={16} />
                  </Button>
                  {mainLoading ? (
                    <Button
                      type="button"
                      loading={isSaveQuotesLoading}
                      loadingWidth={19}
                      className="btn-green"
                      style={{
                        minWidth: '187px',
                      }}
                    >
                      <Loading loading={mainLoading} color="var(--white)" />
                    </Button>
                  ) : (
                    <Button
                      type="button"
                      loading={isSaveQuotesLoading}
                      loadingWidth={19}
                      className="btn-green"
                      onClick={handleFinishAndSaveQuotes}
                      style={{
                        minWidth: '187px',
                        height: '39px',
                      }}
                    >
                      Finish and Save Quotes
                    </Button>
                  )}
                </div>
              )}
              <div
                style={
                  currentStep === 2 &&
                  !searchLoading &&
                  resultItems &&
                  resultItems.length > 0
                    ? {
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }
                    : { display: 'none' }
                }
              >
                {showAvailableValues.current
                  ? currentStep === 2 &&
                    !searchLoading &&
                    resultItems &&
                    resultItems.length > 0 && (
                      <Button
                        style={{
                          background: 'none',
                          border: 'none',
                          color: 'var(--bs-gray-600)',
                          fontWeight: '400',
                        }}
                        onClick={handleShowAvailableValuesToggle}
                        disabled={!readyToSearch}
                      >
                        Show expired values
                        <IoMdEye
                          size={16}
                          style={{ marginLeft: '8px', fontWeight: '700' }}
                        />
                      </Button>
                    )
                  : currentStep === 2 &&
                    !searchLoading &&
                    resultItems &&
                    resultItems.length > 0 && (
                      <Button
                        style={{
                          background: 'none',
                          border: 'none',
                          color: 'var(--bs-gray-600)',
                          fontWeight: '400',
                        }}
                        onClick={handleShowAvailableValuesToggle}
                        disabled={!readyToSearch}
                      >
                        Hide expired values
                        <IoMdEyeOff
                          size={16}
                          style={{ marginLeft: '8px', fontWeight: '700' }}
                        />
                      </Button>
                    )}
                {currentStep === 2 &&
                  !searchLoading &&
                  resultItems &&
                  resultItems.length > 0 &&
                  isUserBackOffice &&
                  !isIEAccount && (
                    <Dropdown>
                      {hideResultAllyPlus.current ? (
                        <InvisibleAllyPlusBtn
                          disabled={!readyToSearch}
                          className="invisible"
                          onClick={() => {
                            handleHideAllyPlusItems(false);
                          }}
                        >
                          <img
                            src={allyPlusIcon}
                            alt="allyplusicon"
                            style={{
                              marginRight: '3px',
                              marginBottom: '3px',
                            }}
                            width={20}
                            height={20}
                          />
                          invisible
                        </InvisibleAllyPlusBtn>
                      ) : (
                        <DropdownToggle disabled={!readyToSearch}>
                          <img
                            src={allyPlusIcon}
                            alt="allyplusicon"
                            style={{
                              marginRight: '3px',
                              marginBottom: '3px',
                            }}
                            width={20}
                            height={20}
                          />
                          visible
                        </DropdownToggle>
                      )}

                      <DropdownMenu>
                        <p>
                          <b>
                            Deseja parar de ver todas as opções disponíveis no
                            Ally+ em seus resultados?
                          </b>
                        </p>
                        <p
                          style={{
                            margin: '20px 0px',
                          }}
                        >
                          Com o Ally+ você amplia o seu portfólio de produtos,
                          oferece condições de pagamento diferenciadas ao seus
                          clientes e muito mais.
                        </p>
                        <div className="display-flex-between">
                          <span>Tornar as opções Ally+ invisíveis?</span>
                          <Switch
                            checked={hideResultAllyPlus.current}
                            onColor="#6fd64b"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            onChange={() => handleHideAllyPlusItems(true)}
                          />
                        </div>
                        <div>
                          <p
                            style={{
                              marginTop: '20px',
                            }}
                          >
                            Atualmente as opções do Ally+ são apenas para
                            visualização. Caso queira ativar o Ally+ na sua
                            conta solicite ao(a) administrador(a) da conta
                            clicando no botão abaixo.
                          </p>
                        </div>
                        <div
                          style={{
                            marginTop: '30px',
                          }}
                        >
                          <Button
                            style={{
                              background: '#ff0775',
                              borderColor: '#ff0775',
                              padding: '7px',
                              color: 'white',
                              width: '150px',
                            }}
                            onClick={() => onClickContactButton()}
                          >
                            {user.role === 'admin'
                              ? 'Ativar o Ally+'
                              : 'Solicitar Ally+'}
                          </Button>
                        </div>
                      </DropdownMenu>
                    </Dropdown>
                  )}
              </div>
            </ContentHeader>

            {currentStep === 2 && (
              <>
                <ContentFilters>
                  <Actions style={{ marginRight: '15px' }}>
                    {(searchLoading ||
                      !resultItems ||
                      resultItems.length === 0) && <div />}
                    {!searchLoading && resultItems && resultItems.length > 0 && (
                      <ResultHeader>
                        <img src={shuttleSvg} alt="Rocket going up" />
                        <strong>
                          {totalItems}
                          {resultType === filtersOptions.FILTERS_COURSES &&
                            ' programs '}
                          {resultType ===
                            filtersOptions.FILTERS_ACCOMMODATIONS &&
                            ' accommodations '}
                          {resultType === filtersOptions.FILTERS_INSURANCES &&
                            ' insurances '}
                          {resultType === filtersOptions.FILTERS_ADDONS &&
                            ' add-ons '}
                          {resultType === filtersOptions.FILTERS_EXPERIENCES &&
                            ' experiences '}
                          found
                        </strong>
                        <strong>
                          {' '}
                          &nbsp;Showing {resultItems.length} items. &nbsp;{' '}
                        </strong>
                        {/* {!isIEAccount && ( */}
                        <small>
                          Sorted by {sortOptions.find(s => s.active)?.label}
                        </small>
                        {/* )} */}

                        <Dropdown style={{ marginLeft: '10px' }}>
                          <Dropdown.Toggle
                            style={{
                              backgroundColor: 'var(--porcelain)',
                              color: 'var(--primary)',
                              border: 'none',
                              fontSize: '.875em',
                            }}
                          >
                            More Filters
                          </Dropdown.Toggle>

                          <Dropdown.Menu
                            style={{
                              width: '315px',
                              padding: '10px 20px 15px',
                            }}
                          >
                            {/* <div className="form-group">
                              <label
                                className="inline"
                                htmlFor="restrictions_1"
                                style={{
                                  fontSize: '1.2rem',
                                  color: 'var(--gray)',
                                }}
                              >
                                Restrict by
                              </label>
                              <div
                                className="form-group form-group--column"
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <Button
                                  type="button"
                                  tooltip="Search only online programs"
                                  className={`btn-outline opt ${
                                    onlyOnline
                                      ? 'toggleTokenOn'
                                      : 'btn-outline--gray toggleTokenOff'
                                  }`}
                                  onClick={() =>
                                    setOnlyOnline(prevState => !prevState)
                                  }
                                >
                                  Online
                                </Button>

                                <Button
                                  type="button"
                                  tooltip="Search only onshore programs"
                                  className={`btn-outline opt ${
                                    onlyOnshore
                                      ? 'toggleTokenOn'
                                      : 'btn-outline--gray toggleTokenOff'
                                  }`}
                                  onClick={() =>
                                    setOnlyOnshore(prevState => !prevState)
                                  }
                                >
                                  Onshore
                                </Button>
                                <Button
                                  type="button"
                                  tooltip="Search only offshore programs"
                                  className={`btn-outline opt ${
                                    onlyOffshore
                                      ? 'toggleTokenOn'
                                      : 'btn-outline--gray toggleTokenOff'
                                  }`}
                                  onClick={() =>
                                    setOnlyOffshore(prevState => !prevState)
                                  }
                                >
                                  Offshore
                                </Button>

                                <Button
                                  type="button"
                                  tooltip="Search only programs limited by the student age"
                                  className={`btn-outline opt ${
                                    onlyStudentAge
                                      ? 'toggleTokenOn'
                                      : 'btn-outline--gray toggleTokenOff'
                                  }`}
                                  onClick={() =>
                                    quotesData.student.age === -1
                                      ? addToast({
                                          type: 'error',
                                          title: 'Error',
                                          description:
                                            'Student without birthdate.',
                                        })
                                      : setOnlyStudentAge(
                                          prevState => !prevState
                                        )
                                  }
                                >
                                  Age
                                </Button>
                              </div>
                            </div> */}

                            <div
                              style={{ margin: '15px 0px' }}
                              className="form-group"
                            >
                              <label
                                className="inline"
                                htmlFor="restrictions_1"
                                style={{
                                  fontSize: '1.2rem',
                                  color: 'var(--gray)',
                                }}
                              >
                                Study lessons/hours per week
                              </label>
                              <Slider
                                getAriaLabel={() => 'Lessons/Hours per week'}
                                value={sliderValue}
                                max={40}
                                min={0}
                                onChange={handleChange}
                                valueLabelDisplay="auto"
                              />
                            </div>

                            <div
                              style={{ margin: '15px 0px' }}
                              className="form-group"
                            >
                              <input
                                id="courseFilterName"
                                placeholder="Program name"
                                value={courseName}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => setCourseName(e.target.value)}
                                style={{
                                  border: '1px solid var(--light-gray)',
                                  resize: 'none',
                                  color: 'var(--gray)',
                                  padding: '9px 15px',
                                  width: '100%',
                                }}
                              />
                            </div>

                            <div
                              className="promotion"
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                fontSize: '1.4rem',
                                color: 'var(--gray)',
                                margin: '15px 0px',
                              }}
                            >
                              <div className="promotion--title">
                                Promotion only
                              </div>
                              <div
                                style={{ margin: '0px' }}
                                className="promotion--switch"
                              >
                                <Switch
                                  checked={promotionOnly}
                                  checkedIcon={
                                    <span className="promotion--yes">YES</span>
                                  }
                                  uncheckedIcon={
                                    <span className="promotion--no">NO</span>
                                  }
                                  width={70}
                                  height={30}
                                  onChange={() =>
                                    setPromotionOnly(!promotionOnly)
                                  }
                                />
                              </div>
                            </div>

                            <ButtonWithLoading
                              isLoading={!readyToSearch}
                              style={{
                                width: '100px',
                                marginTop: '2rem',
                                padding: '7px',
                              }}
                              type="button"
                              className="btn-lg"
                              onClick={handleSearchClick}
                            >
                              Filter
                            </ButtonWithLoading>
                          </Dropdown.Menu>
                        </Dropdown>
                      </ResultHeader>
                    )}

                    <ButtonWithDropdown
                      list={sortOptions}
                      icon={MdSort}
                      onChangeSortOption={handleSortOptionChange}
                    />
                  </Actions>
                </ContentFilters>

                <ContentWrapper>
                  {searchLoading && (
                    <NoContent>
                      <Hero>
                        <Loading
                          loading={searchLoading}
                          color="var(--primary)"
                        />
                      </Hero>
                    </NoContent>
                  )}

                  {!searchLoading && !resultItems && (
                    <NoContent>
                      <Hero>
                        <img src={shuttleSvg} alt="Rocket going up" />
                        <h2>Create quotes much faster</h2>
                        <p>
                          Just select the destination countries or cities and
                          search for hundreds of courses, accommodations,
                          add-ons and much more in a single place
                        </p>
                      </Hero>
                    </NoContent>
                  )}

                  {!searchLoading && resultItems && resultItems.length === 0 && (
                    <>
                      {resultType === filtersOptions.FILTERS_COURSES &&
                        !isUserBackOffice &&
                        suggestiveResult.length > 0 && (
                          <AllyPlusResult>
                            <p>suggestion:</p>
                            {suggestiveResult.map((item, index) => (
                              <ListItem
                                key={index + 50}
                                data={item}
                                type={resultType}
                                onlyRenewal={onlyRenewal}
                                isSuggestive
                                handleDurationChange={handleDurationChange}
                                handleAddItemToQuote={handleAddItemToQuote}
                              />
                            ))}
                          </AllyPlusResult>
                        )}
                      <NoContent>
                        <Hero>
                          <img src={searchFailSvg} alt="Search has failed" />
                          <h2>No results found</h2>
                          <p>
                            We have not found any results for this specific
                            search. Please, try again with other filters.
                          </p>
                          {resultType ===
                            filtersOptions.FILTERS_EXPERIENCES && (
                            <>
                              <span>
                                To learn how to create an Experience, click here
                              </span>
                              <br />
                              <a
                                href="https://ally.tawk.help/article/como-criar-uma-experi%C3%AAncia"
                                target="_blank"
                                rel="noreferrer"
                              >
                                How to Create an Experience
                              </a>
                            </>
                          )}
                          {resultType !==
                            filtersOptions.FILTERS_EXPERIENCES && (
                            <>
                              <span>For more details </span>
                              <a
                                href="https://ally.tawk.help/article/visualizo-a-escola-na-busca-mas-n%C3%A3o-vejo-os-programas"
                                target="_blank"
                                rel="noreferrer"
                              >
                                click here
                              </a>
                            </>
                          )}
                        </Hero>
                      </NoContent>
                    </>
                  )}

                  {!searchLoading && resultItems && resultItems.length > 0 && (
                    <ResultWrapper>
                      <LoadingBar
                        color="blue"
                        progress={progress}
                        onLoaderFinished={() => setProgress(0)}
                      />
                      {resultType === filtersOptions.FILTERS_COURSES &&
                        !isUserBackOffice &&
                        suggestiveResult.length > 0 && (
                          <AllyPlusResult>
                            <p>suggestion:</p>
                            {suggestiveResult.map((item, index) => (
                              <ListItem
                                key={index + 50}
                                data={item}
                                type={resultType}
                                isSuggestive
                                handleDurationChange={handleDurationChange}
                                handleAddItemToQuote={handleAddItemToQuote}
                              />
                            ))}
                          </AllyPlusResult>
                        )}
                      <Result ref={listViewRef} id="scrollableListView">
                        {resultItems
                          .sort((a, b) => {
                            /* TODO: OrderBy orderBy  ordenação order by */
                            /*
                              Priorities:
                              1. data with price (-value to +value);
                              2. no value (-2000000);
                              3. loading data  (-2000000);
                              4. loaded but with price error (2000000);
                            */
                            if (readyToSearch) return false;
                            if (
                              a.loadedRealValues &&
                              resultType === filtersOptions.FILTERS_COURSES &&
                              a.coursecampus.priceError
                            ) {
                              return 2000000; // to go end of queue
                            }

                            if (
                              a.loadedRealValues &&
                              resultType ===
                                filtersOptions.FILTERS_ACCOMMODATIONS &&
                              a.priceError
                            ) {
                              return 2000000; // to go end of queue
                            }

                            if (
                              a.loadedRealValues &&
                              resultType ===
                                filtersOptions.FILTERS_INSURANCES &&
                              a.priceError
                            ) {
                              return 2000000; // to go end of queue
                            }

                            if (
                              a.loadedRealValues &&
                              resultType === filtersOptions.FILTERS_ADDONS &&
                              a.priceError
                            ) {
                              return 2000000; // to go end of queue
                            }

                            // here should be checked if is the data came from
                            // of a pre-fetch request or a new one.
                            // is a pre-fetch
                            let valA;
                            let valB;
                            if (
                              isIEAccount &&
                              selectedSortOpt.sortProp === 'popular'
                            ) {
                              console.log(selectedSortOpt.sortProp);
                              if (
                                resultType === filtersOptions.FILTERS_COURSES
                              ) {
                                valA = a.popular || 0;
                                valB = b.popular || 200000;
                              } else if (
                                resultType ===
                                filtersOptions.FILTERS_ACCOMMODATIONS
                              ) {
                                valA = a.popular || 0;
                                valB = b.popular || 200000;
                              } else if (
                                resultType ===
                                  filtersOptions.FILTERS_INSURANCES ||
                                resultType === filtersOptions.FILTERS_ADDONS
                              ) {
                                valA = a.popular || 0;
                                valB = b.popular || 200000;
                              }

                              if (a.loadedRealValues && b.loadedRealValues) {
                                return valB - valA;
                              }

                              // only b is a pos-fetch
                              if (b.loadedRealValues) {
                                return valB; // b in front on queue
                              }
                              // only a is a pos-fetch
                              if (a.loadedRealValues) {
                                return -valA; // a in front on queue
                              }

                              // neither is a pos-fetch, the 2 values is pre-fetch
                              return 2000000;
                            }
                            if (resultType === filtersOptions.FILTERS_COURSES) {
                              valA =
                                a.coursecampus?.totalThisCourse?.onlyCourse ||
                                0;
                              valB =
                                b.coursecampus?.totalThisCourse?.onlyCourse ||
                                200000;
                            } else if (
                              resultType ===
                              filtersOptions.FILTERS_ACCOMMODATIONS
                            ) {
                              valA =
                                a.totalThisAccommodation?.onlyAccommodation ||
                                0;
                              valB =
                                b.totalThisAccommodation?.onlyAccommodation ||
                                200000;
                            } else if (
                              resultType ===
                                filtersOptions.FILTERS_INSURANCES ||
                              resultType === filtersOptions.FILTERS_ADDONS
                            ) {
                              valA = a.totalThisFee?.value || 0;
                              valB = b.totalThisFee?.value || 200000;
                            }

                            if (a.loadedRealValues && b.loadedRealValues) {
                              return valA - valB;
                            }

                            // only a is a pos-fetch
                            if (a.loadedRealValues) {
                              return -valA; // a in front on queue
                            }
                            // only b is a pos-fetch
                            if (b.loadedRealValues) {
                              return valB; // b in front on queue
                            }

                            // neither is a pos-fetch, the 2 values is pre-fetch
                            return 2000000;
                          })
                          .map((item, idx) => (
                            <ListItem
                              key={idx}
                              data={item}
                              type={resultType}
                              onlyRenewal={onlyRenewal}
                              handleDurationChange={handleDurationChange}
                              handleAddItemToQuote={handleAddItemToQuote}
                            />
                          ))}
                        <ActionsButtons>
                          <InfiniteScrollButton
                            isLoading={!readyToSearch}
                            loadMoreClick={handleInfiniteScrollClick}
                            hasMore={infiniteLoadingHasMore}
                          />
                        </ActionsButtons>
                      </Result>
                    </ResultWrapper>
                  )}
                </ContentWrapper>
                <ReactTooltip effect="solid" />
              </>
            )}
            {currentStep === 3 && (
              <ContentWrapper>
                <ResultWrapper>
                  <ResultHeader
                    style={{
                      justifyContent: 'space-between',
                      margin: '20px 0',
                    }}
                  >
                    <h3>
                      {quotesData.howManyQuotes} Quotes
                      <small>Overview</small>
                    </h3>
                  </ResultHeader>

                  <Result>
                    <ListView id="carousel-slider">
                      <Carousel
                        responsive={responsiveCarousel}
                        arrows={false}
                        renderDotsOutside
                        partialVisible
                        showDots
                      >
                        {quotesData.quotes &&
                          quotesData.quotes.map((quote, index) => {
                            quote.plugAndPlay === 1
                              ? (quote.plugAndPlay = 1)
                              : (quote.plugAndPlay = 0);
                            return (
                              <ResumeItem
                                key={index}
                                index={index}
                                quoteId={quote.id}
                                quote={quote}
                                isLoadingNewValues={mainLoading}
                                dateOfReference={dateOfReference}
                                actionLoading={resumeActionLoading}
                                handleChangeItemValue={handleChangeItemValue}
                                officeCurrencies={officeCurrenciesOptions}
                                saleTypes={saleTypeOptions}
                                officeConfig={officeConfig}
                                currentFiltersTab={currentFiltersTab}
                                defaultDescription={defaultDescription}
                                handleUpdateCourseOptions={
                                  handleUpdateCourseOptions
                                }
                                handleUpdateSR={handleUpdateSR}
                                handleRemoveItemFromQuote={
                                  handleRemoveItemFromQuote
                                }
                                handleConvertQuoteValues={
                                  handleConvertQuoteValues
                                }
                                handleCurrencyValueChange={
                                  handleCurrencyValueChange
                                }
                                handleUpdateQuoteConfigPreferences={
                                  handleUpdateQuoteConfigPreferences
                                }
                                handleChangeQuoteSaleType={
                                  handleChangeQuoteSaleType
                                }
                                handleChangeQuoteDueDate={
                                  handleChangeQuoteDueDate
                                }
                                handleRenameQuote={handleRenameQuote}
                                handleChangeQuoteTranslation={
                                  handleChangeQuoteTranslation
                                }
                                handleDuplicateQuote={handleDuplicateQuote}
                                handleDeleteQuote={handleDeleteQuote}
                                handleBackLinkClick={handleBackLinkClick}
                              />
                            );
                          })}
                      </Carousel>
                    </ListView>
                  </Result>
                </ResultWrapper>
                <ReactTooltip effect="solid" />
              </ContentWrapper>
            )}
          </Content>
        </Wrapper>
      </Container>

      <AddItemToQuoteModal
        isOpen={openAddItemToQuoteModal}
        data={editingItem}
        isLoading={isAddItemLoading}
        isAddOptionLoading={isAddOptionLoading}
        fiveOptions={fiveOptions}
        defaultCheckedOptions={defaultCheckedOptions}
        setDefaultCheckedOptions={setDefaultCheckedOptions}
        handleCloseModal={() => setOpenAddItemToQuoteModal(false)}
        handleAddItemToQuoteSubmit={handleAddItemToQuoteSubmit}
        handleAddOption={handleAddOption}
        programStartDate={programStartDate}
      />
      <SuggestiveFeesModal
        isOpen={openSuggestiveFeeModal}
        isLoading={isAddItemLoading}
        useInternalFields={false}
        suggestiveFees={editingItemSuggestiveFees}
        handleSuggestionFeeDurationChange={handleSuggestionFeeDurationChange}
        handleAddSuggestiveFeeToQuote={handleAddSuggestiveFeeToQuote}
        onCloseModal={closeSuggestiveFeesModal}
      />
      {isSuggestiveFeesOpened ? (
        <SuggestiveFeesModal
          isLoading={isAddItemLoading}
          useInternalFields
          isOpen={isSuggestiveFeesOpened}
          suggestiveFees={editingItemSuggestiveFees}
          handleSuggestionFeeDurationChange={handleSuggestionFeeDurationChange}
          handleAddSuggestiveFeeToQuote={onAddingSugestionsOnEdition}
          onCloseModal={onCloseModalOnEdition}
        />
      ) : null}
    </>
  );
};

export default Playground;
