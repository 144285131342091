import { IErrorsSelectOptions } from '../interfaces';

export const filtersOptions = {
  FILTERS_COURSES: 'Courses',
  FILTERS_ACCOMMODATIONS: 'Accommodations',
  FILTERS_INSURANCES: 'Insurances',
  FILTERS_ADDONS: 'Add-ons',
  FILTERS_EXPERIENCES: 'Experiences',
  FILTERS_OTHERS: 'Others',
};

export const errorOptions: Record<string, IErrorsSelectOptions[]> = {
  valueError: [
    { value: '1', label: 'The registered period is wrong' },
    { value: '2', label: 'The enroll value is wrong' },
    { value: '3', label: 'The material value is wrong' },
    { value: '4', label: 'The value is higher' },
    { value: '5', label: 'The value is lower' },
    { value: '6', label: 'Wrong currency' },
    { value: '7', label: 'Other' },
  ],
  SRError: [
    { value: '8', label: 'The special rate is not applied' },
    { value: '9', label: 'The applied special rate is wrong' },
    { value: '10', label: 'Other' },
  ],
};
