import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ol {
    list-style: none;
    display: flex;
    align-items: center;

    li {
      & + li {
        &:before {
          content: '';
          display: inline-block;
          position: relative;
          top: -2px;
          width: 5px;
          height: 5px;
          transform: rotate(45deg);
          border-top: 1px solid var(--light-gray);
          border-right: 1px solid var(--light-gray);
          margin: 0 15px;
        }
      }

      &.active {
        color: var(--black);
      }
    }
  }
`;
