export default function updateCollapsiblesOverflow(tab: String): void {
  const collapsibleElements = document.getElementsByClassName('Collapsible');

  for (let i = 0; i < collapsibleElements.length; i++) {
    for (let j = 0; j < collapsibleElements[i].classList.length; j++) {
      const filtersElementContent = collapsibleElements[i].querySelectorAll(
        '.Collapsible__contentOuter'
      )[0] as HTMLElement;

      //  If the element iterated is the opened item
      if (collapsibleElements[i].classList[j] === `filters-${tab}`) {
        filtersElementContent.style.overflow = 'visible';
      } else {
        filtersElementContent.style.overflow = 'hidden';
      }
    }
  }
}
