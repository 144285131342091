import styled, { css } from 'styled-components';

interface HeaderBackgroundProps {
  backgroundUrl?: string;
}

interface ContainerProps {
  allyPlus?: boolean;
}

export const Container = styled.div<ContainerProps>`
  ${({ allyPlus }) =>
    allyPlus &&
    css`
      border: 2px solid;
      border-image-slice: 1;
      border-image-source: linear-gradient(to right, #e50069, #eb595d, #f59d24);
    `}

  background: var(--white);
  border-radius: 4px;
  overflow: hidden;
  margin: 0 7px;
  position: relative;

  > form {
    .form-group {
      label {
        display: flex;
        justify-content: space-between;
        font-size: 1.2rem;
        line-height: 1.6rem;
        margin-bottom: 5px;
      }

      & + .form-group {
        margin-top: 15px;
      }
    }

    .form-group--column {
      display: flex;

      .form-group--sale-type {
        flex: 1;
        margin-right: 10px;
      }

      .form-group--date {
        flex: 0.62;
      }
    }
  }

  .opacity {
    opacity: 0.5;
  }

  .quoteDueDate {
    border: 0;
    padding: 0;
    text-decoration: underline;
    cursor: pointer;
  }

  .quote-config-alert {
    color: var(--light-orange);
    font-size: 1.2rem;
    margin-top: 10px;

    > svg {
      margin-right: 5px;
      position: relative;
      top: 2px;
    }

    &.text-right {
      text-align: right;
    }
  }

  .hide {
    display: none !important;
  }
`;

export const ActionLoading = styled.div`
  position: absolute;
  background: var(--white-opacity);
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
`;

export const EmptyQuote = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 40px 20px;
  text-align: center;

  p {
    margin-top: 10px;
  }

  .empty-quote--actions {
    margin-top: 40px;
    width: 100%;

    .empty-quote--or {
      margin: 5px 0;
    }
  }
`;

export const Header = styled.div``;

export const HeaderTop = styled.div`
  height: 100px;
  position: relative;

  img {
    position: absolute;
    top: 12px;
    right: 60px;
  }

  > .dropdown {
    position: absolute;
    top: 12px;
    right: 12px;

    & + div {
      margin-left: 0;
    }
  }
`;

export const HeaderBackground = styled.div<HeaderBackgroundProps>`
  height: 100px;
  background-color: var(--divider);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  ${props =>
    props.backgroundUrl &&
    css`
      background-image: url(${props.backgroundUrl});
    `}
`;

export const HeaderBottom = styled.div`
  display: flex;
  position: relative;
  top: -30px;
  align-items: flex-end;
  flex-wrap: wrap;

  > span {
    margin-left: 15px;
    margin-right: 10px;
    position: relative;
    top: -9px;
  }
`;

export const Avatar = styled.div`
  width: 70px;
  height: 70px;
  border: 2px solid var(--divider);
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--white);
  flex-shrink: 0;

  margin-left: 15px;

  > img {
    width: 100%;
    max-width: 60px;
    max-height: 60px;
  }
`;

export const Option = styled.div`
  display: contents;
  align-items: center;
  justify-content: space-between;
  margin: 0 15px;

  > svg {
    color: var(--yellow);
    flex-shrink: 0;
    margin-left: 0.5rem;
  }
`;

export const OptionName = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 10px;

  display: inherit;
  align-items: center;

  input {
    border-radius: 0;
    padding-left: 10px;
    margin-right: 0.25rem;
    border: 1px solid var(--light-gray);

    overflow: hidden;
    text-overflow: ellipsis;
    top: -9px;
    position: relative;
  }

  input::placeholder {
    font-weight: 400;
  }

  input:disabled {
    background: transparent;
    border-color: transparent;
    padding-left: 0;
    top: -9px;
    position: relative;
  }

  > svg {
    color: var(--inline-link);
    margin-right: 15px;
    flex-shrink: 0;
    cursor: pointer;
    position: relative;
    top: -14px;

    &:hover {
      color: var(--inline-link-hover);
    }
  }

  > button {
    width: 26px;
    height: 26px;
    padding: 2px;
    margin-left: 3px;
  }
`;

export const Resume = styled.div`
  margin: 0 15px 20px 15px;

  .resume-city {
    display: flex;
    align-items: center;
    margin: 3px 0;

    > img {
      width: 26px;
      margin-right: 6px;
    }
  }

  .resume-details {
    font-size: 1.2rem;
  }
`;

export const ResumeInfo = styled.div``;

export const ResumeActions = styled.div`
  margin-top: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--divider);
`;

export const Price = styled.div`
  margin: 0 15px;

  .price-title {
    color: var(--black);
    font-size: 1.6rem;
  }

  .item-title {
    margin-bottom: 15px;
  }

  .preference-title {
    padding-top: 10px;
    margin-top: 15px;
    border-top: 1px solid var(--divider);
  }

  .price + .price {
    margin-top: 20px;
  }

  .price-course > .price-item--title {
    color: var(--green);
  }

  .price-accommodation > .price-item--title {
    color: var(--brown);
  }

  .price-fee > .price-item--title {
    color: var(--light-blue);
  }

  .price-otherfee > .price-item--title {
    color: var(--light-red);
  }

  .price-experience > .price-item--title {
    color: var(--orange);
  }

  .item {
    background: #f4f6f8;
    padding: 10px;
  }

  .item + .item {
    margin-top: 40px;
  }

  .price-item--title {
    font-weight: 700;
    margin-top: 5px;
  }

  .promotion-container {
    background: #ebf8ff;
    padding: 10px;
    margin-top: 10px;

    > strong {
      color: #3172b7;
    }

    .promotion-instruction--icon {
      cursor: pointer;
    }
  }

  .no-promotion-container {
    background: #fcf8e3;
    padding: 10px;
    margin-top: 10px;

    > strong {
      color: #3172b7;
    }

    .promotion-instruction--icon {
      cursor: pointer;
    }
  }

  .easy-edit-component-wrapper input {
    width: 100px;
    border-color: var(--gray);
    border-width: 0px 0px 0.1px 0px;
    text-align: center;
  }

  button[name='save'] {
    background-color: #53c22c;
    color: white;
  }

  .price-item-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
    margin-bottom: 5px;

    .price-item--right > .no-subtotals-actions {
      gap: 0.75rem;
      display: flex;
    }

    .no-subtotals-actions svg {
      display: flex;
    }

    &.title:hover {
      .price-item--right > .actions {
        gap: 0.75rem;
      }

      .price-item--right > .edited-tag {
        display: none;
      }

      svg {
        display: flex;
      }
    }

    .price-item {
      flex: 1;
      margin-top: 2px;

      > span {
        font-size: 1.1rem;
        color: var(--secondary);
        display: flex;
        align-items: center;
      }

      > .resume-city {
        font-size: 1rem;
        display: flex;
        gap: 0.5rem;
        align-items: center;

        > img {
          width: 1.8rem;
          height: 1.8rem;
        }
      }

      .resume-details--school {
        margin-top: 3px;
        font-size: 1.2rem;
      }

      .promotion-name {
        margin-left: 3px;
      }
    }

    .disabled {
      cursor: not-allowed;
    }

    .price-item--right {
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      margin-left: 1rem;

      > .actions {
        display: flex;
        align-items: center;
        margin-left: 10px;
        margin-top: 3px;
      }

      .price-item--old-value {
        font-size: 1.1rem;
        text-decoration: line-through;
        margin-bottom: 3px;

        > svg {
          color: var(--primary);
          margin-left: 5px;

          flex-shrink: 0;
          position: relative;
          top: 1px;
        }
      }

      .price-item--value {
        font-size: 1.2rem;
        font-weight: bold;
        color: var(--secondary);

        > svg {
          color: var(--inline-link);
          margin-left: 5px;

          flex-shrink: 0;
          cursor: pointer;
          position: relative;
          top: 2px;

          &:hover {
            color: var(--inline-link-hover);
          }
        }

        &.with-promotion {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          color: var(--primary);

          .with-promotion--icon {
            color: var(--primary);
            position: relative;
            top: 2px;
          }
        }
      }
    }
  }

  .discount-value {
    margin-top: 3px;
    font-size: 10px;
    text-decoration: line-through;
    color: var(--gray);
  }

  .price-item-info {
    font-size: 1rem;
    color: var(--gray);
    margin-top: 3px;

    > svg {
      // color: var(--inline-link);
      margin-right: 5px;

      flex-shrink: 0;
      cursor: pointer;
      position: relative;

      &:hover {
        color: var(--inline-link-hover);
      }
    }
  }

  .price-item-subtotal {
    display: flex;
    margin-top: 0.8rem;
    justify-content: space-between;

    > span {
      font-size: 1.1rem;
      text-align: right;

      .block {
        display: block;
      }
    }
  }

  .form-group--convert {
    margin: 20px 0;
  }

  .line-through {
    text-decoration: line-through;
  }

  .converted_value_without_sr {
    color: gray;
    font-size: 1.2rem;
  }

  .price-item-total {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;

    > span {
      color: var(--secondary);
    }

    > .price-item-total--value {
      font-size: 1.8rem;
      font-weight: 700;
      color: var(--primary);
      text-align: right;
    }
  }

  .price-item-currencies {
    font-size: 1.1rem;

    > div {
      text-align: right;

      > .editing-currency {
        display: inline-block;

        > .editable-text {
          color: var(--inline-link);
          border-bottom: 1px dashed var(--inline-link);
          margin-left: 4px;
          cursor: pointer;

          &:hover {
            color: var(--inline-link-hover);
            border-bottom: 1px dashed var(--inline-link-hover);
          }
        }

        > .editable-wrapper {
          margin-left: 5px;
          margin-bottom: 5px;
          display: flex;

          > input {
            width: 95px;
            color: var(--gray);
            border: 1px solid var(--light-gray);
            padding: 2px 5px;
          }

          > button {
            width: 25px;
            height: 25px;
            padding: 2px;
            margin-left: 3px;
          }
        }
      }

      & + div {
        margin-top: 5px;
      }
    }
  }

  .form-group--translation {
    margin: 10px 0 20px;
  }

  .form-group--preferences {
    > div {
      margin-top: 10px;
    }
  }

  .form-group--description {
    margin: 20px 0 30px;

    > .quote-description--label {
      justify-content: flex-start;
      align-items: center;

      > svg {
        color: var(--inline-link);
        margin-left: 5px;

        flex-shrink: 0;
        cursor: pointer;
        position: relative;

        &:hover {
          color: var(--inline-link-hover);
        }
      }
    }

    > .quote-description {
      color: var(--secondary);
    }
  }
`;

export const Details = styled.div`
  all: initial;
  cursor: pointer;
  transition: transform 200ms;
  color: var(--secondary);

  > svg {
    inline-block;
    margin-left: 5px;
    margin-right: 5px;
  }
`;

export const Instructions = styled.div`
  all: initial;
  cursor: pointer;
  transition: transform 200ms;
  margin-bottom: 2px;

  > svg {
    inline-block;
  }
`;

export const RemoveItem = styled.div`
  display: inline-block;
  align-items: center;
  border-radius: 50%;
  transition: transform 200ms;
  float: right;
  cursor: pointer;

  > svg {
    display: inline-block;
    position: relative;
    top: -1px;
  }
`;

export const PriceButtons = styled.div`
  margin: 20px 0;
  padding: 15px 0;
  border-top: 1px solid var(--divider);
  border-bottom: 1px solid var(--divider);

  button + button {
    margin-top: 15px;
  }

  button {
    > svg {
      margin-right: 5px;
      position: relative;
      top: 0px;
    }
  }
`;

export const PaymentPlanContainer = styled.div`
  margin-top: 20px;

  > div {
    text-align: center;
  }

  > .payment-plan--link {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--inline-link);
    cursor: pointer;

    > svg {
      margin-right: 5px;
    }

    &:hover {
      color: var(--inline-link-hover);
    }
  }
`;

export const SaleTypeContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  flex: 1;

  div {
    padding: 10px;
    background: var(--primary);
    color: var(--white);
    width: 81px;
    text-align: center;

    &.renewal-badge {
      background: var(--orange);
    }
  }

  p {
    width: 75px;
  }
`;

export const PaymentTypeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  flex-wrap: wrap;

  small {
    font-size: 1rem;
  }

  .entry {
    display: flex;
    justify-content: center;
    align-content: center;
  }

  .radio-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .radio-inputs {
    display: flex;
    justify-content: space-around;
    align-content: center;
  }

  .entry-input {
    width: 80px;
    display: flex;
    align-items: flex-end;
    margin-left: 10px;
  }

  .split-input {
    width: 16%;
    min-width: 65px;
    margin-left: 10px;
  }

  .input-disabled {
    opacity: 0.6;
  }

  .input-disabled:hover {
    cursor: not-allowed;
  }
`;
