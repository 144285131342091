import React from 'react';
import Modal from 'react-modal';
import { MdClose } from 'react-icons/md';
import Button from '../../../components/Button';
import ListItem from '../../../components/ListItem';
import {
  Container,
  ModalContent,
  ModalHeader,
} from '../../../modals/AddItemToQuoteModal/styles';
import { filtersOptions } from '../../../shared/constants';

interface SuggestiveFeesProps {
  isOpen: boolean;
  isLoading: boolean;
  useInternalFields?: boolean;
  suggestiveFees?: any[];
  onCloseModal(): void;
  handleAddSuggestiveFeeToQuote(arg: any): void;
  handleSuggestionFeeDurationChange(arg: any): void;
}

Modal.setAppElement('#root');

const SuggestiveFeesModal: React.FC<SuggestiveFeesProps> = ({
  isOpen,
  isLoading,
  onCloseModal,
  useInternalFields = false,
  suggestiveFees,
  handleAddSuggestiveFeeToQuote,
  handleSuggestionFeeDurationChange,
}) => {
  const calcModalPadding = () => {
    if (typeof suggestiveFees !== 'undefined') {
      return `${suggestiveFees.length * 50}px`;
    }
    return '50px';
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onCloseModal}
      style={{
        overlay: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'auto',
          backgroundColor: 'var(--overlay)',
          paddingBottom: '100px',
          paddingTop: calcModalPadding(),
        },
        content: {
          padding: 0,
          borderRadius: 0,
          position: 'initial',
          boxShadow: 'var(--modal-shadow)',
          border: '1px solid var(--divider)',
          marginTop: calcModalPadding(),
        },
      }}
    >
      <Container style={{ width: 'initial' }}>
        <ModalHeader>
          <span>We found some suggestions for your quote</span>
          <Button
            type="button"
            className="btn-transparent"
            onClick={onCloseModal}
          >
            <MdClose size={20} />
          </Button>
        </ModalHeader>

        <ModalContent>
          {suggestiveFees?.length &&
            suggestiveFees.map((fee, idx) => (
              <ListItem
                key={idx}
                data={fee}
                isLoading={isLoading}
                useInternalFields={useInternalFields}
                wasAdded={fee.wasAdded}
                type={filtersOptions.FILTERS_ADDONS}
                handleDurationChange={handleSuggestionFeeDurationChange}
                handleAddItemToQuote={handleAddSuggestiveFeeToQuote}
              />
            ))}
        </ModalContent>
      </Container>
    </Modal>
  );
};

export default SuggestiveFeesModal;
