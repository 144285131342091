import React, { useEffect, useRef, InputHTMLAttributes } from 'react';
import { useField } from '@unform/core';

import { Container } from './styles';

export interface Option {
  id: string;
  value: string;
  label: string;
  disabled?: boolean;
  allyPlus?: boolean;
}

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  options: Option[];
  className?: string;
}

const CheckboxInput: React.FC<CheckboxProps> = ({
  name,
  options,
  className,
  ...rest
}) => {
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const { fieldName, registerField, defaultValue = [] } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs: HTMLInputElement[]) =>
        refs.filter(ref => ref.checked).map(ref => ref.value),
      clearValue: (refs: HTMLInputElement[]) => {
        refs.forEach(ref => {
          ref.checked = false;
        });
      },
      setValue: (refs: HTMLInputElement[], values: string[]) => {
        refs.forEach(ref => {
          if (values.includes(ref.id)) {
            ref.checked = true;
          }
        });
      },
    });
  }, [defaultValue, fieldName, registerField]);

  return (
    <Container data-testid="checkbox-container">
      {options.map((option, index) => (
        <React.Fragment key={option.id}>
          <label htmlFor={option.id} className={className}>
            <input
              defaultChecked={defaultValue.find(
                (dv: string) => dv === option.id
              )}
              ref={ref => {
                inputRefs.current[index] = ref as HTMLInputElement;
              }}
              value={option.value}
              type="checkbox"
              id={option.id}
              disabled={option.disabled}
              {...rest}
            />
            <span className="checkmark" />
            <span className="checkbox-text">{option.label}</span>
          </label>
        </React.Fragment>
      ))}
    </Container>
  );
};

export default CheckboxInput;
