import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;

  & + div {
    margin-left: 10px;
  }
`;
