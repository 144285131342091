import React from 'react';
import Step from '../Step';
import { WizardProvider } from '../../hooks/wizard';

const Wizard: React.FC = () => (
  <WizardProvider>
    <Step />
  </WizardProvider>
);

export default Wizard;
