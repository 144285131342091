import React from 'react';
import Spinner from 'react-loading';

interface LoadingProps {
  width?: number | string;
  loading: boolean;
  delay?: number;
  color?: string;
}

const Loading: React.FC<LoadingProps> = ({
  width = 20,
  delay,
  loading,
  color = '#ffffff',
}) => {
  return (
    <>
      {loading && (
        <Spinner
          type="spin"
          height="auto"
          color={color}
          width={width}
          delay={delay}
          className="loading-spinner"
        />
      )}
    </>
  );
};

export default Loading;
