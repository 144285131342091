import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 300px;
  padding-bottom: 80px;
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--divider);
  padding: 15px;

  > span {
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
`;

export const ModalContent = styled.div`
  padding: 20px 15px;

  .form-group {
    margin: 20px 0 5px;

    > div {
      margin-top: 10px;
    }
  }
`;
