import styled from 'styled-components';

export const Container = styled.div`
  width: 500px;
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--divider);
  padding: 15px;

  > span {
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
`;

export const ModalContent = styled.div`
  padding: 20px 15px;

  .form-group {
    margin: 20px 0 5px;

    > div {
      margin-top: 10px;
    }
  }
`;

export const ErrorMessage = styled.div`
  span {
    display: block;
    width: 100%;
    text-align: center;
    color: red;
  }
`;

export const SelectContainer = styled.div`
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 470px;
  margin-top: 10px;
`;

export const TextAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 10px 0;
  width: 470px;

  textarea {
    border: 1px solid var(--light-gray);
    resize: none;
    padding: 5px;
    color: var(--gray);
  }
`;

export const AddFileContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 10px 0;
  width: 470px;

  label {
    width: 100px;
    padding: 5px;
    background-color: #4d4c4c;
    color: var(--white);
    text-align: center;
    display: block;
    cursor: pointer;
  }
`;

export const WarningContainer = styled.div`
  background: #fcf8e3;
  max-height: 400px;
  overflow: auto;
  padding: 15px;
  width: initial;
  margin-bottom: 10px;

  h3 {
    color: var(--primary);
  }
`;

export const RadioContainer = styled.div`
  display: flex;
  margin-bottom: 10px;

  label {
    display: flex;
    align-items: center;
    margin-right: 20px;
    display: flex;
    align-items: center;

    position: relative;
    padding-left: 28px;

    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    & + label {
      margin-top: 10px;
    }

    /* Hide the browser's default radio */
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      margin-right: 8px;
    }

    /* Create a custom radio */
    .checkmark {
      position: absolute;
      left: 0;
      height: 19px;
      width: 19px;
      background-color: var(--white);
      border: 1px solid var(--light-gray);
      border-radius: 50%;
    }

    /* When the radio is checked, add a primary border */
    input:checked ~ .checkmark {
      border-color: var(--primary);
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .checkmark:after {
      left: 6px;
      top: 4px;
      width: 4px;
      height: 8px;
      border: solid var(--primary);
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    .radio-text {
      font-size: 1.2rem;
      color: var(--secondary);
      position: relative;
      top: 1px;
    }
  }
`;
