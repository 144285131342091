import styled, { css } from 'styled-components';
import RSelect from 'react-select';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  background: var(--white);
  width: 100%;

  border: 1px solid var(--light-gray);
  color: var(--gray);

  display: flex;
  align-items: center;

  & + div {
    margin-top: 8px;
  }

  ${props =>
    props.isErrored &&
    css`
      border-color: var(--red);
    `}

  ${props =>
    props.isFocused &&
    css`
      /* color: var(--primary); */
      border-color: var(--primary);
    `}

  ${props =>
    props.isFilled &&
    css`
      /* color: var(--primary); */
    `}
`;

export const ReactSelect = styled(RSelect)`
  flex: 1;

  .react-select__control {
    border-radius: 0;
    border: 0;
    box-shadow: none;
    min-height: 37px;

    &:hover,
    &:active,
    &:focus {
      border: 0;
    }

    .react-select__single-value {
      .option-with-image {
        display: flex;
        align-items: center;

        .flag {
          width: 20px;
          margin-right: 10px;
        }
      }
    }
  }

  .react-select__menu {
    margin: 0;

    .react-select__option {
      .option-with-image {
        display: flex;
        align-items: center;

        .flag {
          width: 20px;
          margin-right: 10px;
        }
      }
    }
  }

  &.react-select--is-disabled {
    border: 1px solid var(--light-gray);
  }

  &.input-lg {
    .react-select__control {
      .react-select__value-container {
        padding: 10px 8px !important;
      }

      .react-select__single-value {
        .option-with-image {
          .flag {
            width: 25px;
          }
        }
      }
    }

    .react-select__menu {
      .react-select__option {
        .option-with-image {
          .flag {
            width: 25px;
          }
        }
      }
    }
  }
`;

export const OptionLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    max-width: 234px;
  }

  div {
    width: 25px;

    img {
      width: 100%;
    }
  }
`;
