import React from 'react';
import { Link } from 'react-router-dom';

import { Container } from './styles';

interface BreadcrumbOption {
  label: string;
  active: boolean;
}

interface BreadcrumbProps {
  options: BreadcrumbOption[];
  className?: string;
  backToPlayground?: () => void;
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({
  options,
  className,
  backToPlayground,
}) => {
  function shouldGoBack(option: BreadcrumbOption): void {
    if (option.label === 'Quote playground') {
      backToPlayground?.();
    }
  }

  return (
    <Container className={className}>
      <ol style={{ marginBottom: '0px' }}>
        {options.map((option, index) => {
          return option.active ? (
            <li key={index} className={option.active ? 'active' : ''}>
              <Link
                to="/"
                style={{ color: 'inherit', textDecoration: 'none' }}
                onClick={() => shouldGoBack(option)}
              >
                {option.label}
              </Link>
            </li>
          ) : null;
        })}
      </ol>
    </Container>
  );
};

export default Breadcrumb;
