import React, { useEffect, useRef, InputHTMLAttributes } from 'react';
import { useField } from '@unform/core';

import { Container } from './styles';
import allyPlus from '../../../../assets/allyPlus.png';

interface Option {
  id: number;
  label: string;
  allyPlus?: boolean;
}

interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  options: Option[];
  sendValue: any;
  valueWasExpired?: boolean;
  isSellDirectlyDefault?: boolean;
}

const RadioInput: React.FC<RadioProps> = ({
  name,
  options,
  sendValue,
  valueWasExpired,
  isSellDirectlyDefault,
  ...rest
}) => {
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const { fieldName, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs: HTMLInputElement[]) => {
        const checked = refs.find(ref => ref.checked);

        return checked ? checked.value : '';
      },
      setValue: (refs: HTMLInputElement[], value: string) => {
        const item = refs.find(ref => ref.value === value);

        if (item) {
          item.checked = true;
        }
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container data-testid="radio-container">
      {options.map((option, index) => (
        <label key={option.id}>
          <input
            defaultChecked={
              valueWasExpired || isSellDirectlyDefault
                ? option.label === 'Sell directly'
                : index === 0
            }
            ref={ref => {
              inputRefs.current[index] = ref as HTMLInputElement;
            }}
            name={fieldName}
            value={option.id}
            type="radio"
            id={String(option.id)}
            onChange={e => {
              sendValue(e.target.value);
            }}
            {...rest}
          />
          <span className="checkmark" />

          {option.allyPlus ? (
            <>
              <span className="radio-text">{option.label}</span>
              <img src={allyPlus} alt="allyPlus" />
            </>
          ) : (
            <span className="radio-text">{option.label}</span>
          )}
        </label>
      ))}
    </Container>
  );
};

export default RadioInput;
