import React, {
  useEffect,
  useRef,
  InputHTMLAttributes,
  useCallback,
  useState,
} from 'react';
import { useField } from '@unform/core';

import { Container } from './styles';
import Tooltip from '../Tooltip';

export interface Option {
  isChecked: boolean;
  id: string;
  value: string;
  label: string;
  subtitle: string;
  message?: string;
  isDifferentSchools?: boolean;
  disabled?: boolean;
  allyPlus?: boolean;
}

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  options: Option[];
  className?: string;
}

const QuoteOption: React.FC<CheckboxProps> = ({
  name,
  options,
  className,
  ...rest
}) => {
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const { fieldName, registerField, defaultValue = [] } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs: HTMLInputElement[]) =>
        refs.filter(ref => ref.checked).map(ref => ref.value),
      clearValue: (refs: HTMLInputElement[]) => {
        refs.forEach(ref => {
          ref.checked = false;
        });
      },
      setValue: (refs: HTMLInputElement[], values: string[]) => {
        refs.forEach(ref => {
          if (values.includes(ref.id)) {
            ref.checked = true;
          }
        });
      },
    });
  }, [defaultValue, fieldName, registerField]);

  return (
    <Container data-testid="checkbox-container">
      {options.map((option, index) => (
        <React.Fragment key={option.id}>
          {option.disabled && (
            <Tooltip title={option.message}>
              <label
                htmlFor={option.id}
                className={`${
                  option.allyPlus
                    ? 'quoteOptionAllyPlus quoteOptionDisabled'
                    : 'quoteOption quoteOptionDisabled'
                }`}
              >
                <input
                  ref={ref => {
                    inputRefs.current[index] = ref as HTMLInputElement;
                  }}
                  type="checkbox"
                  {...rest}
                  checked={false}
                />
                <span className="checkmark" />
                <h2 className="checkbox-text">{option.label}</h2>
                <span className="checkbox-text">{option.subtitle}</span>
              </label>
            </Tooltip>
          )}
          {!option.disabled && (
            <label
              htmlFor={option.id}
              className={
                option.allyPlus ? 'quoteOptionAllyPlus' : 'quoteOption'
              }
            >
              <input
                defaultChecked={defaultValue.find(
                  (dv: string) => dv === option.id
                )}
                ref={ref => {
                  inputRefs.current[index] = ref as HTMLInputElement;
                }}
                value={option.value}
                type="checkbox"
                id={option.id}
                {...rest}
              />
              <span className="checkmark" />
              <h2 className="checkbox-text">{option.label}</h2>
              <span className="checkbox-text">{option.subtitle}</span>
              {option.isDifferentSchools && (
                <span className="warning">
                  <b>WARNING: Items are from different schools</b>
                </span>
              )}
            </label>
          )}
        </React.Fragment>
      ))}
    </Container>
  );
};

export default QuoteOption;
