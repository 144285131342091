import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import api from '../services/api';

interface User {
  editValues: number;
  id: number;
  name: string;
  email: string;
  account_id: number;
  tourStep: number;
  office_id: number;
  role: string;
  plugAndPlay: number;
  preference: {
    getOnlyAvailableProgram: boolean;
    allyplus?: number;
  };
  office: {
    city: any;
    quoteDuration: number;
    default_code: string;
    currency: {
      code: string;
      name: string;
      symbol: string;
    };
    account: {
      moduleType: number;
      type: number;
      agency_name: string;
      allyPlus?: number;
    };
    plugAndPlay: {
      office_id?: number;
    };
    name: string;
  };
}

interface AuthState {
  token: string;
  user: User;
  angularPort?: MessagePort;
  toEditQuoteId?: number;
  isPP?: number;
  studentId?: number;
}

interface AuthContextData {
  user: User;
  angularPort?: MessagePort;
  toEditQuoteId?: number;
  isPP?: number;
  studentId?: number;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>({} as AuthState);

  const signIn = useCallback((event: MessageEvent) => {
    if (event.origin !== process.env.REACT_APP_NG_URL) return;

    const { token, user, toEditQuoteId, studentId, isPP } = event.data;

    api.defaults.headers.authorization = `Bearer ${token}`;

    setData({
      token,
      user: JSON.parse(user),
      angularPort: event.ports[0],
      toEditQuoteId,
      studentId,
      isPP,
    });
  }, []);

  useEffect(() => {
    window.addEventListener('message', signIn);

    return () => {
      window.removeEventListener('message', signIn);
    };
  }, [signIn]);

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        angularPort: data.angularPort,
        toEditQuoteId: data.toEditQuoteId,
        studentId: data.studentId,
        isPP: data.isPP,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  return context;
}

export { AuthProvider, useAuth };
