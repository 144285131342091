import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import Wizard from '../pages/Wizard';
import Loading from '../pages/Loading';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/loading" exact component={Loading} />
    <Route path="/" exact component={Wizard} isPrivate />
  </Switch>
);

export default Routes;
