import React, { useEffect } from 'react';
import Loading from '../../components/Loading';

const LoadingPage: React.FC = () => {
  const loading = true;

  return (
    <div
      style={{
        paddingTop: '30rem',
      }}
    >
      <Loading loading={loading} color="var(--primary)" width={40} />
    </div>
  );
};

export default LoadingPage;
