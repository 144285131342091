import { darken, lighten, shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 1rem;
  margin-top: 0;
  border-radius: 4px;
  overflow-y: scroll;
  background-color: ${lighten(0.3, '#f7941d')};

  gap: 2rem;
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;

  // > *:not(:last-child)::after {
  //   content: '';
  //   left: 8px;
  //   right: 8px;
  //   bottom: 80%;
  //   height: 1px;
  //   position: absolute;
  //   background-color: ${lighten(0.15, '#f7941d')};
  // }
`;

export const WarningTitle = styled.h3`
  margin: 0;
  color: var(--primary);
  padding: 10px 0px 0px 0px;
`;

export const Title = styled.h2`
  margin-bottom: 0.5rem;
`;

export const Description = styled.span`
  white-space: break-spaces;
  color: ${darken(0.3, '#f7941d')};
`;
