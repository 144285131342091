import React, { FC, useCallback, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { MdClose } from 'react-icons/md';
import { IComissionModal } from '../../shared/interfaces';
import {
  Button,
  Container,
  LoadingContainer,
  ModalContent,
  ModalHeader,
  WarningContainer,
} from './styles';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import Loading from '../../components/Loading';
import formatPrice from '../../utils/formatPrice';

interface ComissionTotalValue {
  school_id: number;
  plugAndPlay: number;
  quote_id: number;
  tuitionValue: number;
  margin: number;
  soldValue: number;
  value: number;
  currency_code: string;
  sold_currency_code: string;
  created_at: string;
  updated_at: string;
}

interface Comission {
  totalDiscount: number;
  commissionRequest: number;
  commissionTotal: ComissionTotalValue[];
}

const ComissionModal: FC<IComissionModal> = ({
  isOpen,
  setIsComissionModalOpened,
  quoteCode,
  quoteId,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [comission, setComission] = useState<Comission>({
    totalDiscount: 0,
    commissionRequest: 0,
    commissionTotal: [],
  });

  const { addToast } = useToast();

  const getComissionPrice = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await api.get<Comission>(
        '/calculatecommissionplugAndplay?saveCommission=false',
        {
          params: {
            quote_id: quoteId,
          },
        }
      );

      setComission(response.data);
    } catch (e: any) {
      setIsComissionModalOpened(false);
      addToast({
        title: 'Error',
        type: 'error',
        description: e?.message || 'An error has occurred, please try again.',
      });
    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading, addToast, quoteId, setIsComissionModalOpened]);

  useEffect(() => {
    getComissionPrice();
  }, [getComissionPrice]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsComissionModalOpened(false)}
      style={{
        overlay: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          backgroundColor: 'var(--overlay)',
        },
        content: {
          padding: 0,
          borderRadius: 0,
          position: 'initial',
          boxShadow: 'var(--modal-shadow)',
          border: '1px solid var(--divider)',
          width: '350px',
          overflowX: 'hidden',
        },
      }}
    >
      <Container>
        <ModalHeader>
          <span>{`My comission ${quoteCode}`}</span>
          <Button
            type="button"
            className="btn-transparent"
            onClick={() => setIsComissionModalOpened(false)}
          >
            <MdClose size={20} />
          </Button>
        </ModalHeader>

        {isLoading ? (
          <LoadingContainer>
            <Loading loading color="var(--primary)" />
          </LoadingContainer>
        ) : (
          <ModalContent>
            <div className="divider">
              <span>Comission without discount:</span>
              <span>{formatPrice(comission.commissionRequest, 'BRL')}</span>
            </div>
            <div className="divider">
              <span>Quote discount:</span>
              <span>{formatPrice(comission.totalDiscount, 'BRL')}</span>
            </div>
            <div className="total">
              <span>Total comission:</span>
              <span>
                {formatPrice(
                  comission.commissionRequest + comission.totalDiscount,
                  'BRL'
                )}
              </span>
            </div>
            <WarningContainer>
              <h3>Warning!</h3>
              <span>
                Estimated commission, calculated on currency conversion based on
                the sales order date
              </span>
            </WarningContainer>

            <Button
              type="button"
              className="btn-outline"
              onClick={() => setIsComissionModalOpened(false)}
            >
              Ok
            </Button>
          </ModalContent>
        )}
      </Container>
    </Modal>
  );
};

export default ComissionModal;
