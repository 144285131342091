import React, {
  useRef,
  useMemo,
  useEffect,
  useState,
  useCallback,
} from 'react';
import ReactModal from 'react-modal';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { MdClose } from 'react-icons/md';

import { MdAdd } from 'react-icons/md';

import { format, parseJSON } from 'date-fns';
import Button from '../../components/Button';
import { Option } from '../../components/CheckboxInput';

import { useWizard } from '../../hooks/wizard';

import { useAuth } from '../../hooks/auth';

import { filtersOptions } from '../../shared/constants';
import { ListItemFormData } from '../../shared/interfaces';

import {
  ContainerAddModal,
  ModalHeader,
  ModalContent,
  ButtonContainer,
} from './styles';
import api from '../../services/api';
import Warnings from './components/Warnings';
import QuoteOption from '../../components/QuoteOption';
import formatDate from '../../utils/formateDate';
import formatPrice from '../../utils/formatPrice';
import RadioInput from './components/RadioInputAllyPlus';
import allyPlusImage from '../../assets/ally_plus_main.png';
import allyPlusLogo from '../../assets/logo_ally_plus.png';
import { useToast } from '../../hooks/toast';

interface ModalProps {
  isOpen: boolean;
  isLoading?: boolean;
  isAddOptionLoading?: boolean;
  fiveOptions: boolean;
  programStartDate: Date;
  defaultCheckedOptions: boolean;
  data: ListItemFormData;
  setDefaultCheckedOptions: (data: boolean) => void;
  handleCloseModal: () => void;
  handleAddItemToQuoteSubmit: (data: any) => void;
  handleAddOption: () => void;
}

ReactModal.setAppElement('#root');

const AddItemToQuoteModal: React.FC<ModalProps> = ({
  data,
  programStartDate,
  isOpen,
  isLoading,
  isAddOptionLoading,
  fiveOptions,
  defaultCheckedOptions,
  setDefaultCheckedOptions,
  handleCloseModal,
  handleAddItemToQuoteSubmit,
  handleAddOption,
}) => {
  const [warnings, setWarnings] = useState([]);
  const [isWarned, setIsWarned] = useState(false);
  const [futureCourse, setFutureCourse] = useState(false);
  const [futurePrice, setFuturePrice] = useState(false);
  const warningSearch = useRef(0);
  const modalFormRef = useRef<FormHandles>(null);

  const { addToast } = useToast();

  const sendValue = (value: '0' | '1') => {
    setRadioValue(Number(value));
    setDefaultCheckedOptions(false);
  };

  const { quotesData } = useWizard();

  const itemAsJSON = data.data ? JSON.parse(data.data) : null;
  const { user } = useAuth();

  const userType = useMemo(() => {
    const isAccountAllyPlus = user.office.account.allyPlus === 1;
    const isOfficeAllyPlus = Boolean(user.office.plugAndPlay);
    if (isAccountAllyPlus) {
      return 'allyplus';
    }
    if (!isAccountAllyPlus && isOfficeAllyPlus) {
      return 'hybrid';
    }
    return 'backoffice';
  }, [user]);

  const isIEAccount = user.account_id === 2015;

  const isValueExpired: boolean = useMemo(() => {
    if (!itemAsJSON) return '';
    const { type } = data;

    return type === 'Courses'
      ? itemAsJSON.coursecampus.priceIsExpired &&
          !itemAsJSON.coursecampus.priceIsAboutToExpire &&
          !itemAsJSON.coursecampus.srIsAboutToExpire
      : itemAsJSON.priceIsExpired &&
          !itemAsJSON.priceIsAboutToExpire &&
          !itemAsJSON.srIsAboutToExpire;
  }, [itemAsJSON, data]);
  const [radioValue, setRadioValue] = useState(!isValueExpired ? 1 : 0);

  const isItemAllyPlus = itemAsJSON ? Boolean(itemAsJSON.isAllyPlus) : '';
  const isItemBackoffice = itemAsJSON ? Boolean(itemAsJSON.isBackoffice) : '';
  const initialRadioValue =
    isItemAllyPlus && userType !== 'backoffice' && !isValueExpired ? 1 : 0;

  const itemsRadioInput = useMemo(() => {
    const sellDirectly = [{ id: 0, label: 'Sell directly' }];
    const sellWithAllyPlus = [{ id: 1, label: 'Sell with', allyPlus: true }];
    const sellWithAny = [
      { id: 1, label: 'Sell with', allyPlus: true },
      { id: 0, label: 'Sell directly' },
    ];

    if (isIEAccount) {
      return sellDirectly;
    }
    if (!itemAsJSON) {
      return sellWithAny;
    }
    if (userType !== 'allyplus') {
      if (isItemAllyPlus) {
        return isItemBackoffice ? sellWithAny : sellWithAllyPlus;
      }
      return sellDirectly;
    }
    return sellWithAllyPlus;
  }, [userType, isItemAllyPlus, isItemBackoffice, itemAsJSON, isIEAccount]);

  const itemType = useMemo(() => {
    const { type } = data;
    if (!itemAsJSON || !type) {
      return '';
    }

    if (type === filtersOptions.FILTERS_COURSES) {
      return 'courses';
    }
    if (type === filtersOptions.FILTERS_ACCOMMODATIONS) {
      return 'accommodations';
    }
    if (
      type === filtersOptions.FILTERS_INSURANCES ||
      type === filtersOptions.FILTERS_ADDONS ||
      type === filtersOptions.FILTERS_EXPERIENCES
    ) {
      return 'additional';
    }
    return null;
  }, [data, itemAsJSON]);

  const isCourse = useMemo(() => {
    const { type } = data;
    if (!itemAsJSON || !type) {
      return '';
    }
    return type === filtersOptions.FILTERS_COURSES;
  }, [itemAsJSON, data]);

  const itemName = useMemo(() => {
    const { type } = data;
    if (!itemAsJSON || !type) {
      return '';
    }

    if (type === filtersOptions.FILTERS_COURSES) {
      return itemAsJSON.coursecampus.course.name;
    }
    if (type === filtersOptions.FILTERS_ACCOMMODATIONS) {
      return `${itemAsJSON.name} - ${itemAsJSON.type} - ${itemAsJSON.regime}`;
    }
    if (
      type === filtersOptions.FILTERS_INSURANCES ||
      type === filtersOptions.FILTERS_ADDONS ||
      type === filtersOptions.FILTERS_EXPERIENCES
    ) {
      return itemAsJSON.name;
    }
    return '';
  }, [data, itemAsJSON]);

  const itemSchool_id = useMemo(() => {
    if (!itemAsJSON) {
      return '';
    }

    return data?.type === filtersOptions.FILTERS_COURSES
      ? itemAsJSON.coursecampus.campus.school_id
      : itemAsJSON.campus?.school_id ||
          itemAsJSON.campus?.campus?.school_id ||
          undefined;
  }, [data?.type, itemAsJSON]);

  const itemPricePeriod = useMemo(
    () => (itemAsJSON ? itemAsJSON.pricePeriod : ''),
    [itemAsJSON]
  );

  const itemNameSchool = useMemo(() => {
    if (!data.type || itemAsJSON) {
      return '';
    }

    return data.type === filtersOptions.FILTERS_COURSES
      ? itemAsJSON.coursecampus.campus.name
      : itemAsJSON.campus?.name || undefined;
  }, [itemAsJSON, data]);

  const quotesOptions = useCallback((): any[] => {
    let options: Option[] = [];
    if (quotesData.quotes && quotesData.quotes.length > 0) {
      options = quotesData.quotes.map((quote, index) => {
        const isRenewal = quote.courses.some(
          ({
            coursecampus: {
              course: { renewal },
            },
          }: any) => renewal === 1
        );
        return {
          id: `option_${index + 1}`,
          value: index.toString(),
          subtitle: quote.subtitle,
          message:
            isCourse &&
            quote.subtitle !== '' &&
            radioValue !== quote.plugAndPlay
              ? 'Item can not be added on this type of option'
              : 'Items from another school on the same quote are not allowed',
          isDifferentSchools:
            quote.school_id &&
            itemSchool_id &&
            quote.school_id !== itemSchool_id,
          label: quote.config?.name || `Option ${index + 1}`,
          disabled:
            (quote.school_id &&
              itemSchool_id &&
              quote.school_id !== itemSchool_id &&
              quote.plugAndPlay &&
              !isRenewal) ||
            (isCourse &&
              quote.subtitle !== '' &&
              radioValue !== quote.plugAndPlay),
          allyPlus: quote.typeOption === 'allyplus',
        };
      });
    }

    return options;
  }, [isCourse, itemSchool_id, quotesData.quotes, radioValue]);

  const onClickAddOption = (): void => {
    handleAddOption();
    setDefaultCheckedOptions(true);
  };

  const onClickCloseModal = (): void => {
    if (isLoading === false) {
      setDefaultCheckedOptions(false);
      handleCloseModal();
      setRadioValue(initialRadioValue);
    }
  };

  const onClickAddSelected = (): void => {
    setDefaultCheckedOptions(false);
  };

  const onClickContactButton = async () => {
    if (user.role === 'admin') {
      window.open(`${process.env.REACT_APP_NG_URL}/settings`);
      return;
    }

    api
      .get('/sendmailnewrequestallyplus', {
        params: {
          userName: user.name,
          agencyName: user.office.account.agency_name,
        },
      })
      .then(() => {
        addToast({
          title: 'Sucesso',
          description:
            'Breve um agente entrará em contato com mais informações.',
          type: 'success',
        });
      })
      .catch((e: any) => {
        addToast({
          title: 'Erro',
          description:
            e.message || 'Occoreu um erro. Por favor, tente novamente.',
          type: 'error',
        });
      })
      .finally(handleCloseModal);
  };

  const onClickTalkButton = () =>
    window.open('https://calendly.com/ricardolemos');

  const fetchWarnings = useCallback(async (): Promise<void> => {
    if (
      itemAsJSON &&
      data?.type === filtersOptions.FILTERS_COURSES &&
      warningSearch.current !== data.id
    ) {
      warningSearch.current = data.id;
      const res = await api.get(`/warning/${data.id}`);
      setWarnings(res.data);

      await api
        .get(`/firstprice/${itemAsJSON.id}`, {
          params: {
            country: quotesData.student.nationalityCountry,
            courseStartDateFrom: format(
              parseJSON(programStartDate),
              'dd/MM/yyyy'
            ),
            duration: itemAsJSON.coursecampus.duration,
            enrolDate: format(parseJSON(programStartDate), 'dd/MM/yyyy'),
            student_id: quotesData.student.id,
          },
        })
        .then(response => {
          if (!programStartDate) return;
          const courseStartYear = programStartDate.getFullYear();
          const thisDate = new Date();
          const thisYear = thisDate.getFullYear();

          if (courseStartYear > thisYear) {
            setIsWarned(true);
            setFutureCourse(true);
            if (response.data.price) {
              setFuturePrice(true);
            } else {
              setFuturePrice(false);
            }
          } else {
            setFuturePrice(false);
            setFutureCourse(false);
          }
        });
    }
  }, [
    data.id,
    data.type,
    itemAsJSON,
    programStartDate,
    quotesData.student.id,
    quotesData.student.nationalityCountry,
    warningSearch,
  ]);

  useEffect(() => {
    if (!data.id) return;

    quotesData?.quotes?.forEach(quote => {
      quote.subtitle = '';
      if (quote.courses.length > 0) {
        if (
          quote.courses[0].course?.campus.city &&
          quote.courses[0].course?.campus.country
        ) {
          quote.subtitle = `${quote.courses[0].course.campus.city.name} - ${
            quote.courses[0].course.campus.school.name
          } - ${quote.courses[0].course.course.name} (${
            quote.courses[0].duration
          } ${quote.courses[0].pricePeriod}${
            quote.courses[0].duration > 1 ? 's' : ''
          })`;
        } else if (quote.courses[0].course?.campus.country) {
          quote.subtitle = `${quote.courses[0].course.campus.country.name} - ${
            quote.courses[0].course.campus.school.name
          } - ${quote.courses[0].course.course.name} (${
            quote.courses[0].duration
          } ${quote.courses[0].pricePeriod}${
            quote.courses[0].duration > 1 ? 's' : ''
          })`;
        } else if (
          quote.courses[0].coursecampus?.campus?.city &&
          quote.courses[0].coursecampus?.campus?.country
        ) {
          quote.subtitle = `${
            quote.courses[0].coursecampus?.campus?.city?.name
          } - ${quote.courses[0].coursecampus?.campus?.school?.name} - ${
            quote.courses[0].coursecampus?.course?.name
          } (${quote.courses[0].coursecampus?.duration} ${
            quote.courses[0]?.pricePeriod
          }${quote.courses[0].coursecampus?.duration > 1 ? 's' : ''})`;
        } else if (quote.courses[0].coursecampus?.campus?.country) {
          quote.subtitle = `${
            quote.courses[0].coursecampus?.campus?.country?.name
          } - ${quote.courses[0].coursecampus?.campus?.school?.name} - ${
            quote.courses[0].coursecampus?.course?.name
          } (${quote.courses[0].coursecampus?.duration} ${
            quote.courses[0]?.pricePeriod
          }${quote.courses[0].coursecampus?.duration > 1 ? 's' : ''})`;
        } else if (quote.courses[0].course) {
          quote.subtitle = `${
            quote.courses[0].course?.course.campus.country.name
          } - ${quote.courses[0].course?.course.campus.school.name} - ${
            quote.courses[0].course.course.name
          } (${quote.courses[0].duration} ${quote.courses[0].pricePeriod}${
            quote.courses[0].duration > 1 ? 's' : ''
          })`;
        }

        if (
          quote.accommodations.length > 0 &&
          !quote.subtitle.includes('Accommodation')
        ) {
          quote.subtitle += ' + Accommodation';
        }

        if (quote.fees.length > 0) {
          quote.fees.forEach((item: { category_id: number }) => {
            if (
              item.category_id === 4 &&
              !quote.subtitle.includes('Insurance')
            ) {
              quote.subtitle += ' + Insurance';
            }
          });
          quote.fees.forEach((item: { category_id: number }) => {
            if (item.category_id !== 4 && !quote.subtitle.includes('Add-ons')) {
              quote.subtitle += ' + Add-ons';
            }
          });
        }
      }

      if (
        !quote.subtitle &&
        quote.accommodations.length > 0 &&
        quote.courses.length === 0
      ) {
        quote.subtitle = `${quote.accommodations[0].city?.name} - ${
          quote.accommodations[0].partner
        } - ${
          quote.accommodations[0].name
        }(${`${quote.accommodations[0].duration} ${quote.accommodations[0].pricePeriod}`}${
          quote.accommodations[0].duration > 1 ? 's' : ''
        })`;

        if (quote.fees.length > 0) {
          quote.fees.forEach((item: { category_id: number }) => {
            if (
              item.category_id === 4 &&
              !quote.subtitle.includes('Insurance')
            ) {
              quote.subtitle += ' + Insurance';
            }
          });
          quote.fees.forEach((item: { category_id: number }) => {
            if (item.category_id !== 4 && !quote.subtitle.includes('Add-ons')) {
              quote.subtitle += ' + Add-ons';
            }
          });
        }
      }

      if (
        !quote.subtitle &&
        quote.fees.length > 0 &&
        quote.accommodations.length === 0 &&
        quote.courses.length === 0
      ) {
        quote.subtitle = `${quote.fees[0].partner} - ${
          quote.fees[0].name
        } (${`${quote.fees[0].duration} ${quote.fees[0].pricePeriod}`}${
          quote.fees[0].duration > 1 ? 's' : ''
        })`;
      }
    });

    if (
      data?.type === filtersOptions.FILTERS_COURSES &&
      (!warningSearch || warningSearch.current !== data.id)
    ) {
      fetchWarnings();
    }
  }, [
    data.id,
    data.type,
    programStartDate,
    quotesData?.quotes,
    quotesData.student.id,
    quotesData.student.nationalityCountry,
    quotesOptions,
    warningSearch,
    itemAsJSON,
    fetchWarnings,
  ]);

  useEffect(() => {
    if (
      (warnings.length === 0 && !futureCourse) ||
      data?.type !== filtersOptions.FILTERS_COURSES
    ) {
      setIsWarned(false);
    } else {
      setIsWarned(true);
    }
  }, [data?.type, futureCourse, warnings]);

  useEffect(() => {
    if ((isItemAllyPlus && isItemBackoffice) || isItemAllyPlus) {
      setRadioValue(1);
    } else {
      setRadioValue(0);
    }
  }, [isItemAllyPlus, isItemBackoffice]);

  const handleOpen = () => {
    setRadioValue(initialRadioValue);
  };

  const calcModalPadding = () => {
    if (typeof quotesData.quotes !== 'undefined') {
      const quoteSize = quotesData?.quotes.length * 90;
      const warningsSize = !warnings.length ? 0 : warnings.length * 90;
      return !warnings.length
        ? `${quoteSize}px`
        : `${quoteSize + warningsSize}px`;
    }
    return '500';
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onAfterOpen={handleOpen}
      onRequestClose={onClickCloseModal}
      style={{
        overlay: {
          backgroundColor: 'var(--overlay)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflowY: 'auto',
          paddingTop: calcModalPadding(),
        },
        content: {
          position: 'initial',
          padding: 0,
          border: '1px solid var(--divider)',
          borderRadius: 0,
          boxShadow: 'var(--modal-shadow)',
          overflow: 'hidden auto',
          maxHeight: '1000px',
        },
      }}
    >
      <ContainerAddModal isWarned={isWarned}>
        <Form
          ref={modalFormRef}
          onSubmit={handleAddItemToQuoteSubmit}
          style={{ paddingBottom: '20px' }}
        >
          <ModalHeader>
            <span>Add Item to Quotes</span>
            <Button
              type="button"
              className="btn-transparent"
              onClick={onClickCloseModal}
            >
              <MdClose size={20} />
            </Button>
          </ModalHeader>

          {(radioValue === 1 && userType === 'backoffice') ||
          (isItemAllyPlus && !isItemBackoffice && userType === 'backoffice') ? (
            <ModalContent className="block">
              <div style={{ width: '45%' }}>
                <h1>OPS!</h1>
                <p>
                  <strong>
                    Parece que sua conta ainda não tem permissão para vendas com
                    Ally+
                  </strong>
                </p>
                <p>
                  Para ter acesso os benefícios do Ally+, clique no botão abaixo
                  para ativar
                </p>
                <Button
                  type="button"
                  onClick={onClickContactButton}
                  className="ally-plus mb-5"
                >
                  {user.role === 'admin' ? 'Ativar Ally+' : 'Solicitar Ally+'}
                </Button>

                {user.role === 'admin' && (
                  <>
                    <p>
                      <strong>Dúvidas de como funciona?</strong>
                    </p>
                    <p>
                      Agende uma conversa com nosso especialista ou{' '}
                      <a
                        href="https://allyhub.co/ally_plus.html"
                        target="_blank"
                        rel="noreferrer"
                      >
                        acesse o site
                      </a>{' '}
                      do Ally+
                    </p>
                    <Button
                      type="button"
                      onClick={onClickTalkButton}
                      className="mb-3"
                    >
                      Agendar conversa
                    </Button>
                  </>
                )}
              </div>
              <div style={{ width: '45%' }}>
                <div className="ally-plus-image logo">
                  <img src={allyPlusLogo} alt="Ally+ foto" />
                </div>
                <div className="ally-plus-image main">
                  <img src={allyPlusImage} alt="Ally+ foto" />
                </div>
              </div>
            </ModalContent>
          ) : (
            <>
              <ModalContent
                style={{ borderBottom: '1px solid var(--divider)' }}
              >
                <span>Seletected program</span>
                <h3>{itemName}</h3>
                <small>{itemNameSchool}</small>

                {itemType && itemType === 'courses' && (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginTop: '3rem',
                    }}
                  >
                    <div style={{ width: '40%', float: 'left' }}>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span>
                          {itemPricePeriod !== 'fixed' &&
                            `${data?.duration} ${itemPricePeriod}(s)`}
                        </span>
                        <span>{itemPricePeriod === 'fixed' && 'fixed'}</span>
                        <span>
                          {`Start date: ${formatDate(programStartDate)}`}
                        </span>
                      </div>
                    </div>
                    <div style={{ width: '60%', float: 'right' }}>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <span style={{ fontSize: '11px' }}>
                          {itemAsJSON &&
                            `tuition: ${
                              itemAsJSON.coursecampus.totalThisCourse.currency
                            } ${formatPrice(
                              itemAsJSON.coursecampus.totalThisCourse.onlyCourse
                            )}`}
                        </span>
                        <span style={{ fontSize: '11px' }}>
                          {itemAsJSON &&
                            `enrol: ${
                              itemAsJSON.coursecampus.totalThisCourse.currency
                            } ${formatPrice(
                              itemAsJSON.coursecampus.totalThisCourse.onlyEnrol
                            )}`}
                        </span>
                        <span style={{ fontSize: '11px' }}>
                          {itemAsJSON &&
                            `material: ${
                              itemAsJSON.coursecampus.totalThisCourse.currency
                            } ${formatPrice(
                              itemAsJSON.coursecampus.totalThisCourse
                                .onlyMaterial
                            )}`}
                        </span>
                      </div>
                      <span
                        style={{
                          float: 'right',
                          marginTop: '10px',
                          fontSize: '1.6rem',
                        }}
                      >
                        {itemAsJSON &&
                          `${
                            itemAsJSON.coursecampus.totalThisCourse.currency
                          } ${formatPrice(
                            itemAsJSON.coursecampus.totalThisCourse.value
                          )}`}
                      </span>
                    </div>
                  </div>
                )}
                {itemType && itemType === 'accommodations' && (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginTop: '3rem',
                    }}
                  >
                    <div style={{ width: '40%', float: 'left' }}>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span>
                          {itemPricePeriod !== 'fixed' &&
                            `${data?.duration} ${itemAsJSON.typePeriod}(s)`}
                        </span>
                        <span>{itemPricePeriod === 'fixed' && 'fixed'}</span>
                        <span>
                          {`Checkin: ${formatDate(programStartDate)}`}
                        </span>
                      </div>
                    </div>
                    <div style={{ width: '60%', float: 'right' }}>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <span style={{ fontSize: '11px' }}>
                          {itemAsJSON &&
                            `value: ${
                              itemAsJSON.totalThisAccommodation.currency
                            } ${formatPrice(
                              itemAsJSON.totalThisAccommodation
                                .onlyAccommodation
                            )}`}
                        </span>
                        <span style={{ fontSize: '11px' }}>
                          {itemAsJSON &&
                            `placement fee: ${
                              itemAsJSON.totalThisAccommodation.currency
                            } ${formatPrice(
                              itemAsJSON.totalThisAccommodation.onlyEnrol
                            )}`}
                        </span>
                      </div>
                      <span
                        style={{
                          float: 'right',
                          marginTop: '10px',
                          fontSize: '1.6rem',
                        }}
                      >
                        {itemAsJSON &&
                          `${
                            itemAsJSON.totalThisAccommodation.currency
                          } ${formatPrice(
                            itemAsJSON.totalThisAccommodation.valueWithoutSR
                          )}`}
                      </span>
                    </div>
                  </div>
                )}
                {itemType && itemType === 'additional' && (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginTop: '3rem',
                    }}
                  >
                    <div style={{ width: '40%', float: 'left' }}>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span>
                          {itemPricePeriod !== 'fixed' &&
                            `${data?.duration} ${itemAsJSON.typePeriod}(s)`}
                        </span>
                        <span>{itemPricePeriod === 'fixed' && 'fixed'}</span>
                      </div>
                    </div>
                    <div style={{ width: '60%', float: 'right' }}>
                      <span
                        style={{
                          float: 'right',
                          fontSize: '1.6rem',
                        }}
                      >
                        {itemAsJSON && itemAsJSON.totalThisFee
                          ? `${itemAsJSON.totalThisFee.currency} ${formatPrice(
                              itemAsJSON.totalThisFee.value
                            )}`
                          : `${
                              itemAsJSON.totalThisExperience.currency
                            } ${formatPrice(
                              itemAsJSON.totalThisExperience.value
                            )}`}
                      </span>
                    </div>
                  </div>
                )}
              </ModalContent>

              <ModalContent>
                <div className="form-group">
                  <label htmlFor="quotes">
                    Select quotes to add this item:
                  </label>
                  {!!warnings.length &&
                    !futureCourse &&
                    !futurePrice &&
                    data?.type === 'Courses' && (
                      <div>
                        <Warnings futurePrice={false} futureCourse={false}>
                          {warnings}
                        </Warnings>
                      </div>
                    )}
                  {!!warnings.length &&
                    futureCourse &&
                    data?.type === 'Courses' && (
                      <div>
                        <Warnings
                          futurePrice={futurePrice}
                          futureCourse={futureCourse}
                        >
                          {warnings}
                        </Warnings>
                      </div>
                    )}
                  {!warnings.length &&
                    futureCourse &&
                    data?.type === 'Courses' && (
                      <div>
                        <Warnings
                          futurePrice={futurePrice}
                          futureCourse={futureCourse}
                        >
                          {warnings}
                        </Warnings>
                      </div>
                    )}
                  <QuoteOption
                    options={quotesOptions()}
                    name="quotes"
                    className="quoteOption"
                    defaultChecked={defaultCheckedOptions}
                  />
                </div>
                {fiveOptions ? (
                  <div style={{ textAlign: 'center' }}>
                    <p>You can only add up to 5 options in a quote!</p>
                  </div>
                ) : (
                  <Button
                    loading={isAddOptionLoading}
                    loadingColor="var(--primary)"
                    type="button"
                    className="btn-outline add-option-btn"
                    onClick={onClickAddOption}
                    tooltip="Add option"
                  >
                    <MdAdd size={16} />
                    option
                  </Button>
                )}
              </ModalContent>
            </>
          )}

          {!(
            isItemAllyPlus &&
            !isItemBackoffice &&
            userType === 'backoffice'
          ) &&
            isCourse && (
              <RadioInput
                name="allyPlus"
                options={itemsRadioInput}
                sendValue={sendValue}
                valueWasExpired={isValueExpired}
                isSellDirectlyDefault={userType === 'backoffice'}
                className="mt-0"
              />
            )}

          {((isValueExpired && radioValue === 1) ||
            (userType === 'allyplus' && isValueExpired)) &&
            userType !== 'backoffice' && (
              <p className="text-center">
                Unavailable on Ally+. Outdated value.
              </p>
            )}

          {isCourse && userType !== 'backoffice' ? (
            <ButtonContainer
              className={`${
                (radioValue === 1 || userType === 'allyplus') && 'ally-plus'
              }`}
            >
              <Button
                loading={isLoading}
                loadingWidth={19}
                type="submit"
                disabled={
                  (isValueExpired && radioValue === 1) ||
                  (userType === 'allyplus' && isValueExpired) ||
                  isLoading
                }
                className="add-selected-quotes-btn"
                onClick={onClickAddSelected}
              >
                {radioValue === 1 || userType === 'allyplus'
                  ? 'Add with Ally Plus'
                  : 'Add'}
              </Button>
              <span>
                {radioValue === 1 || userType === 'allyplus'
                  ? 'Focus on sales, we take care of the rest'
                  : 'You must handle your own operations department'}
              </span>
            </ButtonContainer>
          ) : (
            radioValue === 0 &&
            isItemBackoffice && (
              <ButtonContainer>
                <Button
                  loading={isLoading}
                  loadingWidth={19}
                  type="submit"
                  className="add-selected-quotes-btn"
                  onClick={onClickAddSelected}
                >
                  Add
                </Button>
                <span>You must handle your own operations department</span>
              </ButtonContainer>
            )
          )}

          {!isCourse && (
            <ButtonContainer>
              <Button
                loading={isLoading}
                loadingWidth={19}
                type="submit"
                className="add-selected-quotes-btn"
                onClick={onClickAddSelected}
              >
                Add
              </Button>
              <span> </span>
            </ButtonContainer>
          )}
        </Form>
      </ContainerAddModal>
    </ReactModal>
  );
};

export default AddItemToQuoteModal;
