import React, { useCallback, useRef, useState, useEffect } from 'react';
import * as Yup from 'yup';
import { format, parseISO } from 'date-fns';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { FiAlertCircle } from 'react-icons/fi';
import _ from 'lodash';

import ReactTooltip from 'react-tooltip';
import { AsyncSelect } from '../../components/Select';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import { useWizard } from '../../hooks/wizard';
import { useToast } from '../../hooks/toast';
import Loading from '../Loading';

import formatValue from '../../utils/formatValue';
import getValidationErrors from '../../utils/getValidationErrors';

import shuttleSvg from '../../assets/shuttle.svg';

import { Container, Box } from './styles';

import Button from '../../components/Button';
import Input from '../../components/Input';
import Breadcrumb from '../../components/Breadcrumb';
import {
  StudentProps,
  TotalByCurrency,
  OfficeCurrency,
  Office,
} from '../../shared/interfaces';
import Tooltip from '../../components/Tooltip';

interface FormData {
  student: StudentProps;
  howManyQuotes: number;
}

const Start: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [officeCurrencies, setOfficeCurrencies] = useState<OfficeCurrency[]>(
    []
  );
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const [isFromMyOffice, setIsFromMyOffice] = useState<boolean>(true);
  const [isPlugAndPlay, setIsPlugAndPlay] = useState<boolean>(true);
  const [defaultDescription, setDefaultDescription] = useState<string>('');
  const formRef = useRef<FormHandles>(null);

  const { user, toEditQuoteId, angularPort, studentId, isPP } = useAuth();
  const { updateQuotesData, goToResume } = useWizard();
  const { addToast } = useToast();

  const breadcrumbOptions = [
    {
      label: 'Start',
      active: true,
    },
  ];

  const isUserAllyPlus = user.office.account.allyPlus === 1;

  const calculateTotalsByCurrency = useCallback((quote: any) => {
    const totalsByCurrency: TotalByCurrency[] = [];

    quote.courses.forEach((course: any) => {
      if (course.coursecampus.totalThisCourse && course.coursecampus.campus) {
        const currencyTotal = totalsByCurrency.find(
          total =>
            total.currency_code === course.coursecampus.campus.currency_code
        );

        if (!currencyTotal) {
          totalsByCurrency.push({
            currency: course.coursecampus.campus.currency.symbol,
            currency_code: course.coursecampus.campus.currency_code,
            value: course.coursecampus.totalThisCourse.value,
            value_without_sr:
              course.coursecampus.totalThisCourse.valueWithoutSR,
          });
          return;
        }

        currencyTotal.value += course.coursecampus.totalThisCourse.value;
        currencyTotal.value_without_sr +=
          course.coursecampus.totalThisCourse.valueWithoutSR;
      }
    });

    quote.experiences.forEach((experience: any) => {
      if (experience.totalThisExperience && experience.currency.code) {
        const currencyTotal = totalsByCurrency.find(
          total => total.currency_code === experience.currency.code
        );

        if (!currencyTotal) {
          totalsByCurrency.push({
            currency: experience.currency.symbol,
            currency_code: experience.currency.code,
            value: experience.totalThisExperience.value,
            value_without_sr: experience.totalThisExperience.value,
          });
          return;
        }

        currencyTotal.value += experience.totalThisExperience.value;
        currencyTotal.value_without_sr += experience.totalThisExperience.value;
      }
    });

    quote.accommodations.forEach((accommodation: any) => {
      if (accommodation.totalThisAccommodation) {
        const currencyTotal = totalsByCurrency.find(
          total => total.currency_code === accommodation.currency_code
        );

        if (!currencyTotal) {
          totalsByCurrency.push({
            currency: accommodation.currency.symbol,
            currency_code: accommodation.currency_code,
            value: accommodation.totalThisAccommodation.value,
            value_without_sr:
              accommodation.totalThisAccommodation.valueWithoutSR,
          });
          return;
        }

        currencyTotal.value += accommodation.totalThisAccommodation.value;
        currencyTotal.value_without_sr +=
          accommodation.totalThisAccommodation.valueWithoutSR;
      }
    });

    quote.fees.forEach((fee: any) => {
      if (fee.totalThisFee) {
        const currencyTotal = totalsByCurrency.find(
          total => total.currency_code === fee.currency_code
        );

        if (!currencyTotal) {
          totalsByCurrency.push({
            currency: fee.currency.symbol,
            currency_code: fee.currency_code,
            value: fee.totalThisFee.value,
            value_without_sr: fee.totalThisFee.valueWithoutSR,
          });
          return;
        }

        currencyTotal.value += fee.totalThisFee.value;
        currencyTotal.value_without_sr += fee.totalThisFee.valueWithoutSR;
      }
    });

    quote.otherfees.forEach((otherfee: any) => {
      const currencyTotal = totalsByCurrency.find(
        total => total.currency_code === otherfee.currency.code
      );

      if (!currencyTotal) {
        if (otherfee.value > 0) {
          totalsByCurrency.push({
            currency: otherfee.currency.symbol,
            currency_code: otherfee.currency.code,
            value: otherfee.value,
            value_without_sr: otherfee.value,
          });
        } else {
          totalsByCurrency.push({
            currency: otherfee.currency.symbol,
            currency_code: otherfee.currency.code,
            value: otherfee.value,
            value_without_sr: 0,
          });
        }
        return;
      }

      currencyTotal.value += otherfee.value;
      if (otherfee.value > 0) {
        currencyTotal.value_without_sr += otherfee.value;
      }
    });

    totalsByCurrency.forEach(total => {
      total.valueFormatted = formatValue(total.value, total.currency_code);
      total.value_without_srFormatted = formatValue(
        total.value_without_sr,
        total.currency_code
      );
    });

    return totalsByCurrency;
  }, []);

  const calculateConvertedValue = useCallback(
    async (quote: any) => {
      const currencyCode = quote.convertedCurrency.code;

      const currencies = toEditQuoteId
        ? quote.quoteCurrencies
        : officeCurrencies;

      const hasDefaultCurrency = currencies.find(
        (currency: any) => currency.destiny_code === user.office.default_code
      );

      if (!hasDefaultCurrency) {
        currencies.push({
          currency: user.office.currency,
          destiny_code: user.office.default_code,
          value: 1,
        });
      }

      const { data: convertedValue } = await api.post(
        `/convert?code=${currencyCode}`,
        {
          saveConverted: true,
          arrayValues: quote.totalsByCurrency,
          rules: currencies,
          quote_id: quote.id,
        }
      );

      const rules: any[] = [];

      currencies.forEach((curr: OfficeCurrency) => {
        if (
          quote.totalsByCurrency.length === 1 &&
          quote.totalsByCurrency[0].currency_code === currencyCode
        )
          return;

        curr.valueFormatted = formatValue(curr.value, curr.destiny_code);

        if (
          curr.destiny_code === currencyCode &&
          curr.destiny_code !== user.office.default_code
        ) {
          rules.push(curr);
        } else {
          const findTotalCurrencyRule = quote.totalsByCurrency.find(
            (total: any) =>
              total.currency_code === curr.destiny_code &&
              total.currency_code !== user.office.default_code
          );
          if (findTotalCurrencyRule) {
            rules.push(curr);
          }
        }
      });

      convertedValue.rules = rules;

      return convertedValue;
    },
    [
      toEditQuoteId,
      officeCurrencies,
      user.office.currency,
      user.office.default_code,
    ]
  );

  const saveQuote = useCallback(
    async (quote: any): Promise<any> => {
      await api.post('/quotecurrency', {
        data: quote.totalConverted ? quote.totalConverted.rules : [],
        quote_id: quote.id,
        plugAndPlay: isUserAllyPlus ? 1 : 0,
      });

      const res = await api.put(`/quote/${quote.id}`, {
        timelog: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
        dueDate: format(parseISO(quote.dueDate), 'dd/MM/yyyy'),
        description: defaultDescription,
        opportunity_id: quote.opportunity_id,
        chosenCourses: quote.courses,
        newAccommodations: quote.accommodations,
        newExperiences: quote.experiences,
        newFees: quote.fees,
        newOtherfees: quote.otherfees,
        quoteValues: quote.totalsByCurrency,
        newQuote: true,
        autoSave: true,
        iof: quote.iof,
        school_id: quote.school_id,
        campus_id: quote.campus_id,
        saleType_id:
          quote.saleType && quote.saleType.id ? quote.saleType.id : undefined,
      });

      return res.data;
    },
    [defaultDescription, isUserAllyPlus]
  );

  const handleSubmit = useCallback(
    async (data: FormData) => {
      // setLoading(true);

      try {
        formRef.current?.setErrors({});

        // const schema = Yup.object().shape({
        //   student: Yup.object().required('Student is required'),
        //   howManyQuotes: Yup.number()
        //     .typeError('Must be a number')
        //     .required('Number of quotes required')
        //     .positive('Only positive numbers')
        //     .integer('Invalid number')
        //     .max(5, 'Max value is 5')
        //     .min(1, 'Min value is 1'),
        // });

        // await schema.validate(data, {
        //   abortEarly: false,
        // });

        const { student, howManyQuotes } = data;

        const { data: fees } = await api.get('/mandatoryrule', {
          params: {
            fromArray: true,
            allQuotes: true,
            birthDate: student.birthDate,
            getFees: true,
            country: student.nationalityCountry,
            startDate: format(new Date(), 'dd/MM/yyyy'),
          },
        });

        const mandatoryFees: any[] = [];
        const suggestiveFees: any[] = [];

        fees.forEach((fee: any) => {
          fee.valueFormatted = formatValue(
            fee.totalThisFee.value,
            fee.currency_code
          );
          fee.oldValueFormatted = fee.totalThisFee.oldValue
            ? formatValue(fee.totalThisFee.oldValue, fee.currency_code)
            : undefined;
          fee.subtotalFormatted = formatValue(
            fee.totalThisFee.value,
            fee.currency_code
          );

          fee.enrolDate = format(new Date(), 'dd/MM/yyyy');

          if (fee.mandatory === 1) {
            mandatoryFees.push(fee);
          } else {
            suggestiveFees.push(fee);
          }
        }, []);

        const res = await api.post('/draft', {
          quotes: howManyQuotes,
          student_id: student.id,
          isPP: isPP ? isPP : 0,
          nationalityCountry: student.nationalityCountry,
        });

        const quotes = Array(howManyQuotes);

        await res.data.quotes.reduce(
          async (promise: Promise<any>, quote: any, idx: number) => {
            await promise;

            quotes[idx] = {
              id: quote.id,
              dueDate: quote.dueDate,
              iof: quote.iof,
              language: quote.language,
              // subtitle: quote.subtitle,
              officeCount: quote.officeCount,
              plugAndPlay: quote.plugAndPlay === 1 ? 1 : 0,
              opportunity_id: quote.opportunity_id,
              empty: mandatoryFees.length <= 0,
              courses: [],
              accommodations: [],
              fees: mandatoryFees,
              experiences: [],
              otherfees: [],
              paymentplan: [],
              school_id: quote.school_id,
              campus_id: quote.campus_id,
              convertedCurrency: user.office.currency,
              description: defaultDescription,
            };

            quotes[idx].totalsByCurrency = calculateTotalsByCurrency(
              quotes[idx]
            );
            quotes[idx].totalConverted = await calculateConvertedValue(
              quotes[idx]
            );
            quotes[idx].totalConverted.valueFormatted = formatValue(
              quotes[idx].totalConverted.value,
              quotes[idx].totalConverted.currency_code
            );
            quotes[idx].totalConverted.value_without_srFormatted = formatValue(
              quotes[idx].totalConverted.value_without_sr,
              quotes[idx].totalConverted.currency_code
            );

            const { quoteCurrencies } = await saveQuote(quotes[idx]);

            quotes[idx].quoteCurrencies = quoteCurrencies;
            quotes[idx].quoteCurrencies.forEach((currency: any) => {
              currency.valueFormatted = formatValue(
                currency.value,
                currency.destiny_code
              );
            });
          },
          Promise.resolve()
        );

        // if (suggestiveFees?.length) {
        //   setOpenSuggestiveFeeModal(true);
        // }

        updateQuotesData({
          quotes,
          student,
          draft_id: res.data.id,
          howManyQuotes,
          mandatoryFees,
          suggestiveFees,
        });

        const message = {
          quotes,
          action: 'navigate-to-edit',
          origin: process.env.REACT_APP_URL as string,
        };

        angularPort?.postMessage(message);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Error',
          description: 'An error has occurred, please try again.',
        });
      } finally {
        setLoading(false);
      }
    },
    [
      isPP,
      updateQuotesData,
      angularPort,
      user.office.currency,
      defaultDescription,
      calculateTotalsByCurrency,
      calculateConvertedValue,
      saveQuote,
      addToast,
    ]
  );

  // const loadStudents = useCallback(
  //   async inputValue => {
  //     const res = await api.get('/student', {
  //       params: {
  //         likeQuery: inputValue,
  //         paginate: false,
  //         with: JSON.stringify(['nationality']),
  //         shortcut: true,
  //         take: 15,
  //         returnMy: user.role === 'user' || user.role === 'userplus',
  //         user_id:
  //           user.role === 'user' || user.role === 'userplus'
  //             ? user.id
  //             : undefined,
  //         office: user.role !== 'user' && user.role !== 'userplus',
  //       },
  //     });

  //     const baseFlagUrl = `${process.env.REACT_APP_NG_URL}/images/flags/`;

  //     const serializedStudents = res.data.map((student: any) => ({
  //       value: student,
  //       label: (
  //         <div className="option-with-image">
  //           <img
  //             src={`${baseFlagUrl}${student.nationality.flag}`}
  //             className="flag"
  //             alt="Flag"
  //           />
  //           {student.fullName}
  //         </div>
  //       ),
  //     }));

  //     return serializedStudents;
  //   },
  //   [user.id, user.role]
  // );

  const applyQuote = useCallback(async (quote: any): Promise<any> => {
    await quote.courses.reduce(async (promise: any, course: any) => {
      await promise;

      course.srReject = [];
      course.srReject.push(1);
      if (course.srperiod_id > 0 && course.srperiod_active === 0) {
        course.srReject.push(course.srperiod_id);
      }
      if (course.srcourse_id > 0 && course.srcourse_active === 0) {
        course.srReject.push(course.srcourse_id);
      }
      if (course.srenrol_id > 0 && course.srenrol_active === 0) {
        course.srReject.push(course.srenrol_id);
      }
      if (course.srmaterial_id > 0 && course.srmaterial_active === 0) {
        course.srReject.push(course.srmaterial_id);
      }

      await api
        .get(`/firstprice/${course.courseCampus_id}`, {
          params: {
            quote_id: quote.id,
            period_id: course.period_id,
            srperiod_id: course.srperiod_id,
            srcourse_id: course.srcourse_id,
            teste: 3,
            srenrol_id: course.srenrol_id,
            srmaterial_id: course.srmaterial_id,
            materialActive: course.materialActive ? true : 'no',
            enrolActive: course.enrolActive ? true : 'no',
            duration: course.duration,
            country: quote.opportunity.student.nationality.country,
            srReject: JSON.stringify(course.srReject),
          },
        })
        .then(response => {
          course.coursecampus = response.data;
          course.coursecampus.srReject = course.srReject;
          course.coursecampus.materialActive = course.materialActive;
          course.coursecampus.enrolActive = course.enrolActive;
          course.coursecampus.srperiod_id = course.srperiod_id;
          course.coursecampus.srcourse_id = course.srcourse_id;
          course.coursecampus.srenrol_id = course.srenrol_id;
          course.coursecampus.srmaterial_id = course.srmaterial_id;
          course.coursecampus.enrolDate = format(
            parseISO(course.enrolDate),
            'dd/MM/yyyy'
          );
          course.pricePeriod = course.coursecampus.price.periodType;
          course.valueFormatted = formatValue(
            course.coursecampus.totalThisCourse.onlyCourse,
            course.coursecampus.campus.currency_code
          );
          course.oldValueFormatted = course.coursecampus.totalThisCourse
            .oldValue
            ? formatValue(
                course.coursecampus.totalThisCourse.oldValue,
                course.coursecampus.campus.currency_code
              )
            : undefined;
          course.enrolFormatted = formatValue(
            course.coursecampus.totalThisCourse.onlyEnrol,
            course.coursecampus.campus.currency_code
          );
          course.materialFormatted = formatValue(
            course.coursecampus.totalThisCourse.onlyMaterial,
            course.coursecampus.campus.currency_code
          );
          course.subtotalFormatted = formatValue(
            course.coursecampus.totalThisCourse.value,
            course.coursecampus.campus.currency_code
          );
          course.coursecampus.startDate = format(
            parseISO(course.startDate),
            'dd/MM/yyyy'
          );
          course.coursecampus.startDateFormatted = format(
            parseISO(course.startDate),
            'LLLL dd, yyyy'
          );
          if (course.pricePeriod === 'fixed') {
            course.endDate = course.startDate;
          }
          course.coursecampus.endDate = format(
            parseISO(course.endDate),
            'dd/MM/yyyy'
          );
          course.coursecampus.endDateFormatted = format(
            parseISO(course.endDate),
            'LLLL dd, yyyy'
          );
          // if (
          //   !quote.school_id ||
          //   (quote.school_id &&
          //     quote.school_id === course.coursecampus.campus.school_id)
          // ) {
          //   quote.school_id = course.coursecampus.campus.school_id;
          // } else {
          //   throw new Error(
          //     'Items from another school on the same quote are not allowed'
          //   );
          // }

          // if (
          //   course.coursecampus.campus.city &&
          //   course.coursecampus.campus.country
          // ) {
          //   if (!quote.subtitle) {
          //     quote.subtitle = `${course.coursecampus.campus.city.name} - ${
          //       course.coursecampus.campus.school.name
          //     } - ${course.coursecampus.course.name} (${course.duration} ${
          //       course.pricePeriod
          //     }${course.duration > 1 ? 's' : ''})`;
          //     if (
          //       quote.accommodations.length > 0 &&
          //       !quote.subtitle.includes('Accommodation')
          //     ) {
          //       quote.subtitle += ' + Accommodation';
          //     }
          //   }
          // }

          course.hasSpecialRates = false;
          course.hasMaterialSR = false;
          course.hasEnrolSR = false;
          course.hasTuitionSR = false;

          if (
            (course.coursecampus.price.srmaterial &&
              course.coursecampus.price.srmaterial.active) ||
            (course.coursecampus.price.srenrol &&
              course.coursecampus.price.srenrol.active) ||
            (course.coursecampus.price.srcourse &&
              course.coursecampus.price.srcourse.active) ||
            (course.coursecampus.price.srperiod &&
              course.coursecampus.price.srperiod.active)
          ) {
            course.hasSpecialRates = true;
          }
          if (
            course.srperiod_id > 0 ||
            course.srcourse_id > 0 ||
            course.srenrol_id > 0 ||
            course.srmaterial_id > 0
          ) {
            course.hasSpecialRates = true;
            if (course.srmaterial_id > 0) {
              course.hasMaterialSR = true;
            }
            if (course.srenrol_id > 0) {
              course.hasEnrolSR = true;
            }
            if (course.srperiod_id > 0 || course.srcourse_id > 0) {
              course.hasTuitionSR = true;
            }
          }
        });
    }, Promise.resolve());

    await quote.experiences.reduce(async (promise: any, experience: any) => {
      await promise;

      await api
        .get(`/experienceprice/${experience.experience_id}`, {
          params: {
            quote_id: quote.id,
            teste: 1,
            period_id: experience.period_id,
            duration: experience.experience_duration,
            country: quote.opportunity.student.nationality.country,
          },
        })
        .then(response => {
          experience.totalThisExperience = response.data.totalThisExperience;
          experience.currency = experience.experience.currency;
          experience.id = experience.experience.id;
          experience.currency_code = response.data.currency_code;
          experience.name = experience.experience.name;
          experience.partner = response.data.partner;
          experience.partner_id = response.data.partner.id;
          experience.duration = experience.experience_duration;
          experience.durationType = experience.period.periodType;
          experience.price = experience.period;

          experience.startDateFormatted = format(
            parseISO(experience.startDate),
            'dd/MM/yyyy'
          );
          experience.endDateFormatted = format(
            parseISO(experience.endDate),
            'dd/MM/yyyy'
          );

          experience.departureDates = {
            startDate: experience.startDateFormatted,
            endDate: experience.endDateFormatted,
            experience_id: Number(experience.id),
          };

          if (experience.totalThisExperience && experience.currency.code) {
            experience.valueFormatted = formatValue(
              experience.totalThisExperience.value,
              experience.currency.code
            );
            experience.subtotalFormatted = formatValue(
              experience.totalThisExperience.value,
              experience.currency.code
            );
          }
        });
    }, Promise.resolve());

    const newAccommodations: any[] = [];
    await quote.accommodations.reduce(
      async (promise: any, accommodation: any) => {
        await promise;

        accommodation.srReject = [];
        accommodation.srReject.push(1);
        if (
          accommodation.srperiod_id > 0 &&
          accommodation.srperiod_active === 0
        ) {
          accommodation.srReject.push(accommodation.srperiod_id);
        }
        if (
          accommodation.srcourse_id > 0 &&
          accommodation.srcourse_active === 0
        ) {
          accommodation.srReject.push(accommodation.srcourse_id);
        }
        if (
          accommodation.srenrol_id > 0 &&
          accommodation.srenrol_active === 0
        ) {
          accommodation.srReject.push(accommodation.srenrol_id);
        }
        if (
          accommodation.srmaterial_id > 0 &&
          accommodation.srmaterial_active === 0
        ) {
          accommodation.srReject.push(accommodation.srmaterial_id);
        }

        await api
          .get(`/accommodationprice/${accommodation.accommodation_id}`, {
            params: {
              quote_id: quote.id,
              period_id: accommodation.period_id,
              srperiod_id: accommodation.srperiod_id,
              srcourse_id: accommodation.srcourse_id,
              teste: 3,
              srenrol_id: accommodation.srenrol_id,
              srmaterial_id: accommodation.srmaterial_id,
              duration: accommodation.accommodation_duration,
              country: quote.opportunity.student.nationality.country,
              srReject: JSON.stringify(accommodation.srReject),
            },
          })
          .then(response => {
            const updatedAccommodation = response.data;
            updatedAccommodation.enrolDate = format(
              parseISO(accommodation.enrolDate),
              'dd/MM/yyyy'
            );
            updatedAccommodation.editedAccommodation =
              accommodation.editedAccommodation ?? 0;
            updatedAccommodation.editedPlacementFee =
              accommodation.editedPlacementFee ?? 0;
            updatedAccommodation.srReject = accommodation.srReject;
            updatedAccommodation.srperiod_id = accommodation.srperiod_id;
            updatedAccommodation.srcourse_id = accommodation.srcourse_id;
            updatedAccommodation.srenrol_id = accommodation.srenrol_id;
            updatedAccommodation.pricePeriod = response.data.price.periodType;
            updatedAccommodation.valueFormatted = formatValue(
              updatedAccommodation.totalThisAccommodation.onlyAccommodation,
              updatedAccommodation.currency_code
            );
            updatedAccommodation.oldValueFormatted = updatedAccommodation
              .totalThisAccommodation.oldValue
              ? formatValue(
                  updatedAccommodation.totalThisAccommodation.oldValue,
                  updatedAccommodation.currency_code
                )
              : undefined;
            updatedAccommodation.placementFeeFormatted = formatValue(
              updatedAccommodation.totalThisAccommodation.onlyEnrol,
              updatedAccommodation.currency_code
            );
            updatedAccommodation.subtotalFormatted = formatValue(
              updatedAccommodation.totalThisAccommodation.value,
              updatedAccommodation.currency_code
            );
            const auxStartDate = accommodation.startDate;
            const auxEndDate = accommodation.endDate;
            updatedAccommodation.startDate = format(
              parseISO(auxStartDate),
              'dd/MM/yyyy'
            );
            updatedAccommodation.startDateFormatted = format(
              parseISO(auxStartDate),
              'LLLL dd, yyyy'
            );
            updatedAccommodation.endDate = format(
              parseISO(auxEndDate),
              'dd/MM/yyyy'
            );
            updatedAccommodation.endDateFormatted = format(
              parseISO(auxEndDate),
              'LLLL dd, yyyy'
            );
            // if (
            //   (!quote.school_id &&
            //     accommodation.agency_id !== user.account_id) ||
            //   (quote.school_id &&
            //     quote.school_id === accommodation.campus?.school_id)
            // ) {
            //   quote.school_id = accommodation.campus?.school_id;
            // } else {
            //   throw new Error(
            //     'Items from another school on the same quote are not allowed'
            //   );
            // }
            newAccommodations.push(updatedAccommodation);

            // if (
            //   !quote.subtitle &&
            //   quote.fee.length === 0 &&
            //   quote.courses.length === 0
            // ) {
            //   quote.subtitle = `${accommodation.accommodation.city?.name} - ${
            //     accommodation.accommodation.partner
            //   } - ${accommodation.accommodation.name}(${
            //     accommodation.accommodation_duration + accommodation.pricePeriod
            //   }${accommodation.accommodation_duration > 1 ? 's' : ''})`;
            // }
            updatedAccommodation.hasSpecialRates = false;
            updatedAccommodation.hasEnrolSR = false;
            updatedAccommodation.hasAccommodationSR = false;

            if (
              (updatedAccommodation.totalThisAccommodation.applied &&
                updatedAccommodation.price.srenrol) ||
              (updatedAccommodation.totalThisAccommodation.applied &&
                updatedAccommodation.price.srcourse) ||
              (updatedAccommodation.totalThisAccommodation.applied &&
                updatedAccommodation.price.srperiod)
            ) {
              updatedAccommodation.hasSpecialRates = true;
            }
            if (
              updatedAccommodation.srperiod_id > 0 ||
              updatedAccommodation.srcourse_id > 0 ||
              updatedAccommodation.srenrol_id > 0
            ) {
              updatedAccommodation.hasSpecialRates = true;
              if (updatedAccommodation.srenrol_id > 0) {
                updatedAccommodation.hasEnrolSR = true;
              }
              if (
                updatedAccommodation.srperiod_id > 0 ||
                updatedAccommodation.srcourse_id > 0
              ) {
                updatedAccommodation.hasAccommodationSR = true;
              }
            }
          });
      },
      Promise.resolve()
    );
    quote.accommodations = newAccommodations;

    const newFees: any[] = [];
    await quote.fees.reduce(async (promise: any, fee: any) => {
      await promise;

      await api
        .get(`/feeprice/${fee.fee_id}`, {
          params: {
            quote_id: quote.id,
            period_id: fee.period_id,
            duration: fee.fee_duration,
            country: quote.opportunity.student.nationality.country,
          },
        })
        .then(response => {
          const updatedFee = response.data;
          updatedFee.enrolDate = format(parseISO(fee.enrolDate), 'dd/MM/yyyy');
          updatedFee.editedFee = fee.editedFee ?? 0;
          updatedFee.pricePeriod = response.data.price.periodType;
          updatedFee.valueFormatted = formatValue(
            updatedFee.totalThisFee.value,
            updatedFee.currency_code
          );
          // if (fee.campus) {
          //   if (
          //     !quote.school_id ||
          //     (quote.school_id && quote.school_id === fee.campus.school_id)
          //   ) {
          //     quote.school_id = fee.campus.school_id;
          //   } else {
          //     throw new Error(
          //       'Items from another school on the same quote are not allowed'
          //     );
          //   }
          // }
          updatedFee.oldValueFormatted = updatedFee.totalThisFee.oldValue
            ? formatValue(
                updatedFee.totalThisFee.oldValue,
                updatedFee.currency_code
              )
            : undefined;
          updatedFee.subtotalFormatted = formatValue(
            updatedFee.totalThisFee.value,
            updatedFee.currency_code
          );
          newFees.push(updatedFee);
        });
    }, Promise.resolve());
    quote.fees = newFees;

    quote.otherfees.forEach((otherfee: any) => {
      otherfee.valueFormatted = formatValue(
        otherfee.value,
        otherfee.currency.code
      );
      otherfee.subtotalFormatted = formatValue(
        otherfee.value,
        otherfee.currency.code
      );
    });

    return quote;
  }, []);

  const getQuote = useCallback(async () => {
    const { data: quote } = await api.get(`/quote/${toEditQuoteId}`, {
      params: {
        withBusiness: true,
      },
    });

    if (quote?.opportunity?.student?.birthDate) {
      if (quote.opportunity.student.birthDate === '0000-00-00') {
        quote.opportunity.student.age = -1;
      } else {
        const today = new Date();
        const birthDate = new Date(quote.opportunity.student.birthDate);
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        quote.opportunity.student.age = age;
      }
    } else if (quote?.opportunity?.student) {
      quote.opportunity.student.age = -1;
    }

    if (quote.office_id !== user.office_id) {
      setIsFromMyOffice(false);
      return;
    }

    let draft;

    const { data: currentDraft } = await api.get('/draft', {
      params: {
        quote_id: toEditQuoteId,
        myTest: 1,
      },
    });
    draft = currentDraft;

    if (!draft) {
      const { data: newDraft } = await api.post('/draft', {
        quotes: 1,
        myTest: 1,
        quote_id: toEditQuoteId,
        student_id: quote.opportunity.student.id,
        nationalityCountry: quote.opportunity.student.nationalityCountry,
      });
      draft = newDraft;
    }

    const quotes = Array(draft.quotes.length);

    await draft.quotes.reduce(
      async (promise: Promise<any>, draftQuote: any, idx: number) => {
        await promise;

        let currentQuote;
        if (draftQuote.quote_id === quote.id) {
          currentQuote = quote;
        } else {
          const { data: draftQuoteComplete } = await api.get(
            `/quote/${draftQuote.quote_id}`,
            {
              params: {
                withBusiness: true,
              },
            }
          );
          currentQuote = draftQuoteComplete;
        }

        if (!currentQuote) return;

        const updatedQuote = await applyQuote(currentQuote);

        setIsPlugAndPlay(currentQuote.plugAndPlay === 1);
        quotes[idx] = {
          id: updatedQuote.id,
          dueDate: updatedQuote.dueDate,
          iof: updatedQuote.iof,
          iepayment: updatedQuote.iepayment,
          language: updatedQuote.language,
          officeCount: updatedQuote.officeCount,
          plugAndPlay: updatedQuote.plugAndPlay === 1 ? 1 : 0,
          // subtitle: updatedQuote.subtitle,
          school_id: updatedQuote.school_id,
          campus_id: updatedQuote.campus_id,
          opportunity_id: updatedQuote.opportunity_id,
          saleType: updatedQuote.config
            ? updatedQuote.config.saletype
            : undefined,
          empty: false,
          config: updatedQuote.config,
          courses: updatedQuote.courses,
          accommodations: updatedQuote.accommodations,
          fees: updatedQuote.fees,
          experiences: updatedQuote.experiences,
          otherfees: updatedQuote.otherfees,
          paymentplan: updatedQuote.paymentplan,
          convertedCurrency: updatedQuote.converted_currency,
          totalsByCurrency: [] as any[],
          totalConverted: {} as any,
          quoteCurrencies: updatedQuote.currency,
        };

        quotes[idx].totalsByCurrency = calculateTotalsByCurrency(quotes[idx]);
        quotes[idx].totalConverted = await calculateConvertedValue(quotes[idx]);
        quotes[idx].totalConverted.valueFormatted = formatValue(
          quotes[idx].totalConverted.value,
          quotes[idx].totalConverted.currency_code
        );
        quotes[idx].totalConverted.value_without_srFormatted = formatValue(
          quotes[idx].totalConverted.value_without_sr,
          quotes[idx].totalConverted.currency_code
        );

        quotes[idx].quoteCurrencies.forEach((currency: any) => {
          currency.valueFormatted = formatValue(
            currency.value,
            currency.destiny_code
          );
        });
      },
      Promise.resolve()
    );

    const { data: fees } = await api.get('/mandatoryrule', {
      params: {
        fromArray: true,
        allQuotes: true,
        birthDate: quote.opportunity.student.birthDate,
        getFees: true,
        country: quote.opportunity.student.nationalityCountry,
        startDate: format(new Date(), 'dd/MM/yyyy'),
      },
    });

    const mandatoryFees: any[] = [];
    const suggestiveFees: any[] = [];

    fees.forEach((fee: any) => {
      fee.valueFormatted = formatValue(
        fee.totalThisFee.value,
        fee.currency_code
      );
      fee.oldValueFormatted = fee.totalThisFee.oldValue
        ? formatValue(fee.totalThisFee.oldValue, fee.currency_code)
        : undefined;
      fee.subtotalFormatted = formatValue(
        fee.totalThisFee.value,
        fee.currency_code
      );

      fee.enrolDate = format(new Date(), 'dd/MM/yyyy');

      if (fee.mandatory === 1) {
        mandatoryFees.push(fee);
      } else {
        suggestiveFees.push(fee);
      }
    }, []);

    // if (suggestiveFees?.length) {
    //   setOpenSuggestiveFeeModal(true);
    // }

    goToResume({
      quotes,
      student: quote.opportunity.student,
      draft_id: draft.id,
      howManyQuotes: quotes.length,
      mandatoryFees,
      suggestiveFees,
    });
  }, [
    toEditQuoteId,
    user.office_id,
    goToResume,
    applyQuote,
    calculateTotalsByCurrency,
    calculateConvertedValue,
  ]);

  useEffect(() => {
    api
      .get<OfficeCurrency[]>('/officecurrency', {
        params: {
          // change to IE id
          start: true,
          office_id: isPlugAndPlay ? 2867 : user.office_id,
        },
      })
      .then(response => {
        const currencies = response.data;

        currencies.unshift({
          destiny_code: user.office.currency.code,
          value: 1,
          currency: user.office.currency,
        });

        setOfficeCurrencies(currencies);
      });
  }, [user.office_id, user.office.currency, isPlugAndPlay, toEditQuoteId]);

  useEffect(() => {
    api.get<Office>(`/office/${user.office_id}`).then(response => {
      const office = response.data;
      setDefaultDescription(office.description);
    });
  }, [user.office_id]);

  useEffect(() => {
    if (toEditQuoteId && firstRender) {
      getQuote();
      setFirstRender(false);
    }
  }, [toEditQuoteId, firstRender, getQuote]);

  useEffect(() => {
    if (!studentId) return;

    setLoading(true);
    api
      .get(`/student/${studentId}`, {
        params: {
          with: JSON.stringify(['nationality']),
        },
      })
      .then(response => {
        formRef.current?.setFieldValue('student', {
          value: response.data,
          label: (
            <div className="option-with-image">
              <img
                src={`${process.env.REACT_APP_NG_URL}/images/flags/${response.data.nationality.flag}`}
                className="flag"
                alt="Flag"
              />
              {response.data.fullName}
            </div>
          ),
        });
        formRef.current?.submitForm();
      });
  }, [studentId]);

  return (
    <Container>
      <Loading />
      {/* {toEditQuoteId && isFromMyOffice && <Loading />} */}

      {toEditQuoteId && !isFromMyOffice && (
        <div style={{ textAlign: 'center' }}>
          <h1>
            This quote is from another office,
            <br />
            you can&apos;t edit it
          </h1>
        </div>
      )}

      {!toEditQuoteId && (
        <Box style={{ display: 'none' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: 10,
              gap: '0.8rem',
            }}
          >
            <img src={shuttleSvg} alt="Rocket going up" width="24" />
            <Breadcrumb
              options={breadcrumbOptions}
              className="breadcrumb-container"
            />
          </div>

          <h2>Let&apos;s start your quotes</h2>

          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            style={{ display: 'none' }}
          >
            <div className="form-group">
              <label htmlFor="student">Student: *</label>
              <AsyncSelect
                name="student"
                cacheOptions
                defaultOptions
                placeholder="Select a student"
                // loadOptions={loadStudents}
                className="input-lg"
              />
            </div>

            <div className="form-group">
              <label htmlFor="howManyQuotes">
                How many quotes are you going to create? *
                <Tooltip title="Don't worry, you can add or remove how many options you want later">
                  <FiAlertCircle size={16} />
                </Tooltip>
              </label>
              <Input
                value="1"
                readOnly
                type="number"
                name="howManyQuotes"
                id="howManyQuotes"
                placeholder="e.g.: 3"
                className="input-lg"
              />
            </div>

            <Button
              type="submit"
              className="btn-lg"
              loading={loading}
              loadingWidth="19px"
            >
              Go to Quote Playground
            </Button>
          </Form>
        </Box>
      )}

      <ReactTooltip effect="solid" />
    </Container>
  );
};

export default Start;
