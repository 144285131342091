import styled from 'styled-components';

export const Container = styled.div`
  width: 350px;
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--divider);
  padding: 15px;

  > span {
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
`;

export const ModalContent = styled.div`
  padding: 0px 15px;

  div {
    &.total {
      display: flex;
      justify-content: space-between;
      padding: 10px 0px;

      span {
        font-weight: 800;
      }
    }

    &.divider {
      display: flex;
      justify-content: space-between;
      padding: 10px 0px;
      border-bottom: 1px solid var(--divider);
    }
  }
`;

export const WarningContainer = styled.div`
  background: #fcf8e3;
  max-height: 400px;
  overflow: auto;
  padding: 15px;
  width: initial;
  margin-bottom: 10px;
  border: none;

  h3 {
    color: var(--primary);
  }
`;

export const Button = styled.button`
  background: var(--primary);
  border: 1px solid var(--primary);
  padding: 7px 16px 7px 16px;
  width: 100%;
  font-weight: 700;
  color: var(--white);
  transition: background 0.15s ease-in-out;

  &.btn-outline {
    background: transparent;
    color: var(--primary);
    margin-bottom: 10px;
    margin-left: 250px;
    width: 70px;

    &:hover {
      background: var(--outline-hover);
    }
  }

  &.btn-transparent {
    background: transparent;
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;

    svg {
      color: var(--light-gray);
    }

    &:hover {
      svg {
        color: var(--gray);
      }
    }
  }
`;

export const LoadingContainer = styled.div`
  height: 282px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
