import React, { useRef, useState, useEffect } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { useField } from '@unform/core';
import { FiAlertTriangle } from 'react-icons/fi';
import 'react-datepicker/dist/react-datepicker.css';

import ReactTooltip from 'react-tooltip';
import { Container } from './styles';
import Tooltip from '../Tooltip';

interface Props extends Omit<ReactDatePickerProps, 'onChange'> {
  name: string;
  containerStyle?: object;
}

const DatePicker: React.FC<Props> = ({
  name,
  containerStyle = {},
  ...rest
}) => {
  const datepickerRef = useRef<ReactDatePicker>(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [date, setDate] = useState(defaultValue || null);
  const [dateInfo, setDateInfo] = useState();

  useEffect(() => {
    return registerField({
      name: fieldName,
      ref: datepickerRef.current,
      path: 'props.selected',
      clearValue: (ref: any) => {
        ref.clear();
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container
      style={containerStyle}
      dateInfoValidation={dateInfo}
      isErrored={!!error}
      data-testid="datepicker-container"
    >
      <ReactDatePicker
        ref={datepickerRef}
        selected={date}
        dateFormat="dd/MM/yyyy"
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        onChange={setDate}
        {...rest}
      />

      {error && (
        <Tooltip title={error}>
          <FiAlertTriangle color="#c53030" size={20} />
        </Tooltip>
      )}
    </Container>
  );
};

export default DatePicker;
