import { format, parseJSON } from 'date-fns';
import { feeType, feeReturnedType } from '../../shared/interfaces';
import formatValue from '../../utils/formatValue';
import { filtersOptions } from '../../shared/constants';

import { WizardFormData } from '../../hooks/wizard';

/** *
 *  Methods to handle fees
 */

export type FeesType = {
  mandatoryFees: feeType[];
  suggestiveFees: feeType[];
};

const formatingMandatoryAndSuggestiveFees = (
  _dateOfReference: Date,
  updatedMandatoryFees: feeType[],
  _quotesData: WizardFormData,
  id: number,
  filterType: string,
  quotesIds: string[]
): FeesType => {
  const mandatoryFees: feeType[] = [];
  const suggestiveFees: feeType[] = [];

  updatedMandatoryFees.forEach((fee: any) => {
    fee.valueFormatted = formatValue(fee.totalThisFee.value, fee.currency_code);
    fee.oldValueFormatted = fee.totalThisFee.oldValue
      ? formatValue(fee.totalThisFee.oldValue, fee.currency_code)
      : undefined;
    fee.subtotalFormatted = formatValue(
      fee.totalThisFee.value,
      fee.currency_code
    );
    fee.loadedRealValues = true;
    fee.campus = fee.campus?.campusname;

    fee.enrolDate = format(parseJSON(_dateOfReference), 'dd/MM/yyyy');

    if (fee.mandatory === 1) {
      const hasMandatory = _quotesData.mandatoryFees?.find(
        (mandatoryFee: feeType) => mandatoryFee.id === fee.id
      );
      fee.mandatoryFromSchool = true;
      fee.mandatoryCourseCampus_id =
        filterType === filtersOptions.FILTERS_COURSES ? id : undefined;
      fee.mandatoryAccommodation_id =
        filterType === filtersOptions.FILTERS_ACCOMMODATIONS ? id : undefined;

      mandatoryFees.push(fee);

      if (!hasMandatory) {
        _quotesData.mandatoryFees?.push(fee);
      } else {
        _quotesData.mandatoryFees?.forEach((mandFee: any) => {
          mandFee.mandatoryCourseCampus_id =
            filterType === filtersOptions.FILTERS_COURSES ? id : undefined;
          mandFee.mandatoryAccommodation_id =
            filterType === filtersOptions.FILTERS_ACCOMMODATIONS
              ? id
              : undefined;
        });
      }
    } else if (fee.mandatory === 2) {
      _quotesData.suggestiveFees?.push(fee);
      suggestiveFees.push(fee);
    }
  });

  return { suggestiveFees, mandatoryFees };
};

const updateOneFee = (fee: feeType, dataReceived: feeReturnedType): feeType => {
  const newFee = { ...fee };
  if (newFee.id === dataReceived.item?.id) {
    newFee.duration = dataReceived.item.duration;
    newFee.price = dataReceived.item.price;

    newFee.totalThisFee = dataReceived.item.totalThisFee;

    newFee.discountFee = 0;
    newFee.editedFee = 0;
    newFee.valueFormatted = formatValue(
      dataReceived.item.totalThisFee.value,
      dataReceived.item.currency_code
    );
    newFee.subtotalFormatted = formatValue(
      dataReceived.item.totalThisFee.value,
      dataReceived.item.currency_code
    );
  }
  return newFee;
};

export { updateOneFee, formatingMandatoryAndSuggestiveFees };
