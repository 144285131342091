import React, { useState } from 'react';
import ReactTooltip, { TooltipProps as ReactTooltipProps } from 'react-tooltip';

interface TooltipProps extends ReactTooltipProps {
  title?: string;
  children: React.ReactNode;
  position?: string;
}

const Tooltip: React.FC<TooltipProps> = ({
  title,
  children,
  place,
  ...rest
}) => {
  const [randomId, setRandomId] = useState(String(Math.random()));

  return (
    <>
      <div data-tip data-for={randomId}>
        {children}
      </div>
      {title && (
        <ReactTooltip id={randomId} effect="solid" delayShow={150} {...rest}>
          {title}
        </ReactTooltip>
      )}
    </>
  );
};

export default Tooltip;
