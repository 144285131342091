import styled from 'styled-components';

export const Container = styled.button`
  background: var(--primary);
  border: 1px solid var(--primary);
  padding: 7px 16px 7px 16px;
  width: 100%;
  font-weight: 700;
  color: var(--white);
  transition: background 0.15s ease-in-out;

  &:hover:not(:disabled) {
    background: var(--primary-hover);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &.btn-lg {
    padding: 14px 16px;
  }

  &.ppbutton {
    color: #4d4c4c !important;
    border: 1px solid #4d4c4c !important;
  }

  &.btn-black {
    background: #4d4c4c;
    border: 1px solid #4d4c4c;

    &:hover {
      background: #333;
    }
  }

  &.btn-green {
    background: var(--green);
    border: 1px solid var(--green);

    &:hover {
      background: var(--green-hover);
    }
  }

  &.btn-orange {
    background: var(--orange);
    border: 1px solid var(--orange);

    &:hover {
      background: var(--orange-hover);
    }
  }

  &.btn-white {
    background: white !important;
  }

  &.btn-outline {
    background: transparent;
    color: var(--primary);

    &.add-option-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid var(--bs-gray-200);
      color: var(--bs-gray-600);
      background-color: var(--bs-gray-100);
      margin: 1rem 0;
    }

    &.btn-outline--gray {
      border: 1px solid var(--divider);
    }

    &.toggleTokenOn {
      display: inline-block;
      width: max-content;
      background: var(--outline-hover);
    }

    &.toggleTokenOff {
      color: gray;
      display: inline-block;
      width: max-content;
    }

    &:hover {
      background: var(--outline-hover);
    }
  }

  &.btn-icon,
  &.btn-dropdown {
    background: var(--white);
    border-color: var(--light-gray);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 39px;
    height: 39px;

    svg {
      color: var(--secondary);
    }

    &:hover {
      background: var(--white-hover);
    }

    &.primary {
      border-color: var(--primary);
      > svg {
        color: var(--primary);
      }
    }
  }

  &.btn-transparent {
    background: transparent;
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;

    svg {
      color: var(--light-gray);
    }

    &:hover {
      svg {
        color: var(--gray);
      }
    }
  }

  &.btn-link {
    border: none;
    background: initial;
    color: var(--primary);
    padding: 0 0.5rem;

    &:hover {
      background: initial;
      text-decoration: underline;
    }
  }

  &[class*='btn-'].icon {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    padding: 8px 12px;
  }
  &[class*='btn-'].opt {
    display: flex;
    align-items: center;
    padding: 12px 9px;
    font-size: 1.1rem;
    line-height: 1.1rem;
  }
`;
