import React, { useEffect, useRef, InputHTMLAttributes } from 'react';
import { useField } from '@unform/core';

import { Container } from './styles';

interface Option {
  id: string;
  label: string;
}

interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  options: Option[];
}

const RadioInput: React.FC<RadioProps> = ({ name, options, ...rest }) => {
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const { fieldName, registerField, defaultValue = [] } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs: HTMLInputElement[]) => {
        const checked = refs.find(ref => ref.checked);

        return checked ? checked.value : '';
      },
      setValue: (refs: HTMLInputElement[], value: string) => {
        const item = refs.find(ref => ref.value === value);

        if (item) {
          item.checked = true;
        }
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container data-testid="radio-container">
      {options.map((option, index) => (
        <label key={option.id}>
          <input
            defaultChecked={defaultValue === option.id}
            ref={ref => {
              inputRefs.current[index] = ref as HTMLInputElement;
            }}
            name={fieldName}
            value={option.id}
            type="radio"
            id={option.id}
            {...rest}
          />
          <span className="checkmark" />
          <span className="radio-text">{option.label}</span>
        </label>
      ))}
    </Container>
  );
};

export default RadioInput;
