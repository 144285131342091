/* eslint-disable react/no-danger */
import React, { useMemo, useState } from 'react';

import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { Container, Title, Description, WarningTitle } from './styles';

interface IWarnings {
  futurePrice?: boolean;
  futureCourse?: boolean;
  children: {
    warning: {
      id: number;
      name: string;
      message: {
        text: string;
      };
    };
  }[];
}

const Warnings: React.FC<IWarnings> = ({
  children,
  futurePrice,
  futureCourse,
}) => {
  const [isCollapsibleOpen, setIsCollapsibleOpen] = useState<boolean>(false);

  let messageCollapsible = '[click for important information]';

  const handleClick = () => {
    setIsCollapsibleOpen(prevState => !prevState);
    messageCollapsible = isCollapsibleOpen
      ? '[click to hide information]'
      : '[click for important information]';
  };
  function sanitizeHtml(text: string): string {
    throw new Error('Function not implemented.');
  }

  return (
    <Container
      style={{
        background: '#fcf8e3',
        maxHeight: '400px',
        overflow: 'auto',
      }}
    >
      <WarningTitle>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            cursor: 'pointer',
          }}
          onClick={handleClick}
        >
          <b style={{ color: 'red' }}>Warning!</b>{' '}
          <small style={{ color: 'grey' }}>{messageCollapsible}</small>
          {isCollapsibleOpen && (
            <div style={{ color: 'grey' }}>
              <FaChevronUp size={14} />
            </div>
          )}
          {!isCollapsibleOpen && (
            <div style={{ color: 'grey' }}>
              <FaChevronDown size={14} />
            </div>
          )}
        </div>
      </WarningTitle>
      <div />
      {futurePrice && futureCourse && isCollapsibleOpen && (
        <span>
          This program has future values, please change the enrol date to acces
          them.
        </span>
      )}
      {!futurePrice && futureCourse && isCollapsibleOpen && (
        <span>
          <Title>Attention!</Title>
          These prices may not be applicable for next year program start dates.
          <br />
          Please, verify with the provider before formally proceed with
          enrolment.
        </span>
      )}
      {isCollapsibleOpen &&
        children.map(({ warning }) => (
          <div key={warning.id}>
            {children.length > 1 && <hr />}
            <Title>{warning.name}</Title>
            <div dangerouslySetInnerHTML={{ __html: warning.message.text }} />
            {children.length > 1 && <hr />}
          </div>
        ))}
    </Container>
  );
};

export default Warnings;
