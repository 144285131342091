import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  // height: 100vh;
`;

export const Box = styled.div`
  flex: 1;
  max-width: 598px;
  padding: 15px 113px 40px;

  > .breadcrumb-container {
    margin-bottom: 45px;
  }

  h2 {
    text-align: center;
    margin-bottom: 40px;
  }

  form {
    .form-group {
      label {
        display: flex;
        justify-content: space-between;
        font-size: 1.2rem;
        line-height: 1.6rem;
        margin-bottom: 10px;
      }

      & + .form-group {
        margin-top: 30px;
      }
    }

    button {
      margin-top: 30px;
    }
  }
`;
